import { createSelectOptions } from '@common/index';
import { WidgetType } from '@constants/commontypes';
import entries from 'lodash/entries';
import map from 'lodash/map';

import { getExportStatusMap, StaticFilterKey } from '../common';

const useFilterFields = ({ t }) => {
  return {
    static: {
      fields: [
        {
          name: StaticFilterKey.ID,
          placeholder: t('batch_request_id'),
          widget: WidgetType.INPUT,
        },
        {
          name: StaticFilterKey.STATUS,
          placeholder: t('file_status'),
          widget: WidgetType.SELECT,
          options: createSelectOptions(
            map(entries(getExportStatusMap({ t })), ([key, value]) => ({ key, value })),
            {
              valueKey: 'key',
              labelKey: 'value',
            }
          ),
        },
      ],
    },
    dynamic: {
      fields: [],
      isLoading: false,
    },
  };
};

export default useFilterFields;
