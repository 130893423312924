import { Form, Input, InputNumber, Switch } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

const EditableCell = ({
  editing,
  dataIndex,
  record,
  rules,
  children,
  inputProps,
  ...restProps
}) => {
  const { t } = useTranslation();
  const { type } = inputProps ?? {};
  let inputNode;

  switch (type) {
    case 'checkbox':
      inputNode = <Switch checkedChildren={t('active')} unCheckedChildren={t('passive')} />;
      break;
    case 'number':
      inputNode = <InputNumber {...inputProps} />;
      break;
    default:
      inputNode = <Input {...inputProps} />;
  }
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={rules}
          valuePropName={type === 'checkbox' ? 'checked' : 'value'}
          initialValue={record[dataIndex]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

export default EditableCell;
