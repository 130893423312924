import AkinonFilter from '@components/AkinonFilter';
import AkinonTable from '@components/AkinonTable';
import { Divider } from 'antd';
import { useTranslation } from 'react-i18next';

export function ItemList({ filterFields, columns, dataSource, loading, total, paginationProps }) {
  const { t } = useTranslation('Finance');

  return (
    <>
      <AkinonFilter title={t('filters')} filterFields={filterFields} total={total} />

      <Divider />

      <AkinonTable
        title={t('reconciliations')}
        columns={columns}
        dataSource={dataSource}
        loading={loading}
        total={total}
        rowKey="pk"
        {...paginationProps}
        description={`${total} ${t('results.found')}`}
      />
    </>
  );
}
