import { getActivePassiveOptions, WidgetType } from '@constants/commontypes';

import { StaticFilterKey } from '../common';

export const useStaticFilterFields = ({ t }) => {
  const initiallyVisibleFields = [
    {
      name: StaticFilterKey.NAME,
      placeholder: t('campaign_name'),
    },
    {
      name: StaticFilterKey.STATUS,
      widget: WidgetType.SELECT,
      options: getActivePassiveOptions({ t }),
      placeholder: t('status'),
    },
  ];
  const restFields = [
    {
      widget: WidgetType.DATE_PICKER,
      name: StaticFilterKey.FIRST_CREATED_DATE,
      placeholder: t('first.creation.date'),
    },
    {
      widget: WidgetType.DATE_PICKER,
      name: StaticFilterKey.LAST_CREATED_DATE,
      placeholder: t('last.creation.date'),
    },
    {
      widget: WidgetType.DATE_PICKER,
      name: StaticFilterKey.FIRST_EXPIRATION_DATE,
      placeholder: t('first_expiration_date'),
    },
    {
      widget: WidgetType.DATE_PICKER,
      name: StaticFilterKey.LAST_EXPIRATION_DATE,
      placeholder: t('last_expiration_date'),
    },
  ].map((field) => ({ ...field, isInitiallyVisible: false }));

  return {
    fields: initiallyVisibleFields.concat(restFields),
  };
};
