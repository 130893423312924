import { getStaticFilters } from '../common';

const useFilterFields = ({ t, registryOptions }) => {
  const staticFilters = getStaticFilters({ t, registryOptions });
  return {
    dynamic: { fields: [], isLoading: false },
    static: staticFilters,
  };
};

export default useFilterFields;
