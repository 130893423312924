import './styles.scss';

import { CloseOutlined } from '@ant-design/icons';
import AkinonCheckbox from '@components/AkinonCheckbox';
import { AkinonSearch } from '@components/AkinonInput';
import Drawer from '@components/Drawer';
import { Col, Form, Row, Typography } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

const { Title } = Typography;

const FilterOptionsDrawer = ({
  isFilterOptionsModalOpen,
  onModalCancel,
  search,
  onSearch,
  visibleFilterOptions,
  form,
}) => {
  const { t } = useTranslation();

  return (
    <Drawer
      open={isFilterOptionsModalOpen}
      closable={false}
      width={'100%'}
      className="filter-options-drawer"
    >
      <Row justify="space-between" className="filter-drawer-header">
        <Col>
          <Title className="filters-drawer__title">{t('filters')}</Title>
        </Col>
        <CloseOutlined className="filters-drawer__close-icon" onClick={onModalCancel} />
      </Row>
      <div className="filter-options__search-filter">
        <AkinonSearch
          defaultValue={search}
          onKeyUp={(e) => onSearch(e.target.value)}
          onBlur={(e) => onSearch(e.target.value)}
          style={{ width: 300 }}
          placeholder={t('search')}
          data-testid="search-filter"
          onSearch={onSearch}
        />
      </div>
      <Form data-testid="filter-options-modal-form" className="filter-options__modal-form">
        <Row>
          {visibleFilterOptions.map((optionField) => (
            <Col key={optionField.name} span={24}>
              <AkinonCheckbox
                formItemProps={{
                  control: form.control,
                  name: optionField.name,
                }}
                // eslint-disable-next-line react/no-children-prop
                children={optionField?.placeholder}
              />
            </Col>
          ))}
        </Row>
      </Form>
    </Drawer>
  );
};

export default FilterOptionsDrawer;
