import './styles.scss';

import { usePageFilters } from '@components/AkinonFilter/hooks/store/usePageFilters';
import AkinonTable from '@components/AkinonTable';
import usePagination from '@components/AkinonTable/hooks/usePagination';
import { defaultRowKey } from '@constants/index';
import { usePaginatedCancellationReasonsQuery } from '@services/api/hooks/usePaginatedCancellationReasonsQuery';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { getColumns } from './common';
import { useBreadcrumbs } from './hooks/useBreadcrumbs';
import { usePageMeta } from './hooks/usePageMeta';

const OrderRejectionDeductionList = () => {
  const { t } = useTranslation('Settings');
  const [pagination, setPagination] = usePagination();
  const filters = usePageFilters();

  useBreadcrumbs();
  usePageMeta();

  const { cancellationReasons, totalCancellationReasons, isFetchingCancellationReasons } =
    usePaginatedCancellationReasonsQuery({
      pagination,
      filters,
    });

  const columns = getColumns({ t });

  return (
    <section className="order-rejection-reasons">
      <AkinonTable
        rowKey={defaultRowKey}
        title={t('order_rejection_reasons')}
        dataSource={cancellationReasons}
        columns={columns}
        loading={isFetchingCancellationReasons}
        total={totalCancellationReasons || 0}
        pagination={pagination}
        setPagination={setPagination}
        description={`${totalCancellationReasons || 0} ${t('results.found')}`}
      />
    </section>
  );
};

export default OrderRejectionDeductionList;
