import { getAgreementDetailUrl } from '@constants/apiUrls';
import { QueryKey } from '@constants/query';

import { useAkinonQuery } from '../adapters/useAkinonQuery';
import { client } from '../client';

/**
 * @param {Object} [args]
 * @param {string} [args.agreementId]
 * @param {import('@tanstack/react-query').UseQueryOptions} [args.queryOptions]
 * @param {(data: any) => void} [args.onSuccess]
 */
export function useAgreementDetailQuery({ agreementId, queryOptions, onSuccess } = {}) {
  const { data, isFetching } = useAkinonQuery({
    queryKey: [QueryKey.AGREEMENT_DETAIL, agreementId],
    async queryFn() {
      const response = await client.get(getAgreementDetailUrl, {
        urlParams: { agreementId },
      });
      onSuccess?.(response);

      return response;
    },
    ...queryOptions,
  });

  return {
    agreementDetail: data,
    isAgreementDetailFetching: isFetching,
  };
}
