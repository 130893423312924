import * as z from 'zod';

export const FormKey = {
  NAME: 'name',
  PRIORITY: 'priority',
  LOCATION_CODE: 'code',
  COUNTRY: 'country',
  CITY: 'city',
  DISTRICT: 'district',
  TOWNSHIP: 'township',
  POSTAL_CODE: 'zip',
  LATITUDE: 'latitude',
  LONGITUDE: 'longitude',
  PHONE_NUMBER: 'phone',
  EMAIL: 'email',
  LINE: 'line',
  IS_ACTIVE: 'is_active',
};

export const getFormSchema = () => {
  return z.object({
    [FormKey.NAME]: z.string().min(1),
    [FormKey.PRIORITY]: z.number().int().positive(1).optional(),
    [FormKey.LOCATION_CODE]: z.string().min(1).max(16),
    [FormKey.LINE]: z.string().min(1),
    [FormKey.COUNTRY]: z.string().min(1),
    [FormKey.CITY]: z.string().min(1),
    [FormKey.DISTRICT]: z.string().min(1),
    [FormKey.TOWNSHIP]: z.string().min(1),
    [FormKey.POSTAL_CODE]: z.string().nullish().optional(),
    [FormKey.LATITUDE]: z.coerce.number().min(-90).max(90),
    [FormKey.LONGITUDE]: z.coerce.number().min(-180).max(180),
    [FormKey.PHONE_NUMBER]: z.coerce.string().nullish().optional(),
    [FormKey.EMAIL]: z.string().email().nullish().optional(),
    [FormKey.IS_ACTIVE]: z.boolean().nullish().optional(),
  });
};
