import { createSelectOptions } from '@common/index';
import AkinonButton from '@components/AkinonButton';
import AkinonCheckbox from '@components/AkinonCheckbox';
import AkinonInput from '@components/AkinonInput';
import AkinonSelect from '@components/AkinonSelect';
import Box from '@components/utility/box';
import { verticalFormItemLayout } from '@constants/layoutTypes';
import { TranslationKey } from '@root/i18n';
import useStore from '@zustand-store/index';
import { variantsFormDynamicMetaFieldsSelector } from '@zustand-store/selectors/preOffer';
import { Divider, Form, Modal, Space, Typography } from 'antd';
import { last, merge, omit, pick, range } from 'lodash';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ProductsToBeAddedTableKey } from '../../common';

const { Text } = Typography;

const BulkUpdatePricesModal = ({
  form: productSummaryForm,
  isBulkUpdatePriceModalVisible,
  setIsBulkUpdatePriceModalVisible,
  dataSource,
  productPricesOptions,
  isProductPricesOptionsLoading,
}) => {
  const form = useForm({
    defaultValues: {},
  });
  const { handleSubmit, reset, control } = form;
  const { t } = useTranslation(TranslationKey.PRODUCTS_AND_CATEGORIES);
  const [selectedRows, setSelectedRows] = useState(range(dataSource.length).map(() => false));
  const variantsKeys = useStore(variantsFormDynamicMetaFieldsSelector).map((metaField) =>
    last(metaField.key.split('.'))
  );
  const variantsPairs = dataSource.map((dataSourceItem) =>
    Object.values(pick(dataSourceItem, variantsKeys))
  );

  const currencyOptions = createSelectOptions(
    productPricesOptions?.actions?.POST.currency_type.choices,
    {
      valueKey: 'value',
      labelKey: 'display_name',
    }
  );

  const onClose = () => {
    reset();
    setIsBulkUpdatePriceModalVisible(false);
  };

  const onSubmit = (values) => {
    productSummaryForm.reset(merge(productSummaryForm.getValues(), omit(values, 'root')));
    onClose();
  };
  const handleApplyToAll = () => {
    const rootValues = form.getValues().root;
    dataSource.forEach((product) => {
      form.setValue(product.id, rootValues);
    });

    setSelectedRows(range(dataSource.length).map(() => true));
  };
  const handleApplyToSelectedOnes = () => {
    const rootValues = form.getValues().root;

    selectedRows.forEach((isSelected, index) => {
      if (!isSelected) return;

      const product = dataSource[index];
      form.setValue(product.id, rootValues);
    });
  };
  const handleCleanAllContent = () => {
    dataSource.forEach((product) => {
      form.setValue(product.id, {
        [ProductsToBeAddedTableKey.RETAIL_PRICE]: null,
        [ProductsToBeAddedTableKey.PRICE]: null,
        [ProductsToBeAddedTableKey.CURRENCY_TYPE]: null,
        [ProductsToBeAddedTableKey.TAX_RATE]: null,
      });
    });
  };

  return (
    <Modal
      className="form-modal"
      onCancel={onClose}
      open={isBulkUpdatePriceModalVisible}
      width={'75%'}
      destroyOnClose
      footer={null}
    >
      <Box className="box-primary form-box">
        <Form className="akn-form" layout="vertical" wrapperCol={verticalFormItemLayout.wrapperCol}>
          <div className="form-modal__header">
            <div className="form-modal__header-banner" />
            <div className="form-modal__header-title">{t('bulk.update.price')}</div>
          </div>
          <Divider className="form-modal__divider" />
          <div className="form-modal__description">{t('bulk.update.price.description')}</div>
          <Divider orientation="left" className="form-modal__divider">
            {t('update.price').toUpperCase()}
          </Divider>
          <div className="flex flex-1 gap-[25px]">
            <AkinonInput
              formItemProps={{
                control,
                name: ['root', ProductsToBeAddedTableKey.RETAIL_PRICE].join('.'),
                label: t('retail.price'),
                className: 'w-[256px] ml-[323px]',
              }}
              placeholder={t('retail.price')}
            />
            <AkinonInput
              formItemProps={{
                control,
                name: ['root', ProductsToBeAddedTableKey.PRICE].join('.'),
                label: t('selling.price'),
                className: 'w-[256px]',
              }}
              placeholder={t('selling.price')}
            />
            <AkinonSelect
              formItemProps={{
                control,
                name: ['root', ProductsToBeAddedTableKey.CURRENCY_TYPE].join('.'),
                label: t('Currency'),
                className: 'w-[256px]',
              }}
              placeholder={t('Currency')}
              options={currencyOptions}
              isLoading={isProductPricesOptionsLoading}
            />
            <AkinonInput
              formItemProps={{
                control,
                name: ['root', ProductsToBeAddedTableKey.TAX_RATE].join('.'),
                label: t('VAT'),
                className: 'w-[256px]',
              }}
              placeholder={t('VAT')}
            />
          </div>
          <Space direction="horizontal" className="form-modal__form-actions">
            <AkinonButton onClick={handleApplyToAll} htmlType="button" type="primary">
              {t('apply.to.all').toUpperCase()}
            </AkinonButton>
            <AkinonButton onClick={handleApplyToSelectedOnes} htmlType="button" type="primary">
              {t('apply.to.selected.ones').toUpperCase()}
            </AkinonButton>
          </Space>
          {dataSource.map((product, index) => {
            const handleCheckboxChange = (e) => {
              const newselectedRows = [...selectedRows];
              newselectedRows[index] = e.target.checked;
              setSelectedRows(newselectedRows);
            };

            return (
              <div className="form-modal-item" key={product?.id}>
                <div className="form-modal-item__wrapper">
                  <AkinonCheckbox
                    checked={selectedRows[index]}
                    className="form-modal-item__checkbox"
                    onChange={handleCheckboxChange}
                  />
                  <Text className="form-modal-item__variant singular-form-modal-item__variant">
                    {variantsPairs[index].join('/')}
                  </Text>
                  <AkinonInput
                    formItemProps={{
                      control,
                      name: [product.id, ProductsToBeAddedTableKey.RETAIL_PRICE].join('.'),
                      className: 'w-[256px]',
                    }}
                    placeholder={t('retail.price')}
                  />
                  <AkinonInput
                    formItemProps={{
                      control,
                      name: [product.id, ProductsToBeAddedTableKey.PRICE].join('.'),
                      className: 'w-[256px]',
                    }}
                    placeholder={t('selling.price')}
                  />
                  <AkinonSelect
                    formItemProps={{
                      control,
                      name: [product.id, ProductsToBeAddedTableKey.CURRENCY_TYPE].join('.'),
                      className: 'w-[256px]',
                    }}
                    placeholder={t('Currency')}
                    options={currencyOptions}
                    isLoading={isProductPricesOptionsLoading}
                  />
                  <AkinonInput
                    formItemProps={{
                      control,
                      name: [product.id, ProductsToBeAddedTableKey.TAX_RATE].join('.'),
                      className: 'w-[256px]',
                    }}
                    placeholder={t('VAT')}
                  />
                </div>
                <Divider className="form-modal-item__divider" />
              </div>
            );
          })}
          <Space>
            <AkinonButton type="primary" htmlType="submit" onClick={handleSubmit(onSubmit)}>
              {t('save').toUpperCase()}
            </AkinonButton>
            <AkinonButton onClick={handleCleanAllContent} type="danger" htmlType="button">
              {t('clean.all.content').toUpperCase()}
            </AkinonButton>
          </Space>
        </Form>
      </Box>
    </Modal>
  );
};

export default BulkUpdatePricesModal;
