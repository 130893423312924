import { useWatch } from 'react-hook-form';

export default function useOnModalCancel({ form, setIsFilterOptionsModalOpen, setVisibleFilters }) {
  const formValues = useWatch({ control: form.control });

  const onModalCancel = async () => {
    setIsFilterOptionsModalOpen(false);
    const selectedFilters = Object.entries(formValues)
      .filter(([_, value]) => value)
      .map(([key]) => key);
    setVisibleFilters(selectedFilters);
  };

  return onModalCancel;
}
