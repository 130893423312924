import { useDynamicSettingsRegistryQuery } from '@pages/Settings/DynamicSettings/hooks/api/useDynamicSettingsRegistryQuery';
import { find, get } from 'lodash';

import { useDynamicSettingsQuery } from './useDynamicSettingsQuery';

const useImageDimensionsDataSourceQuery = () => {
  const {
    dynamicSettings: maxImageDimensionSettings,
    isDynamicSettingsError: isMaxImageDimensionSettingsError,
  } = useDynamicSettingsQuery({
    params: {
      key: 'MAX_IMAGE_DIMENSIONS',
      is_active: true,
    },
  });
  const {
    dynamicSettings: minImageDimensionSettings,
    isDynamicSettingsError: isMinImageDimensionSettingsError,
  } = useDynamicSettingsQuery({
    params: {
      key: 'MIN_IMAGE_DIMENSIONS',
      is_active: true,
    },
  });

  const { dynamicSettingsRegistry } = useDynamicSettingsRegistryQuery({
    queryOptions: {
      enabled: isMaxImageDimensionSettingsError || isMinImageDimensionSettingsError,
    },
  });

  const maxImageDimensions = get(
    find(isMaxImageDimensionSettingsError ? dynamicSettingsRegistry : maxImageDimensionSettings, {
      key: 'MAX_IMAGE_DIMENSIONS',
    }),
    isMaxImageDimensionSettingsError ? 'default' : 'value'
  );

  const minImageDimensions = get(
    find(isMinImageDimensionSettingsError ? dynamicSettingsRegistry : minImageDimensionSettings, {
      key: 'MIN_IMAGE_DIMENSIONS',
    }),
    isMinImageDimensionSettingsError ? 'default' : 'value'
  );

  const maxWidth = get(maxImageDimensions, 'WIDTH', 2000);
  const maxHeight = get(maxImageDimensions, 'HEIGHT', 2000);
  const minWidth = get(minImageDimensions, 'WIDTH', 1);
  const minHeight = get(minImageDimensions, 'HEIGHT', 1);

  return {
    maxWidth,
    maxHeight,
    minWidth,
    minHeight,
    dimensionBounds: { maxWidth, maxHeight, minWidth, minHeight },
  };
};

export default useImageDimensionsDataSourceQuery;
