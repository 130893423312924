import clsx from 'clsx';
import React, { forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';

/**
 * @param {React.HTMLAttributes & {
 * children: React.ReactNode,
 * direction: 'row' | 'column' | 'row-reverse' | 'column-reverse',
 * wrap: 'wrap' | 'nowrap' | 'wrap-reverse',
 * justifyContent: 'center' | 'flex-start' | 'flex-end' | 'space-between' | 'space-around' | 'space-evenly',
 * alignItems: 'center' | 'flex-start' | 'flex-end' | 'baseline' | 'stretch',
 * className: string,
 * }} props
 * @returns {React.ReactElement}
 */
const AkinonFlex = (
  { children, direction = 'row', wrap, justifyContent, alignItems, className = '', ...rest },
  ref
) => {
  return (
    <div
      className={twMerge(
        clsx(
          'flex',
          {
            'flex-row': direction === 'row',
            'flex-col': direction === 'column',
            'flex-row-reverse': direction === 'row-reverse',
            'flex-col-reverse': direction === 'column-reverse',
            'justify-center': justifyContent === 'center',
            'justify-start': justifyContent === 'flex-start',
            'justify-end': justifyContent === 'flex-end',
            'justify-between': justifyContent === 'space-between',
            'justify-around': justifyContent === 'space-around',
            'justify-evenly': justifyContent === 'space-evenly',
            'items-center': alignItems === 'center',
            'items-start': alignItems === 'flex-start',
            'items-end': alignItems === 'flex-end',
            'items-baseline': alignItems === 'baseline',
            'items-stretch': alignItems === 'stretch',
            'flex-wrap': wrap === 'wrap',
            'flex-nowrap': wrap === 'nowrap',
            'flex-wrap-reverse': wrap === 'wrap-reverse',
          },
          className
        )
      )}
      ref={ref}
      {...rest}
    >
      {children}
    </div>
  );
};

export default forwardRef(AkinonFlex);
