import { useStaticFilterFields } from './useStaticFilterFields';

const useFilterFields = ({ t }) => {
  const staticFilters = useStaticFilterFields({ t });

  return {
    dynamic: { fields: [], isLoading: false },
    static: staticFilters,
  };
};

export default useFilterFields;
