import { patchCancellationReasonUrl } from '@constants/apiUrls';
import { useAkinonMutation } from '@services/api/adapters/useAkinonMutation';

import { client } from '../client';

export function usePatchCancellationReasonMutation({ mutationOptions } = {}) {
  const { mutate, isLoading } = useAkinonMutation({
    mutationFn: ({ requestBody, requestConfig, cancellationReasonId }) =>
      client.patch(patchCancellationReasonUrl, requestBody, {
        ...requestConfig,
        urlParams: {
          cancellationReasonId,
        },
      }),
    ...mutationOptions,
  });

  return {
    updateCancellationReason: mutate,
    isCancellationReasonUpdating: isLoading,
  };
}
