import { deleteRestrictedProductsUrl } from '@constants/apiUrls';
import { useAkinonMutation } from '@services/api/adapters/useAkinonMutation';

import { client } from '../client';

export function useDeleteProductRestrictionMutation(mutationOptions = {}) {
  const { mutateAsync, isLoading } = useAkinonMutation({
    mutationFn: ({ restrictionId }) =>
      client.delete(deleteRestrictedProductsUrl, {
        urlParams: {
          restrictionId,
        },
      }),
    onSuccess() {
      mutationOptions.onSuccess?.();
    },
    ...mutationOptions,
  });

  return {
    deleteProductRestriction: mutateAsync,
    isDeletingProductRestriction: isLoading,
  };
}
