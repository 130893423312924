import { getDataSourcesUrl } from '@constants/apiUrls';
import { UserRole } from '@constants/auth';
import { WidgetType } from '@constants/commontypes';
import { QueryKey } from '@constants/query';
import { useUserRole } from '@utils/hooks/useUserRole';

import { StaticFilterKey } from '../common';

export function useStaticFilterFields({ t }) {
  const userRole = useUserRole();
  const isSuperUser = userRole === UserRole.SUPER_USER;

  const fields = [
    isSuperUser && {
      name: StaticFilterKey.SELLER,
      widget: WidgetType.REMOTE_SELECT,
      showSearch: true,
      labelInValue: true,
      queryProps: {
        remoteKey: QueryKey.DATA_SOURCES,
        remoteUrl: getDataSourcesUrl,
        params: {
          limit: 20,
          sort: 'name',
          is_active: true,
        },
        searchKey: 'name__icontains',
      },
      placeholder: t('seller'),
    },
    {
      widget: WidgetType.INPUT,
      name: StaticFilterKey.ORDER,
      placeholder: t('order.number'),
    },
  ].filter(Boolean);

  return {
    fields,
  };
}
