import { limitQuery } from '@constants/commontypes';
import {
  usePaginatedDataSourcesQuery,
  useProductImagesQuery,
  useProductsQuery,
} from '@services/api/hooks';
import { useAttributeValuesQuery } from '@services/api/hooks/useAttributeValuesQuery';
import { usePaginatedProductRevisionsQuery } from '@services/api/hooks/usePaginatedProductRevisionsQuery';
import { useProductRevisionsImagesQuery } from '@services/api/hooks/useProductRevisionsImagesQuery';
import { mapValues } from 'lodash';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import keyBy from 'lodash/keyBy';
import map from 'lodash/map';
import merge from 'lodash/merge';
import pick from 'lodash/pick';
import uniq from 'lodash/uniq';

import { DataIndex } from '../common';

const useDataSource = ({ pagination, filters }) => {
  const {
    productRevisions,
    totalProductRevisions,
    isFetchingProductRevisions,
    refetchProductRevisions,
  } = usePaginatedProductRevisionsQuery({
    pagination,
    filters,
    params: {
      status__in: filters?.status__in ?? '1,2,3,4,5',
    },
  });

  const productIds = uniq(map(productRevisions, 'product'));

  const datasourceIds = uniq(productRevisions?.map((item) => item.datasource)).filter(Boolean);
  const { dataSources } = usePaginatedDataSourcesQuery({
    params: { id__in: datasourceIds.join(','), limit: datasourceIds?.length },
    queryOptions: {
      enabled: !isFetchingProductRevisions && !isEmpty(datasourceIds),
    },
  });
  const { productRevisionImages } = useProductRevisionsImagesQuery({
    params: {
      id__in: productIds?.join(','),
    },
  });
  const { productImages } = useProductImagesQuery({
    params: {
      parent__in: productIds?.join(','),
      ...limitQuery,
    },
  });

  const { products } = useProductsQuery({
    params: {
      limit: productIds?.length,
      id__in: productIds?.join(','),
    },
  });

  const { attributeValues } = useAttributeValuesQuery({});

  const productsMap = keyBy(products, 'id');
  const dataSource = productRevisions?.map?.((productRevision) => {
    const product = productsMap[productRevision.product];
    const revisionImages = productRevisionImages?.filter(
      (image) => image.parent === productRevision.id && !isNil(image.image)
    );
    const images = productImages?.filter(
      (image) => image.parent === product?.id && !isNil(image.image)
    );

    const mapAttributeValues = (value) =>
      attributeValues?.find((attr) => attr.value === value)?.label ?? value;
    const valueMappedProductAttributes = mapValues(product?.attributes, mapAttributeValues);
    productRevision.attributes = mapValues(productRevision.attributes, mapAttributeValues);

    const datasource = dataSources?.find((item) => item.id === productRevision.datasource);
    if (datasource) {
      productRevision[DataIndex.DATASOURCE_NAME] = datasource.name;
    }
    if (product) {
      productRevision[DataIndex.CURRENT_ATTRIBUTES] = merge(
        valueMappedProductAttributes,
        pick(product, 'name')
      );
      productRevision.product_detail = product;
    }
    if (revisionImages?.length > 0) {
      productRevision.revisionImages = revisionImages;
    }
    if (images?.length > 0) {
      productRevision.images = images;
    }

    return productRevision;
  });

  return {
    dataSource,
    isFetchingProductRevisions,
    totalProductRevisions,
    refetchProductRevisions,
  };
};

export default useDataSource;
