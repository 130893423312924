import './styles.scss';

import { useIsMobile } from '@hooks/useIsMobile';
import usePageData from '@hooks/usePageData';
import { Space } from 'antd';
import { pick } from 'lodash';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useEffectOnce } from 'react-use';

import FilterActions from '../FilterActions';
import FilterOptionsDrawer from '../FilterOptionsDrawer';
import FilterOptionsModal from '../FilterOptionsModal';
import useOnModalCancel from './hooks/useOnModalCancel';

const FilterOptions = ({
  showFileImport,
  isDynamicFiltersLoading,
  isFilterOptionsModalOpen,
  setIsFilterOptionsModalOpen,
  filterFields,
  setVisibleFilters,
  filterForm,
  filterActions,
}) => {
  const { pageData } = usePageData();
  const [search, setSearch] = useState(null);
  const isMobile = useIsMobile();
  const form = useForm();
  const { reset } = form;
  const onModalCancel = useOnModalCancel({
    form,
    setIsFilterOptionsModalOpen,
    setVisibleFilters,
  });

  const visibleFilterOptions = filterFields
    .map((field) => pick(field, ['name', 'placeholder']))
    .filter((field) =>
      search ? field?.placeholder?.toLowerCase?.().includes(search.toLowerCase()) : field
    );

  const handleOpenModalIconClick = () => {
    setIsFilterOptionsModalOpen(true);
  };

  const addFilterFile = (selectFile) => {
    filterForm.setValue('filter_file', selectFile.file);
  };

  const onSearch = (val) => {
    setSearch(val);
  };

  useEffectOnce(() => {
    if (!pageData?.visibleFilters) {
      const initialValues = filterFields
        .filter((field) => field?.isInitiallyVisible)
        .reduce((acc, field) => {
          acc[field.name] = true;
          return acc;
        }, {});
      reset(initialValues);
    } else {
      const initialValues = pageData?.visibleFilters.reduce((acc, filter) => {
        acc[filter] = true;
        return acc;
      }, {});
      reset(initialValues);
    }
  });

  return (
    <Space className="filter-options">
      <FilterActions
        showFileImport={showFileImport}
        filterActions={filterActions}
        addFilterFile={addFilterFile}
        isDynamicFiltersLoading={isDynamicFiltersLoading}
        handleOpenModalIconClick={handleOpenModalIconClick}
      />
      {isMobile ? (
        <FilterOptionsDrawer
          isFilterOptionsModalOpen={isFilterOptionsModalOpen}
          onModalCancel={onModalCancel}
          search={search}
          onSearch={onSearch}
          form={form}
          visibleFilterOptions={visibleFilterOptions}
        />
      ) : (
        <FilterOptionsModal
          isFilterOptionsModalOpen={isFilterOptionsModalOpen}
          onModalCancel={onModalCancel}
          search={search}
          onSearch={onSearch}
          form={form}
          visibleFilterOptions={visibleFilterOptions}
        />
      )}
    </Space>
  );
};

export default FilterOptions;
