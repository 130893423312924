const useOnSubmit = ({ collectionId, createCollection, putCollection, t }) => {
  const requestConfig = {
    successMessage: t('transaction_success'),
    successDescription: t('collection_save_message.success'),
    errorMessage: t('transaction_failed'),
    errorDescription: t('collection_save_message.error'),
  };
  return (formValues) => {
    if (collectionId) {
      putCollection({ collectionId, requestBody: formValues, requestConfig });
    } else {
      createCollection({ requestBody: formValues, requestConfig });
    }
  };
};

export default useOnSubmit;
