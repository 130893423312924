import { createSelectOptions } from '@common/index';
import AkinonButton from '@components/AkinonButton';
import AkinonForm from '@components/AkinonForm';
import AkinonFormItem from '@components/AkinonFormItem';
import AkinonSelect from '@components/AkinonSelect';
import { limitQuery } from '@constants/commontypes';
import { zodResolver } from '@hookform/resolvers/zod';
import { TranslationKey } from '@root/i18n';
import { usePatchPackageMutation } from '@services/api/hooks';
import { useGetLocationsQuery } from '@services/api/hooks/useGetLocationsQuery';
import { Modal } from 'antd';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import * as z from 'zod';

const LocationModalFormKey = {
  LOCATION: 'transfer_location',
};

const resolver = zodResolver(z.object({ [LocationModalFormKey.LOCATION]: z.string().min(1) }));

const ChangeLocationModal = ({ onCancel, isVisible, setIsVisible }) => {
  const { t } = useTranslation(TranslationKey.ORDERS);
  const { packageId } = useParams();
  const { updatePackagePartially, isUpdatingPackagePartially } = usePatchPackageMutation();
  const { locations, isFetchingLocations } = useGetLocationsQuery({
    params: {
      ...limitQuery,
      is_active: true,
    },
    queryOptions: {
      enabled: isVisible,
    },
  });
  const { control, reset, handleSubmit } = useForm({
    resolver,
  });
  const activeLocationSelectOptions = createSelectOptions(locations, {
    valueKey: 'id',
    labelKey: 'name',
  });

  const onSubmit = (formValues) => {
    updatePackagePartially(
      {
        requestBody: formValues,
        packageId,
        requestConfig: {
          successMessage: t('success'),
          errorMessage: t('error'),
          successDescription: t('change_location_success_message'),
          errorDescription: t('change_location_error_message'),
        },
      },
      {
        onSettled() {
          onReset();
        },
      }
    );
  };

  const onReset = () => {
    reset();
    setIsVisible(false);
  };

  return (
    <Modal
      destroyOnClose
      className="package-detail__action-form box-primary form-box"
      open={isVisible}
      onCancel={() => {
        onReset();
        onCancel();
      }}
      centered
      title={t('change_location')}
      footer={null}
    >
      <AkinonForm layout="vertical">
        <AkinonSelect
          formItemProps={{
            name: LocationModalFormKey.LOCATION,
            label: t('location'),
            control: control,
            required: true,
          }}
          placeholder={t('location')}
          loading={isFetchingLocations}
          options={activeLocationSelectOptions}
          showSearch
          optionFilterProp="label"
        />
        <AkinonFormItem wrapperCol={{ span: 10, offset: 14 }}>
          <AkinonButton
            loading={isUpdatingPackagePartially}
            className="footer-button"
            type="primary"
            onClick={handleSubmit(onSubmit)}
          >
            {t('save').toUpperCase()}
          </AkinonButton>
        </AkinonFormItem>
      </AkinonForm>
    </Modal>
  );
};

export default ChangeLocationModal;
