import { useStaticFilterFields } from './useStaticFilterFields';

const useFilterFields = ({ userRole }) => {
  const staticFields = useStaticFilterFields({ userRole });

  return {
    dynamic: { fields: [], isLoading: false },
    static: staticFields,
  };
};

export default useFilterFields;
