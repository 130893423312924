import './style.scss';

import { DeleteOutlined, EyeOutlined, FilePdfTwoTone } from '@ant-design/icons';
import Anchor from '@components/Anchor';
import Intrinsic from '@components/Intrinsic';
import { T } from '@utils/languageProvider';
import { Image, Modal, Upload } from 'antd';
import React, { Component } from 'react';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';

const { Dragger } = Upload;

class FileInput extends Component {
  onInputImported = (file, fileList) => {
    const { onChange, singleAction } = this.props;
    if (onChange) onChange(singleAction ? fileList[fileList.length - 1] : fileList);
    return false;
  };

  render() {
    const {
      accept,
      disabled,
      listType,
      multiple,
      showUploadList,
      onPreview,
      onRemove,
      defaultFileList,
      children,
    } = this.props;
    return (
      <Upload
        beforeUpload={this.onInputImported}
        accept={accept}
        disabled={disabled}
        listType={listType}
        defaultFileList={defaultFileList}
        multiple={multiple}
        showUploadList={showUploadList}
        onPreview={onPreview}
        onRemove={onRemove}
      >
        {children}
      </Upload>
    );
  }
}

export default FileInput;

const SortableItem = SortableElement(({ value }) => (
  <li tabIndex={0} className="file-drag-item">
    {value}
  </li>
));

const SortableList = SortableContainer(({ items = [] }) => {
  return (
    <ul className="file-drag-wrapper">
      {items.map((value, index) => (
        <SortableItem key={`item-${index}`} index={index} value={value} />
      ))}
    </ul>
  );
});

export class DraggerFileInput extends Component {
  state = {
    modalVisible: false,
    preview: null,
  };

  closeModal = () => {
    this.setState(
      {
        modalVisible: false,
      },
      () => this.setState({ preview: null })
    );
  };

  closePreview = () => {
    this.setState({
      preview: null,
    });
  };

  onPreviewModal = (preview) => {
    this.setState({
      modalVisible: true,
      preview,
    });
  };

  onSortable = (moverInfo) => {
    const { onFileSortable } = this.props;
    return onFileSortable && onFileSortable(moverInfo);
  };

  itemList = (fileList = [], type) => {
    const { onRemove, onPreview } = this.props;
    const files = fileList?.length
      ? fileList.map((fileItem, index) => {
          const url = fileItem?.thumbUrl || fileItem?.url;
          return (
            <div className="file-item image-item" key={index}>
              {type === 'video' ? (
                <video key={url}>
                  <source src={url} />
                </video>
              ) : (
                <Image src={url} />
              )}
              <span className="image-item-action">
                <EyeOutlined
                  onClick={() => {
                    onPreview ? onPreview(fileItem) : this.onPreviewModal(fileItem);
                  }}
                />
                {onRemove && <DeleteOutlined onClick={() => onRemove(fileItem)} />}
              </span>
            </div>
          );
        })
      : [];
    return files;
  };

  imagesRenderer = (fileList = [], type) => {
    const fileItem = this.itemList(fileList, type);
    const lastFileIndex = fileItem?.length - 1;
    if (fileList?.length > 0) {
      return (
        <>
          {fileItem[lastFileIndex]}
          {fileList.length > 1 && (
            <div className="file-item remaining-images" onClick={() => this.onPreviewModal(null)}>
              {`+ ${fileList.length - 1}`}
            </div>
          )}
        </>
      );
    }
  };

  render() {
    const { children, fileList, type, fileUrl, previewImagesSlice = [], ...restProps } = this.props;
    const { modalVisible, preview } = this.state;
    const url = preview && (preview.thumbUrl || preview.url);
    const item = this.itemList(fileList, type);

    const nullHandledFileList = fileList?.map((file) => ({ ...file, url: file?.url ?? '' }));

    return (
      <>
        <div className="dragger-wrapper">
          {type && type === 'file' && fileUrl ? (
            <div className="file-images-wrapper">
              <div className="file-item">
                <Anchor
                  className="file-icon"
                  title={T('view.file')}
                  href={fileUrl}
                  wrapHref={false}
                  target="_blank"
                >
                  <FilePdfTwoTone />
                </Anchor>
              </div>
            </div>
          ) : (
            <div className="file-images-wrapper">{this.imagesRenderer(fileList, type)}</div>
          )}

          <Dragger
            {...restProps}
            fileList={nullHandledFileList?.slice(...previewImagesSlice)}
            className="dragger-file-input"
          >
            {children || <span className="dragger-file-input-desc">{T('dragger.desc')} </span>}
          </Dragger>
          <Modal
            open={modalVisible}
            onCancel={this.closeModal}
            closable
            title={false}
            className="dragger-modal"
            centered
            footer={false}
          >
            <div className="file-images-wrapper">
              {preview ? (
                <div className="preview-wrapper">
                  {fileList.length > 1 && (
                    <Intrinsic className="icon-chevron_up" onClick={this.closePreview} />
                  )}
                  <div className="preview-image">
                    {type === 'video' ? (
                      <video controls>
                        <source src={url} />
                      </video>
                    ) : (
                      <Image src={url} />
                    )}
                  </div>
                </div>
              ) : (
                <SortableList items={item} axis="xy" distance={7} onSortEnd={this.onSortable} />
              )}
            </div>
          </Modal>
        </div>
      </>
    );
  }
}

export const DraggerInput = (props) => {
  const { children, fileList, ...restProps } = props;
  const nullHandledFileList = fileList?.map((file) => ({ ...file, url: file?.url ?? '' }));

  return (
    <div className="dragger-wrapper">
      <Dragger {...restProps} fileList={nullHandledFileList} className="dragger-file-input">
        {children || <span className="dragger-file-input-desc">{T('dragger.desc')} </span>}
      </Dragger>
    </div>
  );
};
