import AkinonButton from '@components/AkinonButton';
import AkinonDatePicker from '@components/AkinonDatePicker';
import clsx from 'clsx';
import moment from 'moment';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import CategoryBasedTable from './CategoryBasedTable';
import ProductBasedTable from './ProductBasedTable';

const InfoBox = ({ title, value }) => (
  <div className="flex flex-col bg-card-color p-3 justify-center items-center rounded-lg flex-1">
    <span className="text-spanish-gray">{title}</span>
    <span className="text-white text-[30px] font-semibold">{value}</span>
  </div>
);

const Period = {
  TODAY: 'today',
  THIS_WEEK: 'thisWeek',
  THIS_MONTH: 'thisMonth',
};

const SalesTab = {
  PRODUCT_BASED: 'productBased',
  CATEGORY_BASED: 'categoryBased',
};

export default function Sales() {
  const { t } = useTranslation('Reporting');
  const [startDate, setStartDate] = useState(moment());
  const [endDate, setEndDate] = useState(moment());
  const [period, setPeriod] = useState('today');
  const [activeTab, setActiveTab] = useState(SalesTab.PRODUCT_BASED);
  const numberOfOrders = 0;
  const totalOrderAmount = 0;
  const avarageBasketSize = 0;
  const numberOfBasketItems = 0;

  const handlePeriodChange = (period) => {
    setPeriod(period);
    switch (period) {
      case 'today':
        setStartDate(moment());
        setEndDate(moment());
        break;
      case 'thisWeek':
        setStartDate(moment().startOf('week'));
        setEndDate(moment().endOf('week'));
        break;
      case 'thisMonth':
        setStartDate(moment().startOf('month'));
        setEndDate(moment().endOf('month'));
        break;
      default:
        break;
    }
  };

  return (
    <div className="flex flex-col gap-3 w-full flex-wrap">
      <div className="flex items-center w-full border-b border-b-police-blue pb-2 justify-between">
        <span className="relative text-lg text-white after:join-[absolute,w-full,h-1,bg-info,-bottom-4,left-0,inline-flex] ">
          {t('sale_reports.stepper_sales_title')}
        </span>
        <div className="flex items-center gap-2">
          <div className="flex gap-2">
            <AkinonDatePicker value={startDate} onChange={(date) => setStartDate(date)} />
            <AkinonDatePicker value={endDate} onChange={(date) => setEndDate(date)} />
          </div>
          <div className="flex items-center gap-2">
            <AkinonButton
              type="text"
              className={clsx({
                '!border !border-solid !border-info': period === Period.TODAY,
              })}
              onClick={() => handlePeriodChange(Period.TODAY)}
            >
              {t('sale_reports.stepper_sales_filters_period_today')}
            </AkinonButton>
            <AkinonButton
              type="text"
              className={clsx({
                '!border !border-solid !border-info': period === Period.THIS_WEEK,
              })}
              onClick={() => handlePeriodChange(Period.THIS_WEEK)}
            >
              {t('sale_reports.stepper_sales_filters_period_this_week')}
            </AkinonButton>
            <AkinonButton
              type="text"
              className={clsx({
                '!border !border-solid !border-info': period === Period.THIS_MONTH,
              })}
              onClick={() => handlePeriodChange(Period.THIS_MONTH)}
            >
              {t('sale_reports.stepper_sales_filters_period_this_month')}
            </AkinonButton>
          </div>
        </div>
      </div>
      <div className="flex w-full gap-2">
        <InfoBox
          title={t('sale_reports.stepper_sales_info_number_of_orders').toUpperCase()}
          value={numberOfOrders}
        />

        <InfoBox
          title={t('sale_reports.stepper_sales_info_total_order_amount').toUpperCase()}
          value={totalOrderAmount}
        />

        <InfoBox
          title={t('sale_reports.stepper_sales_info_average_basket_size').toUpperCase()}
          value={avarageBasketSize}
        />

        <InfoBox
          title={t('sale_reports.stepper_sales_info_number_of_basket_items').toUpperCase()}
          value={numberOfBasketItems}
        />
      </div>

      <div className="flex w-full flex-wrap gap-2 border-b border-b-police-blue pb-3.5 mt-8">
        <AkinonButton
          className={clsx('text-[20px]', {
            'after:join-[absolute,w-full,h-1,bg-info,-bottom-4,left-0,inline-flex]':
              activeTab === SalesTab.PRODUCT_BASED,
          })}
          type="text"
          onClick={() => setActiveTab(SalesTab.PRODUCT_BASED)}
        >
          {t('sale_reports.stepper_sales_tabs_based_on_product_title')}
        </AkinonButton>
        <AkinonButton
          className={clsx('text-[20px]', {
            'after:join-[absolute,w-full,h-1,bg-info,-bottom-4,left-0,inline-flex]':
              activeTab === SalesTab.CATEGORY_BASED,
          })}
          type="text"
          onClick={() => setActiveTab(SalesTab.CATEGORY_BASED)}
        >
          {t('sale_reports.stepper_sales_tabs_based_on_category_title')}
        </AkinonButton>
      </div>
      {activeTab === SalesTab.PRODUCT_BASED && <ProductBasedTable />}
      {activeTab === SalesTab.CATEGORY_BASED && <CategoryBasedTable />}
    </div>
  );
}
