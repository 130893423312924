import './styles.scss';

import AkinonSelect from '@components/AkinonSelect';
import { Button, Select, Space } from 'antd';
import isEqual from 'lodash/isEqual';
import isFunction from 'lodash/isFunction';
import omit from 'lodash/omit';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { usePrevious } from 'react-use';

/**
 * @param {{ placeholder: string, tableActions: import('@components/AkinonTable/common').TableAction[] }} props
 * @returns
 */
const AkinonTableActions = ({ selectedRows, tableActions, placeholder, setSelectedRows }) => {
  const { t } = useTranslation();
  const [selectedActionIndex, setSelectedActionIndex] = React.useState(null);
  const prevTableActions = usePrevious(tableActions);
  const tableActionSelectOptions = useMemo(
    () =>
      tableActions.map((action, index) => ({
        ...omit(action, ['actionHandler']),
        value: index,
        disabled:
          (isFunction(action.isDisabled) ? action.isDisabled(selectedRows) : action.isDisabled) ||
          (action.isSelectionRequired && selectedRows.length === 0) ||
          (action.isSingleSelectionRequired && selectedRows.length !== 1),
      })),
    [tableActions, selectedRows]
  );
  const actionHandlers = tableActions.map((action) => action.actionHandler);

  const handleActionApply = () => {
    actionHandlers[selectedActionIndex]?.(selectedRows, setSelectedRows);
  };

  const handleActionSelectChange = (value) => {
    setSelectedActionIndex(value);
  };

  const isActionSelectDisabled = tableActionSelectOptions.length === 0;
  const isActionButtonDisabled =
    selectedActionIndex === null || tableActionSelectOptions.length === 0;

  useEffect(() => {
    if (!(selectedRows?.length > 0)) {
      setSelectedActionIndex(null);
    }
  }, [selectedRows]);

  useEffect(() => {
    if (
      !isEqual(
        prevTableActions?.map((action) => action.label),
        tableActions?.map((action) => action.label)
      )
    )
      setSelectedActionIndex(null);
  }, [tableActions]);

  return (
    <Space className="akinon-table-actions">
      <AkinonSelect
        value={selectedActionIndex}
        onChange={handleActionSelectChange}
        placeholder={placeholder}
        className="akinon-table-actions__select"
        disabled={isActionSelectDisabled}
        aria-label="table_action_select"
      >
        {tableActionSelectOptions.map((option) => (
          <Select.Option
            className={option.className}
            key={option.value}
            value={option.value}
            disabled={option.disabled}
          >
            {isFunction(option.label) ? option.label(selectedRows) : option.label}
          </Select.Option>
        ))}
      </AkinonSelect>
      <Button
        disabled={isActionButtonDisabled}
        className="akinon-table-actions__apply-button"
        onClick={handleActionApply}
      >
        {t('apply').toUpperCase()}
      </Button>
    </Space>
  );
};

export default AkinonTableActions;
