import React from 'react';

import ChangePasswordForm from './components/ChangePasswordForm';
import UserProfileForm from './components/UserProfileForm';
import { usePageMeta } from './hooks/usePageMeta';

const Profile = () => {
  usePageMeta();

  return (
    <>
      <UserProfileForm />
      <ChangePasswordForm />
    </>
  );
};

export default Profile;
