import { postRejectionReasonUrl } from '@constants/apiUrls';

import { useAkinonMutation } from '../adapters/useAkinonMutation';
import { client } from '../client';

export function useCreateOfferRejectReasonMutation({ mutationOptions } = {}) {
  const { mutate, isLoading } = useAkinonMutation({
    mutationFn: ({ requestBody, requestConfig }) =>
      client.post(postRejectionReasonUrl, requestBody, requestConfig),
    ...mutationOptions,
  });

  return {
    createOfferRejectReason: mutate,
    isCreatingOfferRejectReason: isLoading,
  };
}
