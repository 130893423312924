import { createSelectOptions } from '@common/index';
import Anchor from '@components/Anchor';
import { getDataSourcesUrl } from '@constants/apiUrls';
import { PackageStatus, WidgetType } from '@constants/commontypes';
import { defaultDateFormat, defaultDateTimeFormat } from '@constants/index';
import { QueryKey } from '@constants/query';
import { RouteUrls } from '@constants/routeUrls';
import i18n from '@root/i18n';
import { Tag, Tooltip } from 'antd';
import moment from 'moment';
import React from 'react';
import { generatePath } from 'react-router-dom';

export const StaticFilterKey = {
  ORDER_NUMBER: 'order__number',
  SELLER: 'datasource',
  SKU: 'sku',
  BASE_CODE: 'base_code',
  TRACKING_NUMBER: 'tracking_number',
  PACKAGE_NUMBER: 'number',
  UPDATED_AT: 'updated_at',
  ORDER_DATE_PLACED_GTE: 'order__date_placed__gte',
  ORDER_DATE_PLACED_LTE: 'order__date_placed__lte',
  SHIPMENT_DATE_GTE: 'shipment_date__gte',
  SHIPMENT_DATE_LTE: 'shipment_date__lte',
  PACKAGE_STATUS: 'status',
  ORDER_CANCEL_STATUS: 'order__cancel_status',
  DELIVERY_DATE_GTE: 'delivery_date__gte',
  DELIVERY_DATE_LTE: 'delivery_date__lte',
  SHIPPING_COMPANY: 'shipping_company',
  SHIPPING_METHOD: 'shipping_method',
  ORDER_SHIPPING: {
    ADDRESS_EMAIL: 'order__shipping_address__email',
  },
};

export const StaticFiltersNameKey = {
  COMMON: 'common',
  PACKAGE_LIST: 'package_list',
  PACKAGE_PREPARING: 'package_preparing',
  PACKAGE_SHIPPED: 'package_shipped',
};

export const DataIndex = {
  NUMBER: 'number',
  ORDER: 'order',
  UPDATED_AT: 'updated_at',
  STATUS: 'status',
  SHIPPING_METHOD: 'shippingMethod',
  SHIPPING_COMPANY: 'shippingCompany',
  TRACKING_NUMBER: 'tracking_number',
  SHIPPING_ADDRESS_EMAIL: ['order', 'shipping_address', 'email'],
  SELLER: ['order', 'datasource', 'name'],
};

export const OrderCancelStatuses = ({ t }) => [
  {
    label: t('waiting'),
    value: 'waiting',
  },
  {
    label: t('confirmation.waiting'),
    value: 'confirmation_waiting',
  },
  {
    label: t('confirmed'),
    value: 'confirmed',
  },
  {
    label: t('approved'),
    value: 'approved',
  },
  {
    label: t('rejected'),
    value: 'rejected',
  },
  {
    label: t('waiting.for.payment'),
    value: 'waiting_for_payment',
  },
  {
    label: t('manuel.refund.need'),
    value: 'manuel_refund_need',
  },
  {
    label: t('completed'),
    value: 'completed',
  },
];

export const PackageStatusOptions = ({ t }) => [
  {
    label: t('cancelled'),
    value: PackageStatus.CANCELED,
  },
  {
    label: t('preparing'),
    value: PackageStatus.PREPARING,
  },
  {
    label: t('ready_to_ship'),
    value: PackageStatus.READY,
  },
  {
    label: t('shipped'),
    value: PackageStatus.SHIPPED,
  },
  {
    label: t('delivered'),
    value: PackageStatus.DELIVERED,
  },
  {
    label: t('failed'),
    value: PackageStatus.FAILED,
  },
];

export const getPackagesStatusRenderMap = ({ t }) => {
  return {
    [PackageStatus.CANCELED]: {
      label: t('cancelled'),
      tagColor: 'red',
    },
    [PackageStatus.PREPARING]: {
      label: t('preparing'),
      tagColor: 'yellow',
    },
    [PackageStatus.READY]: {
      label: t('ready_to_ship'),
      tagColor: 'blue',
    },
    [PackageStatus.SHIPPED]: {
      label: t('shipped'),
      tagColor: 'purple',
    },
    [PackageStatus.DELIVERED]: {
      label: t('delivered'),
      tagColor: 'green',
    },
    [PackageStatus.FAILED]: {
      label: t('failed'),
      tagColor: 'volcano',
    },
  };
};

export const getStaticFilters = ({
  t,
  staticFilterNameKey,
  shippingCompanies = [],
  shippingMethodOptions = [],
  isSuperUser,
}) => {
  const shippingCompanyOptions = createSelectOptions(shippingCompanies, {
    labelKey: 'name',
    valueKey: 'name',
  });

  const fields = [
    ...[
      {
        widget: WidgetType.INPUT,
        name: StaticFilterKey.ORDER_NUMBER,
        placeholder: t('order.number'),
        isHighlightedField: true,
      },
      isSuperUser && {
        name: StaticFilterKey.SELLER,
        widget: WidgetType.REMOTE_SELECT,
        showSearch: true,
        labelInValue: true,
        queryProps: {
          remoteKey: QueryKey.DATA_SOURCES,
          remoteUrl: getDataSourcesUrl,
          params: {
            limit: 20,
            sort: 'name',
            is_active: true,
          },
          searchKey: 'name__icontains',
        },
        placeholder: t('seller'),
      },
      {
        widget: WidgetType.INPUT,
        name: StaticFilterKey.ORDER_SHIPPING.ADDRESS_EMAIL,
        placeholder: t('email'),
      },
      {
        widget: WidgetType.INPUT,
        name: StaticFilterKey.SKU,
        placeholder: t('sku'),
      },
      {
        widget: WidgetType.INPUT,
        name: StaticFilterKey.BASE_CODE,
        placeholder: t('base.code'),
      },
      StaticFiltersNameKey.PACKAGE_PREPARING !== staticFilterNameKey && {
        widget: WidgetType.INPUT,
        name: StaticFilterKey.TRACKING_NUMBER,
        placeholder: t('trackingNumber'),
      },
      {
        widget: WidgetType.INPUT,
        name: StaticFilterKey.PACKAGE_NUMBER,
        placeholder: t('packaging_no'),
      },
      {
        widget: WidgetType.DATE_PICKER,
        name: StaticFilterKey.UPDATED_AT,
        placeholder: t('last.modified.date'),
      },
      {
        widget: WidgetType.DATE_PICKER,
        name: StaticFilterKey.ORDER_DATE_PLACED_GTE,
        placeholder: t('first_order_date'),
      },
      {
        widget: WidgetType.DATE_PICKER,
        name: StaticFilterKey.ORDER_DATE_PLACED_LTE,
        placeholder: t('last_order_date'),
      },
      {
        widget: WidgetType.SELECT,
        name: StaticFilterKey.ORDER_CANCEL_STATUS,
        placeholder: t('order_cancel_status'),
        options: OrderCancelStatuses({ t }),
      },
      {
        widget: WidgetType.SELECT,
        name: StaticFilterKey.SHIPPING_COMPANY,
        placeholder: t('shipping.company'),
        showSearch: true,
        options: shippingCompanyOptions,
      },
      {
        widget: WidgetType.SELECT,
        name: StaticFilterKey.SHIPPING_METHOD,
        placeholder: t('shipping_method'),
        showSearch: true,
        options: shippingMethodOptions || [],
      },
    ],
    ...(StaticFiltersNameKey.PACKAGE_PREPARING !== staticFilterNameKey
      ? [
          {
            widget: WidgetType.DATE_PICKER,
            name: StaticFilterKey.SHIPMENT_DATE_GTE,
            placeholder: t('first_shipment_date'),
          },
          {
            widget: WidgetType.DATE_PICKER,
            name: StaticFilterKey.SHIPMENT_DATE_LTE,
            placeholder: t('last_shipment_date'),
          },
          {
            widget: WidgetType.DATE_PICKER,
            name: StaticFilterKey.DELIVERY_DATE_GTE,
            placeholder: t('first_delivery_date'),
          },
          {
            widget: WidgetType.DATE_PICKER,
            name: StaticFilterKey.DELIVERY_DATE_LTE,
            placeholder: t('last_delivery_date'),
          },
        ]
      : []),
    ...(StaticFiltersNameKey.PACKAGE_LIST === staticFilterNameKey
      ? [
          {
            widget: WidgetType.SELECT,
            name: StaticFilterKey.PACKAGE_STATUS,
            placeholder: t('packaging_status'),
            options: PackageStatusOptions({ t }),
          },
        ]
      : []),
  ].filter(Boolean);

  return {
    fields: fields,
  };
};

export const renderAsLink = (value, href) => <Anchor href={href}>{value}</Anchor>;

export const getColumns = ({ t, isSuperUser, staticFilterNameKey }) => {
  const packageStatusRenderMap = getPackagesStatusRenderMap({ t });

  return [
    {
      dataIndex: DataIndex.NUMBER,
      visibleOnFilter: [StaticFilterKey.PACKAGE_NUMBER],
      exportKey: StaticFilterKey.PACKAGE_NUMBER,
      title: t('packaging_no'),
      render: (_, record) =>
        renderAsLink(
          record.number,
          generatePath(RouteUrls.order.packages.packageDetail, {
            packageId: record.id,
          })
        ),
    },
    {
      dataIndex: DataIndex.ORDER,
      visibleOnFilter: [StaticFilterKey.ORDER_NUMBER],
      exportKey: StaticFilterKey.ORDER_NUMBER,
      title: t('order.number'),
      render: (_, record) =>
        renderAsLink(
          record.order?.number,
          generatePath(RouteUrls.order.orderDetail, {
            orderId: record.order?.id,
          })
        ),
    },
    isSuperUser && {
      dataIndex: DataIndex.SELLER,
      visibleOnFilter: [StaticFilterKey.SELLER],
      exportKey: StaticFilterKey.SELLER,
      exportTitle: t('seller'),
      title: t('seller').toLocaleUpperCase(i18n.language),
      render: (sellerName) => <Tooltip title={sellerName}>{sellerName}</Tooltip>,
    },
    {
      dataIndex: DataIndex.SHIPPING_ADDRESS_EMAIL,
      visibleOnFilter: [StaticFilterKey.ORDER_SHIPPING.ADDRESS_EMAIL],
      exportKey: StaticFilterKey.ORDER_SHIPPING.ADDRESS_EMAIL,
      exportTitle: t('email'),
      title: t('email').toLocaleUpperCase(i18n.language),
      render: (shippingAddressEmail) => (
        <Tooltip title={shippingAddressEmail}>{shippingAddressEmail}</Tooltip>
      ),
    },
    staticFilterNameKey === StaticFiltersNameKey.PACKAGE_SHIPPED && {
      dataIndex: DataIndex.TRACKING_NUMBER,
      visibleOnFilter: [StaticFilterKey.TRACKING_NUMBER],
      exportKey: StaticFilterKey.TRACKING_NUMBER,
      exportTitle: t('trackingNumber'),
      title: t('trackingNumber').toLocaleUpperCase(i18n.language),
      render: (trackingNumber) => <Tooltip title={trackingNumber}>{trackingNumber}</Tooltip>,
    },
    {
      dataIndex: DataIndex.UPDATED_AT,
      visibleOnFilter: [StaticFilterKey.UPDATED_AT],
      exportKey: StaticFilterKey.UPDATED_AT,
      exportTitle: t('last.modified.date'),
      title: t('last.modified.date'),
      render: (updatedAt) => moment(updatedAt).format(defaultDateFormat),
    },
    {
      dataIndex: DataIndex.ORDER,
      visibleOnFilter: [
        StaticFilterKey.ORDER_DATE_PLACED_GTE,
        StaticFilterKey.ORDER_DATE_PLACED_LTE,
      ],
      exportKey: 'order_date_placed',
      title: t('order.date'),
      render: (_, record) => moment(record.order?.date_placed).format(defaultDateTimeFormat),
    },
    {
      dataIndex: DataIndex.STATUS,
      visibleOnFilter: [StaticFilterKey.PACKAGE_STATUS],
      exportKey: StaticFilterKey.PACKAGE_STATUS,
      title: t('package_status'),
      render: (packageStatus) => {
        const { tagColor, label } = packageStatusRenderMap[packageStatus];
        return <Tag color={tagColor}>{label}</Tag>;
      },
    },
    {
      dataIndex: DataIndex.SHIPPING_METHOD,
      visibleOnFilter: [StaticFilterKey.SHIPPING_METHOD],
      exportKey: StaticFilterKey.SHIPPING_METHOD,
      title: t('shipping_option'),
      render: (_, record) => (
        <Tooltip title={record.shipping_option?.name}>{record.shipping_option?.name}</Tooltip>
      ),
    },
    {
      dataIndex: DataIndex.SHIPPING_COMPANY,
      visibleOnFilter: [StaticFilterKey.SHIPPING_COMPANY],
      exportKey: StaticFilterKey.SHIPPING_COMPANY,
      title: t('shipping.company'),
      render: (_, record) => (
        <Tooltip title={record.shipping_company?.name}>{record.shipping_company?.name}</Tooltip>
      ),
    },
  ];
};
