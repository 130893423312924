import { IconLicense } from '@tabler/icons-react';

import AgreementConsentList from '../AgreementConsentList';
import AgreementForm from './AgreementForm';

export const StepKey = {
  AGREEMENT_DETAILS: 'agreement_details',
  CONSENT_DETAILS: 'consent_details',
};

export const getStepItems = ({ t }) => {
  return [
    {
      title: t('agreement_detail'),
      content: <AgreementForm />,
      icon: <IconLicense />,
      key: StepKey.AGREEMENT_DETAILS,
    },
    {
      title: t('agreement_consent_step_title'),
      content: <AgreementConsentList />,
      icon: <IconLicense />,
      key: StepKey.CONSENT_DETAILS,
    },
  ];
};
