import { postNotificationAllMarkAsReadUrl } from '@constants/apiUrls';
import { useAkinonMutation } from '@services/api/adapters/useAkinonMutation';

import { client } from '../client';

/**
 * @param {{ mutationOptions: import('@tanstack/react-query').UseMutationOptions }} args
 */
export function useNotificationAllMarkAsReadMutation({ mutationOptions = {} } = {}) {
  const { mutate, isLoading } = useAkinonMutation({
    mutationFn: ({ requestBody }) => client.post(postNotificationAllMarkAsReadUrl, requestBody),
    ...mutationOptions,
  });

  return {
    postNotificationAllMarkAsRead: mutate,
    isPostingNotificationAllMarkAsRead: isLoading,
  };
}
