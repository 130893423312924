import { defaultDateFormat, defaultTimeFormat } from '@constants/index';
import { Tooltip } from 'antd';
import isEmpty from 'lodash/isEmpty';
import isPlainObject from 'lodash/isPlainObject';
import mapValues from 'lodash/mapValues';
import sortBy from 'lodash/sortBy';
import moment from 'moment';
import React from 'react';

export function isBase64(str) {
  if (str === '' || str.trim() === '') {
    return false;
  }

  try {
    return btoa(atob(str)) === str;
  } catch (err) {
    return false;
  }
}

export function hasHTMLTag(str) {
  if (str === '' || str.trim() === '') {
    return false;
  }

  return /<\/?[a-z][\s\S]*>/i.test(str);
}

export function isValidUrl(str, protocol) {
  const match =
    str.match(/^(?:(https?):\/\/)?(www\.)?([a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,})$/) || [];
  return !!match[3] && (!protocol || protocol === match[1]);
}

export function dateFormatter(date) {
  const _date = moment(date);
  return _date.isValid() ? _date.format(defaultDateFormat + ' ' + defaultTimeFormat) : '-';
}

export function cancellationTypeFormatter({ t, cancellation_type }) {
  return <label>{cancellation_type === 'cancel' ? t('cancellation') : t('return')}</label>;
}

export function statusRenderer(statusArr, cellData) {
  const status = statusArr.find((item) => item.value.toString() === cellData.toString());

  return status?.label ?? cellData;
}

export const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

export async function fileBase64(file) {
  if (file) {
    const { name, uid } = file;
    const result = await getBase64(file);
    return {
      name,
      uid,
      status: 'done',
      thumbUrl: result,
    };
  }
}

export function latentNameRenderer(cellData) {
  return <Tooltip title={cellData}>{cellData}</Tooltip>;
}

export function statusActivePassiveFormatter({ t, cellData }) {
  return <label className={cellData ? 'green' : 'red'}>{t(cellData ? 'active' : 'passive')}</label>;
}

// source: https://stackoverflow.com/a/30810322
function fallbackCopyTextToClipboard(text) {
  let textArea = document.createElement('textarea');
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = '0';
  textArea.style.left = '0';
  textArea.style.position = 'fixed';

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    document.execCommand('copy');
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error('Fallback: Oops, unable to copy', err);
  }

  document.body.removeChild(textArea);
}

// source: https://stackoverflow.com/a/30810322
export function copyTextToClipboard(text, cb = () => {}) {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text);
    return;
  }
  navigator.clipboard
    .writeText(text)
    .then(() => {
      cb?.(text);
    })
    .catch(() => {
      // eslint-disable-next-line no-console
      console.log('Can not copy to clipboard.');
    });
}

export const sleep = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

/**
 * Gets pure filename from a full url.
 *
 * @param {*} url - url to be parsed
 * @returns
 */
export const getFilenameFromURL = (url) => {
  const urlObject = new URL(url);
  return urlObject.pathname.split('/').pop();
};

/**
 * Given the data and filename, automatically downloads the file.
 *
 * @param {*} data - file data as 'arraybuffer'
 * @param {*} filename - filename to be saved
 */
export const downloadBinaryFile = (data, filename) => {
  const url = window.URL.createObjectURL(new Blob([data]));

  const link = document.createElement('a');
  link.href = url;

  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
};

const PATH_KEY_LENGTH = 4;
const ROOT_DEPTH = 1;

/**
 * @typedef {{ path: string, depth: number } & Record<string, any>} TreeItem
 */

/**
 * @param {{ data: TreeItem[], valueKey: string, useItemId: boolean }} args
 */
export const generateTreeData = ({
  data = [],
  valueKey = 'id',
  useItemId = false,
  disableItemBy = () => false,
}) => {
  const treeData = [];
  const treeMap = {};

  const sortedData = sortBy(data, ['depth', 'path']);

  sortedData.forEach((item) => {
    const treeItem = {
      value: item[useItemId ? 'id' : valueKey],
      title: item.name,
      path: item.name,
      children: [],
      disabled: !item[valueKey] || disableItemBy(item),
      intrinsicValue: item[valueKey],
    };
    treeMap[item.path] = treeItem;

    const isRootItem = item.depth === ROOT_DEPTH;
    if (isRootItem) {
      treeData.push(treeItem);
    } else {
      const parentPath = item.path.slice(0, -PATH_KEY_LENGTH);
      const parentItem = treeMap[parentPath];

      if (parentItem) {
        parentItem.children.push(treeItem);
        treeItem.path = `${parentItem.path} > ${item.name}`;
      }
    }
  });

  return treeData;
};

export function linkParents({ parentNode, currentNode }) {
  currentNode.parent = parentNode;
  currentNode.children.forEach((child) => {
    linkParents({ parentNode: currentNode, currentNode: child });
  });

  return currentNode;
}

export function markPermission({ node, userCategoryNodePermissions }) {
  node.hasPermission = Boolean(
    userCategoryNodePermissions.find((permission) => permission.category_node === node.extra.id)?.id
  );

  node.children.forEach((child) => {
    markPermission({ node: child, userCategoryNodePermissions });
  });

  return node;
}

export function getTreeNodeByValue({ tree, nodeValue, key = 'value' }) {
  if (isEmpty(tree) || isEmpty(nodeValue)) {
    return null;
  }

  for (const node of tree) {
    if (node[key] === nodeValue) {
      return node;
    }
    if (node.children) {
      const childNode = getTreeNodeByValue({ tree: node.children, nodeValue, key });
      if (childNode) {
        return childNode;
      }
    }
  }
  return null;
}

export const safeParseJSON = (value) => {
  try {
    return JSON.parse(value);
  } catch (error) {}
  return value;
};

export const stringifyValuesInObject = (object) =>
  mapValues(object, (value) => {
    if (isPlainObject(value)) {
      return JSON.stringify(value);
    } else {
      return value;
    }
  });

export const injectAccessibilityRole = ({ element, role }) => {
  return React.cloneElement(element, {
    role,
    tabIndex: 0,
  });
};

export const lockBodyScroll = () => {
  document.body.style.overflow = 'hidden';
};

export const unlockBodyScroll = () => {
  document.body.style.overflow = 'auto';
};

export const dig = (obj, target) =>
  obj && obj[target] !== undefined
    ? obj[target]
    : Object.values(obj ?? {})?.reduce((acc, val) => {
        if (acc !== undefined) return acc;
        if (typeof val === 'object') return dig(val, target);
        return undefined;
      }, undefined);

export const formatDuration = ({ t, duration }) => {
  if (duration < 0 || isNaN(duration)) {
    return '';
  }

  const _duration = moment.duration(duration, 'seconds');

  const years = _duration.years();
  const months = _duration.months();
  const days = _duration.days();
  const hours = _duration.hours();
  const minutes = _duration.minutes();
  const secs = _duration.seconds();

  const parts = [];

  if (years) parts.push(t('duration_year', { count: years }));
  if (months) parts.push(t('duration_month', { count: months }));
  if (days) parts.push(t('duration_day', { count: days }));
  if (hours) parts.push(t('duration_hour', { count: hours }));
  if (minutes) parts.push(t('duration_minute', { count: minutes }));
  if (secs) parts.push(t('duration_second', { count: secs }));

  return `${parts.slice(0, -2).join(' ')} ${parts.slice(-2).join(` ${t('and')} `)}`;
};
