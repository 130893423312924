import './styles.scss';

import { dateAndTimeFormat } from '@common/date';
import AkinonButton from '@components/AkinonButton';
import AkinonFlex from '@components/AkinonFlex';
import AkinonSpin from '@components/AkinonSpin';
import AkinonSwitch from '@components/AkinonSwitch';
import If from '@components/If';
import Show from '@components/Show';
import { FileExtension } from '@constants/commontypes';
import { useGetAnnouncementsQuery } from '@services/api/hooks/useGetAnnouncementsQuery';
import { useNotificationAllMarkAsReadMutation } from '@services/api/hooks/useNotificationAllMarkAsReadMutation';
import { usePostNotificationStateMutation } from '@services/api/hooks/usePostNotificationStateMutation';
import { IconCalendarTime, IconDownload, IconX } from '@tabler/icons-react';
import useStore from '@zustand-store/index';
import {
  isLogCenterOpenSelector,
  logCenterRecordsSelector,
  setIsLogCenterOpenSelector,
  setLogCenterRecordsSelector,
} from '@zustand-store/selectors/app';
import { Divider, Drawer, Empty, Space, Typography } from 'antd';
import clsx from 'clsx';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { CSVLink } from 'react-csv';
import { useTranslation } from 'react-i18next';

import { getCSVHeaders, getRecordFragmentsByType, transformLogCenterRecordsForCSV } from './common';

const { Text, Paragraph, Title } = Typography;

const LogCenterTab = {
  LOG_CENTER: 'logCenter',
  ANNOUNCEMENTS: 'announcements',
};

const AnnouncementType = {
  READ: 'read',
  UNREAD: 'unread',
};

/**
 * @param {import('antd').DrawerProps} props
 */

const AkinonLogDrawer = (props) => {
  const { t } = useTranslation();
  const isLogCenterOpen = useStore(isLogCenterOpenSelector);
  const setIsLogCenterOpen = useStore(setIsLogCenterOpenSelector);
  const logCenterRecords = useStore(logCenterRecordsSelector);
  const setLogCenterRecords = useStore(setLogCenterRecordsSelector);
  const [activeTab, setActiveTab] = useState(LogCenterTab.LOG_CENTER);
  const [announcementType, setAnnouncementType] = useState(AnnouncementType.UNREAD);

  const { postNotificationAllMarkAsRead, isPostingNotificationAllMarkAsRead } =
    useNotificationAllMarkAsReadMutation({
      mutationOptions: {
        onSuccess: () => {
          refetchAnnouncements();
          setIsRemoveAnnouncementId(null);
        },
      },
    });

  const [isRemoveAnnouncementId, setIsRemoveAnnouncementId] = useState(null);
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    setIsExpanded(false);
  }, [activeTab]);

  const {
    announcements: readPushNotifications = [],
    refetchAnnouncements: refetchReadAnnouncements,
    isFetchingAnnouncements: isFetchingReadAnnouncements,
  } = useGetAnnouncementsQuery({
    params: {
      ntype: 1,
      is_read: true,
    },
    queryOptions: {
      refetchInterval: 30 * 1000,
      initialData: [],
      enabled: isLogCenterOpen && activeTab === LogCenterTab.ANNOUNCEMENTS,
    },
  });

  const {
    announcements: unreadPushNotifications = [],
    refetchAnnouncements: refetchUnreadAnnouncements,
    isFetchingAnnouncements: isFetchingUnreadAnnouncements,
  } = useGetAnnouncementsQuery({
    params: {
      ntype: 1,
      is_read: false,
    },
    queryOptions: {
      refetchInterval: 30 * 1000,
      initialData: [],
      enabled: isLogCenterOpen && activeTab === LogCenterTab.ANNOUNCEMENTS,
    },
  });

  const isFetchingAnnouncements = isFetchingReadAnnouncements || isFetchingUnreadAnnouncements;

  const refetchAnnouncements = () => {
    refetchReadAnnouncements();
    refetchUnreadAnnouncements();
  };

  const { postNotificationState, isPostNotificationStateSuccess } =
    usePostNotificationStateMutation({
      mutationOptions: {
        onSuccess: refetchAnnouncements,
      },
    });

  const recordFragmentsByType = getRecordFragmentsByType({ t });
  const csvHeaders = getCSVHeaders({ t });

  const onDrawerClose = () => {
    setIsLogCenterOpen(false);
  };

  const onClearAllRecordsClick = () => {
    setLogCenterRecords([]);
  };

  const onClearAllAnnouncementsClick = () => {
    postNotificationAllMarkAsRead({
      requestBody: {
        is_read: true,
        is_seen: true,
      },
    });
  };

  const announcements = useMemo(() => {
    return announcementType === AnnouncementType.READ
      ? readPushNotifications?.map((notification) => ({
          ...notification,
          is_read: true,
        }))
      : unreadPushNotifications;
  }, [announcementType, readPushNotifications, unreadPushNotifications]);

  return (
    <Drawer
      className="log-center-drawer"
      placement="right"
      onClose={onDrawerClose}
      open={isLogCenterOpen}
      destroyOnClose
      width={450}
      {...props}
      closable={true}
      title={
        <>
          <AkinonFlex wrap="wrap" className="w-full" justifyContent="space-between">
            <AkinonButton type="text" onClick={() => setActiveTab(LogCenterTab.LOG_CENTER)}>
              {t('layout.logCenter.title')}
            </AkinonButton>
            <AkinonButton type="text" onClick={() => setActiveTab(LogCenterTab.ANNOUNCEMENTS)}>
              {t('layout.announcements.title')}
            </AkinonButton>
          </AkinonFlex>
          <Divider
            className={clsx('bg-bright-gray h-[2px] relative', {
              'underline-left': activeTab === LogCenterTab.LOG_CENTER,
              'underline-right': activeTab === LogCenterTab.ANNOUNCEMENTS,
            })}
          />
        </>
      }
      closeIcon={
        <IconX
          role="button"
          aria-label="log-center-close-btn"
          className="text-white absolute right-0 top-6"
          size={24}
        />
      }
    >
      {activeTab === LogCenterTab.LOG_CENTER && (
        <Show
          when={!isEmpty(logCenterRecords)}
          fallback={
            <Empty className="text-slate-gray" description={t('layout.logCenter.noRecords')} />
          }
        >
          <AkinonFlex direction="column" className="log-center-errors gap-1">
            <Space size="small">
              <AkinonButton
                type="primary"
                onClick={onClearAllRecordsClick}
                aria-label={t('layout.logCenter.clearAllRecords')}
              >
                {t('layout.logCenter.clearAllRecords').toUpperCase()}
              </AkinonButton>
              <CSVLink
                filename={[t('layout.logCenter.csv.title'), FileExtension.CSV].join('.')}
                headers={csvHeaders}
                data={transformLogCenterRecordsForCSV(logCenterRecords)}
              >
                <AkinonButton icon={<IconDownload size={16} />}>
                  <Text className="text-white leading-2 uppercase">{t('csv')}</Text>
                </AkinonButton>
              </CSVLink>
            </Space>
            {logCenterRecords.map((record, index) => (
              <div
                key={index}
                className={clsx('border-solid border p-5 bg-primary-color opacity-80', {
                  'border-success': record.type === 'success',
                  'border-color-error': record.type === 'error',
                  'border-warning': record.type === 'warning',
                  'border-info': record.type === 'info',
                })}
              >
                <div className="flex items-center">
                  {recordFragmentsByType[record.type].icon}
                  <Text className="font-semibold">{recordFragmentsByType[record.type].label}</Text>
                </div>
                <div className="my-4">
                  <Text className="font-bold text-white flex mb-1">{record.message}</Text>
                  <Text className="text-white">{record.description}</Text>
                </div>
                <div className="flex items-center gap-1">
                  <IconCalendarTime size={14} className="text-slate-gray" />
                  <Text className="text-slate-gray">
                    {moment(record.date).format(dateAndTimeFormat)}
                  </Text>
                </div>
              </div>
            ))}
          </AkinonFlex>
        </Show>
      )}
      {activeTab === LogCenterTab.ANNOUNCEMENTS && (
        <>
          <AkinonFlex justifyContent="space-between">
            <Space size="small" className="flex items-center">
              <span className="text-white font-bold">
                {t('layout.announcements.onlyShowUnread')}{' '}
              </span>
              <AkinonSwitch
                checked={announcementType === AnnouncementType.UNREAD}
                onChange={(checked) => {
                  setAnnouncementType(checked ? AnnouncementType.UNREAD : AnnouncementType.READ);
                }}
              />
            </Space>
            <Space size="small">
              <Show when={announcementType === AnnouncementType.UNREAD && !isEmpty(announcements)}>
                <AkinonButton
                  type="primary"
                  onClick={onClearAllAnnouncementsClick}
                  aria-label={t('layout.announcement.clearAllRecords')}
                  loading={isPostingNotificationAllMarkAsRead}
                  disabled={isPostingNotificationAllMarkAsRead}
                >
                  {t('layout.announcement.clearAllRecords').toUpperCase()}
                </AkinonButton>
              </Show>
            </Space>
          </AkinonFlex>
          <If
            condition={!isEmpty(announcements)}
            then={
              <AkinonSpin
                spinning={
                  isFetchingAnnouncements ||
                  isPostNotificationStateSuccess ||
                  isPostingNotificationAllMarkAsRead
                }
              >
                <AkinonFlex direction="column" className="gap-1 my-5">
                  {announcements?.map((record, index) => (
                    <div
                      key={index}
                      className={clsx('border-solid border p-5 bg-primary-color opacity-80', {
                        'border-success': !record?.is_read,
                        'announcement-card-hidden':
                          isPostNotificationStateSuccess && record?.id === isRemoveAnnouncementId,
                      })}
                    >
                      <div className="flex flex-col my-2">
                        <AkinonFlex justifyContent="space-between">
                          <Title
                            level={4}
                            ellipsis={{
                              tooltip: <span> {record?.title} </span>,
                            }}
                            className="font-bold !text-white mb-1"
                          >
                            {record?.title}
                          </Title>
                          {!record?.is_read && (
                            <IconX
                              size={16}
                              color="white"
                              className="cursor-pointer"
                              onClick={() => {
                                setIsRemoveAnnouncementId(record?.id);
                                postNotificationState({
                                  notificationId: record?.id,
                                  requestBody: {
                                    is_read: true,
                                    is_seen: true,
                                  },
                                });
                              }}
                            />
                          )}
                        </AkinonFlex>

                        <Paragraph
                          className={clsx('text-white max-h-32 ', {
                            'overflow-y-auto': isExpanded,
                          })}
                          ellipsis={{
                            expandable: true,
                            symbol: <span>{t('more')}</span>,
                            rows: 4,
                            onExpand: () => setIsExpanded(true),
                          }}
                        >
                          {record?.message}
                        </Paragraph>
                      </div>
                      <div className="flex items-center gap-1">
                        <IconCalendarTime size={14} className="text-slate-gray" />
                        <Text className="text-slate-gray">
                          {moment(record?.created_at).format(dateAndTimeFormat)}
                        </Text>
                      </div>
                    </div>
                  ))}
                </AkinonFlex>
              </AkinonSpin>
            }
            otherwise={
              <Empty
                className="text-slate-gray my-5"
                description={t('layout.logCenter.noRecords')}
              />
            }
          />
        </>
      )}
    </Drawer>
  );
};

export default AkinonLogDrawer;
