import './style.scss';

import AkinonButton from '@components/AkinonButton';
import AkinonSpin from '@components/AkinonSpin';
import DynamicFormElement from '@components/DynamicFormElement';
import { useIsMobile } from '@hooks/useIsMobile';
import { IconAdjustments } from '@tabler/icons-react';
import { Col, Form, Row } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import ActiveFilters from '../ActiveFilters';
import FiltersDrawer from '../FiltersDrawer';
import HighlightedFiltersForm from '../HighlightedFiltersForm';
import MobileSearchFilter from '../MobileSearchFilter';

const FiltersForm = ({
  filterFields,
  form,
  applyFilters,
  total,
  searchQuery,
  visibleFilters,
  actions,
}) => {
  const { t } = useTranslation();
  const isMobile = useIsMobile();

  const [isFiltersModalVisible, setIsFiltersModalVisible] = useState(false);
  if (isMobile) {
    return (
      <>
        <MobileSearchFilter filterForm={form} searchQuery={searchQuery} />
        <HighlightedFiltersForm
          filterForm={form}
          filterFields={filterFields}
          visibleFilters={visibleFilters}
        />
        <AkinonButton
          className="mobile-show-filters-button"
          onClick={() => setIsFiltersModalVisible(true)}
        >
          {t('filters')}
          <IconAdjustments />
        </AkinonButton>
        <FiltersDrawer
          visibleFilters={visibleFilters}
          filterFields={filterFields}
          isFiltersModalVisible={isFiltersModalVisible}
          setIsFiltersModalVisible={setIsFiltersModalVisible}
          applyFilters={applyFilters}
          total={total}
        />
      </>
    );
  }

  const visibleFilterFields = filterFields.filter((field) =>
    visibleFilters?.includes?.(field.name)
  );

  return (
    <>
      <ActiveFilters filterFields={filterFields} form={form} actions={actions} />
      <Form className="akinon-form-dark akinon-filter__form">
        <Row gutter={[10, 0]}>
          {visibleFilterFields.map((field) => (
            <Col key={field.name} span={6}>
              <AkinonSpin spinning={field?.loading ?? false} tip="">
                <DynamicFormElement {...field} propsToOmit={['isInitiallyVisible']} />
              </AkinonSpin>
            </Col>
          ))}
        </Row>
      </Form>
    </>
  );
};

export default FiltersForm;
