import './styles.scss';

import { generateTreeData } from '@common/data';
import AkinonBox from '@components/AkinonBox';
import AkinonButton from '@components/AkinonButton';
import AkinonSpin from '@components/AkinonSpin';
import { Color } from '@constants/theme';
import { Tree } from 'antd';
import isEmpty from 'lodash/isEmpty';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { DataIndex, optimizeCheckedKeys } from '../../common';

const CategoryPermissionsTree = ({
  userCategoryNodePermissions,
  categories,
  checkedKeys,
  setCheckedKeys,
  isLoading,
  ...props
}) => {
  const { t } = useTranslation('Finance');

  const { setValue, handleSubmit, getValues } = useForm({
    defaultValues: {
      category_permissions: {
        active: [],
        inactive: [],
      },
    },
  });

  const allExpandedKeys = categories?.map((item) => item.path);

  const [expandedKeys, setExpandedKeys] = useState([]);
  const [autoExpandParent, setAutoExpandParent] = useState(true);

  useEffect(() => {
    const defaultExpandedKeys = categories?.map((item) => item.path);
    setExpandedKeys(defaultExpandedKeys);
  }, [categories]);

  const treeData = generateTreeData({
    data: categories,
    columnKeys: Object.values(DataIndex),
    keepMeta: true,
  });

  const handleExpandAll = () => {
    setExpandedKeys(allExpandedKeys);
    setAutoExpandParent(false);
  };

  const handleCollapseAll = () => {
    setExpandedKeys([]);
    setAutoExpandParent(false);
  };

  const handleRemoveAll = () => {
    setCheckedKeys([]);
  };

  const handleOnExpand = (expandedKeys) => {
    setExpandedKeys(expandedKeys);
    setAutoExpandParent(false);
  };

  const handleOnCheck = (checkedKeys) => {
    setCheckedKeys(checkedKeys);
  };

  const onSubmit = () => {
    const [keysToActivate, keysToDeactivate] = optimizeCheckedKeys(
      userCategoryNodePermissions,
      treeData,
      checkedKeys
    );
    setValue('category_permissions', {
      active: keysToActivate,
      inactive: keysToDeactivate,
    });

    props?.handleSubmit(getValues());
  };

  return (
    <AkinonBox
      bannerColor={Color.WILD_WATERMELON}
      title={
        <div className="flex justify-between items-center gap-6">
          <span>{t('added_permissions')}</span>
          <AkinonButton htmlType="submit" type="primary" onClick={handleSubmit(onSubmit)}>
            {t('submit').toUpperCase()}
          </AkinonButton>
        </div>
      }
      className="box-primary form-box category-permissions-tree"
    >
      <AkinonSpin spinning={isLoading}>
        <div className="tree-table-actions">
          <AkinonButton
            className="title-section-btn"
            onClick={handleExpandAll}
            disabled={isEmpty(treeData)}
          >
            {t('expand_all')}
          </AkinonButton>
          <AkinonButton
            className="title-section-btn"
            onClick={handleCollapseAll}
            disabled={isEmpty(treeData)}
          >
            {t('minimize_all')}
          </AkinonButton>
          <AkinonButton
            className="title-section-btn"
            onClick={handleRemoveAll}
            disabled={isEmpty(treeData)}
          >
            {t('remove_all')}
          </AkinonButton>
        </div>
        {!isLoading && (
          <Tree
            showLine={true}
            checkable={true}
            autoExpandParent={autoExpandParent}
            treeData={!isEmpty(treeData) ? treeData : []}
            checkedKeys={checkedKeys}
            expandedKeys={expandedKeys}
            switcherIcon={({ expanded }) => {
              return expanded ? (
                <i className="icon-folder_minus table-icon" />
              ) : (
                <i className="icon-folder_plus table-icon" />
              );
            }}
            onCheck={handleOnCheck}
            onExpand={handleOnExpand}
          />
        )}
      </AkinonSpin>
    </AkinonBox>
  );
};

export default CategoryPermissionsTree;
