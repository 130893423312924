import { RouteUrls } from '@constants/routeUrls';
import useAppNavigate from '@hooks/useAppNavigate';
import { TranslationKey } from '@root/i18n';
import useStore from '@zustand-store/index';
import { setPageMetaSelector } from '@zustand-store/selectors/app';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useUnmount } from 'react-use';

export function usePageMeta() {
  const { t } = useTranslation(TranslationKey.LOCATIONS);
  const navigate = useAppNavigate();
  const setPageMeta = useStore(setPageMetaSelector);

  useEffect(() => {
    setPageMeta({
      title: t('locations_title'),
      description: t('location_edit_desc'),
      actions: [
        {
          label: t('create_new_location'),
          onClick: () => {
            navigate(RouteUrls.stockLocations.create);
          },
          icon: <i className="icon-arti" />,
        },
      ],
    });
  }, [t]);

  useUnmount(() => {
    setPageMeta({});
  });
}
