import { submitPreOfferURL } from '@constants/apiUrls';
import { client } from '@services/api/client';
import filter from 'lodash/filter';

const useSubmitOffers = ({ refetchOffers, limitPerBulkRequest = 1 }) => {
  const submitSelectedItems = async ({ selectedRows, mutationOptions }) => {
    const { onSuccess, onError } = mutationOptions;
    const submitCount = selectedRows.length;

    if (submitCount > 0) {
      let result = [];
      const bulkRequestSize = Math.ceil(submitCount / limitPerBulkRequest);
      for (let i = 0; i < bulkRequestSize; i++) {
        const start = i * limitPerBulkRequest;
        const end = (i + 1) * limitPerBulkRequest;
        const bulkSelectedRows = selectedRows.slice(start, end);

        const submitPromises = bulkSelectedRows.map((row) => {
          return client.post(submitPreOfferURL, {}, { urlParams: { id: row.id } });
        });

        const bulkResult = await Promise.allSettled(submitPromises);
        const errorCount = filter(bulkResult, { status: 'rejected' }).length;
        const successCount = filter(bulkResult, { status: 'fulfilled' }).length;
        onError(errorCount);
        onSuccess(successCount);
        result = [...result, ...bulkResult];
      }

      const isAllFulfilled = result.every((item) => item.status === 'fulfilled');
      const isSomeRejected = result.some((item) => item.status === 'rejected');
      const isSomeFulfilled = result.some((item) => item.status === 'fulfilled');
      if (isAllFulfilled || (isSomeFulfilled && isSomeRejected)) {
        refetchOffers();
      }
    }
  };

  return { submitSelectedItems };
};

export default useSubmitOffers;
