const useApproveOrderItems = ({ approveBulkRefund }) => {
  const onApproveOrderItems = ({ selectedRows, mutationOptions }) => {
    const cancellationRequests = selectedRows.map((row) => row?.cancellation_id);

    approveBulkRefund(
      {
        requestBody: {
          cancellation_requests: cancellationRequests,
        },
      },
      mutationOptions
    );
  };

  return { onApproveOrderItems };
};

export default useApproveOrderItems;
