import { createSelectOptions } from '@common/index';
import AkinonButton from '@components/AkinonButton';
import AkinonSelect from '@components/AkinonSelect';
import Show from '@components/Show';
import { QueryKey } from '@constants/query';
import { usePutCategoryAttributeSetMutation } from '@services/api/hooks/usePutCategoryAttributeSetMutation';
import { useQueryClient } from '@tanstack/react-query';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

/**
 * @param {import('antd').SelectProps & { attributeSets: any[], categoryId: string }} props
 */
const AttributeSetSelect = ({ attributeSets, categoryId, ...selectProps }) => {
  const { t } = useTranslation('Settings');
  const queryClient = useQueryClient();
  const [attributeSet, setAttributeSet] = useState();
  const { putCategoryAttributeSet, isPutCategoryAttributeSetLoading } =
    usePutCategoryAttributeSetMutation();

  const attributeSetsOptions = createSelectOptions(attributeSets, {
    labelKey: 'name',
    valueKey: 'id',
  });

  const onSaveAttributeClick = () => {
    putCategoryAttributeSet(
      {
        categoryId,
        requestBody: { attribute_set: attributeSet },
        requestConfig: {
          successMessage: t('transaction_success'),
          successDescription: t('attribute_set_mapping_message.success'),
          errorMessage: t('transaction_failed'),
          errorDescription: t('attribute_set_mapping_message.error'),
        },
      },
      {
        onSuccess() {
          queryClient.invalidateQueries(QueryKey.CATEGORIES);
        },
      }
    );
  };

  return (
    <div style={{ display: 'flex', gap: 24 }}>
      <AkinonSelect
        style={{ width: '64%' }}
        placeholder={t('pick_attribute')}
        options={attributeSetsOptions}
        value={attributeSet}
        onChange={setAttributeSet}
        {...selectProps}
      />
      <Show when={attributeSet}>
        <AkinonButton loading={isPutCategoryAttributeSetLoading} onClick={onSaveAttributeClick}>
          {t('save').toUpperCase()}
        </AkinonButton>
      </Show>
    </div>
  );
};

export default AttributeSetSelect;
