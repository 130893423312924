import { getAgreementsUrl } from '@constants/apiUrls';

import { useAkinonMutation } from '../adapters/useAkinonMutation';
import { client } from '../client';

/**
 * @param {Object} [args]
 * @param {import('@tanstack/react-query').MutationOptions} [args.mutationOptions]
 */
export function usePostAgreementMutation({ mutationOptions } = {}) {
  const { mutate, isLoading } = useAkinonMutation({
    mutationFn: ({ requestBody, requestConfig }) =>
      client.post(getAgreementsUrl, requestBody, requestConfig),
    ...mutationOptions,
  });

  return {
    createAgreement: mutate,
    isAgreementCreating: isLoading,
  };
}
