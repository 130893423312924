import { getCitiesUrl } from '@constants/apiUrls';
import { limitQuery } from '@constants/commontypes';
import { QueryKey } from '@constants/query';
import { client } from '@services/api/client';

import { useAkinonQuery } from '../adapters/useAkinonQuery';

export function useCitiesQuery({ queryOptions, params } = {}) {
  const { data, isFetching } = useAkinonQuery({
    queryKey: [QueryKey.CITIES, params],
    queryFn: () =>
      client.get(getCitiesUrl, {
        params: {
          ...limitQuery,
          ...params,
        },
      }),
    ...queryOptions,
  });

  return {
    cities: data?.results,
    isCitiesFetching: isFetching,
  };
}
