import { getDataSourcesUrl } from '@constants/apiUrls';
import { getActivePassiveBooleanOptions, WidgetType } from '@constants/commontypes';
import { QueryKey } from '@constants/query';

import { StaticFilterKey } from '../common';

const useFilterFields = ({ t }) => {
  return {
    static: {
      fields: [
        {
          name: StaticFilterKey.FIRST_NAME,
          placeholder: t('first_name'),
        },
        {
          name: StaticFilterKey.LAST_NAME,
          placeholder: t('last_name'),
        },
        {
          name: StaticFilterKey.EMAIL,
          visibleOnFilter: [StaticFilterKey.EMAIL],
          placeholder: t('email'),
        },
        {
          name: StaticFilterKey.IS_ACTIVE,
          placeholder: t('status'),
          widget: WidgetType.SELECT,
          options: getActivePassiveBooleanOptions({ t }),
          showSearch: true,
          optionFilterProp: 'label',
        },
        {
          name: StaticFilterKey.DATASOURCE,
          widget: WidgetType.REMOTE_SELECT,
          showSearch: true,
          labelInValue: true,
          queryProps: {
            remoteKey: QueryKey.DATA_SOURCES,
            remoteUrl: getDataSourcesUrl,
            params: {
              limit: 20,
              sort: 'name',
              is_active: true,
            },
            searchKey: 'name__icontains',
          },
          placeholder: t('seller'),
        },
        {
          name: StaticFilterKey.SOURCE_IN,
          widget: WidgetType.CHECKBOX_GROUP,
          placeholder: t('show_integration_users'),
          options: [
            { label: t('show_integration_users'), name: StaticFilterKey.SOURCE_IN, value: false },
          ],
        },
      ],
    },
    dynamic: { fields: [], isLoading: false },
  };
};

export default useFilterFields;
