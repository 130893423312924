import './styles.scss';

import AkinonButton from '@components/AkinonButton';
import AkinonForm from '@components/AkinonForm';
import { AkinonPasswordInput } from '@components/AkinonInput';
import { openNotification } from '@components/akinonNotification';
import AkinonSpin from '@components/AkinonSpin';
import Box from '@components/utility/box/index';
import { zodResolver } from '@hookform/resolvers/zod';
import { useChangePasswordMutation } from '@services/hooks/useChangePasswordMutation';
import { Form } from 'antd';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormKey, getFormSchema } from './common';

const ChangePasswordForm = () => {
  const { t } = useTranslation('Profile');
  const schema = getFormSchema({ t });
  const { control, handleSubmit } = useForm({
    resolver: zodResolver(schema),
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
  });

  const { changePassword, isChangingPassword } = useChangePasswordMutation();

  const onSubmit = (values) => {
    changePassword(
      {
        requestBody: {
          password: values.new_password,
          current_password: values.old_password,
        },
      },
      {
        onSuccess: () => {
          openNotification({
            message: t('process.successful'),
            description: t('password_changed'),
            type: 'success',
          });
        },
        onError: (err) => {
          openNotification({
            message: t('process.failed'),
            description:
              err?.response?.data?.code === 'invalid_current_password'
                ? t('invalid_current_password')
                : err?.response?.data?.detail,
            type: 'error',
          });
        },
      }
    );
  };

  return (
    <section className="change-password">
      <Box className="box-primary form-box" title={t('change.password')}>
        <AkinonSpin spinning={isChangingPassword}>
          <AkinonForm layout="vertical" onFinish={handleSubmit(onSubmit)}>
            <AkinonPasswordInput
              formItemProps={{
                name: FormKey.OLD_PASSWORD,
                label: t('password'),
                control,
                required: true,
              }}
              placeholder={t('password')}
            />
            <AkinonPasswordInput
              formItemProps={{
                name: FormKey.NEW_PASSWORD,
                label: t('new_password'),
                control,
                required: true,
              }}
              placeholder={t('new_password')}
            />
            <AkinonPasswordInput
              formItemProps={{
                name: FormKey.NEW_PASSWORD_REPEAT,
                label: t('new_password_repeat'),
                control,
                required: true,
              }}
              placeholder={t('new_password_repeat')}
            />
            <Form.Item wrapperCol={{ span: 24 }}>
              <AkinonButton
                disabled={isChangingPassword}
                htmlType="submit"
                type="primary"
                name="change-password-submit"
                className="change-password__submit-button"
              >
                {t('change')}
              </AkinonButton>
            </Form.Item>
          </AkinonForm>
        </AkinonSpin>
      </Box>
    </section>
  );
};

export default ChangePasswordForm;
