import { WidgetType } from '@constants/commontypes';

import { StaticFilterKey } from '../common';

export default function useStaticFilterFields({ t }) {
  return {
    fields: [
      {
        name: StaticFilterKey.PRODUCT_NAME,
        widget: WidgetType.INPUT,
        placeholder: t('product_name'),
      },
      {
        name: StaticFilterKey.BASE_CODE,
        widget: WidgetType.INPUT,
        placeholder: t('base.code'),
      },
      {
        name: StaticFilterKey.SKU,
        widget: WidgetType.INPUT,
        placeholder: t('sku'),
      },
    ],
  };
}
