import { getLocationsUrl } from '@constants/apiUrls';
import { QueryKey } from '@constants/query';

import { useAkinonQuery } from '../adapters/useAkinonQuery';
import { client } from '../client';

/**
 * @param {{params: Object, queryOptions: import('@tanstack/react-query').UseQueryOptions, onSuccess: Function}} args
 */

export function useGetLocationsQuery({ params, queryOptions, onSuccess } = {}) {
  const { data, isFetching } = useAkinonQuery({
    queryKey: [QueryKey.LOCATIONS, params],
    async queryFn() {
      const response = await client.get(getLocationsUrl, {
        params: {
          ...params,
        },
      });
      onSuccess?.(response);

      return response;
    },
    ...queryOptions,
  });

  return {
    locations: data?.results,
    isFetchingLocations: isFetching,
    totalLocations: data?.count,
  };
}
