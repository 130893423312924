import './styles.scss';

import { createSelectOptions } from '@common/index';
import AkinonBox from '@components/AkinonBox';
import AkinonButton from '@components/AkinonButton';
import AkinonForm from '@components/AkinonForm';
import AkinonInput, { AkinonTextArea } from '@components/AkinonInput';
import AkinonSelect from '@components/AkinonSelect';
import AkinonSpin from '@components/AkinonSpin';
import AkinonSwitch from '@components/AkinonSwitch';
import { flattenTree } from '@components/SortableTree/utilities';
import { UserRole } from '@constants/auth';
import { limitQuery } from '@constants/commontypes';
import { formBuilderVerticalLayout } from '@constants/layoutTypes';
import { RouteUrls } from '@constants/routeUrls';
import { Color } from '@constants/theme';
import { zodResolver } from '@hookform/resolvers/zod';
import useAppNavigate from '@root/hooks/useAppNavigate';
import {
  useDataSourceShippingCompanyOptionConfigQuery,
  usePatchDataSourceShippingCompanyOptionConfigMutation,
  usePostDataSourceShippingCompanyOptionConfigMutation,
  useShippingCompanyOptionsQuery,
} from '@services/api/hooks';
import { usePatchShippingCompanyOptionMutation } from '@services/api/hooks/usePatchShippingCompanyOptionMutation';
import { usePostShippingCompanyOptionsMutation } from '@services/api/hooks/usePostShippingCompanyOptionsMutation';
import { useShippingCompaniesQuery } from '@services/api/hooks/useShippingCompaniesQuery';
import { useShippingCompanyOptionQuery } from '@services/api/hooks/useShippingCompanyOptionQuery';
import { useShippingCompanyOptionHttpOptionsQuery } from '@services/api/hooks/useShippingCompanyOptionsHttpOptionsQuery';
import { useShippingOptionsQuery } from '@services/api/hooks/useShippingOptionsQuery';
import { useUserRole } from '@utils/hooks/useUserRole';
import get from 'lodash/get';
import last from 'lodash/last';
import mapValues from 'lodash/mapValues';
import merge from 'lodash/merge';
import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import CalculatorFields from './CalculatorFields';
import { FormKey, getFormSchema, parseRuleData, transformRules } from './common';
import ConfigFormList from './ConfigFormList';
import { useBreadcrumbs } from './hooks/useBreadcrumbs';
import { useOnSubmit } from './hooks/useOnSubmit';
import { usePageMeta } from './hooks/usePageMeta';
import Rules from './Rules';

const defaultInitialValues = {
  [FormKey.CONFIG]: {
    url: '',
  },
};
const DeliverySettingsForm = () => {
  const { t } = useTranslation('Settings');
  const navigate = useAppNavigate();
  const { id: optionId, configId } = useParams();

  const userRole = useUserRole();
  const isSuperUser = userRole === UserRole.SUPER_USER;

  const { shippingCompanyOptions } = useShippingCompanyOptionsQuery({
    params: {
      sort: 'priority',
      ...limitQuery,
    },
  });

  const { shippingCompanyOptionHttpOptions } = useShippingCompanyOptionHttpOptionsQuery({
    optionId: optionId,
    queryOptions: {
      retry: 3,
    },
  });

  const { shippingCompanyOption, isFetchingShippingCompanyOption } = useShippingCompanyOptionQuery({
    optionId,
    queryOptions: {
      enabled: Boolean(optionId),
    },
  });

  const { dataSourceShippingCompanyOptionConfig } = useDataSourceShippingCompanyOptionConfigQuery({
    configId,
    queryOptions: {
      enabled: (isSuperUser && Boolean(configId)) || !isSuperUser,
    },
  });

  const { shippingOptions, isShippingOptionsLoading } = useShippingOptionsQuery({
    params: {
      is_active: true,
    },
  });
  const { shippingCompanies, isShippingCompaniesLoading } = useShippingCompaniesQuery({
    params: {
      is_active: true,
    },
  });
  const { updateShippingCompanyOption, isUpdatingShippingCompanyOption } =
    usePatchShippingCompanyOptionMutation();
  const { createShippingCompanyOptions, isCreatingShippingCompanyOptions } =
    usePostShippingCompanyOptionsMutation({
      onSuccess() {
        navigate(RouteUrls.settings.deliverySettingsShipmentSettings);
      },
    });
  const {
    isCreatingDataSourceShippingCompanyOptionConfig,
    createDataSourceShippingCompanyOptionConfig,
  } = usePostDataSourceShippingCompanyOptionConfigMutation();
  const {
    isUpdatingDataSourceShippingCompanyOptionConfig,
    updateDataSourceShippingCompanyOptionConfig,
  } = usePatchDataSourceShippingCompanyOptionConfigMutation();

  useBreadcrumbs();
  usePageMeta();

  const isFormLoading =
    isShippingCompaniesLoading ||
    isShippingOptionsLoading ||
    isCreatingShippingCompanyOptions ||
    isFetchingShippingCompanyOption;

  const defaultPriority = (last(shippingCompanyOptions)?.priority ?? 0) + 1;

  const onSubmit = useOnSubmit({
    t,
    optionId,
    isSuperUser,
    configId,
    updateShippingCompanyOption,
    createDataSourceShippingCompanyOptionConfig,
    updateDataSourceShippingCompanyOptionConfig,
    createShippingCompanyOptions,
    defaultPriority,
    shippingCompanyOptionHttpOptions,
  });

  const rules = useMemo(() => {
    const logicalRules = transformRules(get(shippingCompanyOptionHttpOptions, 'rules.logical', []));
    const conditionalRules = transformRules(
      get(shippingCompanyOptionHttpOptions, 'rules.conditional', [])
    );
    return {
      conditional: conditionalRules,
      logical: logicalRules,
    };
  }, [shippingCompanyOptionHttpOptions]);

  const schema = getFormSchema({
    shippingCompanies,
    shippingOptions,
    shippingCompanyOptionHttpOptions,
    isSuperUser,
    configId,
    rules,
    t,
  });

  const defaultValues = useMemo(() => {
    return mapValues(
      merge({}, defaultInitialValues, shippingCompanyOption, dataSourceShippingCompanyOptionConfig),
      (value, key) => {
        if (key === FormKey.CALCULATOR) {
          return {
            ...value,
            type: value?.slug ?? shippingCompanyOptionHttpOptions?.calculators?.[0]?.slug,
          };
        } else if (key === FormKey.RULE) {
          return flattenTree(parseRuleData(value, rules));
        } else if (key === FormKey.CONFIG) {
          return Object.entries(value).map(([key, value]) => {
            return { key, value };
          });
        }

        return value;
      }
    );
  }, [defaultInitialValues, rules, shippingCompanyOption, dataSourceShippingCompanyOptionConfig]);

  const form = useForm({
    defaultValues,
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: zodResolver(schema),
  });
  const { control, handleSubmit, reset } = form;

  useEffect(() => {
    if (defaultValues) {
      reset(defaultValues);
    }
  }, [defaultValues]);

  const isRequiresManualEntry = defaultValues?.is_requires_manual_entry;

  return (
    <section className="delivery-settings-form">
      <AkinonBox
        title={t('add_new_delivery_setting')}
        bannerColor={Color.CRIMSON}
        className="box-primary form-box"
      >
        <AkinonSpin spinning={isFormLoading}>
          <AkinonForm layout="vertical" wrapperCol={formBuilderVerticalLayout.wrapperCol}>
            <AkinonSelect
              formItemProps={{
                name: FormKey.SHIPPING_COMPANY,
                label: t('shipping.company'),
                control,
                required: true,
              }}
              placeholder={t('shipping.company')}
              options={createSelectOptions(shippingCompanies, { labelKey: 'name', valueKey: 'id' })}
              disabled={
                (isSuperUser && Boolean(configId)) || (!isRequiresManualEntry && !isSuperUser)
              }
            />
            <AkinonSelect
              formItemProps={{
                name: FormKey.SHIPPING_OPTION,
                label: t('shipping_option'),
                control,
                required: true,
              }}
              placeholder={t('shipping_option')}
              options={createSelectOptions(shippingOptions, { labelKey: 'name', valueKey: 'id' })}
              disabled={
                (isSuperUser && Boolean(configId)) || (!isRequiresManualEntry && !isSuperUser)
              }
            />
            <AkinonSwitch
              formItemProps={{
                name: FormKey.IS_ACTIVE,
                label: t('status'),
                control,
              }}
              checkedChildren={t('active')}
              unCheckedChildren={t('passive')}
              placeholder={t('is.active.choose')}
              disabled={!isRequiresManualEntry && !isSuperUser}
            />
            <AkinonInput
              formItemProps={{
                name: FormKey.SLUG,
                label: t('slug'),
                control,
              }}
              disabled={!isSuperUser || Boolean(optionId)}
              placeholder={t('slug')}
            />
            <AkinonInput
              formItemProps={{
                name: FormKey.NAME,
                label: t('name'),
                control,
              }}
              disabled={!isRequiresManualEntry && !isSuperUser}
              placeholder={t('name')}
            />
            <AkinonInput
              formItemProps={{
                name: FormKey.PRIORITY,
                label: t('priority'),
                control,
              }}
              disabled={!isRequiresManualEntry && !isSuperUser}
              placeholder={t('priority')}
            />
            <CalculatorFields
              control={control}
              disabled={!isRequiresManualEntry && !isSuperUser}
              shippingCompanyOptionHttpOptions={shippingCompanyOptionHttpOptions}
            />
            {isSuperUser && (
              <AkinonSwitch
                formItemProps={{
                  name: FormKey.IS_REQUIRES_MANUAL_ENTRY,
                  label: t('allow_manual_entry'),
                  control,
                }}
                checkedChildren={t('yes')}
                unCheckedChildren={t('no')}
              />
            )}
            <Rules
              rules={rules}
              form={form}
              name={FormKey.RULE}
              readOnly={!isRequiresManualEntry && !isSuperUser}
              label={t('rule')}
            />
            {/* <AkinonCodeEditor
              formItemProps={{
                name: FormKey.RULE,
                label: t('rule'),
                control,
              }}
              placeholder={t('rule')}
              height={200}
              options={{ readOnly: !isRequiresManualEntry && !isSuperUser }}
            />
            /> */}

            {((isSuperUser && Boolean(configId)) || !isSuperUser) && (
              <AkinonTextArea
                formItemProps={{
                  name: FormKey.DESCRIPTION,
                  label: t('description'),
                  control,
                }}
                placeholder={t('description')}
              />
            )}
            {isSuperUser && Boolean(configId) ? null : (
              <ConfigFormList
                control={control}
                id={FormKey.CONFIG}
                disabled={!isRequiresManualEntry && !isSuperUser}
              />
            )}
            <AkinonButton
              style={{ width: '100%' }}
              type="primary"
              loading={
                isFormLoading ||
                isCreatingDataSourceShippingCompanyOptionConfig ||
                isUpdatingDataSourceShippingCompanyOptionConfig ||
                isCreatingShippingCompanyOptions ||
                isUpdatingShippingCompanyOption
              }
              onClick={handleSubmit(onSubmit)}
            >
              {t('submit')}
            </AkinonButton>
          </AkinonForm>
        </AkinonSpin>
      </AkinonBox>
    </section>
  );
};

export default DeliverySettingsForm;
