import './styles.scss';

import { getFormRuleMap } from '@common/form';
import { usePageFilters } from '@components/AkinonFilter/hooks/store/usePageFilters';
import AkinonTable from '@components/AkinonTable';
import usePagination from '@components/AkinonTable/hooks/usePagination';
import { getRejectionReasonsUrl } from '@constants/apiUrls';
import { QueryKey } from '@constants/query';
import { useIsMobile } from '@hooks/useIsMobile';
import { usePatchOfferRejectReasonMutation } from '@services/api/hooks/usePatchOfferRejectReasonMutation';
import { queryClient } from '@services/api/queryClient';
import { useOfferRejectReasonsQuery } from '@services/hooks/offer/useOfferRejectReasonsQuery';
import { Form } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getColumns } from './common';
import EditableCell from './components/EditableCell';
import RejectionReasonForm from './components/RejectionReasonForm';
import { useBreadcrumbs } from './hooks/useBreadcrumbs';
import { usePageMeta } from './hooks/usePageMeta';

const SellerOfferRejectReasons = () => {
  const { t } = useTranslation('Settings');
  const isMobile = useIsMobile();
  const [form] = Form.useForm();
  const [pagination, setPagination] = usePagination();
  const [showRejectReasonModal, setShowRejectReasonModal] = React.useState(false);
  const [editingKey, setEditingKey] = useState('');

  const formRuleMap = getFormRuleMap({ t });
  const filters = usePageFilters();

  const { data, isFetching, total } = useOfferRejectReasonsQuery({
    pagination,
    filters,
  });

  const { patchOfferRejectReason } = usePatchOfferRejectReasonMutation({
    mutationOptions: {
      onSuccess: () => {
        handleCancelEditing();
        queryClient.invalidateQueries([QueryKey.REJECTION_REASONS]);
      },
    },
  });

  const handleEditRow = (record) => {
    form.setFieldsValue({
      ...record,
    });
    setEditingKey(record.id);
  };

  const handleCancelEditing = () => {
    setEditingKey('');
  };

  const handleSaveRow = async (record) => {
    const formValues = await form.validateFields();
    patchOfferRejectReason({
      requestBody: formValues,
      rejectionReasonId: record.id,
      requestConfig: {
        successMessage: t('transaction_success'),
        successDescription: t('offer_reject_reason_message.update.success'),
        errorMessage: t('transaction_failed'),
        errorDescription: t('offer_reject_reason_message.update.error'),
      },
    });
  };

  const isEditing = (record) => record.id === editingKey;
  const columns = getColumns({
    t,
    formRuleMap,
    editingKey,
    isEditing,
    handleEditRow,
    handleCancelEditing,
    handleSaveRow,
    isMobile,
  });

  usePageMeta({
    setShowRejectReasonModal,
  });
  useBreadcrumbs();

  return (
    <section className="offer-reject-reasons">
      <Form form={form} component={false}>
        <AkinonTable
          rowKey="id"
          columns={columns}
          dataSource={data}
          optionsUrl={getRejectionReasonsUrl}
          loading={isFetching}
          total={total}
          pagination={pagination}
          setPagination={setPagination}
          description={`${total} ${t('results.found')}`}
          title={t('seller_offer_reject_reasons')}
          components={{
            body: {
              cell: EditableCell,
            },
          }}
        />
      </Form>
      <RejectionReasonForm
        modalProps={{
          open: showRejectReasonModal,
          onCancel: () => setShowRejectReasonModal(false),
          title: t('add_new_offer_rejection_reason'),
        }}
      />
    </section>
  );
};

export default SellerOfferRejectReasons;
