import { useDynamicFilterFields } from '@utils/hooks/useDynamicFilterFields';
import { useMemo } from 'react';

import { getStaticFilters } from '../common';

const useFilterFields = ({
  t,
  attributes,
  isAttributesLoading,
  isSuperUser,
  categoryTreeData,
  isCategoriesLoading,
}) => {
  const dynamicFields = useDynamicFilterFields({ attributes, isAttributesLoading });
  const staticFilters = useMemo(
    () => getStaticFilters({ t, isSuperUser, categoryTreeData, isCategoriesLoading }),
    [t, isSuperUser, categoryTreeData, isCategoriesLoading, getStaticFilters]
  );

  return useMemo(
    () => ({
      dynamic: dynamicFields,
      static: staticFilters,
    }),
    [dynamicFields, staticFilters]
  );
};

export default useFilterFields;
