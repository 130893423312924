import './style.scss';

import AkinonFormItem from '@components/AkinonFormItem';
import { IconInfoCircleFilled } from '@tabler/icons-react';
import { Radio, Tooltip } from 'antd';
import clsx from 'clsx';
import { forwardRef } from 'react';

const AkinonRadioGroup = Radio.Group;
const AkinonRadioButton = Radio.Button;
const AkinonRadioBox = Radio.Box;

const AkinonRadio = forwardRef((props, ref) => {
  const { formItemProps, className, options, ...restProps } = props;
  const component = (
    <AkinonRadioGroup className={clsx('akinon-radio', className)} {...restProps} ref={ref}>
      {options.map((option, index) => {
        const { label, value, optionType, tooltip, ...rest } = option;
        const Option = optionType ? Radio[optionType] : Radio;
        return (
          <Option key={index} value={value} {...rest}>
            {label}
            {tooltip && (
              <Tooltip title={tooltip} className="akinon-radio__tooltip">
                <IconInfoCircleFilled size={20} />
              </Tooltip>
            )}
          </Option>
        );
      })}
    </AkinonRadioGroup>
  );
  return formItemProps ? (
    <AkinonFormItem {...formItemProps}>{component}</AkinonFormItem>
  ) : (
    component
  );
});

export { AkinonRadio, AkinonRadioBox, AkinonRadioButton, AkinonRadioGroup };
