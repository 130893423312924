import { useDynamicFilterFields } from '@utils/hooks/useDynamicFilterFields';

import useStaticFilterFields from './useStaticFilterFields';

const useFilterFields = ({ t, attributes, isAttributesLoading }) => {
  const dynamicFilterFields = useDynamicFilterFields({ attributes, isAttributesLoading });
  const staticFilterFields = useStaticFilterFields({ t });

  return {
    dynamic: dynamicFilterFields,
    static: staticFilterFields,
  };
};

export default useFilterFields;
