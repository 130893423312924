import { useCategoriesQuery } from '@services/api/hooks/useCategoriesQuery';
import { useMemo } from 'react';

import { generateTreeData } from '..';

export function useCategoryTreeData({ queryOptions, generateTreeDataOptions } = {}) {
  const { categories, isCategoriesLoading, isCategoriesFetching } = useCategoriesQuery({
    queryOptions,
  });

  const categoryTreeData = useMemo(
    () =>
      generateTreeData({
        data: categories,
        ...(generateTreeDataOptions ?? {}),
      }),
    [categories]
  );

  return {
    categoryTreeData,
    categories,
    isCategoriesLoading,
    isCategoriesFetching,
  };
}
