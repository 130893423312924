import { isSuperUser } from '@constants/commontypes';
import { defaultDateTimeFormat } from '@constants/index';
import { latentNameRenderer, statusActivePassiveFormatter, statusRenderer } from '@utils/index';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import * as z from 'zod';

export const FormKey = {
  EMAIL: 'email',
  FIRST_NAME: 'first_name',
  LAST_NAME: 'last_name',
  IS_ACTIVE: 'is_active',
  IS_STAFF: 'is_staff',
  IS_SUPERUSER: 'is_superuser',
  SOURCE_IN: 'source__in',
  DATASOURCE: 'datasource',
};

export const DataIndex = {
  IS_SUPERUSER: 'is_superuser',
  IS_ACTIVE: 'is_active',
  FIRST_NAME: 'first_name',
  LAST_NAME: 'last_name',
  EMAIL: 'email',
  DATASOURCE: 'datasource',
  DATE_JOINED: 'date_joined',
  LAST_LOGIN: 'last_login',
};

export const UserSources = {
  LOCAL: '0',
  OMNITRON: '1',
  INTEGRATION: '2',
};

export const StaticFilterKey = {
  FIRST_NAME: 'first_name__icontains',
  LAST_NAME: 'last_name__icontains',
  EMAIL: 'email__icontains',
  IS_ACTIVE: 'is_active',
  DATASOURCE: 'datasource',
  SOURCE_IN: 'source__in',
};

export const getFormSchema = ({ dynamicUserSettings }) => {
  return z.object({
    [FormKey.EMAIL]: z.string().email(),
    [FormKey.FIRST_NAME]: z.string().min(1),
    [FormKey.LAST_NAME]: z.string().min(1),
    [FormKey.IS_ACTIVE]: z.coerce.boolean(),
    [FormKey.IS_SUPERUSER]: z.coerce.boolean(),
    [FormKey.DATASOURCE]: z.string().optional(),
    extra: z
      .object({
        ...dynamicUserSettings?.reduce((acc, key) => {
          acc[key] = z.string().nullish();
          return acc;
        }, {}),
      })
      .nullish(),
  });
};

const getLastLoginDate = (cellData) => {
  if (isEmpty(cellData)) {
    return 'N/A';
  }
  return moment(cellData).format(defaultDateTimeFormat);
};

export const getColumns = ({ t }) => {
  return [
    {
      dataIndex: DataIndex.DATASOURCE,
      visibleOnFilter: [StaticFilterKey.DATASOURCE],
      title: t('seller'),
      render: (cellData) => cellData?.name,
    },
    {
      dataIndex: DataIndex.EMAIL,
      visibleOnFilter: [StaticFilterKey.EMAIL],
      title: t('email'),
      render: (cellData) => latentNameRenderer(cellData),
      className: 'none-capitalize',
    },
    {
      dataIndex: DataIndex.FIRST_NAME,
      visibleOnFilter: [StaticFilterKey.FIRST_NAME],
      title: t('first_name'),
    },
    {
      dataIndex: DataIndex.LAST_NAME,
      visibleOnFilter: [StaticFilterKey.LAST_NAME],
      title: t('last_name'),
    },
    {
      dataIndex: DataIndex.IS_ACTIVE,
      visibleOnFilter: [StaticFilterKey.IS_ACTIVE],
      title: t('status'),
      render: (cellData) => statusActivePassiveFormatter({ t, cellData }),
    },
    {
      dataIndex: DataIndex.IS_SUPERUSER,
      title: t('permission'),
      render: (cellData) => statusRenderer(isSuperUser({ t }), cellData),
    },
    {
      dataIndex: DataIndex.LAST_LOGIN,
      title: t('last_login'),
      render: (cellData) => getLastLoginDate(cellData),
    },
    {
      dataIndex: DataIndex.DATE_JOINED,
      title: t('registration_date'),
      render: (cellData) => moment(cellData).format(defaultDateTimeFormat),
    },
  ];
};
