import { formatRowCurrency } from '@common/currency';
import { formatDateTime } from '@common/dateTime';
import { findElementByInnerText, getTourSelectorWithFallback } from '@common/index';
import { RouteUrls } from '@constants/routeUrls';
import { ProductStatus, StatusText } from '@pages/Orders/common';
import i18n from '@root/i18n';
import { Tag, Tooltip, Typography } from 'antd';
import clsx from 'clsx';
import React, { Fragment } from 'react';
import { generatePath, Link } from 'react-router-dom';

const { Text, Title } = Typography;

export const orderStatuses = ({ t }) =>
  [
    {
      value: ProductStatus.PENDING_APPROVAL,
      label: StatusText({ t })?.[ProductStatus.PENDING_APPROVAL],
    },
    {
      value: ProductStatus.APPROVED,
      label: StatusText({ t })?.[ProductStatus.APPROVED],
    },
    {
      value: ProductStatus.PENDING_PACKAGING,
      label: StatusText({ t })?.[ProductStatus.PENDING_PACKAGING],
    },
    {
      value: ProductStatus.PACKAGED,
      label: StatusText({ t })?.[ProductStatus.PACKAGED],
    },
    {
      value: ProductStatus.DELIVERED_TO_SHIPPING,
      label: StatusText({ t })?.[ProductStatus.DELIVERED_TO_SHIPPING],
    },
    {
      value: ProductStatus.DELIVERY_FAILED,
      label: StatusText({ t })?.[ProductStatus.DELIVERY_FAILED],
    },
    {
      value: ProductStatus.DELIVERED_TO_CUSTOMER,
      label: StatusText({ t })?.[ProductStatus.DELIVERED_TO_CUSTOMER],
    },
    {
      value: ProductStatus.CANCELLATION_WAITING,
      label: StatusText({ t })?.[ProductStatus.CANCELLATION_WAITING],
    },
    {
      value: ProductStatus.CANCELLED_AND_RETURNED,
      label: StatusText({ t })?.[ProductStatus.CANCELLED_AND_RETURNED],
    },
    {
      value: ProductStatus.REJECTED,
      label: StatusText({ t })?.[ProductStatus.REJECTED],
    },
  ].map((item, index) => ({ ...item, pk: index }));

export const getOrderStatusesRenderMap = ({ t }) => {
  return {
    [ProductStatus.PENDING_APPROVAL]: {
      label: StatusText({ t })?.[ProductStatus.PENDING_APPROVAL],
      tagColor: 'blue',
    },
    [ProductStatus.APPROVED]: {
      label: StatusText({ t })?.[ProductStatus.APPROVED],
      tagColor: 'green',
    },
    [ProductStatus.PENDING_PACKAGING]: {
      label: StatusText({ t })?.[ProductStatus.PENDING_PACKAGING],
      tagColor: 'orange',
    },
    [ProductStatus.PACKAGED]: {
      label: StatusText({ t })?.[ProductStatus.PACKAGED],
      tagColor: 'yellow',
    },
    [ProductStatus.DELIVERED_TO_SHIPPING]: {
      label: StatusText({ t })?.[ProductStatus.DELIVERED_TO_SHIPPING],
      tagColor: 'purple',
    },
    [ProductStatus.DELIVERY_FAILED]: {
      label: StatusText({ t })?.[ProductStatus.DELIVERY_FAILED],
      tagColor: 'volcano',
    },
    [ProductStatus.DELIVERED_TO_CUSTOMER]: {
      label: StatusText({ t })?.[ProductStatus.DELIVERED_TO_CUSTOMER],
      tagColor: 'green',
    },
    [ProductStatus.CANCELLATION_WAITING]: {
      label: StatusText({ t })?.[ProductStatus.CANCELLATION_WAITING],
      tagColor: 'red',
    },
    [ProductStatus.CANCELLED_AND_RETURNED]: {
      label: StatusText({ t })?.[ProductStatus.CANCELLED_AND_RETURNED],
      tagColor: 'red',
    },
    [ProductStatus.REJECTED]: {
      label: StatusText({ t })?.[ProductStatus.REJECTED],
      tagColor: 'red',
    },
  };
};

export const StaticFilterKey = {
  SELLER: 'datasource',
  SKU: 'sku__startswith',
  BASE_CODE: 'base_code',
  TRACKING_NUMBER: 'tracking_number',
  NUMBER: 'number',
  DATE_GTE: 'date_placed__gte',
  DATE_LTE: 'date_placed__lte',
  STATUS: 'status',
  CANCELLATION: 'cancellationplan__status',
  AMOUNT_GTE: 'amount__gte',
  AMOUNT_LTE: 'amount__lte',
  DISCOUNT_AMOUNT_GTE: 'discount_amount__gte',
  DISCOUNT_AMOUNT_LTE: 'discount_amount__lte',
  FIRST_NAME: 'shipping_address__first_name',
  LAST_NAME: 'shipping_address__last_name',
  GIFT_BOX: 'gift_box_note__isnull',
  SHIPPING_ADDRESS: {
    EMAIL: 'shipping_address__email',
  },
};

const DataIndex = {
  NUMBER: 'number',
  DATE: 'date_placed',
  FIRST_NAME: ['customer', 'first_name'],
  LAST_NAME: ['customer', 'last_name'],
  STATUS: 'status',
  AMOUNT: 'amount',
  DISCOUNT_AMOUNT: 'discount_amount',
  DATASOURCE: ['datasource', 'name'],
  CUSTOMER_EMAIL: ['customer', 'email'],
  INVOICE_NUMBER: 'invoice_number',
  CANCELLATION_PLAN: 'cancellation_plan',
};

export const ExportOption = {
  ORDER_ITEMS: 'orderItems',
  ORDERS: 'orders',
};

export const getColumns = ({ t, isSuperUser }) => {
  const orderStatusesRenderMap = getOrderStatusesRenderMap({ t });
  return [
    {
      dataIndex: DataIndex.NUMBER,
      visibleOnFilter: [StaticFilterKey.NUMBER],
      title: t('order.number'),
      render: (_, record, index) => {
        const isFirstRow = index === 0;
        return (
          <Link
            className={clsx('order-number', { 'order-number--first-row': isFirstRow })}
            to={generatePath(RouteUrls.order.orderDetail, {
              orderId: record.id,
            })}
          >
            {record.number}
          </Link>
        );
      },
    },
    {
      dataIndex: DataIndex.DATE,
      visibleOnFilter: [StaticFilterKey.DATE_GTE, StaticFilterKey.DATE_LTE],
      title: t('order.date'),
      render: (orderDate) => (
        <Tooltip title={formatDateTime(orderDate)}>{formatDateTime(orderDate)}</Tooltip>
      ),
    },
    {
      dataIndex: DataIndex.STATUS,
      visibleOnFilter: [StaticFilterKey.STATUS],
      title: t('order.status'),
      render: (cellData) => {
        // TODO: Dynamic color.
        // const status = statusRenderer(orderStatuses({ t }), JSON.stringify(cellData));
        const { tagColor, label } = orderStatusesRenderMap[cellData];

        return (
          <Tooltip title={label}>
            <Tag color={tagColor}>{label}</Tag>
          </Tooltip>
        );
      },
    },
    {
      dataIndex: DataIndex.AMOUNT,
      visibleOnFilter: [StaticFilterKey.AMOUNT_GTE, StaticFilterKey.AMOUNT_LTE],
      title: t('order_amount'),
      render: (amount, record) => (
        <Tooltip title={formatRowCurrency(amount, record)}>
          {formatRowCurrency(amount, record)}
        </Tooltip>
      ),
    },
    {
      dataIndex: DataIndex.DISCOUNT_AMOUNT,
      visibleOnFilter: [StaticFilterKey.DISCOUNT_AMOUNT_LTE, StaticFilterKey.DISCOUNT_AMOUNT_GTE],
      title: t('discount_amount'),
      render: (discountAmount, record) => (
        <Tooltip title={formatRowCurrency(discountAmount, record)}>
          {formatRowCurrency(discountAmount, record)}
        </Tooltip>
      ),
    },
    isSuperUser && {
      dataIndex: DataIndex.DATASOURCE,
      visibleOnFilter: [StaticFilterKey.SELLER],
      title: t('seller'),
      render: (seller) => <Tooltip title={seller}>{seller}</Tooltip>,
    },
    {
      dataIndex: DataIndex.FIRST_NAME,
      visibleOnFilter: [StaticFilterKey.FIRST_NAME],
      title: t('first_name'),
      render: (firstName) => <Tooltip title={firstName}>{firstName}</Tooltip>,
    },
    {
      dataIndex: DataIndex.LAST_NAME,
      visibleOnFilter: [StaticFilterKey.LAST_NAME],
      title: t('last_name'),
      render: (lastName) => <Tooltip title={lastName}>{lastName}</Tooltip>,
    },
    {
      dataIndex: DataIndex.CUSTOMER_EMAIL,
      visibleOnFilter: [StaticFilterKey.SHIPPING_ADDRESS.EMAIL],
      title: t('email'),
      render: (email) => <Tooltip title={email}>{email}</Tooltip>,
    },
    {
      dataIndex: DataIndex.INVOICE_NUMBER,
      title: t('invoice_number'),
      render: (invoiceNumber) => <Tooltip title={invoiceNumber}>{invoiceNumber}</Tooltip>,
    },
    {
      dataIndex: DataIndex.CANCELLATION_PLAN,
      visibleOnFilter: [StaticFilterKey.CANCELLATION],
      title: t('cancel_status'),
      render: (cellData) => <Tooltip title={cellData}>{cellData}</Tooltip>,
    },
  ];
};

export const getTourSteps = ({ t }) => {
  return [
    {
      selector: '.filter-options__filter-modal-open-button',
      content: (
        <Fragment>
          <Title level={4}>{t('order_list_tutorial.dynamic_filters_title')}</Title>
          <Text>{t('order_list_tutorial.dynamic_filters')}</Text>
        </Fragment>
      ),
    },
    {
      selector: '.standard-akinon-table-header',
      content: (
        <Fragment>
          <Title level={4}>{t('order_list_tutorial.table_title')}</Title>
          <Text>{t('order_list_tutorial.table')}</Text>
        </Fragment>
      ),
    },
    {
      selector: '.export-list__export-options-popover',
      content: (
        <Fragment>
          <Title level={4}>{t('order_list_tutorial.table_export_title')}</Title>
          <Text>{t('order_list_tutorial.table_export')}</Text>
        </Fragment>
      ),
    },
    {
      selector: getTourSelectorWithFallback(
        ['.order-number--first-row'],
        findElementByInnerText(t('order.number').toLocaleUpperCase(i18n.language), 'th')
      ),
      content: (
        <Fragment>
          <Title level={4}>{t('order_list_tutorial.table_order_number_title')}</Title>
          <Text>{t('order_list_tutorial.table_order_number')}</Text>
        </Fragment>
      ),
    },
  ];
};
