import AkinonFilter from '@components/AkinonFilter';
import { usePageFilters } from '@components/AkinonFilter/hooks/store/usePageFilters';
import AkinonTable from '@components/AkinonTable';
import usePagination from '@components/AkinonTable/hooks/usePagination';
import { getAgreementConsentsUrl } from '@constants/apiUrls';
import { defaultRowKey } from '@constants/index';
import { TranslationKey } from '@root/i18n';
import { useDataSourcesQuery } from '@services/api/hooks';
import { Divider } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { getColumns } from './common';
import useDataSource from './hooks/useDataSource';
import useFilterFields from './hooks/useFilterFields';

function AgreementConsentList() {
  const { t } = useTranslation(TranslationKey.FINANCE);
  const [pagination, setPagination] = usePagination();
  const filters = usePageFilters();

  const { dataSources, isFetchingDataSources } = useDataSourcesQuery();

  const { dataSource, isDataSourceLoading, total } = useDataSource({ pagination, filters });
  const columns = getColumns({ t });

  const filterFields = useFilterFields({
    t,
    dataSources,
    isDataSourcesLoading: isFetchingDataSources,
  });

  return (
    <>
      <AkinonFilter title={t('filter')} filterFields={filterFields} />
      <Divider />
      <AkinonTable
        title={t('agreement_consents')}
        rowKey={defaultRowKey}
        dataSource={dataSource}
        optionsUrl={getAgreementConsentsUrl}
        columns={columns}
        loading={isDataSourceLoading}
        total={total}
        description={`${total} ${t('results.found')}`}
        pagination={pagination}
        setPagination={setPagination}
      />
    </>
  );
}

export default AgreementConsentList;
