import { useApproveDataSourceLandingPageMutation } from '@services/api/hooks/useApproveDataSourceLandingPageMutation';
import { queryClient } from '@services/api/queryClient';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';

const ApproveDataSourceLandingPageModal = ({
  isVisible,
  onClose,
  dataSourceLandingPage,
  queryKey,
}) => {
  const { approveDataSourceLandingPage } = useApproveDataSourceLandingPageMutation();
  const { t } = useTranslation('SellerStoreManagement');

  return (
    <div>
      <Modal
        open={isVisible}
        title={t('approve_datasource_landing_page')}
        centered
        onCancel={() => onClose()}
        onOk={async () => {
          approveDataSourceLandingPage(
            {
              dataSourceLandingPageId: dataSourceLandingPage.id,
              requestConfig: {
                successMessage: t('transaction_success'),
                successDescription: t('approve_landing_page_message.success'),
                errorMessage: t('transaction_failed'),
                errorDescription: t('approve_landing_page_message.error'),
              },
            },
            {
              onSuccess: () => {
                if (queryKey) queryClient.invalidateQueries(queryKey);
                onClose();
              },
            }
          );
        }}
      >
        <div>{t('approve_datasource_landing_page_are_you_sure')}</div>
      </Modal>
    </div>
  );
};

export default ApproveDataSourceLandingPageModal;
