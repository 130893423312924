import './styles.scss';

import AkinonTable from '@components/AkinonTable';
import usePagination from '@components/AkinonTable/hooks/usePagination';
import { UserRole } from '@constants/auth';
import { WidgetPropsKeys } from '@constants/commontypes';
import { defaultRowKey } from '@constants/index';
import { useIsMobile } from '@hooks/useIsMobile';
import { useProductImagesQuery } from '@services/api/hooks/usePaginatedProductImagesQuery';
import { useOptionsProductPricesQuery } from '@services/hooks/products/useOptionsProductPricesQuery';
import { useUserRole } from '@utils/hooks/useUserRole';
import useStore from '@zustand-store/index';
import {
  setVariantsListAttributeMetaFieldsSelector,
  variantsFormDynamicMetaFieldsSelector,
  variantsListAttributeMetaFieldsSelector,
} from '@zustand-store/selectors/preOffer';
import { Form, Space } from 'antd';
import isEmpty from 'lodash/isEmpty';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useQueriesContext } from '../../contexts/Queries';
import { useQueries } from '../../hooks/api/useQueries';
import {
  getColumns,
  getInitialTableFormData,
  getTableActions,
  ProductsToBeAddedTableKey,
} from './common';
import BulkUpdatePricesModal from './components/BulkUpdatePricesModal';
import BulkUpdateStocksModal from './components/BulkUpdateStocksModal';
import ProductImagesModal from './components/ProductImagesModal';

const ProductsToBeAddedTableForm = ({
  form,
  onShowProductRevisionModal,
  productSummariesDataSource,
  setRowsToHide,
  visibleProductSummariesDataSource,
}) => {
  const { t } = useTranslation('ProductsAndCategories');
  const isMobile = useIsMobile();
  const [isProductImagesModalVisible, setIsProductImagesModalVisible] = useState(false);
  const [pagination, setPagination] = usePagination();
  const { buyboxPrice } = useQueries();
  const { productMeta } = useQueriesContext();

  useEffect(() => {
    form.reset(getInitialTableFormData({ productSummariesDataSource, buyboxPrice }));
  }, [productSummariesDataSource, buyboxPrice]);

  const userRole = useUserRole();
  const isSuperUser = userRole === UserRole.SUPER_USER;
  const { productImages, isProductImagesLoading } = useProductImagesQuery({
    params: {
      limit: productSummariesDataSource?.length,
      parent__in: productSummariesDataSource?.map((product) => product.id).filter(Boolean),
    },
    queryOptions: { enabled: !isEmpty(productSummariesDataSource) },
  });
  const [isBulkUpdatePriceModalVisible, setIsBulkUpdatePriceModalVisible] = useState(false);
  const [isBulkUpdateStockModalVisible, setIsBulkUpdateStockModalVisible] = useState(false);
  const { productPricesOptions, isProductPricesOptionsLoading } = useOptionsProductPricesQuery();
  // TODO refactor below, why are these fetched from store?
  const variantsFormDynamicMetaFields = useStore(variantsFormDynamicMetaFieldsSelector);
  const variantsListAttributeMetaFields = useStore(variantsListAttributeMetaFieldsSelector);

  const dataSourceWithImages = visibleProductSummariesDataSource?.map((item) => ({
    ...item,
    [ProductsToBeAddedTableKey.PRODUCT_IMAGE]: productImages?.find(
      (productImage) => productImage.parent === item.id
    )?.image,
  }));
  const tableActions = getTableActions({
    t,
    setIsBulkUpdatePriceModalVisible,
    setIsBulkUpdateStockModalVisible,
    dataSource: visibleProductSummariesDataSource,
    onShowProductRevisionModal,
  });
  const columns = getColumns({
    t,
    variantsFormDynamicMetaFields,
    variantsListAttributeMetaFields,
    dataSource: visibleProductSummariesDataSource,
    form,
    productPricesOptions,
    isProductPricesOptionsLoading,
    setIsProductImagesModalVisible,
    isMobile,
    isSuperUser,
    setRowsToHide,
  });

  const isTableLoading = isProductImagesLoading || isEmpty(productMeta);

  const onImageModalCancel = () => {
    setIsProductImagesModalVisible(false);
  };

  // TODO refactor below effect, why is this fetched from store?
  const setVariantsListAttributeMetaFields = useStore(setVariantsListAttributeMetaFieldsSelector);

  useEffect(() => {
    const newVariantsListAttributeMetaFields = variantsListAttributeMetaFields?.map((metaField) => {
      metaField.widgetProps[WidgetPropsKeys.SHOW_SEARCH] = true;
      return metaField;
    });

    setVariantsListAttributeMetaFields([...newVariantsListAttributeMetaFields]);
  }, []);

  return (
    <Space
      className="products-to-be-added-table-form"
      style={{ width: '100%' }}
      direction="vertical"
      size="large"
    >
      <Form className="akn-form akn-form-v2">
        <AkinonTable
          className="variants-list"
          title={t('product_summary')}
          description={t('products.to.be.added.table.description')}
          columns={columns}
          rowKey={defaultRowKey}
          dataSource={dataSourceWithImages}
          tableActions={isSuperUser ? [] : tableActions}
          pagination={pagination}
          setPagination={setPagination}
          total={visibleProductSummariesDataSource.length}
          loading={isTableLoading}
        />
      </Form>
      <BulkUpdatePricesModal
        dataSource={visibleProductSummariesDataSource}
        form={form}
        isBulkUpdatePriceModalVisible={isBulkUpdatePriceModalVisible}
        setIsBulkUpdatePriceModalVisible={setIsBulkUpdatePriceModalVisible}
        productPricesOptions={productPricesOptions}
        isProductPricesOptionsLoading={isProductPricesOptionsLoading}
      />
      <BulkUpdateStocksModal
        dataSource={visibleProductSummariesDataSource}
        form={form}
        isBulkUpdateStockModalVisible={isBulkUpdateStockModalVisible}
        setIsBulkUpdateStockModalVisible={setIsBulkUpdateStockModalVisible}
      />
      <ProductImagesModal
        onImageModalCancel={onImageModalCancel}
        isProductImagesModalVisible={isProductImagesModalVisible}
        productImages={productImages}
      />
    </Space>
  );
};

export default ProductsToBeAddedTableForm;
