import { useAkinonQuery } from '../adapters/useAkinonQuery';
import { client } from '../client';

export function useOptionsQuery({ url, queryOptions } = {}) {
  const { data, isFetching } = useAkinonQuery({
    queryKey: ['options', url],
    queryFn: () => client.options(url),
    ...queryOptions,
  });

  return {
    options: data,
    isFetchingOptions: isFetching,
  };
}
