import { useDynamicFilterFields } from '@utils/hooks/useDynamicFilterFields';
import { useMemo } from 'react';

import useStaticFilterFields from './useStaticFilterFields';

const useFilterFields = ({ t, attributes, isAttributesLoading }) => {
  const dynamicFilterFields = useDynamicFilterFields({ attributes, isAttributesLoading });
  const staticFilterFields = useStaticFilterFields({ t });

  return useMemo(
    () => ({
      dynamic: dynamicFilterFields,
      static: staticFilterFields,
    }),
    [dynamicFilterFields, staticFilterFields]
  );
};

export default useFilterFields;
