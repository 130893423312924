import { usePaginatedPackagesQuery, usePaginatedPackagesWithFileQuery } from '@services/api/hooks';
import { useCitiesQuery } from '@services/api/hooks/useCitiesQuery';
import { useCountriesQuery } from '@services/api/hooks/useCountriesQuery';
import { useCustomerAddressesQuery } from '@services/api/hooks/useCustomerAddressQuery';
import { useDistrictsQuery } from '@services/api/hooks/useDistrictsQuery';
import { useDataSourcesQuery } from '@services/api/hooks/usePaginatedDataSourcesQuery';
import { useTownshipsQuery } from '@services/api/hooks/useTownshipsQuery';
import find from 'lodash/find';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import map from 'lodash/map';
import reject from 'lodash/reject';
import uniq from 'lodash/uniq';

import { useDeliveryOptionForPackage, useOrderDetailsForPackage } from './index';

export const useDataSource = ({ pagination, filters, params = {}, isSuperUser }) => {
  const hasFileFilter = Object.values(filters ?? {}).some((filter) => filter?.originFileObj);
  const { packages, totalPackages, isFetchingPackages } = usePaginatedPackagesQuery({
    pagination,
    filters,
    params,
    queryOptions: {
      enabled: !hasFileFilter,
    },
  });

  const {
    packages: _packages,
    totalPackages: _totalPackages,
    isFetchingPackages: _isFetchingPackages,
  } = usePaginatedPackagesWithFileQuery({
    pagination,
    filters,
    params,
    queryOptions: {
      enabled: hasFileFilter,
    },
  });

  const _dataSource = hasFileFilter ? _packages : packages;

  const orderIds = uniq(reject(map(_dataSource, 'order'), isNil));

  const { packagesWithOrders, isFetchingOrders } = useOrderDetailsForPackage({
    orderIds,
    dataSource: _dataSource,
  });

  const { dataSources } = useDataSourcesQuery({
    queryOptions: {
      enabled: Boolean(isSuperUser),
    },
  });

  const {
    packagesWithDeliveryOption,
    isDeliveryOptionsLoading,
    shippingCompanies,
    shippingMethodOptions,
  } = useDeliveryOptionForPackage({
    dataSource: packagesWithOrders,
  });

  const shippingAddressIds = uniq(
    reject(map(packagesWithDeliveryOption, 'order.shipping_address'), isNil)
  );

  const billingAddressIds = uniq(
    reject(map(packagesWithDeliveryOption, 'order.billing_address'), isNil)
  );

  const { customerAddresses: customerBillingAddresses } = useCustomerAddressesQuery({
    filters: {
      id__in: billingAddressIds?.join(','),
      limit: billingAddressIds.length,
    },
    queryOptions: {
      enabled: !isEmpty(billingAddressIds),
    },
  });

  const { customerAddresses: customerShippingAddresses } = useCustomerAddressesQuery({
    filters: {
      id__in: shippingAddressIds?.join(','),
      limit: shippingAddressIds?.length,
    },
    queryOptions: {
      enabled: !isEmpty(shippingAddressIds),
    },
  });
  const countryIds = uniq(reject(map(customerShippingAddresses, 'country'), isNil));
  const cityIds = uniq(reject(map(customerShippingAddresses, 'city'), isNil));
  const districtIds = uniq(reject(map(customerShippingAddresses, 'district'), isNil));
  const townshipIds = uniq(reject(map(customerShippingAddresses, 'township'), isNil));

  const { countries } = useCountriesQuery({
    params: {
      id__in: countryIds.join(','),
      limit: countryIds.length,
    },
    queryOptions: {
      enabled: !isEmpty(countryIds),
    },
  });

  const { cities } = useCitiesQuery({
    params: {
      id__in: cityIds.join(','),
      limit: cityIds.length,
    },
    queryOptions: {
      enabled: !isEmpty(cityIds),
    },
  });

  const { districts } = useDistrictsQuery({
    params: {
      id__in: districtIds.join(','),
      limit: districtIds.length,
    },
    queryOptions: {
      enabled: !isEmpty(districtIds),
    },
  });

  const { townships } = useTownshipsQuery({
    params: {
      id__in: townshipIds.join(','),
      limit: townshipIds.length,
    },
    queryOptions: {
      enabled: !isEmpty(townshipIds),
    },
  });

  const __dataSource = map(packagesWithDeliveryOption, (_package) => {
    const shippingAddress = find(customerShippingAddresses, {
      id: get(_package, 'order.shipping_address'),
    });
    const billingAddress = find(customerBillingAddresses, {
      id: get(_package, 'order.billing_address'),
    });

    return {
      ..._package,
      order: {
        ...get(_package, 'order'),
        datasource: find(dataSources, { id: get(_package, 'order.datasource') }),
        billing_address: {
          ...billingAddress,
        },
        shipping_address: {
          ...shippingAddress,
          country: find(countries, { id: get(shippingAddress, 'country') }),
          city: find(cities, { id: get(shippingAddress, 'city') }),
          district: find(districts, { id: get(shippingAddress, 'district') }),
          township: find(townships, { id: get(shippingAddress, 'township') }),
        },
      },
    };
  });

  const __totalPackages = hasFileFilter ? _totalPackages : totalPackages;
  const __isFetchingPackages = hasFileFilter ? _isFetchingPackages : isFetchingPackages;

  return {
    dataSource: __dataSource,
    isDataSourceLoading: __isFetchingPackages || isFetchingOrders || isDeliveryOptionsLoading,
    total: __totalPackages || 0,
    shippingCompanies,
    shippingMethodOptions,
  };
};
