import usePageData from '@hooks/usePageData';
import { getDynamicColumns } from '@pages/ProductsAndCategories/common';
import { Tooltip } from 'antd';
import intersection from 'lodash/intersection';
import { useMemo } from 'react';

import AkinonTableSorter from '../components/AkinonTableSorter';
// import { getSimpleDynamicColumns } from './common';
import { useOrderingQuery } from './useOrderingQuery';

const useColumns = ({
  columns = [],
  appendDynamicColumnsAt = -1,
  enableDynamicColumns,
  dynamicFilterFields,
  optionsUrl,
}) => {
  const { ordering } = useOrderingQuery({
    queryOptions: {
      enabled: Boolean(optionsUrl),
    },
    url: optionsUrl,
  });
  const { pageData } = usePageData();
  const visibleFilters = pageData?.visibleFilters ?? [];

  const filteredStaticColumns = columns.filter((column) => {
    if (column?.visibleOnFilter && !intersection(visibleFilters, column.visibleOnFilter)?.length)
      return false;
    else return true;
  });
  // const simpleDynamicColumns = getSimpleDynamicColumns({ dynamicFiltersFormMetaFields });
  // const _dynamicColumns = enableDynamicColumns ? simpleDynamicColumns : [];
  const { dynamicColumns } = getDynamicColumns({
    visibleFilters,
    dynamicFilterFields,
  });
  const _dynamicColumns = enableDynamicColumns ? dynamicColumns : [];

  const slicedColumns = useMemo(
    () => filteredStaticColumns.slice(0, appendDynamicColumnsAt),
    [filteredStaticColumns, appendDynamicColumnsAt]
  );
  const restColumns = useMemo(
    () => filteredStaticColumns.slice(appendDynamicColumnsAt),
    [filteredStaticColumns, appendDynamicColumnsAt]
  );
  const visibleColumns = useMemo(
    () => [...slicedColumns, ..._dynamicColumns, ...restColumns],
    [slicedColumns, _dynamicColumns, restColumns]
  );

  const allColumns = visibleColumns
    .filter(Boolean)
    .map((column, index) => {
      let key = column.key;
      if (!key) {
        key = column?.dataIndex ?? index;
        if (Array.isArray(key)) key = key.join('_');
      }

      return { ...column, key };
    })
    .map((column) => {
      const col = { ...column };
      const { render, title, sortKey, dataIndex, tooltip } = col;
      const isImageColumn = col?.dataIndex?.includes('image');
      if (ordering?.includes?.(sortKey ?? dataIndex)) {
        col.title = <AkinonTableSorter sortKey={sortKey ?? dataIndex} title={title} />;
      }
      if (tooltip) {
        col.render = (cellData, rowData, rowIndex) => {
          const tooltipProps = {};
          const renderedColumn = (
            <div className="akinon-table-td-inner-container">
              <span className="akinon-table-td-inner-content">
                {render ? render(cellData, rowData, rowIndex) : cellData}
              </span>
            </div>
          );
          if (typeof tooltip === 'string') {
            tooltipProps.title = tooltip;
          } else if (typeof tooltip === 'function') {
            tooltipProps.title = tooltip(cellData, rowData, rowIndex);
          } else if (typeof tooltip === 'object') {
            Object.assign(tooltipProps, tooltip);
          } else if (tooltip === true) {
            tooltipProps.title = renderedColumn;
          }

          return <Tooltip {...tooltipProps}>{renderedColumn}</Tooltip>;
        };
      } else {
        col.render = (cellData, rowData, rowIndex) => {
          return (
            <div className="akinon-table-td-inner-container">
              <span className="akinon-table-td-inner-content">
                {render ? render(cellData, rowData, rowIndex) : cellData}
              </span>
            </div>
          );
        };
      }
      col.title = (
        <div className="akinon-table-th-inner-container">
          <span className="akinon-table-th-inner-content">{col.title}</span>
        </div>
      );

      if (isImageColumn) {
        col.className = 'akinon-table__image-column';
      }

      return col;
    });

  return { columns: allColumns };
};

export default useColumns;
