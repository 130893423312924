import { getRequestFiltersParams, getRequestPaginationParams } from '@common/index';
import { useAkinonQuery } from '@services/api/adapters/useAkinonQuery';
import { getReconciliationItems } from '@services/api/index';

/**
 * @typedef {import('@tanstack/react-query').UseQueryResult} UseQueryResult
 * @returns {import('@tanstack/react-query').UseQueryResult}
 */
export const useReconciliationItems = ({ pagination, filters, id, enabled = true }) => {
  const _params = {
    ...getRequestPaginationParams(pagination),
    ...getRequestFiltersParams(filters),
  };
  const queryKey = ['reconciliation-items', _params, id];

  const queryFn = () => getReconciliationItems(_params, id);

  const response = useAkinonQuery({ queryKey, queryFn, enabled });

  return {
    ...response,
    total: response.data?.count,
    reconciliationItems: response.data?.results,
  };
};
