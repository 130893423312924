import AkinonFilter from '@components/AkinonFilter';
import { useFilterParams } from '@components/AkinonFilter/hooks/store/usePageFilters';
import { openNotification } from '@components/akinonNotification';
import AkinonTable from '@components/AkinonTable';
import { ExportFormat } from '@components/AkinonTable/components/StandardAkinonTableHeader/ExportModal';
import usePagination from '@components/AkinonTable/hooks/usePagination';
import { getOrdersUrl } from '@constants/apiUrls';
import { UserRole } from '@constants/auth';
import { FileExtension } from '@constants/commontypes';
import { defaultRowKey } from '@constants/index';
import { useTour } from '@reactour/tour';
import { useExportOrderItemsQuery } from '@services/api/hooks/useExportOrderItemsQuery';
import { useExportOrdersQuery } from '@services/api/hooks/useExportOrdersQuery';
import { useUserRole } from '@utils/hooks/useUserRole';
import { Divider } from 'antd';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { ExportOption, getColumns, getTourSteps } from './common';
import { useBreadcrumbs } from './hooks/useBreadcrumbs';
import useFilterFields from './hooks/useFilterFields';
import { useMergeData } from './hooks/useMergeData';
import { usePageMeta } from './hooks/usePageMeta';

const OrderList = () => {
  const { t } = useTranslation('Orders');
  const userRole = useUserRole();
  const isSuperUser = userRole === UserRole.SUPER_USER;
  const [pagination, setPagination] = usePagination();
  const filterFields = useFilterFields();
  const { setSteps } = useTour();
  const filters = useFilterParams({
    filterFields,
  });
  const { data, isLoading, total } = useMergeData({ pagination, filters });

  useEffect(() => {
    setSteps(
      getTourSteps({
        t,
      })
    );
  }, [t]);

  const exportOnSuccess = () => {
    openNotification({
      message: t('file.preparing'),
      description: t('file.download.modal.description'),
      type: 'success',
    });
  };

  const { refetch: exportOrdersAsXlsx, isFetching: isExportingOrders } = useExportOrdersQuery({
    requestConfig: { params: { ...filters, extension: FileExtension.XLSX } },
    onSuccess: exportOnSuccess,
    filters,
  });

  const { exportOrderItems, isExportingOrderItems } = useExportOrderItemsQuery({
    params: { ...filters, extension: FileExtension.XLSX },
    onSuccess() {
      openNotification({
        type: 'success',
        message: t('file.preparing'),
        description: t('file.download.modal.description'),
      });
    },
  });

  useBreadcrumbs();
  usePageMeta();

  const columns = getColumns({ t, isSuperUser });

  const onExport = ({ exportFormat, exportOption }) => {
    if (exportFormat === ExportFormat.XLSX) {
      switch (exportOption) {
        case ExportOption.ORDERS:
          exportOrdersAsXlsx();
          break;
        case ExportOption.ORDER_ITEMS:
          exportOrderItems();
          break;
      }
    }
  };

  return (
    <section className="order-list">
      <AkinonFilter title={t('filters')} filterFields={filterFields} total={total} />
      <Divider />
      <AkinonTable
        title={t('orders_list')}
        columns={columns}
        dataSource={data}
        optionsUrl={getOrdersUrl}
        loading={isLoading}
        total={total}
        pagination={pagination}
        rowKey={defaultRowKey}
        exportFormats={[ExportFormat.XLSX]}
        exportOptions={[
          {
            label: t('export_orders'),
            value: ExportOption.ORDERS,
            loading: isExportingOrders,
          },
          {
            label: t('export_order_items'),
            value: ExportOption.ORDER_ITEMS,
            loading: isExportingOrderItems,
          },
        ]}
        onExport={onExport}
        setPagination={setPagination}
        description={`${total} ${t('results.found')}`}
      />
    </section>
  );
};

export default OrderList;
