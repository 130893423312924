import { RouteUrls } from '@constants/routeUrls';
import useAppNavigate from '@hooks/useAppNavigate';
import { TranslationKey } from '@root/i18n';
import useStore from '@zustand-store/index';
import { setPageMetaSelector } from '@zustand-store/selectors/app';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useUnmount } from 'react-use';

function usePageMeta() {
  const { t } = useTranslation(TranslationKey.FINANCE);
  const setPageMeta = useStore(setPageMetaSelector);
  const navigate = useAppNavigate();

  useEffect(() => {
    setPageMeta({
      title: t('agreements'),
      description: t('agreements_description'),
      actions: [
        {
          label: t('create_new_agreement'),
          icon: <i className="icon-arti" />,
          onClick() {
            navigate(RouteUrls.finance.agreementDetail, {
              agreementId: undefined,
            });
          },
        },
      ],
    });
  }, [t]);

  useUnmount(() => {
    setPageMeta({});
  });
}

export default usePageMeta;
