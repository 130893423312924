import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Button } from 'antd';

export const MetricControls = ({ t, handlePlusClick, handleMinusClick, rangeIndex, fields }) => (
  <div className="metric-box__panel-header">
    <span>{`${t('range')} ${rangeIndex + 1}`}</span>
    <Button
      type="text"
      shape="circle"
      icon={<PlusCircleOutlined className="metric-box__controls--add" />}
      onClick={(event) => handlePlusClick(event, rangeIndex)}
      disabled={fields.length >= 10}
    />
    <Button
      type="text"
      shape="circle"
      icon={<MinusCircleOutlined className="metric-box__controls--remove" />}
      onClick={(event) => handleMinusClick(event, rangeIndex)}
      disabled={fields.length <= 1}
    />
  </div>
);
