import './styles.scss';

import AkinonButton from '@components/AkinonButton';
import AuthorizedComponent from '@components/AuthorizedComponent';
import Box from '@components/utility/box';
import { UserRole } from '@constants/auth';
import { Col, Form, Row } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import SubmitConfirmationModal from './SubmitConfirmationModal';

const SubmitForm = ({
  onSubmit,
  isPatchPreOffersMutating,
  isSubmitPreOfferMutating,
  isApprovePreOfferMutating,
  canEditOffer,
  isRejected,
}) => {
  const [isSubmitModalVisible, setIsSubmitModalVisible] = useState(false);
  const isApproveButtonLoading = isPatchPreOffersMutating;
  const isSaveAndApproveButtonLoading =
    isPatchPreOffersMutating || isSubmitPreOfferMutating || isApprovePreOfferMutating;
  const isRejectButtonLoading = isPatchPreOffersMutating;

  const { t } = useTranslation('ProductsAndCategories');

  const onSubmitClick = () => {
    setIsSubmitModalVisible(true);
  };

  const onRejectClick = () => {
    onSubmit({ isRejectAction: true });
  };

  if (!canEditOffer) return null;

  return (
    <>
      <SubmitConfirmationModal
        onCancel={() => setIsSubmitModalVisible(false)}
        isVisible={isSubmitModalVisible}
        onOk={() => onSubmit({ isSubmitAction: true })}
      />

      <Box className="box-primary form-box">
        <Form className="akn-form akn-form-v2 submit-form">
          <Form.Item wrapperCol={{ span: 24 }}>
            <AuthorizedComponent userRole={UserRole.SELLER}>
              <Row gutter={[24, 0]}>
                <Col span={12}>
                  <AkinonButton
                    className="submit-form__approve-button"
                    loading={isApproveButtonLoading}
                    htmlType="button"
                    onClick={onSubmit}
                    type="primary"
                  >
                    {t('save_as_draft').toUpperCase()}
                  </AkinonButton>
                </Col>
                <Col span={12}>
                  <AkinonButton
                    className="submit-form__approve-button"
                    loading={isSaveAndApproveButtonLoading}
                    type="primary"
                    onClick={onSubmitClick}
                  >
                    {t('submit_for_approval').toUpperCase()}
                  </AkinonButton>
                </Col>
              </Row>
            </AuthorizedComponent>
            <AuthorizedComponent userRole={UserRole.SUPER_USER}>
              <Row gutter={[24, 0]}>
                <Col span={12}>
                  <AkinonButton
                    className="submit-form__submit-button"
                    loading={isSaveAndApproveButtonLoading}
                    htmlType="button"
                    onClick={onSubmit}
                    type="primary"
                    style={{
                      fontSize: '18px',
                    }}
                  >
                    {t(isRejected ? 'save' : 'save.and.approve').toUpperCase()}
                  </AkinonButton>
                </Col>
                {!isRejected && (
                  <Col span={12}>
                    <AkinonButton
                      className="submit-form__submit-button"
                      loading={isRejectButtonLoading}
                      onClick={onRejectClick}
                      htmlType="button"
                      type="danger"
                      style={{
                        fontSize: '18px',
                      }}
                    >
                      {t('deny').toUpperCase()}
                    </AkinonButton>
                  </Col>
                )}
              </Row>
            </AuthorizedComponent>
          </Form.Item>
        </Form>
      </Box>
    </>
  );
};

export default SubmitForm;
