import { createSelectValidation } from '@common/index';
import { buildTree } from '@components/SortableTree/utilities';
import { defaultTimeFormat, defaultTimeFormatWithSeconds } from '@constants/index';
import assign from 'lodash/assign';
import entries from 'lodash/entries';
import find from 'lodash/find';
import first from 'lodash/first';
import flatMap from 'lodash/flatMap';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import map from 'lodash/map';
import omit from 'lodash/omit';
import pick from 'lodash/pick';
import reject from 'lodash/reject';
import some from 'lodash/some';
import toPairs from 'lodash/toPairs';
import moment from 'moment';
import { z } from 'zod';

function getCrypto() {
  try {
    return window.crypto;
  } catch {
    return crypto;
  }
}

export const compatibleCrypto = getCrypto();

export const RuleType = {
  CONDITIONAL: 'conditional',
  LOGICAL: 'logical',
};

export const SchemaType = {
  STRING: 'string',
  NUMBER: 'number',
  LIST: 'list',
  TIME: 'time',
  BOOLEAN: 'boolean',
  CHOICE: 'choice',
  FIELD: 'field',
  MULTI_CHOICE: 'multiple choice',
};

export const getErrorPaths = (obj, currentPath = []) => {
  let paths = [];

  for (let key in obj) {
    if (typeof obj[key] === 'object' && obj[key] !== null) {
      if ('message' in obj[key]) {
        paths.push(currentPath + key);
      }
      paths = paths.concat(getErrorPaths(obj[key], currentPath + key + '.'));
    }
  }

  return paths;
};

export const transformRules = (_rules) => {
  return map(_rules, (_rule) => {
    return {
      ..._rule,
      params: {
        ...Object.entries(_rule.params).reduce((acc, [key, value]) => {
          const { type, required, min_length, max_length } = value;
          const _value = { ...value };
          let _key = key;
          if (type === 'list') {
            if (isNil(min_length)) {
              _value.min_length = required ? 1 : 0;
            }
            if (isNil(max_length)) {
              _value.max_length = Infinity;
            }
          } else if (type === 'field' && _key === 'child') {
            _value.type = 'list';
            _value.min_length = 1;
            _value.max_length = 1;
            _value.child = {
              ..._value.child,
              required: _value.required,
              type: 'field',
            };
            _key = 'children';
          }

          acc[_key] = _value;

          return acc;
        }, {}),
      },
    };
  });
};

const getDynamicSchema = ({ schema, rules, t }) => {
  const { type, required, child, choices, min_length, max_length } = schema;

  if (type === SchemaType.STRING) {
    const stringSchema = z.string();
    return required ? stringSchema.min(1) : stringSchema.nullish().optional();
  } else if (type === SchemaType.NUMBER) {
    const numberSchema = z.coerce.number();
    return required ? numberSchema : numberSchema.nullish().optional();
  } else if (type === SchemaType.LIST) {
    const listSchema = z.preprocess(
      (val) => {
        if (isNil(val)) return [];
        return val;
      },
      z
        .array(
          child
            ? getDynamicSchema({
                schema: { ...child, type: !!schema.resource ? 'any' : child.type },
                rules,
                t,
              })
            : z.any()
        )
        .min(min_length || 0, {
          message: t('list_min_validation_message', {
            count: min_length,
          }),
        })
        .max(max_length || Infinity, {
          message: t('list_max_validation_message', {
            count: max_length,
          }),
        })
    );

    return required ? listSchema : listSchema.optional();
  } else if (type === SchemaType.TIME) {
    const time = z
      .custom((val) => {
        return (
          !isNil(val) &&
          (moment.isMoment(val) ||
            moment(val).isValid() ||
            /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/.test(val) ||
            /^(?:(?:([01]?\d|2[0-3]):)?([0-5]?\d):)?([0-5]?\d)$/.test(val))
        );
      })
      .transform((val) =>
        /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/.test(val)
          ? val
          : /^(?:(?:([01]?\d|2[0-3]):)?([0-5]?\d):)?([0-5]?\d)$/.test(val)
            ? moment(val, defaultTimeFormatWithSeconds).format(defaultTimeFormat)
            : moment(val).format(defaultTimeFormat)
      );

    return required ? time : time.nullish().optional();
  } else if (type === SchemaType.BOOLEAN) {
    const booleanSchema = z.coerce.boolean();
    return required ? booleanSchema : booleanSchema.nullish().optional();
  } else if (type === SchemaType.MULTI_CHOICE) {
    const multiChoiceSchema = z.array(z.union(map(choices, (choice) => z.literal(choice.value))));
    return required ? multiChoiceSchema : multiChoiceSchema.nullish().optional();
  } else if (type === SchemaType.CHOICE) {
    const choiceSchema = z.union(map(choices, (choice) => z.literal(choice.value)));
    return required ? choiceSchema : choiceSchema.nullish().optional();
  } else if (type === SchemaType.FIELD) {
    const fieldSchema = z.lazy(() => getBaseRuleSchema({ rules, t }));
    return required ? fieldSchema : fieldSchema.nullish().optional();
  } else {
    return z.any();
  }
};

export const getRulesSchema = ({ rules, t }) =>
  z.discriminatedUnion(
    'slug',
    map(toPairs(rules), ([_type, _rules]) => {
      return map(_rules, (_rule) => {
        const params = Object.entries(omit(_rule.params, 'slug')).reduce(
          (acc, [key, value], _, _params) => {
            return acc.merge(
              z.object({
                [key]: getDynamicSchema({
                  schema: value,
                  rules,
                  t,
                }),
                conditions: z.any().default(
                  reject(
                    _params.flatMap(([key, _value]) => {
                      return get(_value, 'conditions', [])
                        .map((condition) => {
                          return Object.entries(condition).map(([operator, targetKey]) => {
                            return {
                              key,
                              operator,
                              targetKey,
                              label: _value.label,
                              targetLabel: _params.find(([key]) => key === targetKey)?.at(1).label,
                              type: _value.type,
                            };
                          });
                        })
                        ?.flat();
                    }),
                    isNil
                  )
                ),
                minLength: z
                  .number()
                  .optional()
                  .default(value?.min_length ?? 0),
                maxLength: z
                  .number()
                  .optional()
                  .default(value?.max_length ?? Infinity),
              })
            );
          },
          z.object({
            slug: z.literal(_rule.slug),
            title: z.string().optional().default(_rule.name),
            type: z.string().default(_type),
            canHaveChildren: z
              .boolean()
              .or(z.function().args(z.any().optional()).returns(z.boolean()))
              .default(false),
          })
        );
        return params;
      });
    }).flat()
  );

const getBaseRuleSchema = ({ rules, t }) => {
  return z.preprocess(
    (value) => {
      if (!value.id) value.id = compatibleCrypto.randomUUID();
      return value;
    },
    z
      .object({
        id: z.coerce.string().uuid(),
        collapsed: z.coerce.boolean().default(false),
        parentId: z.coerce.string().uuid().nullable().optional(),
      })
      .and(z.discriminatedUnion('slug', [...getRulesSchema({ rules, t }).options]))
  );
};

export const FormKey = {
  SHIPPING_COMPANY: 'shipping_company',
  SHIPPING_OPTION: 'shipping_option',
  IS_ACTIVE: 'is_active',
  SLUG: 'slug',
  NAME: 'name',
  PRIORITY: 'priority',
  CALCULATOR: 'calculator',
  CALCULATOR_TYPE: 'calculator.type',
  IS_REQUIRES_MANUAL_ENTRY: 'is_requires_manual_entry',
  RULE: 'rule',
  CONFIG: 'config',
  DESCRIPTION: 'description',
};

export const SchemaKey = {
  ...FormKey,
  CALCULATOR_TYPE: 'type',
};

export const getDynamicCalculatorSchema = (calculator) => {
  const calcParams = omit(calculator?.params, 'slug');

  const result = {};
  for (const key in calcParams) {
    const value = calcParams[key];
    if (value.type === 'decimal') {
      result[key] = z.coerce.number().optional();
    }
  }

  return result;
};

export const refineConditions =
  ({ t }) =>
  (val, ctx) => {
    const conditions = get(val, 'conditions');
    if (!isEmpty(conditions)) {
      conditions.forEach((condition) => {
        const { key, targetKey, operator, type, label, targetLabel } = condition;
        const targetValue = get(val, targetKey);
        const value = get(val, key);
        if (type === SchemaType.TIME) {
          if (operator === 'gt') {
            const result = moment(targetValue, defaultTimeFormat).isBefore(
              moment(value, defaultTimeFormat)
            );
            if (!result) {
              ctx.addIssue({
                code: z.ZodIssueCode.custom,
                path: [key],
                message: t('field_comparison_gt_validation_message', {
                  fieldName: label,
                  otherFieldName: targetLabel,
                }),
              });
            }
          } else if (operator === 'lt') {
            const result = moment(value, defaultTimeFormat).isBefore(
              moment(targetValue, defaultTimeFormat)
            );
            if (!result) {
              ctx.addIssue({
                code: z.ZodIssueCode.custom,
                path: [key],
                message: t('field_comparison_lt_validation_message', {
                  fieldName: label,
                  otherFieldName: targetLabel,
                }),
              });
            }
          }
        }
      });
    }
  };

export const getFormSchema = ({
  shippingCompanies,
  shippingOptions,
  shippingCompanyOptionHttpOptions,
  isSuperUser,
  configId,
  rules,
  t,
}) => {
  const configFormListValidation =
    isSuperUser && Boolean(configId)
      ? null
      : z.array(z.object({ key: z.string(), value: z.string() }));

  const calculators = shippingCompanyOptionHttpOptions?.calculators;

  const calculatorSchemas = assign(
    {},
    ...(calculators?.map((calculator) => getDynamicCalculatorSchema(calculator)) ?? [])
  );

  const baseRuleSchema = getBaseRuleSchema({
    rules,
    t,
  });
  const schema = z.object({
    [FormKey.SHIPPING_COMPANY]: createSelectValidation(shippingCompanies),
    [FormKey.SHIPPING_OPTION]: createSelectValidation(shippingOptions),
    [FormKey.IS_ACTIVE]: z.coerce.boolean(),
    [FormKey.SLUG]: z.string(),
    [FormKey.NAME]: z.string(),
    [FormKey.PRIORITY]: z.coerce.number(),
    [FormKey.RULE]: z.preprocess((val) => buildTree(val ?? []), z.array(baseRuleSchema)),
    [FormKey.CALCULATOR]: z
      .object({
        [SchemaKey.CALCULATOR_TYPE]: createSelectValidation(calculators, { valueKey: 'slug' }),
        ...calculatorSchemas,
      })
      .superRefine((val, ctx) => {
        const calculator = calculators.find((calc) => calc.slug === val.type);
        const expectedKeys = Object.keys(omit(calculator.params, ['slug', 'prices_per_currency']));
        const emptyKeys = expectedKeys.filter((key) => val[key] === undefined);
        if (emptyKeys.length > 0) {
          emptyKeys.forEach((key) => {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: t('form_validation.required'),
              path: [key],
            });
          });
        }
      })
      .transform((val) => {
        const calculator = calculators.find((calc) => calc.slug === val.type);
        const expectedKeys = Object.keys(omit(calculator.params, ['prices_per_currency']));
        return pick(val, [...expectedKeys, 'type']);
      }),
    [FormKey.IS_REQUIRES_MANUAL_ENTRY]: z.coerce.boolean(),
    [FormKey.CONFIG]: z.array(z.object({ key: z.coerce.string(), value: z.coerce.string() })),
    [FormKey.DESCRIPTION]: z.string().optional(),
  });

  if (configFormListValidation) schema[FormKey.CONFIG] = configFormListValidation;
  return schema;
};

const transformRuleData = (data) =>
  map(data, (item) => {
    const { children, slug, maxLength, ...rest } = item;
    let key = 'children';
    if (maxLength === 1) {
      key = 'child';
    }
    const omittedRestObject = omit(rest, [
      'type',
      'canHaveChildren',
      'minLength',
      'collapsed',
      'id',
      '_id',
      'parent',
      'parentId',
      'title',
      'conditions',
    ]);
    const labelInValueFields = Object.entries(omittedRestObject)
      .filter(
        ([_, value]) =>
          isArray(value) &&
          !isEmpty(value) &&
          value.some(
            (valItem) => typeof valItem === 'object' && 'label' in valItem && 'value' in valItem
          )
      )
      .reduce((acc, [key, value]) => {
        acc[key] = map(value, (_val) => get(_val, 'value', _val));
        return acc;
      }, {});

    const nestedData = {
      ...(!!children
        ? {
            [key]:
              key === 'child'
                ? {
                    ...first(transformRuleData(children)),
                  }
                : transformRuleData(children),
          }
        : {}),
      ...omittedRestObject,
      ...labelInValueFields,
    };
    return {
      slug,
      ...nestedData,
    };
  });

const getRuleData = (data) =>
  first(
    transformRuleData(
      data?.length > 1
        ? [
            {
              slug: 'and-rule',
              children: data,
            },
          ]
        : data
    )
  );

export const parseRuleData = (data, rules, parentId = null) => {
  const ruleOptionEntries = entries(rules);
  const ruleOptions = flatMap(ruleOptionEntries, ([_, ruleOptionValue]) => ruleOptionValue);
  if (isEmpty(data) || isEmpty(ruleOptions)) return [];
  return map(isArray(data) ? data : [data], (value) => {
    const { slug, children, child, ...rest } = value;
    const [type] = find(ruleOptionEntries, ([_, _rules]) => some(_rules, { slug }));
    const _children = children ?? (child && [child]);
    const id = compatibleCrypto.randomUUID();
    const rule = find(rules[type], { slug });
    const ruleParams = get(rule, 'params.children') ?? get(rule, 'params.child');
    const minLength = get(ruleParams, 'min_length');
    const maxLength = get(ruleParams, 'max_length');

    return {
      type,
      slug,
      id,
      title: rule.name,
      minLength,
      maxLength,
      parentId,
      canHaveChildren: (_, parent) => {
        const parentChildCount = parent?.children?.length;
        return parentChildCount < parent.maxLength;
      },
      ...rest,
      ...(!isEmpty(_children)
        ? {
            children: parseRuleData(_children, rules, id),
          }
        : {}),
    };
  });
};

export const getRequestBodyFromFormValues = ({ formValues, shippingCompanyOptionHttpOptions }) => {
  const configFormValues = formValues[FormKey.CONFIG] || [];
  const selectedCalculator = formValues[FormKey.CALCULATOR].type;
  const selectedCalculatorName = shippingCompanyOptionHttpOptions?.calculators?.find?.(
    (calculator) => calculator.slug === selectedCalculator
  ).name;

  return {
    ...omit(formValues, [FormKey.CONFIG]),
    [FormKey.CALCULATOR]: {
      ...omit(formValues[FormKey.CALCULATOR], 'type'),
      slug: selectedCalculator,
      name: selectedCalculatorName,
    },
    [FormKey.RULE]: getRuleData(formValues[FormKey.RULE]),
    [FormKey.CONFIG]: configFormValues.reduce((obj, item) => {
      obj[item.key] = item.value;
      return obj;
    }, {}),
  };
};
