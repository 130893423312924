import { dateFormatter, statusActivePassiveFormatter } from '@utils/index';
import { Tooltip } from 'antd';
import React from 'react';

export const SellerSegment = {
  STANDARD: 0,
  PREMIUM: 1,
};

export const StaticFilterKey = {
  NAME: 'name__icontains',
  IS_ACTIVE: 'is_active',
  CREATED_AT_GTE: 'created_at__gte',
  CREATED_AT_LTE: 'created_at__lte',
};

export const DataIndex = {
  NAME: 'name',
  IS_ACTIVE: 'is_active',
  CREATED_AT: 'created_at',
  UPDATED_AT: 'updated_at',
  SUPPLIER_CODE: 'supplier_code',
  SEGMENT: 'segment',
};

export const getColumns = ({ t }) => [
  {
    dataIndex: 'supplier_code',
    key: DataIndex.SUPPLIER_CODE,
    title: t('seller_code'),
    render: (code) => <Tooltip title={code}>{code}</Tooltip>,
  },
  {
    dataIndex: 'name',
    key: DataIndex.NAME,
    visibleOnFilter: [StaticFilterKey.NAME],
    title: t('seller.name'),
    render: (name) => <Tooltip title={name}>{name}</Tooltip>,
  },
  {
    dataIndex: 'segment',
    key: DataIndex.SEGMENT,
    title: t('segment'),
    render: (segment) => {
      return segment === SellerSegment.STANDARD
        ? t('standard')
        : segment === SellerSegment.PREMIUM
          ? t('premium')
          : t('empty');
    },
  },
  {
    dataIndex: 'is_active',
    key: DataIndex.IS_ACTIVE,
    visibleOnFilter: [StaticFilterKey.IS_ACTIVE],
    title: t('seller_status'),
    render: (cellData) => statusActivePassiveFormatter({ t, cellData }),
  },
  {
    dataIndex: 'created_at',
    key: DataIndex.CREATED_AT,
    visibleOnFilter: [StaticFilterKey.CREATED_AT_GTE, StaticFilterKey.CREATED_AT_LTE],
    title: t('creation.date1'),
    render: dateFormatter,
  },
  {
    dataIndex: 'updated_at',
    key: DataIndex.UPDATED_AT,
    title: t('last.modified.date'),
    render: dateFormatter,
  },
];
