import './style.scss';

import { CloseOutlined } from '@ant-design/icons';
import AkinonBox from '@components/AkinonBox';
import { getInitialValues } from '@components/AkinonFilter/common';
import useFiltersSlice from '@components/AkinonFilter/hooks/store/useFiltersSlice';
import AkinonSpin from '@components/AkinonSpin';
import Drawer from '@components/Drawer';
import DynamicFormElement from '@components/DynamicFormElement';
import { Col, Form, Row, Typography } from 'antd';
import { mapValues } from 'lodash';
import get from 'lodash/get';
import React from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation, useMount } from 'react-use';

import FiltersDrawerFooter from './FiltersDrawerFooter';

const { Title, Text } = Typography;

const FiltersDrawer = ({
  filterFields,
  isFiltersModalVisible,
  setIsFiltersModalVisible,
  visibleFilters,
  applyFilters,
  total,
}) => {
  const location = useLocation();
  const { t } = useTranslation();
  const { pageFilters } = useFiltersSlice();
  const form = useForm({
    defaultValues: {},
  });

  const { reset, control } = form;
  const searchQuery = get(location, ['state', 'searchQuery']);
  const formValues = useWatch({ control });

  useMount(() => {
    const initialValues = getInitialValues({
      pageFilters,
      fields: filterFields,
      searchQuery,
    });
    reset(initialValues);
  });

  const onClose = () => {
    setIsFiltersModalVisible(false);
  };

  const onApplyFilters = () => {
    applyFilters(formValues);
    onClose();
  };

  const onClearFilters = () => {
    reset(mapValues(formValues, () => null));
    applyFilters(formValues);
  };

  const visibleFilterFields = filterFields
    .filter((field) => visibleFilters?.includes?.(field.name))
    .map((field) => ({
      ...field,
      formItemProps: {
        ...field.formItemProps,
        control,
      },
    }));

  return (
    <Drawer
      open={isFiltersModalVisible}
      closable={false}
      width={'100%'}
      className="filters-drawer"
      footer={
        <FiltersDrawerFooter onApplyFilters={onApplyFilters} onClearFilters={onClearFilters} />
      }
    >
      <Row justify="space-between" className="filter-drawer-header">
        <Col>
          <Title className="filters-drawer__title">
            {t('filters')} ({filterFields?.length ?? 0})
          </Title>
        </Col>
        <CloseOutlined className="filters-drawer__close-icon" onClick={onClose} />
      </Row>
      <AkinonBox>
        <div className="filters-drawer__info">
          <Text>{`${total ?? '0'} ${t('results.found')}`}</Text>
        </div>
        <Form className="akinon-form-dark akinon-filter__form">
          <Row gutter={[0, 10]}>
            {visibleFilterFields.map((field) => (
              <Col key={field.name} span={24}>
                <AkinonSpin spinning={field?.loading ?? false} tip="">
                  <DynamicFormElement {...field} />
                </AkinonSpin>
              </Col>
            ))}
          </Row>
        </Form>
      </AkinonBox>
    </Drawer>
  );
};

export default FiltersDrawer;
