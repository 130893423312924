import { postFetchProductsUrl } from '@constants/apiUrls';

import { useAkinonMutation } from '../adapters/useAkinonMutation';
import { client } from '../client';

export function usePostFetchProductsMutation({ mutationOptions } = {}) {
  const { mutate, isLoading } = useAkinonMutation({
    mutationFn: ({ requestBody }) => {
      return client.post(postFetchProductsUrl, requestBody);
    },
    ...mutationOptions,
  });

  return {
    postFetchProducts: mutate,
    isPostFetchingProducts: isLoading,
  };
}
