import './styles.scss';

import AkinonButton from '@components/AkinonButton';
import AkinonDatePicker from '@components/AkinonDatePicker';
import AkinonForm from '@components/AkinonForm';
import AkinonInput from '@components/AkinonInput';
import { formBuilderLayout } from '@constants/layoutTypes';
import { zodResolver } from '@hookform/resolvers/zod';
import { Form, Modal } from 'antd';
import moment from 'moment';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormKey, schema } from './common';

const AccessTokenFormModal = ({
  show,
  onClose,
  createIntegrationAccessToken,
  isCreatingNewIntegrationAccessToken,
}) => {
  const { t } = useTranslation('Settings');

  const { handleSubmit, control, reset } = useForm({
    defaultValues: {},
    resolver: zodResolver(schema),
  });

  const onSubmit = (formValues) => {
    createIntegrationAccessToken({
      requestBody: formValues,
      requestConfig: {
        successMessage: t('transaction_success'),
        successDescription: t('access_tokens_message.add.success'),
        errorMessage: t('transaction_failed'),
        errorDescription: t('access_tokens_message.error'),
      },
    });
  };

  const handleClose = () => {
    onClose();
    reset();
  };

  return (
    <Modal open={show} onCancel={handleClose} footer={null} title={t('generate_new_access_token')}>
      <AkinonForm onFinish={onSubmit} layout="vertical">
        <AkinonDatePicker
          formItemProps={{
            label: t('validUntil'),
            name: FormKey.VALID_UNTIL,
            control,
            required: true,
          }}
          disabledDate={(selectedDate) => {
            if (selectedDate) {
              return moment(selectedDate).valueOf() < moment().valueOf();
            }
          }}
          style={{ width: '100%' }}
          placeholder={t('validUntil')}
        />
        <AkinonInput
          formItemProps={{
            label: t('note'),
            name: FormKey.NOTE,
            control,
          }}
          placeholder={t('note')}
        />
        <Form.Item wrapperCol={{ span: formBuilderLayout.wrapperCol }}>
          <AkinonButton
            type="primary"
            htmlType="submit"
            className="access-token-form__submit-button"
            loading={isCreatingNewIntegrationAccessToken}
            onClick={handleSubmit(onSubmit)}
          >
            {t('generate').toUpperCase()}
          </AkinonButton>
        </Form.Item>
      </AkinonForm>
    </Modal>
  );
};

export default AccessTokenFormModal;
