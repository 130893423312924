import './styles.scss';

import { CloseCircleOutlined } from '@ant-design/icons';
import useFiltersSlice from '@components/AkinonFilter/hooks/store/useFiltersSlice';
import AkinonInput from '@components/AkinonInput';
import useAppNavigate from '@hooks/useAppNavigate';
import { Row } from 'antd';
import React from 'react';
import { useLocation } from 'react-router-dom';

const MobileSearchFilter = ({ filterForm, searchQuery }) => {
  const { pageFilters } = useFiltersSlice();
  const { pathname } = useLocation();

  const navigate = useAppNavigate();

  const removeSearchFilter = () => {
    filterForm.setValue('q', undefined);
    if (pageFilters?.q) removeQueryParams();
  };

  const removeQueryParams = () => navigate(pathname, { replace: true });

  if (!searchQuery) return null;

  return (
    <Row align="middle" className="mobile-search-filter akinon-form-dark">
      <AkinonInput
        value={searchQuery}
        readOnly
        suffix={
          <div className="mobile-search-filter__button" onClick={() => removeSearchFilter()}>
            <CloseCircleOutlined />
          </div>
        }
      />
    </Row>
  );
};

export default MobileSearchFilter;
