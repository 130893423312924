import DynamicFormElement from '@components/DynamicFormElement';
import { WidgetType } from '@constants/commontypes';
import get from 'lodash/get';
import intersection from 'lodash/intersection';
import React from 'react';
import { z } from 'zod';

const ValidatorType = {
  LIST: 'list',
  NESTED: 'nested object',
  INPUT: 'input',
  STRING: 'string',
  NUMBER: 'number',
  FLOAT: 'float',
  CHOICE: 'choice',
  BOOLEAN: 'boolean',
  SELECT: 'select',
  PASSWORD: 'password',
  EMAIL: 'email',
  INTEGER: 'integer',
  DECIMAL: 'decimal',
  PERCENTAGE: 'percentage',
};

const getWidgetByValidatorType = ({ types }) => {
  return [
    { validatorTypes: [ValidatorType.LIST], widgetType: WidgetType.LIST },
    { validatorTypes: [ValidatorType.NESTED], widgetType: WidgetType.NESTED },
    {
      validatorTypes: [ValidatorType.FLOAT, ValidatorType.DECIMAL],
      widgetType: WidgetType.DECIMAL,
    },
    {
      validatorTypes: [ValidatorType.PERCENTAGE],
      widgetType: WidgetType.PERCENTAGE,
    },
    {
      validatorTypes: [ValidatorType.INTEGER, ValidatorType.NUMBER],
      widgetType: WidgetType.NUMBER,
    },
    {
      validatorTypes: [ValidatorType.BOOLEAN],
      widgetType: WidgetType.BOOLEAN,
    },
    {
      validatorTypes: [ValidatorType.CHOICE, ValidatorType.BOOLEAN, ValidatorType.SELECT],
      widgetType: WidgetType.SELECT,
    },
    {
      validatorTypes: [ValidatorType.PASSWORD],
      widgetType: WidgetType.PASSWORD,
    },
    {
      validatorTypes: [ValidatorType.EMAIL],
      widgetType: WidgetType.EMAIL,
    },
    {
      validatorTypes: [ValidatorType.INPUT],
      widgetType: WidgetType.INPUT,
    },
  ].find(({ validatorTypes }) => intersection(validatorTypes, types).length > 0)?.widgetType;
};

const serializeToFieldProps = ({ t, schema, form }) => {
  if (!schema) return;

  const { key } = schema ?? {};
  const types = get(schema, ['validators', '0', 'types'], [get(schema, 'type', WidgetType.INPUT)]);
  const widget = getWidgetByValidatorType({ types });

  if (widget === WidgetType.NESTED) {
    const fields = Object.entries(get(schema, ['validators', '0', 'fields'], {})).map(
      ([fieldKey, field]) => {
        return serializeToFieldProps({
          t,
          schema: { ...field, key: [key, fieldKey].join('.') },
          form,
        });
      }
    );

    return { widget, fields };
  }

  const formItemProps = {
    name: key ?? schema?.label,
    label: schema?.label ?? t('value'),
    control: form.control,
  };
  const placeholder = schema?.label ?? t('value');

  if (widget === WidgetType.LIST) {
    return { widget, formItemProps, placeholder, fieldKeys: ['value'] };
  }

  return {
    widget,
    formItemProps,
    placeholder,
  };
};

const transformToKeyValueFormat = (values) => {
  const keys = Object.keys(values);
  if (keys.length === 1) {
    const key = keys[0];
    return {
      key,
      value: values[key],
    };
  } else {
    return values;
  }
};

export const generateFormFields = ({ t, dynamicSetting, form }) => {
  const fieldProps = serializeToFieldProps({ t, schema: dynamicSetting, form });
  if (!fieldProps) return null;
  return <DynamicFormElement {...fieldProps} />;
};

export const getFormInitialValues = ({ dynamicSetting }) => {
  if (!dynamicSetting) return {};
  const types = get(dynamicSetting, ['validators', '0', 'types'], []);
  const widgetType = getWidgetByValidatorType({ types });
  let initialValue = dynamicSetting.value ?? dynamicSetting.default;

  if (widgetType === WidgetType.PERCENTAGE) {
    initialValue = initialValue * 100;
  }
  if (widgetType === WidgetType.LIST) {
    return {
      [dynamicSetting.key]: { value: initialValue.map((val) => ({ listItemValue: val })) },
    };
  }
  return {
    [dynamicSetting.key]: initialValue,
  };
};

export const getFormSchema = ({ schema }) => {
  if (!schema) return {};
  const types = get(schema, ['validators', '0', 'types'], [get(schema, 'type', WidgetType.INPUT)]);
  const widgetType = getWidgetByValidatorType({ types });
  const isOptional = !schema?.required;

  if (widgetType === WidgetType.LIST) {
    return z
      .object({
        [schema.key]: z
          .object({
            value: z.array(z.object({ listItemValue: z.string().optional() })),
          })
          .transform((values) => {
            return values?.value.map((val) => val?.listItemValue);
          }),
      })
      .transform(transformToKeyValueFormat);
  } else if (widgetType === WidgetType.NESTED) {
    const fields = get(schema, ['validators', '0', 'fields'], {});

    return z
      .object({
        [schema.key]: z.object(
          Object.entries(fields).reduce((acc = {}, [fieldKey]) => {
            return { ...acc, [fieldKey]: z.any().optional() };
          }, {})
        ),
      })
      .transform(transformToKeyValueFormat);
  } else if (widgetType === WidgetType.NUMBER) {
    return z
      .object({ [schema.key]: isOptional ? z.number().optional() : z.number() })
      .transform(transformToKeyValueFormat);
  } else if (widgetType === WidgetType.PERCENTAGE) {
    const transformToNumber = (value) => (value / 100).toString();
    return z
      .object({
        [schema.key]: isOptional
          ? z.number().optional().transform(transformToNumber)
          : z.number().transform(transformToNumber),
      })
      .transform(transformToKeyValueFormat);
  } else if (widgetType === WidgetType.BOOLEAN) {
    return z
      .object({
        [schema.key]: isOptional ? z.coerce.boolean().optional() : z.coerce.boolean(),
      })
      .transform(transformToKeyValueFormat);
  } else {
    return z
      .object({ [schema.key]: isOptional ? z.string().optional() : z.string() })
      .transform(transformToKeyValueFormat);
  }
};
