import { standardDateFormat } from '@common/date';
import { WidgetType } from '@constants/commontypes';
import { Tag } from 'antd';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';

const NotificationVisibility = {
  PUBLIC: 1,
  PRIVATE: 2,
};

const visibilityOptions = [
  { label: 'Public', value: NotificationVisibility.PUBLIC, color: 'green' },
  { label: 'Private', value: NotificationVisibility.PRIVATE, color: 'blue' },
];

export const DataIndex = {
  START_DATE: 'start_date',
  END_DATE: 'end_date',
  TITLE: 'title',
  AUDIENCES: 'audiences',
  VISIBILITY: 'visibility',
};

export const getColumns = ({ t }) => {
  return [
    {
      title: t('startDate'),
      dataIndex: DataIndex.START_DATE,
      render: (startDate, record) => (
        <span>{moment(startDate ?? record?.created_at).format(standardDateFormat)}</span>
      ),
    },
    {
      title: t('endDate'),
      dataIndex: DataIndex.END_DATE,
      render: (endDate, record) => (
        <span>{moment(endDate ?? record?.created_at).format(standardDateFormat)}</span>
      ),
    },
    {
      title: t('title'),
      dataIndex: DataIndex.TITLE,
      render: (title) => <span>{title ?? '-'}</span>,
    },
    {
      title: t('audience'),
      dataIndex: DataIndex.AUDIENCES,
      render: (audiences, rowData) =>
        rowData.visibility === NotificationVisibility.PUBLIC ? (
          '-'
        ) : (
          <span>{!isEmpty(audiences) ? `(${audiences?.length})` : '-'}</span>
        ),
    },
    {
      title: t('visibility'),
      dataIndex: DataIndex.VISIBILITY,
      render: (visibility) => {
        const visibilityOption = visibilityOptions.find((option) => option.value === visibility);
        return <Tag color={visibilityOption?.color}>{visibilityOption?.label ?? '-'}</Tag>;
      },
    },
  ];
};

export const StaticFilterKey = {
  START_DATE: 'created_at__gte',
  END_DATE: 'created_at__lte',
};

export const getStaticFilters = ({ t, disabledStartDate, disabledEndDate }) => {
  return {
    fields: [
      {
        name: StaticFilterKey.START_DATE,
        widget: WidgetType.DATE_PICKER,
        placeholder: t('startDate'),
        disabledDate: disabledStartDate,
      },
      {
        name: StaticFilterKey.END_DATE,
        widget: WidgetType.DATE_PICKER,
        placeholder: t('endDate'),
        disabledDate: disabledEndDate,
      },
    ],
  };
};
