import { useDynamicFilterFields } from '@utils/hooks/useDynamicFilterFields';
import { useMemo } from 'react';

import { getStaticFilters } from '../common';

const useFilterFields = ({ t, attributes, isAttributesLoading }) => {
  const dynamicFields = useDynamicFilterFields({ attributes, isAttributesLoading });
  const staticFilters = useMemo(() => getStaticFilters({ t }), [t, getStaticFilters]);

  return {
    dynamic: dynamicFields,
    static: staticFilters,
  };
};

export default useFilterFields;
