import { Form, Input, InputNumber } from 'antd';
import React from 'react';

const EditableCell = ({ editing, dataIndex, rules, children, inputProps, ...restProps }) => {
  const { type } = inputProps ?? {};
  const inputNode = type === 'number' ? <InputNumber {...inputProps} /> : <Input {...inputProps} />;
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={rules}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

export default EditableCell;
