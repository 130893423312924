import AkinonForm from '@components/AkinonForm';
import AkinonInput from '@components/AkinonInput';
import { useRejectDataSourceLandingPageMutation } from '@services/api/hooks/useRejectDataSourceLandingPageMutation';
import { queryClient } from '@services/api/queryClient';
import { Modal } from 'antd';
import get from 'lodash/get';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { FormItem } from 'react-hook-form-antd';
import { useTranslation } from 'react-i18next';

const RejectDataSourceLandingPageModal = ({
  isVisible,
  onClose,
  dataSourceLandingPage,
  queryKey,
}) => {
  const { rejectDataSourceLandingPage } = useRejectDataSourceLandingPageMutation();
  const { t } = useTranslation('SellerStoreManagement');

  const { control, reset, getValues } = useForm();

  useEffect(() => {
    reset({
      rejection_reason: dataSourceLandingPage?.rejection_reason,
    });
  }, [dataSourceLandingPage]);

  return (
    <div>
      <Modal
        open={isVisible}
        title={t('reject_datasource_landing_page')}
        centered
        onCancel={() => onClose()}
        onOk={async () => {
          rejectDataSourceLandingPage(
            {
              dataSourceLandingPageId: dataSourceLandingPage.id,
              requestBody: {
                rejection_reason: get(getValues(), 'rejection_reason'),
              },
              requestConfig: {
                successMessage: t('transaction_success'),
                successDescription: t('reject_landing_page_message.success'),
                errorMessage: t('transaction_failed'),
                errorDescription: t('reject_landing_page_message.error'),
              },
            },
            {
              onSuccess: () => {
                if (queryKey) queryClient.invalidateQueries(queryKey);
                onClose();
              },
            }
          );
        }}
      >
        <div>{t('reject_datasource_landing_page_are_you_sure')}</div>
        <AkinonForm layout="vertical" className="resolve-rejection-form box-primary form-box">
          <FormItem control={control} name="rejection_reason" label={t('rejection.reason')}>
            <AkinonInput placeholder={t('rejection.reason')} />
          </FormItem>
        </AkinonForm>
      </Modal>
    </div>
  );
};

export default RejectDataSourceLandingPageModal;
