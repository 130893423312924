import { downloadReconciliationRuleTemplateURL } from '@constants/apiUrls';
import { useAkinonMutation } from '@services/api/adapters/useAkinonMutation';
import { client } from '@services/api/client';

export function useDownloadReconciliationRulesTemplateMutation() {
  const mutationResponse = useAkinonMutation({
    mutationFn: ({ type }) =>
      client.get(downloadReconciliationRuleTemplateURL, { params: { type } }),
  });

  return {
    ...mutationResponse,
    mutate: mutationResponse.mutate,
    isMutating: mutationResponse.isLoading,
  };
}
