import { usePageFilters } from '@components/AkinonFilter/hooks/store/usePageFilters';
import AkinonTable from '@components/AkinonTable';
import usePagination from '@components/AkinonTable/hooks/usePagination';
import { getAgreementsUrl } from '@constants/apiUrls';
import { defaultRowKey } from '@constants/index';
import { TranslationKey } from '@root/i18n';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { getColumns } from './common';
import useBreadcrumbs from './hooks/useBreadcrumb';
import useDataSource from './hooks/useDataSource';
import usePageMeta from './hooks/usePageMeta';

function AgreementList() {
  const { t } = useTranslation(TranslationKey.FINANCE);
  const [pagination, setPagination] = usePagination();
  const filters = usePageFilters();

  const { dataSource, isDataSourceLoading, total } = useDataSource({ pagination, filters });
  const columns = getColumns({ t });

  useBreadcrumbs();
  usePageMeta();

  return (
    <section>
      <AkinonTable
        title={t('agreements')}
        rowKey={defaultRowKey}
        dataSource={dataSource}
        optionsUrl={getAgreementsUrl}
        columns={columns}
        loading={isDataSourceLoading}
        total={total}
        description={`${total} ${t('results.found')}`}
        pagination={pagination}
        setPagination={setPagination}
      />
    </section>
  );
}

export default AgreementList;
