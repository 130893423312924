import AkinonLink from '@components/AkinonLink';
import { RouteUrls } from '@constants/routeUrls';
import { Switch, Tooltip } from 'antd';
import { generatePath } from 'react-router-dom';

export const DataIndex = {
  REASON: 'subject',
  IS_ACTIVE: 'is_active',
};

export const getColumns = ({ t }) => [
  {
    dataIndex: DataIndex.REASON,
    title: t('reason'),
    editable: true,
    inputProps: {
      className: 'editable-cell-input',
    },
    render(name, record) {
      return (
        <AkinonLink
          to={generatePath(RouteUrls.settings.orderRejectionDeductionDetail, {
            cancellationReasonId: record?.id,
          })}
        >
          <Tooltip title={name}>{name}</Tooltip>
        </AkinonLink>
      );
    },
  },
  {
    dataIndex: DataIndex.IS_ACTIVE,
    title: t('status'),
    editable: true,
    render: (value) => {
      return (
        <Switch
          disabled={true}
          checked={value}
          checkedChildren={t('active')}
          unCheckedChildren={t('passive')}
        />
      );
    },
  },
];
