import { z } from 'zod';

const MAX_WEIGHT = 100;

export const getFormSchema = ({ t, maxGrade }) => {
  return z
    .object({
      seller_performance_metrics: z.record(
        z.object({
          id: z.string().nullable(),
          name: z.string(),
          key: z.string(),
          weight: z
            .number()
            .min(0, { message: t('metric_errors.weight_min', { minWeight: 0 }) })
            .max(MAX_WEIGHT, { message: t('metric_errors.weight_max', { maxWeight: MAX_WEIGHT }) }),
          grade_list: z.array(
            z.object({
              min: z
                .number()
                .min(0, { message: t('metric_errors.min_value_min', { minValue: 0 }) })
                .max(100, { message: t('metric_errors.min_value_max', { maxValue: 100 }) }),
              max: z
                .number()
                .min(0, { message: t('metric_errors.max_value_min', { minValue: 0 }) })
                .max(100, { message: t('metric_errors.max_value_max', { maxValue: 100 }) }),
              score: z
                .number()
                .min(0, { message: t('metric_errors.score_min', { minGrade: 0 }) })
                .max(maxGrade, { message: t('metric_errors.score_max', { maxGrade }) }),
            })
          ),
        })
      ),
    })
    .superRefine((data, ctx) => {
      const metrics = data.seller_performance_metrics;

      const totalWeight = Object.values(metrics).reduce((acc, metric) => acc + metric.weight, 0);
      if (totalWeight !== MAX_WEIGHT) {
        Object.keys(metrics).forEach((metricKey) => {
          ctx.addIssue({
            path: [`seller_performance_metrics.${metricKey}.weight`],
            message: t('metric_errors.total_weight', {
              totalWeight,
              maxWeight: MAX_WEIGHT,
            }),
            code: z.ZodIssueCode.custom,
          });
        });
      }

      Object.entries(metrics).forEach(([metricKey, metricValue]) => {
        const gradeList = metricValue.grade_list;

        gradeList.forEach((grade, index) => {
          const currentMin = grade.min;
          const currentMax = grade.max;
          const previousMax = gradeList[index - 1]?.max;
          const nextMin = gradeList[index + 1]?.min;

          if (previousMax !== undefined && currentMin < previousMax) {
            ctx.addIssue({
              path: [`seller_performance_metrics.${metricKey}.grade_list[${index}].min`],
              message: t('metric_errors.min_value_previous', {
                currentMin,
                previousMin: previousMax,
              }),
              code: z.ZodIssueCode.custom,
            });
          }

          if (nextMin !== undefined && currentMin > nextMin) {
            ctx.addIssue({
              path: [`seller_performance_metrics.${metricKey}.grade_list[${index}].min`],
              message: t('metric_errors.min_value_next', { currentMin, nextMin }),
              code: z.ZodIssueCode.custom,
            });
          }

          if (currentMax < currentMin) {
            ctx.addIssue({
              path: [`seller_performance_metrics.${metricKey}.grade_list[${index}].max`],
              message: t('metric_errors.min_value_self_max', {
                currentMin,
                currentMax,
              }),
              code: z.ZodIssueCode.custom,
            });
          }
        });
      });
    });
};

export const processSellerPerformanceMetrics = (sellerPerformanceMetrics) => {
  return sellerPerformanceMetrics.reduce((acc, metric) => {
    acc[metric.key] = {
      id: metric.id,
      name: metric.name,
      key: metric.key,
      weight: metric.weight,
      grade_list: Object.entries(metric.grade_list).map(([min, score], index) => {
        const nextMin = Object.entries(metric.grade_list)[index + 1]?.[0];
        return {
          min: Number(min),
          max: nextMin ? Number(nextMin) : 100,
          score,
        };
      }),
    };
    return acc;
  }, {});
};

export const transformMetrics = (data) => {
  return Object.values(data.seller_performance_metrics)
    .filter((metric) => metric.weight !== 0 || (metric.id !== null && metric.weight === 0))
    .map((metric) => ({
      key: metric.key,
      name: metric.name,
      weight: metric.weight,
      grade_list: metric.grade_list.reduce((acc, { min, score }) => {
        acc[min] = score;
        return acc;
      }, {}),
    }));
};

export const defaultSellerPerformanceMetrics = [
  {
    key: 'seller_cancel_rate',
    name: 'Seller Cancel Rate',
    tooltip: 'seller_cancel_rate_tooltip',
  },
  {
    key: 'customer_cancel_rate',
    name: 'Customer Cancel Rate',
    tooltip: 'customer_cancel_rate_tooltip',
  },
  {
    key: 'shipment_in_target_duration',
    name: 'Shipment in Target Duration',
    tooltip: 'shipment_in_target_duration_tooltip',
  },
  {
    key: 'seller_related_delivery_problems_ratio',
    name: 'Seller Related Delivery Problems',
    tooltip: 'seller_related_delivery_problems_ratio_tooltip',
  },
];

export const defaultSellerPerformanceMetricsData = {
  seller_cancel_rate: {
    name: 'Seller Cancel Rate',
    key: 'seller_cancel_rate',
    weight: 0,
    grade_list: [
      {
        min: 0,
        max: 100,
        score: 0,
      },
    ],
  },
  customer_cancel_rate: {
    name: 'Customer Cancel Rate',
    key: 'customer_cancel_rate',
    weight: 0,
    grade_list: [
      {
        min: 0,
        max: 100,
        score: 0,
      },
    ],
  },
  shipment_in_target_duration: {
    name: 'Shipment in Target Duration',
    key: 'shipment_in_target_duration',
    weight: 0,
    grade_list: [
      {
        min: 0,
        max: 100,
        score: 0,
      },
    ],
  },
  seller_related_delivery_problems_ratio: {
    name: 'Seller Related Delivery Problems',
    key: 'seller_related_delivery_problems_ratio',
    weight: 0,
    grade_list: [
      {
        min: 0,
        max: 100,
        score: 0,
      },
    ],
  },
};
