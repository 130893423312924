import { postAgreementConsentUrl } from '@constants/apiUrls';

import { useAkinonMutation } from '../adapters/useAkinonMutation';
import { client } from '../client';

/**
 * @param {Object} [args]
 * @param {import('@tanstack/react-query').MutationOptions} [args.mutationOptions]
 */
export function usePostAgreementConsentMutation({ mutationOptions } = {}) {
  const { mutate, isLoading } = useAkinonMutation({
    mutationFn: ({ requestBody, agreementId, requestConfig }) =>
      client.post(postAgreementConsentUrl, requestBody, {
        urlParams: { agreementId },
        ...requestConfig,
      }),
    ...mutationOptions,
  });

  return {
    acceptAgreementConsent: mutate,
    isAgreementConsentAccepting: isLoading,
  };
}
