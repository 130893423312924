import Link from '@components/Link';
import { RouteUrls } from '@constants/routeUrls';
import { Tag } from 'antd';
import { generatePath } from 'react-router-dom';

const DataIndex = {
  NAME: 'name',
  DESCRIPTION: 'description',
  ID: 'id',
  STATUS: 'status',
};

const AgreementStatus = {
  PASSIVE: 0,
  ACTIVE: 1,
  DRAFT: 2,
};

export const getAgreementStatusMap = ({ t }) => {
  return {
    [AgreementStatus.PASSIVE]: {
      label: t('passive'),
      tagColor: 'red',
    },
    [AgreementStatus.ACTIVE]: {
      label: t('active'),
      tagColor: 'green',
    },
    [AgreementStatus.DRAFT]: {
      label: t('draft'),
      tagColor: 'yellow',
    },
  };
};

export const getColumns = ({ t }) => {
  const agreementStatusMap = getAgreementStatusMap({ t });
  return [
    {
      title: t('name'),
      dataIndex: DataIndex.NAME,
      tooltip: true,
      render: (name, { id }) => {
        return (
          <Link
            to={generatePath(RouteUrls.finance.agreementDetail, {
              agreementId: id,
            })}
          >
            {name}
          </Link>
        );
      },
    },
    {
      title: t('description'),
      dataIndex: DataIndex.DESCRIPTION,
      tooltip: true,
    },
    {
      title: t('status'),
      dataIndex: DataIndex.STATUS,
      tooltip: (cellData) => {
        return agreementStatusMap[cellData].label;
      },
      render: (status) => {
        return (
          <Tag color={agreementStatusMap[status].tagColor}>{agreementStatusMap[status].label}</Tag>
        );
      },
    },
  ];
};
