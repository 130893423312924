import { useAttributeValuesQuery } from '@services/api/hooks/useAttributeValuesQuery';
import { useProductImagesQuery } from '@services/api/hooks/usePaginatedProductImagesQuery';
import { usePaginatedProductsQuery } from '@services/api/hooks/usePaginatedProductsQuery';
import entries from 'lodash/entries';
import find from 'lodash/find';
import flatten from 'lodash/flatten';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import pick from 'lodash/pick';
import reduce from 'lodash/reduce';
import uniq from 'lodash/uniq';

import { DataIndexKey } from '../common';

export function useDataSource({ pagination, filters, dynamicFormMetaFields }) {
  const { products, isFetchingProducts, totalProducts } = usePaginatedProductsQuery({
    pagination,
    filters,
  });

  const productIds = products?.map(({ id }) => id).filter(Boolean);
  const { productImages, isFetchingProductImages } = useProductImagesQuery({
    params: {
      limit: productIds?.length,
      parent__in: uniq(productIds).join(','),
    },
    queryOptions: {
      enabled: !isEmpty(productIds),
    },
  });

  const attributeIds = uniq(map(dynamicFormMetaFields, 'id'));
  const attributeKeys = uniq(map(dynamicFormMetaFields, 'key'));
  const attributeVals = uniq(
    flatten(
      map(products, (product) => {
        return Object.values(pick(product?.attributes, attributeKeys));
      })
    )
  );
  const { attributeValues, isFetchingAttributeValues } = useAttributeValuesQuery({
    params: {
      attribute__in: attributeIds.join(','),
      value__in: attributeVals.join(','),
    },
    queryOptions: {
      enabled: !isFetchingProducts && !isEmpty(attributeVals) && !isEmpty(attributeIds),
    },
  });

  const dataSource = products?.map((product) => ({
    ...product,
    [DataIndexKey.IMAGE]: productImages?.find((image) => image?.parent === product?.id)?.image,
    attributes: reduce(
      entries(product?.attributes),
      (acc, [key, value]) => {
        const attribute = find(dynamicFormMetaFields, { key });
        if (!attribute) return acc;
        const valueLabel = get(
          find(attributeValues, { value, attribute: attribute.id }),
          'label',
          value
        );
        return {
          ...acc,
          [key]: valueLabel,
        };
      },
      {}
    ),
  }));
  const isDataSourceLoading =
    isFetchingProducts || isFetchingProductImages || isFetchingAttributeValues;

  return {
    dataSource,
    isDataSourceLoading,
    total: totalProducts,
  };
}
