import './style.scss';

import { usePageFilters } from '@components/AkinonFilter/hooks/store/usePageFilters';
import AkinonTable from '@components/AkinonTable';
import usePagination from '@components/AkinonTable/hooks/usePagination';
import { getQuickRepliesUrl } from '@constants/apiUrls';
import { defaultRowKey } from '@constants/index';
import { RouteUrls } from '@constants/routeUrls';
import useAppNavigate from '@root/hooks/useAppNavigate';
import { useDeleteQuickReplyMutation } from '@services/api/hooks/useDeleteQuickReplyMutation';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { getColumns } from './common';
import { useBreadcrumbs } from './hooks/useBreadcrumbs';
import { usePageMeta } from './hooks/usePageMeta';
import { usePaginatedQuickRepliesQuery } from './hooks/usePaginatedQuickRepliesQuery';

const QuickReplies = () => {
  const { t } = useTranslation('Messages');
  const navigate = useAppNavigate();
  const [pagination, setPagination] = usePagination();
  const filters = usePageFilters();
  const { quickReplies, isFetchingQuickReplies, total, refetchQuickReplies } =
    usePaginatedQuickRepliesQuery({
      filters,
      pagination,
    });

  const { deleteQuickReply } = useDeleteQuickReplyMutation({
    mutationOptions: {
      onSuccess: refetchQuickReplies,
    },
  });

  useBreadcrumbs();
  usePageMeta();

  const onRowClick = (record) => () => {
    record && navigate(RouteUrls.messages.quickRepliesForm, { id: record?.id });
  };

  const columns = getColumns({ t, deleteQuickReply });

  return (
    <section className="quick-replies">
      <AkinonTable
        title={t('quick.replies')}
        columns={columns}
        dataSource={quickReplies}
        optionsUrl={getQuickRepliesUrl}
        loading={isFetchingQuickReplies}
        total={total}
        pagination={pagination}
        rowKey={defaultRowKey}
        setPagination={setPagination}
        description={`${total} ${t('results.found')}`}
        onRow={(record) => ({ onClick: onRowClick(record) })}
      />
    </section>
  );
};

export default QuickReplies;
