import { useAttributesQuery } from '@services/api/hooks/useAttributesQuery';
import { useDynamicFilterFields } from '@utils/hooks/useDynamicFilterFields';
import { useMemo } from 'react';

import { getStaticFilters } from '../common';

const useFilterFields = ({ t }) => {
  const { attributes, isAttributesLoading } = useAttributesQuery();
  const dynamicFields = useDynamicFilterFields({ attributes, isAttributesLoading });

  const staticFilters = getStaticFilters({
    t,
  });

  return useMemo(
    () => ({
      dynamic: dynamicFields,
      static: staticFilters,
    }),
    [dynamicFields, staticFilters]
  );
};

export default useFilterFields;
