import './styles.scss';

import { Typography } from 'antd';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const { Title, Text } = Typography;

/**
 * @param {React.HTMLAttributes & { shadow: boolean, title: string, description: string, bannerColor: string, titleClassName: string, descriptionClassName: string }} props
 */
const AkinonBox = (props) => {
  return (
    <section
      className={classNames('akinon-box', props.className, {
        'shadow-md shadow-gray-900': props.shadow,
      })}
    >
      <span
        data-testid="akinon-box__banner"
        className="akinon-box__banner"
        style={{ backgroundColor: props.bannerColor }}
      />
      <div className="akinon-box__header">
        {props.title && (
          <Title level={1} className={classNames('akinon-box__title', props.titleClassName)}>
            {props.title}
          </Title>
        )}
        {props.actions}
      </div>
      {props.description && (
        <Text className={classNames('akinon-box__description', props.descriptionClassName)}>
          {props.description}
        </Text>
      )}
      {props.children}
    </section>
  );
};

AkinonBox.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  bannerColor: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default AkinonBox;
