import './style.scss';

import AkinonFilter from '@components/AkinonFilter';
import AkinonTable from '@components/AkinonTable';
import usePagination from '@components/AkinonTable/hooks/usePagination';
import { IconChevronDown, IconChevronRight } from '@tabler/icons-react';
import { Divider, Table } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export default function ProductBasedTable() {
  const { t } = useTranslation('Reporting');
  const [pagination, setPagination] = usePagination();
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);

  const columns = [
    {
      title: t('sale_reports.stepper_sales_tabs_based_on_product_columns_category'),
      dataIndex: 'category',
      key: 'category',
      render: (category, record) => {
        const expanded = expandedRowKeys.includes(record.id);
        return (
          <div className="flex gap-2">
            {expanded ? (
              <IconChevronDown
                onClick={() => {
                  setExpandedRowKeys((prev) => prev.filter((id) => id !== record.id));
                }}
                className="cursor-pointer text-info"
              />
            ) : (
              <IconChevronRight
                onClick={() => {
                  setExpandedRowKeys((prev) => [...prev, record.id]);
                }}
                className="cursor-pointer text-info"
              />
            )}
            <span>{category}</span>
          </div>
        );
      },
    },
    {
      title: t('sale_reports.stepper_sales_tabs_based_on_product_columns_sales_quantity'),
      dataIndex: 'sales_quantity',
      key: 'sales_quantity',
    },
    {
      title: t('sale_reports.stepper_sales_tabs_based_on_product_columns_sales_amount'),
      dataIndex: 'sales_amount',
      key: 'sales_amount',
    },
    {
      title: t('sale_reports.stepper_sales_tabs_based_on_product_columns_commission'),
      dataIndex: 'commission',
      key: 'commission',
    },
  ];

  const expandedColumns = [
    {
      title: t('sale_reports.stepper_sales_tabs_based_on_product_columns_category'),
      dataIndex: 'category',
      key: 'category',
    },
    {
      title: t('sale_reports.stepper_sales_tabs_based_on_product_columns_sales_quantity'),
      dataIndex: 'sales_quantity',
      key: 'sales_quantity',
    },
    {
      title: t('sale_reports.stepper_sales_tabs_based_on_product_columns_sales_amount'),
      dataIndex: 'sales_amount',
      key: 'sales_amount',
    },
    {
      title: t('sale_reports.stepper_sales_tabs_based_on_product_columns_commission'),
      dataIndex: 'commission',
      key: 'commission',
    },
  ];
  const dataSource = [
    {
      id: 1,
      category: 'Category 1',
      sales_quantity: 100,
      sales_amount: 100,
      commission: 100,
    },
    {
      id: 2,
      category: 'Category 2',
      sales_quantity: 200,
      sales_amount: 200,
      commission: 200,
    },
    {
      id: 3,
      category: 'Category 3',
      sales_quantity: 300,
      sales_amount: 300,
      commission: 300,
    },
    {
      id: 4,
      category: 'Category 4',
      sales_quantity: 400,
      sales_amount: 400,
      commission: 400,
    },
    {
      id: 5,
      category: 'Category 5',
      sales_quantity: 500,
      sales_amount: 500,
      commission: 500,
    },
  ];

  const filterFields = {
    dynamic: { fields: [], isLoading: false },
    static: { fields: [], isLoading: false },
  };
  const total = dataSource.length;
  const isDataSourceLoading = false;

  return (
    <div className="product-based-table">
      <AkinonFilter title={t('filters')} filterFields={filterFields} total={total} />
      <Divider />
      <AkinonTable
        title={t('sale_reports.stepper_sales_tabs_based_on_product_title')}
        columns={columns}
        dataSource={dataSource}
        pagination={pagination}
        setPagination={setPagination}
        loading={isDataSourceLoading}
        rowClassName={(record) =>
          expandedRowKeys.includes(record.id) &&
          '[&.ant-table-row_td]:join-[!text-info,bg-magnolia]'
        }
        expandable={{
          expandedRowKeys,
          showExpandColumn: false,
          expandedRowClassName: () => 'expanded-row',
          expandedRowRender: () => (
            <Table
              rowKey="id"
              className="expanded-table"
              dataSource={dataSource}
              columns={expandedColumns}
              pagination={false}
            />
          ),
        }}
        rowKey="id"
        total={total}
        description={`${total} ${t('results.found')}`}
      />
    </div>
  );
}
