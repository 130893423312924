import AkinonButton from '@components/AkinonButton';
import { IconFilterUp } from '@tabler/icons-react';
import { Space, Spin, Typography, Upload } from 'antd';
import React from 'react';

const { Text } = Typography;

const FilterActions = ({
  showFileImport,
  addFilterFile,
  isDynamicFiltersLoading,
  handleOpenModalIconClick,
  filterActions,
}) => {
  return (
    <Space>
      {showFileImport && (
        <Upload
          showUploadList={false}
          multiple={false}
          onChange={addFilterFile}
          accept=".xlsx,.csv"
          customRequest={() => {}}
        >
          <div className="filter-options__item filter-options__export-item">
            <IconFilterUp className="filter-options__export-icon" size={16} />
            <Text className="filter-options__export-label">XLSX</Text>
          </div>
        </Upload>
      )}
      {filterActions}
      <Spin spinning={isDynamicFiltersLoading} className="filter-options__spinner">
        <AkinonButton
          type="primary"
          className="filter-options__item filter-options__filter-modal-open-button"
          onClick={handleOpenModalIconClick}
          data-testid="filter-options-button"
        >
          <i className="icon-filter filter-options__filter-modal-open-icon" />
        </AkinonButton>
      </Spin>
    </Space>
  );
};

export default FilterActions;
