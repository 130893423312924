import map from 'lodash/map';
import { z } from 'zod';

export const FormKey = {
  EMAIL: 'email',
  FIRST_NAME: 'first_name',
  LAST_NAME: 'last_name',
  DATASOURCE_PERMISSION_LEVEL: 'datasource_permission_level',
};

export const getFormSchema = ({ permissionLevelChoises }) =>
  z.object({
    [FormKey.EMAIL]: z.string().email().min(1),
    [FormKey.FIRST_NAME]: z.string(),
    [FormKey.LAST_NAME]: z.string(),
    [FormKey.DATASOURCE_PERMISSION_LEVEL]: z
      .union(map(permissionLevelChoises, (choice) => z.literal(choice.value)))
      .optional(),
  });
