import './style.scss';

import { createSelectOptions } from '@common/index';
import AkinonBox from '@components/AkinonBox';
import AkinonButton from '@components/AkinonButton';
import AkinonForm from '@components/AkinonForm';
import AkinonInput from '@components/AkinonInput';
import AkinonSelect from '@components/AkinonSelect';
import AkinonSwitch from '@components/AkinonSwitch';
import { getDataSourcePermissionsUrl } from '@constants/apiUrls';
import {
  DatasourcePermissionLevel,
  getDatasourcePermissionLevelOptions,
} from '@constants/commontypes';
import { overallDateFormat } from '@constants/index';
import { verticalFormItemLayout } from '@constants/layoutTypes';
import { RouteUrls } from '@constants/routeUrls';
import { Color } from '@constants/theme';
import { zodResolver } from '@hookform/resolvers/zod';
import useAppNavigate from '@root/hooks/useAppNavigate';
import { useMeQuery } from '@services/api/hooks';
import { useOptionsQuery } from '@services/api/hooks/useOptionsQuery';
import { useDataSourceUpdateUserMutation } from '@services/hooks/datasources/useDataSourceUpdateUserMutation';
import get from 'lodash/get';
import moment from 'moment';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';

import { FormKey, getFormSchema } from './common';

const SellerEditUser = () => {
  const { t } = useTranslation('Settings');
  const navigate = useAppNavigate();
  const { me: currentUser } = useMeQuery();
  const hasAdminPermission =
    currentUser?.datasource_permission_level === DatasourcePermissionLevel.ADMIN;

  const { options: userOptions } = useOptionsQuery({
    url: getDataSourcePermissionsUrl,
  });
  const permissionLevelChoises = get(
    userOptions,
    'actions.POST.datasource_permission_level.choices',
    getDatasourcePermissionLevelOptions({ t })
  );

  const {
    state: { user },
  } = useLocation();
  const { userId, dataSourceId } = useParams();

  const schema = getFormSchema({ permissionLevelChoises });

  const { handleSubmit, reset, control, getValues } = useForm({
    defaultValues: {},
    resolver: zodResolver(schema),
  });

  useEffect(() => {
    reset(user);
  }, [userId]);

  const { updateUser } = useDataSourceUpdateUserMutation({
    mutationOptions: {
      onSuccess: () => navigate(RouteUrls.settings.sellerUserManagementSettings),
    },
  });

  const onSubmit = (values) => {
    updateUser({
      dataSourceId,
      userId,
      params: values,
      requestConfig: {
        successMessage: t('transaction_success'),
        successDescription: t('seller_detail_message.update.success'),
        errorMessage: t('transaction_failed'),
        errorDescription: t('seller_detail_message.update.error'),
      },
    });
  };

  return (
    <AkinonBox
      className="box-primary form-box seller-edit-user"
      bannerColor={Color.WILD_WATERMELON}
      title={t('add_edit_user')}
      description={t('add_edit_user_desc')}
    >
      <AkinonForm className="ant-form" layout="vertical" {...verticalFormItemLayout}>
        <AkinonInput
          formItemProps={{
            control,
            name: FormKey.EMAIL,
            label: t('email'),
          }}
          placeholder={t('enter_variable', { variable: t('email') })}
          disabled
        />
        <AkinonSwitch
          formItemProps={{
            control,
            name: FormKey.IS_ACTIVE,
            label: t('status'),
          }}
          checkedChildren={t('active')}
          unCheckedChildren={t('passive')}
        />
        <AkinonSelect
          formItemProps={{
            control,
            label: t('datasource_permission_level'),
            name: FormKey.DATASOURCE_PERMISSION_LEVEL,
            disabled: !hasAdminPermission,
          }}
          options={createSelectOptions(permissionLevelChoises, {
            valueKey: 'value',
            labelKey: 'display_name',
          })}
          placeholder={t('datasource_permission_level')}
        />
        <AkinonInput
          formItemProps={{
            control,
            name: FormKey.LAST_LOGIN,
            label: t('last_login'),
          }}
          value={moment(getValues(FormKey.LAST_LOGIN)).format(overallDateFormat)}
          placeholder={t('enter_variable', { variable: t('last_login') })}
          disabled
        />
        <AkinonButton
          htmlType="button"
          type="primary"
          className="akn-submit-button"
          onClick={handleSubmit(onSubmit)}
        >
          {t('save')}
        </AkinonButton>
      </AkinonForm>
    </AkinonBox>
  );
};

export default SellerEditUser;
