import { SearchOutlined } from '@ant-design/icons';
import DynamicFormElement from '@components/DynamicFormElement';
import { Col, Form, Row } from 'antd';
import first from 'lodash/first';

const HighlightedFiltersForm = ({ form, filterFields, visibleFilters }) => {
  const visibleFilterFields = filterFields.filter((field) =>
    visibleFilters?.includes?.(field.name)
  );
  const highlightedFields = visibleFilterFields.filter((filter) => filter?.isHighlightedField);

  if (highlightedFields?.length === 0) return null;
  const firstField = first(highlightedFields);
  firstField.suffix = <SearchOutlined />;
  firstField.allowClear = true;

  return (
    <Form
      className="akinon-form-dark akinon-filter__form akinon-filter__form--highlighted"
      form={form}
    >
      <Row gutter={[10, 0]}>
        {highlightedFields.map((field) => (
          <Col key={field.name} span={24}>
            <DynamicFormElement {...field} />
          </Col>
        ))}
      </Row>
    </Form>
  );
};

export default HighlightedFiltersForm;
