import { getRequestFiltersParams, getRequestPaginationParams } from '@common/index';
import { getProductsUrl } from '@constants/apiUrls';
import { HttpMethod } from '@constants/commontypes';
import { QueryKey } from '@constants/query';
import { useAkinonQuery } from '@services/api/adapters/useAkinonQuery';
import { client } from '@services/api/client';
import get from 'lodash/get';
import omit from 'lodash/omit';

export const useProductsQuery = ({ queryOptions, params, onSuccess } = {}) => {
  const { data, isLoading, isFetching, error, refetch } = useAkinonQuery({
    queryKey: [QueryKey.PRODUCTS, params],
    async queryFn() {
      const response = await client.get(getProductsUrl, { params });
      onSuccess?.(response);
      return response;
    },
    ...queryOptions,
  });

  return {
    products: data?.results,
    refetchProducts: refetch,
    isProductsLoading: isLoading,
    isFetchingProducts: isFetching,
    productsError: error,
  };
};

export const usePaginatedProductsQuery = ({
  queryOptions,
  pagination,
  filters,
  params,
  onSuccess,
}) => {
  const _pagination = getRequestPaginationParams(pagination);
  const _filters = getRequestFiltersParams(filters);

  const { data, isFetching, error, refetch } = useAkinonQuery({
    queryKey: [QueryKey.PRODUCTS, _pagination, _filters, params],
    async queryFn() {
      const response = await client.get(getProductsUrl, {
        params: {
          ..._pagination,
          ..._filters,
          ...params,
        },
      });
      onSuccess?.(response);
      return response;
    },
    ...queryOptions,
  });

  return {
    products: data?.results,
    refetchProducts: refetch,
    totalProducts: data?.count,
    isFetchingProducts: isFetching,
    productsError: error,
  };
};

export const usePaginatedProductsWithFileQuery = ({
  queryOptions,
  pagination,
  filters,
  params,
  onSuccess,
}) => {
  const _pagination = getRequestPaginationParams(pagination);
  const fileFilter = get(filters, 'filter_file');
  const _filters = getRequestFiltersParams(omit(filters, 'filter_file'));

  const { data, isFetching, error, refetch } = useAkinonQuery({
    queryKey: [QueryKey.PRODUCTS, _pagination, _filters, params, fileFilter],
    async queryFn() {
      const formData = new FormData();
      formData.append('filter_file', new Blob([fileFilter?.originFileObj]), fileFilter?.name);

      const response = await client.post(getProductsUrl, formData, {
        params: {
          ..._pagination,
          ..._filters,
          ...params,
        },
        suppressedNotifications: ['success', 'error'],
        headers: {
          'Origin-Method': HttpMethod.GET,
        },
      });

      onSuccess?.(response);
      return response;
    },
    ...queryOptions,
  });

  return {
    products: data?.results,
    refetchProducts: refetch,
    totalProducts: data?.count,
    isFetchingProducts: isFetching,
    productsError: error,
  };
};
