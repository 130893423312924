import { useOrdersQuery } from '@services/api/hooks/useOrdersQuery';
import isEmpty from 'lodash/isEmpty';

export const useOrderDetailsForPackage = ({ orderIds, dataSource }) => {
  const { orders, isFetchingOrders } = useOrdersQuery({
    params: {
      id__in: orderIds.join(','),
      limit: orderIds.length,
    },
    queryOptions: {
      enabled: !isEmpty(orderIds),
    },
  });

  const packagesWithOrders = dataSource?.map((packageItem) => {
    const order = orders?.find((item) => item.id === packageItem.order);

    return {
      ...packageItem,
      order,
      orderId: order?.id,
    };
  });
  const isOrderIdDefined = packagesWithOrders?.every((item) => item.orderId);

  return {
    packagesWithOrders: isOrderIdDefined ? packagesWithOrders : [],
    isFetchingOrders,
  };
};
