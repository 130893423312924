import { useProductImagesQuery } from '@services/api/hooks/usePaginatedProductImagesQuery';
import { usePaginatedProductOffersQuery } from '@services/api/hooks/usePaginatedProductOffersQuery';
import { useProductsQuery } from '@services/api/hooks/usePaginatedProductsQuery';
import { usePaginatedReconciliationRulesQuery } from '@services/api/hooks/usePaginatedReconciliationRulesQuery';
import isEmpty from 'lodash/isEmpty';
import uniq from 'lodash/uniq';

export const useDataSource = ({
  filters,
  productPoolPagination,
  specialCommissionProductPagination,
}) => {
  const { productOffers, isFetchingProductOffers, totalProductOffers, refetchProductOffers } =
    usePaginatedProductOffersQuery({
      pagination: productPoolPagination,
      filters,
    });

  const productIds = uniq(productOffers?.map(({ product }) => product)).filter(Boolean);

  const { products, isFetchingProducts } = useProductsQuery({
    params: {
      limit: productIds?.length,
      id__in: productIds?.join(','),
    },
    queryOptions: {
      enabled: !isFetchingProductOffers && !isEmpty(productIds),
    },
  });

  const { productImages, isFetchingProductImages } = useProductImagesQuery({
    params: {
      limit: productIds?.length,
      parent__in: productIds?.join(','),
      main: true,
    },
    queryOptions: {
      enabled: !isFetchingProductOffers && !isEmpty(productIds),
    },
  });

  const {
    reconciliationRules,
    isFetchingReconciliationRules,
    totalReconciliationRules,
    refetchReconciliationRules,
  } = usePaginatedReconciliationRulesQuery({
    pagination: specialCommissionProductPagination,
    filters,
    params: {
      sort: 'created_at',
    },
  });

  const productPoolDataSource = productOffers?.map((productOffers) => ({
    ...productOffers,
    image: productImages?.find((productImage) => productImage.parent === productOffers.product)
      ?.image,
  }));

  const specialCommissionProductDataSource = reconciliationRules?.map((reconciliationRule) => ({
    ...reconciliationRule,
    productOfferId: productOffers?.find(
      (productOffer) => productOffer.id === reconciliationRule.product
    )?.id,
    image: productImages?.find(
      () =>
        productOffers?.find((productOffer) => productOffer.id === reconciliationRule.product)
          ?.product
    )?.image,
    product: products?.find(
      (product) =>
        product.id ===
        productOffers?.find((productOffer) => productOffer.id === reconciliationRule.product)
          ?.product
    ),
  }));

  return {
    productPoolDataSource,
    isProductPoolDataSourceLoading:
      isFetchingProductOffers || isFetchingProducts || isFetchingProductImages,
    totalProductPoolDataSource: totalProductOffers,
    specialCommissionProductDataSource,
    isSpecialCommissionProductDataSourceLoading:
      isFetchingReconciliationRules || isFetchingProducts || isFetchingProductImages,
    totalSpecialCommissionProductDataSource: totalReconciliationRules,
    refetchProductOffers,
    refetchReconciliationRules,
  };
};
