import './styles.scss';

import { createLogCenterRecord } from '@common/index';
import AkinonForm from '@components/AkinonForm';
import AkinonInput, { AkinonTextArea } from '@components/AkinonInput';
import { useResolveRejectionMutation } from '@services/hooks/preOffers/useResolveRejectionMutation';
import useStore from '@zustand-store/index';
import { addLogCenterRecordSelector } from '@zustand-store/selectors/app';
import { Modal } from 'antd';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { FormItem } from 'react-hook-form-antd';
import { useTranslation } from 'react-i18next';

const ResolveRejectionModal = ({ preOffer, isResolveRejectionModalVisible, onClose }) => {
  const addLogCenterRecord = useStore(addLogCenterRecordSelector);
  const { resolveRejectionAsync } = useResolveRejectionMutation();
  const { t } = useTranslation('ProductsAndCategories');

  const { control, reset } = useForm();

  useEffect(() => {
    reset({
      rejection_reason: preOffer?.reason?.reason,
      rejection_reason_explanation: preOffer?.rejection?.explanation ?? '-',
    });
  }, [preOffer]);

  return (
    <div>
      <Modal
        open={isResolveRejectionModalVisible}
        title={t('reject.resolve')}
        centered
        onCancel={() => onClose({ isRejectionResolved: false, offerId: preOffer.id })}
        onOk={async () => {
          const rejectionReasonId = preOffer.rejection.id;
          try {
            const requestConfig = {
              errorMessage: t('transaction_failed'),
              errorDescription: t('resolve_reject_modal_message.error'),
            };
            await resolveRejectionAsync({ rejectionReasonId, requestConfig });
            onClose({ isRejectionResolved: true, offerId: preOffer.id });
          } catch (error) {
            addLogCenterRecord(
              createLogCenterRecord({
                type: 'error',
                message: t('an.error.occurred'),
                description: t('an.error.occurred'),
              })
            );
            onClose({ isRejectionResolved: false, offerId: preOffer.id });
          }
        }}
      >
        <div>{t('reject.resolve.are.you.sure')}</div>
        <AkinonForm layout="vertical" className="resolve-rejection-form box-primary form-box">
          <FormItem
            control={control}
            name="rejection_reason"
            label={t('rejection.reason')}
            disabled
          >
            <AkinonInput placeholder={t('rejection.reason')} />
          </FormItem>
          <FormItem
            control={control}
            name="rejection_reason_explanation"
            label={t('rejection.reason.explanation')}
            disabled
          >
            <AkinonTextArea
              placeholder={t('rejection.reason.explanation')}
              autoSize={{ minRows: 2, maxRows: 6 }}
              style={{
                overflowX: 'hidden',
              }}
            />
          </FormItem>
        </AkinonForm>
      </Modal>
    </div>
  );
};

export default ResolveRejectionModal;
