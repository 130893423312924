import { dynamicSettingsURL } from '@constants/apiUrls';
import { QueryKey } from '@constants/query';

import { useAkinonQuery } from '../adapters/useAkinonQuery';
import { client } from '../client';

export function useDynamicSettingsQuery({ params, queryOptions, onSuccess }) {
  const { data, isFetching, isError } = useAkinonQuery({
    queryKey: [QueryKey.DYNAMIC_SETTINGS, params],
    async queryFn() {
      const response = await client.get(dynamicSettingsURL, {
        params,
      });
      onSuccess?.(response);
      return response;
    },
    ...queryOptions,
  });

  return {
    dynamicSettingsResult: data,
    dynamicSettingsTotal: data?.count,
    dynamicSettings: data?.results,
    isDynamicSettingsError: isError,
    isDynamicSettingsFetching: isFetching,
  };
}
