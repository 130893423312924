import { getStaticFilters } from '../common';

const useFilterFields = ({ t, disabledStartDate, disabledEndDate }) => {
  const staticFilters = getStaticFilters({ t, disabledStartDate, disabledEndDate });

  return {
    dynamic: { fields: [], isLoading: false },
    static: staticFilters,
  };
};

export default useFilterFields;
