import './styles.scss';

import { convertFileToBase64 } from '@common/file';
import AkinonFormItem from '@components/AkinonFormItem';
import { openNotification } from '@components/akinonNotification';
import { DraggerInput } from '@components/FileInput';
import { Space, Typography, Upload } from 'antd';
import clsx from 'clsx';
import omit from 'lodash/omit';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const { Dragger } = Upload;
const { Text } = Typography;

/**
 * We are emulating action prop by using dummyRequest.
 * So that all added files the accepted as successful.
 * @param {import('antd/lib/upload').DraggerProps & { shouldResetOnChange: boolean }} props
 */
const AkinonDragger = ({ formItemProps, shouldResetOnChange, ...draggerProps }) => {
  const { t } = useTranslation();
  const [fileList, setFileList] = useState(draggerProps.fileList ?? draggerProps.value ?? []);

  const dummyRequest = ({ onSuccess }) => {
    setTimeout(() => {
      onSuccess('ok');
    });
  };

  const beforeUpload = (file) => {
    convertFileToBase64(file).then((base64) => {
      file.base64 = base64;
    });

    draggerProps.beforeUpload?.(file);

    return false;
  };

  const onChange = ({ fileList, ...otherParams }) => {
    try {
      draggerProps.onChange?.({ fileList, ...otherParams });
      if (shouldResetOnChange) {
        setFileList([]);
        return;
      }
      setFileList(fileList.slice(draggerProps.maxCount ? -draggerProps.maxCount : 0));
    } catch (error) {
      openNotification({
        message: error?.message ?? t('error'),
        description: error?.description ?? t('error'),
        type: 'error',
      });
    }
  };

  const onRemove = (file) => {
    setFileList((previousFileList) =>
      previousFileList.filter((previousFile) => previousFile.uid !== file.uid)
    );
  };

  const component = (
    <Dragger
      data-testId="akinon-dragger"
      beforeUpload={beforeUpload}
      onRemove={onRemove}
      fileList={fileList}
      {...omit(draggerProps, ['maxCount'])}
      onChange={onChange}
      customRequest={dummyRequest}
      className={clsx('akinon-dragger', draggerProps.className)}
    >
      {draggerProps.children ?? t('dragger.desc')}
    </Dragger>
  );
  if (formItemProps) {
    return <AkinonFormItem {...formItemProps}>{component}</AkinonFormItem>;
  }
  return component;
};

export default AkinonDragger;

export const DraggerInputWithLabel = ({ label, ...draggerInputProps }) => {
  const { t } = useTranslation('Finance');

  return (
    <Space className="dragger-input-with-label" direction="vertical">
      <DraggerInput {...draggerInputProps} />
      <Text className="dragger-input-with-label__label">{label ?? t('uploadLimit5Mb')}</Text>
    </Space>
  );
};
