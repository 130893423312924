import { QueryKey } from '@constants/query';
import { queryClient } from '@services/api/queryClient';
import filter from 'lodash/filter';

const useAddRemoveProductFromCollection = ({
  addProductToCollectionAsync,
  deleteProductFromCollectionAsync,
  collectionId,
  limitPerBulkRequest = 1,
}) => {
  const onAddSelectedItems = async ({ selectedRows, mutationOptions }) => {
    const { onSuccess, onError } = mutationOptions;
    const promiseCount = selectedRows.length;

    if (promiseCount > 0) {
      let result = [];
      const bulkRequestSize = Math.ceil(promiseCount / limitPerBulkRequest);
      for (let i = 0; i < bulkRequestSize; i++) {
        const start = i * limitPerBulkRequest;
        const end = (i + 1) * limitPerBulkRequest;
        const bulkSelectedRows = selectedRows.slice(start, end);

        const promises = bulkSelectedRows.map((record) => {
          return addProductToCollectionAsync({
            requestBody: {
              offer: record.id,
              collection: collectionId,
            },
            requestConfig: {
              suppressedNotifications: ['error', 'success'],
            },
          });
        });

        const bulkResult = await Promise.allSettled(promises);
        const errorCount = filter(bulkResult, { status: 'rejected' }).length;
        const successCount = filter(bulkResult, { status: 'fulfilled' }).length;
        onError(errorCount);
        onSuccess(successCount);
        result = [...result, ...bulkResult];
      }

      const isAllFulfilled = result.every((item) => item.status === 'fulfilled');
      const isSomeRejected = result.some((item) => item.status === 'rejected');
      const isSomeFulfilled = result.some((item) => item.status === 'fulfilled');
      if (isAllFulfilled || (isSomeFulfilled && isSomeRejected)) {
        queryClient.invalidateQueries([QueryKey.COLLECTION_ITEMS, { collection: collectionId }]);
        queryClient.invalidateQueries([QueryKey.PRODUCT_OFFERS]);
      }
    }
  };
  const onDeleteSelectedItems = async ({ selectedRows, mutationOptions }) => {
    const { onSuccess, onError } = mutationOptions;
    const promiseCount = selectedRows.length;

    if (promiseCount > 0) {
      let result = [];
      const bulkRequestSize = Math.ceil(promiseCount / limitPerBulkRequest);
      for (let i = 0; i < bulkRequestSize; i++) {
        const start = i * limitPerBulkRequest;
        const end = (i + 1) * limitPerBulkRequest;
        const bulkSelectedRows = selectedRows.slice(start, end);

        const promises = bulkSelectedRows.map((record) => {
          return deleteProductFromCollectionAsync({
            id: record.collectionItem.id,
            requestConfig: {
              suppressedNotifications: ['error', 'success'],
            },
          });
        });

        const bulkResult = await Promise.allSettled(promises);
        const errorCount = filter(bulkResult, { status: 'rejected' }).length;
        const successCount = filter(bulkResult, { status: 'fulfilled' }).length;
        onError(errorCount);
        onSuccess(successCount);
        result = [...result, ...bulkResult];
      }

      const isAllFulfilled = result.every((item) => item.status === 'fulfilled');
      const isSomeRejected = result.some((item) => item.status === 'rejected');
      const isSomeFulfilled = result.some((item) => item.status === 'fulfilled');
      if (isAllFulfilled || (isSomeFulfilled && isSomeRejected)) {
        queryClient.invalidateQueries([QueryKey.PRODUCT_OFFERS]);
      }
    }
  };
  return { onAddSelectedItems, onDeleteSelectedItems };
};

export default useAddRemoveProductFromCollection;
