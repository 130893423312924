import { AkinonInputNumber } from '@components/AkinonInput';
import React from 'react';

export const RangeInput = ({ t, metricKey, rangeIndex, control, setValue }) => {
  const handleMinChange = (value, rangeIndex) => {
    setValue(`seller_performance_metrics.${metricKey}.grade_list[${rangeIndex}].min`, value);

    if (rangeIndex > 0) {
      setValue(`seller_performance_metrics.${metricKey}.grade_list[${rangeIndex - 1}].max`, value);
    }
  };

  return (
    <div className="flex flex-col">
      <AkinonInputNumber
        formItemProps={{
          name: `seller_performance_metrics.${metricKey}.grade_list[${rangeIndex}].min`, // metricKey kullanımı
          label: t('min'),
          control,
          required: true,
        }}
        formatter={(value) => (value ? `% ${value}` : '')}
        parser={(value) => value?.replace('%', '')}
        placeholder={t('min')}
        onChange={(value) => handleMinChange(value, rangeIndex)}
      />

      <AkinonInputNumber
        formItemProps={{
          name: `seller_performance_metrics.${metricKey}.grade_list[${rangeIndex}].max`,
          label: t('max'),
          control,
        }}
        formatter={(value) => (value ? `% ${value}` : '')}
        parser={(value) => value?.replace('%', '')}
        min={0}
        max={100}
        placeholder={t('max')}
        disabled
      />

      <AkinonInputNumber
        formItemProps={{
          name: `seller_performance_metrics.${metricKey}.grade_list[${rangeIndex}].score`,
          control,
          label: t('score'),
        }}
        placeholder={t('score')}
      />
    </div>
  );
};
