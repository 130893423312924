import { getIsPublicPrivateOptions, WidgetType } from '@constants/commontypes';

import { StaticFilterKey } from '../common';

export const useStaticFilterFields = ({ t }) => {
  return {
    fields: [
      {
        name: StaticFilterKey.BASE_CODE,
        widget: WidgetType.INPUT,
        placeholder: t('base.code'),
      },
      {
        name: StaticFilterKey.FIRST_CREATE_MESSAGE_DATE,
        widget: WidgetType.DATE_PICKER,
        placeholder: t('first.message.creation.date'),
      },
      {
        name: StaticFilterKey.LAST_CREATE_MESSAGE_DATE,
        widget: WidgetType.DATE_PICKER,
        placeholder: t('last.message.creation.date'),
      },
      {
        name: StaticFilterKey.FIRST_UPDATE_MESSAGE_DATE,
        widget: WidgetType.DATE_PICKER,
        placeholder: t('first.message.update.date'),
      },
      {
        name: StaticFilterKey.LAST_UPDATE_MESSAGE_DATE,
        widget: WidgetType.DATE_PICKER,
        placeholder: t('last.message.update.date'),
      },
      {
        name: StaticFilterKey.IS_PUBLIC,
        widget: WidgetType.SELECT,
        placeholder: t('messagesConfig.messageState'),
        options: getIsPublicPrivateOptions({ t }),
      },
    ],
  };
};
