import { RouteUrls } from '@constants/routeUrls';
import { generatePath, Link } from 'react-router-dom';

import { StaticFilterKey } from './hooks/useStaticFilterFields';

export const DataIndex = {
  NUMBER: 'number',
  NAME: 'name',
  SKU: 'sku',
  SUPPLIER: 'supplier',
  CREATED_AT: 'created_at',
  TOTAL_AMOUNT: 'total_amount',
  DISCOUNT_AMOUNT: 'discount_amount',
  NET_AMOUNT: 'net_amount',
  TAX_RATE: 'tax_rate',
  COMMISSION_PERCENT: 'commission_percent',
  COMMISSION_AMOUNT: 'commission_amount',
  PRODUCT_NAME: ['orderItem', 'product', 'name'],
  PRODUCT_OFFER_SKU: ['orderItem', 'productOffer', 'sku'],
  ORDER_NUMBER: ['orderItem', 'order', 'number'],
};

//? unsupported columns were deleted in SELLER-2703. Add them back when BE supports them
export function getColumns({ t, reconciliation }) {
  return [
    {
      dataIndex: DataIndex.NUMBER,
      visibleOnFilter: [StaticFilterKey.RECONCILIATION_NO],
      title: t('reconciliation_number'),
      render: () => reconciliation?.number,
    },
    {
      dataIndex: DataIndex.ORDER_NUMBER,
      title: t('order.number'),
      render: (orderNumber, rowData) => {
        return (
          orderNumber && (
            <Link
              to={generatePath(RouteUrls.order.orderDetail, {
                orderId: rowData?.orderItem?.order?.id,
              })}
            >
              {orderNumber}
            </Link>
          )
        );
      },
    },
    {
      dataIndex: DataIndex.PRODUCT_OFFER_SKU,
      title: t('sku'),
    },
    {
      dataIndex: DataIndex.PRODUCT_NAME,
      title: t('product_name'),
    },
    {
      dataIndex: DataIndex.TOTAL_AMOUNT,
      title: t('selling.price'),
    },
    {
      dataIndex: DataIndex.COMMISSION_AMOUNT,
      title: t('commission.amount'),
    },
  ];
}
