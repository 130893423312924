import {
  closestCenter,
  defaultDropAnimation,
  DndContext,
  DragOverlay,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

import { SortableTreeItem } from './SortableTreeItem';
import {
  addItem,
  buildTree,
  findItemDeep,
  findNextItemDeep,
  findPrevItemDeep,
  flattenTree,
  getChildCount,
  getProjection,
  removeChildrenOf,
  removeItem,
  setProperty,
} from './utilities';

const customListSortingStrategy = (isValid) => {
  const sortingStrategy = ({ activeIndex, activeNodeRect, index, rects, overIndex }) => {
    if (isValid(activeIndex, overIndex)) {
      return verticalListSortingStrategy({
        activeIndex,
        activeNodeRect,
        index,
        rects,
        overIndex,
      });
    }
    return null;
  };
  return sortingStrategy;
};

const defaultPointerSensorOptions = {
  activationConstraint: {
    distance: 3,
  },
};

export const dropAnimationDefaultConfig = {
  keyframes({ transform }) {
    return [
      { opacity: 1, transform: CSS.Transform.toString(transform.initial) },
      {
        opacity: 0,
        transform: CSS.Transform.toString({
          ...transform.final,
          x: transform.final.x + 5,
          y: transform.final.y + 5,
        }),
      },
    ];
  },
  easing: 'ease-out',
  sideEffects({ active }) {
    active.node.animate([{ opacity: 0 }, { opacity: 1 }], {
      duration: defaultDropAnimation.duration,
      easing: defaultDropAnimation.easing,
    });
  },
};

export function SortableTree({
  items,
  indicator,
  indentationWidth = 20,
  onItemsChanged = () => {},
  TreeItemComponent,
  pointerSensorOptions,
  disableSorting,
  dropAnimation,
  dndContextProps,
  sortableProps,
  keepGhostInPlace,
  canRootHaveChildren,
  ...rest
}) {
  const [activeId, setActiveId] = useState(null);
  const [overId, setOverId] = useState(null);
  const [offsetLeft, setOffsetLeft] = useState(0);

  const flattenedTree = flattenTree(items);

  const flattenedItems = useMemo(() => {
    const collapsedItems = flattenedTree.reduce(
      (acc, { children, collapsed, id }) => (collapsed && children?.length ? [...acc, id] : acc),
      []
    );

    const result = removeChildrenOf(
      flattenedTree,
      activeId ? [activeId, ...collapsedItems] : collapsedItems
    );
    return result;
  }, [activeId, flattenedTree]);

  const projected = getProjection(
    flattenedItems,
    activeId,
    overId,
    offsetLeft,
    indentationWidth,
    keepGhostInPlace ?? false,
    canRootHaveChildren
  );

  const sensorContext = useRef({
    items: flattenedItems,
    offset: offsetLeft,
  });

  const sensors = useSensors(
    useSensor(PointerSensor, pointerSensorOptions ?? defaultPointerSensorOptions)
  );

  const sortedIds = useMemo(() => flattenedItems.map(({ id }) => id), [flattenedItems]);
  const activeItem = activeId ? flattenedItems.find(({ id }) => id === activeId) : null;

  useEffect(() => {
    sensorContext.current = {
      items: flattenedItems,
      offset: offsetLeft,
    };
  }, [flattenedItems, offsetLeft]);

  const itemsRef = useRef(items);
  itemsRef.current = items;
  const handleRemove = useCallback(
    (id) => {
      const item = findItemDeep(itemsRef.current, id);
      onItemsChanged(removeItem(itemsRef.current, id), {
        type: 'removed',
        item,
      });
    },
    [onItemsChanged]
  );

  const onAppend = useCallback(
    (parentId, item) => {
      onItemsChanged(addItem(itemsRef.current, item, parentId), {
        type: 'added',
        item,
        parent: parentId ? findItemDeep(itemsRef.current, parentId) : null,
      });
    },
    [onItemsChanged, flattenedTree]
  );

  function onMove(from, direction = 'up') {
    const fn = direction === 'down' ? findNextItemDeep : findPrevItemDeep;
    const to = fn(itemsRef.current, from);

    if (!to) return;
    const depth = to ? to.depth : 0;
    const parentId = to ? to.parentId : null;

    const fromIndex = flattenedTree.findIndex(({ id }) => id === from);
    const toIndex = flattenedTree.findIndex(({ id }) => id === to.id);

    const activeTreeItem = flattenedTree[fromIndex];
    flattenedTree[fromIndex] = {
      ...activeTreeItem,
      depth,
      parentId,
    };

    const sortedItems = arrayMove(flattenedTree, fromIndex, toIndex);
    const newItems = buildTree(sortedItems);
    onItemsChanged(newItems, {
      type: 'moved',
      from: flattenedTree[fromIndex],
      to: flattenedTree[toIndex],
    });
  }

  const handleCollapse = useCallback(
    (id) => {
      const item = findItemDeep(itemsRef.current, id);

      onItemsChanged(
        setProperty(itemsRef.current, id, 'collapsed', (value) => {
          return !value;
        }),
        {
          type: item.collapsed ? 'collapsed' : 'expanded',
          item: item,
        }
      );
    },
    [onItemsChanged]
  );

  const strategyCallback = useCallback(() => {
    return !!projected;
  }, [projected]);

  if (!React.isValidElement(React.createElement(TreeItemComponent))) {
    return <div className="text-red-600">Invalid TreeItemComponent</div>;
  }

  return (
    <DndContext
      sensors={disableSorting ? undefined : sensors}
      modifiers={indicator ? modifiersArray : undefined}
      collisionDetection={closestCenter}
      onDragStart={disableSorting ? undefined : handleDragStart}
      onDragMove={disableSorting ? undefined : handleDragMove}
      onDragOver={disableSorting ? undefined : handleDragOver}
      onDragEnd={disableSorting ? undefined : handleDragEnd}
      onDragCancel={disableSorting ? undefined : handleDragCancel}
      {...dndContextProps}
      autoScroll
    >
      <SortableContext
        items={sortedIds}
        strategy={disableSorting ? undefined : customListSortingStrategy(strategyCallback)}
      >
        {flattenedItems.map((item) => {
          return (
            <SortableTreeItem
              {...rest}
              key={item.id}
              id={item.id}
              item={item}
              childCount={item.children?.length}
              depth={
                item.id === activeId && projected && !keepGhostInPlace
                  ? projected.depth
                  : item.depth
              }
              indentationWidth={indentationWidth}
              indicator={indicator}
              collapsed={Boolean(item.collapsed && item.children?.length)}
              onCollapse={item.children?.length ? handleCollapse : undefined}
              onRemove={handleRemove}
              onAppend={onAppend}
              onMove={onMove}
              isLast={item.id === activeId && projected ? projected.isLast : item.isLast}
              parent={item.id === activeId && projected ? projected.parent : item.parent}
              TreeItemComponent={TreeItemComponent}
              disableSorting={disableSorting}
              sortableProps={sortableProps}
              keepGhostInPlace={keepGhostInPlace}
            />
          );
        })}
        {createPortal(
          <DragOverlay
            dropAnimation={dropAnimation === undefined ? dropAnimationDefaultConfig : dropAnimation}
          >
            {activeId && activeItem ? (
              <TreeItemComponent
                {...rest}
                item={activeItem}
                depth={activeItem.depth}
                clone
                childCount={getChildCount(items, activeId) + 1}
                indentationWidth={indentationWidth}
                isLast={false}
                parent={activeItem.parent}
                isOver={false}
                isOverParent={false}
              />
            ) : null}
          </DragOverlay>,
          document.body
        )}
      </SortableContext>
    </DndContext>
  );

  function handleDragStart({ active: { id: activeId } }) {
    setActiveId(activeId);
    setOverId(activeId);

    document.body.style.setProperty('cursor', 'grabbing');
  }

  function handleDragMove({ delta }) {
    setOffsetLeft(delta.x);
  }

  function handleDragOver({ over }) {
    setOverId(over?.id ?? null);
  }

  function handleDragEnd({ active, over }) {
    resetState();

    if (projected && over) {
      const { depth, parentId } = projected;
      if (keepGhostInPlace && over.id === active.id) return;
      const overIndex = flattenedTree.findIndex(({ id }) => id === over.id);
      const activeIndex = flattenedTree.findIndex(({ id }) => id === active.id);
      const activeTreeItem = flattenedTree[activeIndex];
      flattenedTree[activeIndex] = {
        ...activeTreeItem,
        depth,
        parentId,
      };

      const draggedFromParent = activeTreeItem.parent;
      const sortedItems = arrayMove(flattenedTree, activeIndex, overIndex);
      const newItems = buildTree(sortedItems);
      const newActiveItem = sortedItems.find((x) => x.id === active.id);
      const currentParent = newActiveItem.parentId
        ? sortedItems.find((x) => x.id === newActiveItem.parentId)
        : null;

      setTimeout(() =>
        onItemsChanged(newItems, {
          type: 'dropped',
          draggedItem: newActiveItem,
          draggedFromParent: draggedFromParent,
          droppedToParent: currentParent,
        })
      );
    }
  }

  function handleDragCancel() {
    resetState();
  }

  function resetState() {
    setOverId(null);
    setActiveId(null);
    setOffsetLeft(0);

    document.body.style.setProperty('cursor', '');
  }
}

const adjustTranslate = ({ transform }) => {
  return {
    ...transform,
    y: transform.y - 25,
  };
};
const modifiersArray = [adjustTranslate];
