import { getLocationsUrl } from '@constants/apiUrls';
import { useAkinonMutation } from '@services/api/adapters/useAkinonMutation';
import { client } from '@services/api/client';

/**
 * @param {{ mutationOptions: import('@tanstack/react-query').UseMutationOptions }} args
 */
export function usePostLocationMutation({ mutationOptions } = {}) {
  const { isLoading, mutate } = useAkinonMutation({
    mutationFn: ({ requestBody, requestConfig }) =>
      client.post(getLocationsUrl, requestBody, requestConfig),
    ...mutationOptions,
  });

  return {
    postLocation: mutate,
    isPostingLocation: isLoading,
  };
}
