import './styles.scss';

import { createSelectOptions } from '@common/index';
import AkinonBox from '@components/AkinonBox';
import AkinonButton from '@components/AkinonButton';
import AkinonDragger from '@components/AkinonDragger';
import AkinonEmpty from '@components/AkinonEmpty';
import AkinonForm from '@components/AkinonForm';
import AkinonFormItem from '@components/AkinonFormItem';
import { AkinonTextArea } from '@components/AkinonInput';
import { openNotification } from '@components/akinonNotification';
import AkinonSelect from '@components/AkinonSelect';
import AkinonSpin from '@components/AkinonSpin';
import AuthorizedComponent from '@components/AuthorizedComponent';
import If from '@components/If';
import Show from '@components/Show';
import { UserRole } from '@constants/auth';
import { Color } from '@constants/theme';
import { zodResolver } from '@hookform/resolvers/zod';
import { useUsersQuery } from '@services/api/hooks/usePaginatedUsersQuery';
import { usePatchTicketMutation } from '@services/api/hooks/usePatchTicketMutation';
import { usePostTicketMessageMutation } from '@services/api/hooks/usePostTicketMessageMutation';
import { useTicketMessagesQuery } from '@services/api/hooks/useTicketMessagesQuery';
import { useTicketQuery } from '@services/api/hooks/useTicketQuery';
import { useUserRole } from '@utils/hooks/useUserRole';
import { Form, Skeleton, Space, Tooltip, Typography } from 'antd';
import reverse from 'lodash/fp/reverse';
import isEmpty from 'lodash/isEmpty';
import { useEffect, useRef } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';

import { getTicketPriorityOptions, getTicketStatusTag, TicketStatus } from '../common';
import { closedTicketStatuses, FormKey, getFormSchema, shortenId } from './common';
import TicketMessage from './components/TicketMessage';
import { useBreadcrumbs } from './hooks/useBreadcrumbs';
import { useOnSubmit } from './hooks/useOnSubmit';
import { usePageMeta } from './hooks/usePageMeta';

const { Title } = Typography;

function SupportTicketDetail() {
  const { t } = useTranslation('Support');
  const formSchema = getFormSchema({ t });
  const { control, handleSubmit, reset } = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: { [FormKey.CONTENT]: '', [FormKey.ATTACHMENT]: null },
    mode: 'onChange',
  });
  const attachments = useWatch({
    control,
    defaultValue: undefined,
    name: FormKey.ATTACHMENT,
  });
  const { ticketId } = useParams();
  const { state } = useLocation();
  const { ticket, isTicketLoading } = useTicketQuery({ ticketId });
  const userRole = useUserRole();
  const { users, isUsersLoading } = useUsersQuery({
    params: {
      is_superuser: true,
    },
    queryOptions: {
      enabled: userRole === UserRole.SUPER_USER,
    },
  });
  const messageInputRef = useRef();
  const { ticketMessages, isFetchingTicketMessages } = useTicketMessagesQuery({ ticketId });
  const { createTicketMessage, isCreatingTicketMessage } = usePostTicketMessageMutation();
  const { updateTicket, isUpdatingTicket } = usePatchTicketMutation();
  const onSubmit = useOnSubmit({ reset, createTicketMessage, ticketId, t });

  const handlePriorityChange = (value) => {
    if (userRole === UserRole.SUPER_USER) {
      updateTicket(
        {
          ticketId,
          requestBody: { priority: value },
          requestConfig: { suppressedNotifications: ['error', 'success'] },
        },
        {
          onSuccess() {
            openNotification({
              message: t('transactions.title.success'),
              description: t('transactions.body.update_priority_success'),
              type: 'success',
            });
          },
          onError() {
            openNotification({
              message: t('transactions.title.unsuccess'),
              description: t('transactions.body.update_priority_unsuccess'),
              type: 'error',
            });
          },
        }
      );
    }
  };

  const handleAssigneeChange = (value) => {
    if (userRole === UserRole.SUPER_USER) {
      updateTicket(
        {
          ticketId,
          requestBody: { assignee: value },
          requestConfig: { suppressedNotifications: ['error', 'success'] },
        },
        {
          onSuccess() {
            openNotification({
              message: t('transactions.title.success'),
              description: t('transactions.body.update_assignee_success'),
              type: 'success',
            });
          },
          onError() {
            openNotification({
              message: t('transactions.title.unsuccess'),
              description: t('transactions.body.update_assignee_unsuccess'),
              type: 'error',
            });
          },
        }
      );
    }
  };

  useBreadcrumbs();
  usePageMeta({ subject: ticket?.subject });

  const isMessageClosed = closedTicketStatuses.includes(ticket?.status);
  const assigneeOptions = createSelectOptions(users, {
    labelKey: 'email',
    valueKey: 'id',
    transformLabel: (label) => (
      <Tooltip mouseEnterDelay={1} title={label}>
        {label}
      </Tooltip>
    ),
  });
  const isTicketClosed = closedTicketStatuses.includes(ticket?.status);

  useEffect(() => {
    if (messageInputRef.current && Boolean(state?.isFocusToMessageInput)) {
      messageInputRef.current.focus();
      messageInputRef.current?.resizableTextArea.textArea.scrollIntoView({
        block: 'start',
        behavior: 'smooth',
      });
    } else if (Boolean(state?.isScrollToTop)) {
      window.scrollTo({ behavior: 'smooth', top: 0, left: 0 });
    }
  }, [state, messageInputRef]);

  const closeTicket = () => {
    updateTicket(
      {
        ticketId,
        requestBody: { status: TicketStatus.CLOSED },
        requestConfig: { suppressedNotifications: ['error', 'success'] },
      },
      {
        onSuccess() {
          openNotification({
            message: t('transactions.title.success'),
            description: t('transactions.body.close_ticket_success'),
            type: 'success',
          });
        },
        onError() {
          openNotification({
            message: t('transactions.title.unsuccess'),
            description: t('transactions.body.close_ticket_unsuccess'),
            type: 'error',
          });
        },
      }
    );
  };

  return (
    <section className="support-ticket-detail">
      <div className="support-ticket-detail__messages">
        <AkinonSpin spinning={isTicketLoading}>
          <div className="support-ticket-detail__messages-header">
            <div className="support-ticket-detail__messages-header-banner" />
            <Space size={16}>
              <Title level={2} className="support-ticket-detail__messages-header-title">
                {ticket ? (
                  `#${shortenId(ticket.id)}`
                ) : (
                  <Skeleton.Input active style={{ height: 27 }} />
                )}
              </Title>
              <div className="support-ticket-detail__messages-header-tag">
                {getTicketStatusTag({ status: ticket?.status, t })}
              </div>
            </Space>
            <AuthorizedComponent userRole={UserRole.SUPER_USER}>
              <Space className="support-ticket-detail__messages-actions">
                <AkinonSelect
                  disabled={isTicketClosed}
                  value={ticket?.assignee}
                  onChange={handleAssigneeChange}
                  options={assigneeOptions}
                  loading={isUsersLoading || isUpdatingTicket || isTicketLoading}
                  style={{ width: 200 }}
                  placeholder={t('selectEntity', { entity: t('assignee') })}
                  showSearch
                  optionFilterProp="label"
                />
                <AkinonSelect
                  disabled={isTicketClosed}
                  value={ticket?.priority}
                  onChange={handlePriorityChange}
                  style={{ width: 120 }}
                  options={getTicketPriorityOptions({ t })}
                  placeholder={t('selectEntity', { entity: t('priority') })}
                  loading={isUpdatingTicket || isTicketLoading}
                />
                <Show when={!isTicketClosed}>
                  <AkinonButton onClick={closeTicket} type="default">
                    {t('closeTicket').toUpperCase()}
                  </AkinonButton>
                </Show>
              </Space>
            </AuthorizedComponent>
          </div>
          <div className="support-ticket-detail__messages-content">
            <If
              condition={isFetchingTicketMessages}
              then={
                <>
                  <Skeleton />
                  <Skeleton style={{ transform: 'scaleX(-1)' }} />
                </>
              }
              otherwise={
                <If
                  condition={isEmpty(ticketMessages)}
                  then={<AkinonEmpty description={t('noEntityFound', { entity: t('messages') })} />}
                  otherwise={reverse(ticketMessages)?.map((message) => (
                    <TicketMessage key={message.id} message={message} />
                  ))}
                />
              }
            />
          </div>
        </AkinonSpin>
      </div>
      <Show when={!isMessageClosed}>
        <AkinonBox
          title={t('reply')}
          bannerColor={Color.DODGER_BLUE}
          className="support-ticket-detail__form-container box-primary form-box"
        >
          <AkinonSpin spinning={isTicketLoading}>
            <AkinonForm layout="vertical">
              <AkinonFormItem
                inputRef={messageInputRef}
                name={FormKey.CONTENT}
                label={t('message')}
                control={control}
              >
                <AkinonTextArea
                  autoSize={{ minRows: 3 }}
                  placeholder={t('enter_variable', { variable: t('message') })}
                />
              </AkinonFormItem>
              <AkinonFormItem name={FormKey.ATTACHMENT} label={t('upload')} control={control}>
                <AkinonDragger multiple fileList={attachments?.fileList} />
              </AkinonFormItem>
              <Form.Item wrapperCol={{ span: 16 }}>
                <AkinonButton
                  type="primary"
                  htmlType="button"
                  onClick={handleSubmit(onSubmit)}
                  className="support-ticket-form__submit-button"
                  loading={isCreatingTicketMessage}
                >
                  {t('send').toUpperCase()}
                </AkinonButton>
              </Form.Item>
            </AkinonForm>
          </AkinonSpin>
        </AkinonBox>
      </Show>
    </section>
  );
}

export default SupportTicketDetail;
