import { Form, Input, InputNumber } from 'antd';
import React from 'react';

const EditableCell = ({
  editing,
  dataIndex,
  record,
  rules,
  children,
  inputProps,
  reset,
  ...restProps
}) => {
  const { type } = inputProps ?? {};
  const inputNode =
    type === 'number' ? (
      <InputNumber
        {...inputProps}
        onChange={(val) => {
          reset({ ...record, [dataIndex]: val });
        }}
      />
    ) : (
      <Input {...inputProps} />
    );
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={rules}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

export default EditableCell;
