import { zodResolver } from '@hookform/resolvers/zod';
import isEmpty from 'lodash/isEmpty';
import trim from 'lodash/trim';
import * as z from 'zod';

export const FormKey = {
  PENALTY_CONFIG: {
    CALCULATION_TYPE: 'penalty_config.calculation_type',
    REFERENCE: 'penalty_config.reference',
    MULTIPLIER: 'penalty_config.multiplier',
    AMOUNT: 'penalty_config.amount',
  },
};

export const CalculationType = {
  FIXED: 'fixed',
  PERCENTAGE: 'percentage',
};

export const getFloatSchema = ({ t }) =>
  z.coerce
    .string()
    .superRefine((value, ctx) => {
      if (trim(value) !== value) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: t('white_space_validation_message'),
        });
      } else if (isEmpty(value)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: t('formRule.required'),
        });
      }
    })
    .pipe(
      z.coerce
        .number({
          invalid_type_error: t('number.validation.message'),
        })
        .min(0.01, { message: t('value_must_be_greater_than_min', { min: 0.01 }) })
        .max(1.0, { message: t('value_must_be_less_than_max', { max: 1.0 }) })
        .nonnegative({ message: t('positive_validation_message') })
    );

export const getFormResolver = ({ t, referenceOptions }) => {
  const floatSchema = getFloatSchema({ t });

  return zodResolver(
    z.object({
      penalty_config: z.discriminatedUnion('calculation_type', [
        z.object({
          calculation_type: z.literal(CalculationType.FIXED),
          amount: z.number().min(0),
        }),
        z.object({
          calculation_type: z.literal(CalculationType.PERCENTAGE),
          reference: z.union(referenceOptions?.map((reference) => z.literal(reference.value))),
          multiplier: floatSchema,
        }),
      ]),
    })
  );
};
