import '../styles.scss';

import { createSelectOptions } from '@common/index';
import AkinonFormItem from '@components/AkinonFormItem';
import { limitQuery } from '@constants/commontypes';
import { useIsMobile } from '@hooks/useIsMobile';
import { useAkinonQuery } from '@services/api/adapters/useAkinonQuery';
import { client } from '@services/api/client';
import { Select } from 'antd';
import clsx from 'clsx';
import debounce from 'lodash/debounce';
import filter from 'lodash/filter';
import find from 'lodash/find';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import omit from 'lodash/omit';
import some from 'lodash/some';
import { memo, useEffect, useMemo, useState } from 'react';

import MobileSelectDropdown from '../components/MobileSelectDropdown';
import usePaginatedQuery from './hooks/usePaginatedQuery';

const Component = memo((props) => {
  const { isLightTheme = false, value: _value, queryProps = {}, ...restProps } = props;
  const { valueKey = 'id', labelKey = 'name', className } = restProps;
  const { remoteKey, remoteUrl, params = {}, searchKey, ...restQueryProps } = queryProps;

  const [searchValue, setSearchValue] = useState();
  const isMobile = useIsMobile();
  const [isFadingOut, setIsFadingOut] = useState(false);
  const [value, setValue] = useState(_value);
  const [isOpen, setIsOpen] = useState(false);

  const onClear = () => {
    //? workaround for antd bug, onChange sometimes does not trigger when the value is undefined.
    props?.onChange?.(false);
    setTimeout(() => {
      props?.onChange?.(undefined);
    }, 0);
    onClose();
  };

  const onApply = () => {
    onClose();
    props?.onChange?.(value);
  };

  const onClose = () => {
    setIsFadingOut(true);
    setTimeout(() => {
      setIsOpen(false);
      setIsFadingOut(false);
    }, 300);
  };

  const mobileProps = isMobile
    ? {
        showSearch: false, // <- very annoying on a real device
        onClick: (e) => {
          // this is more reliable then onFocus
          const selectParentsClasses = [
            'ant-select-selection-item',
            'ant-select-selection-search-input',
          ];
          const isSelectParent = selectParentsClasses.some((className) =>
            e.target?.className.includes?.(className)
          );
          if (isSelectParent) {
            setIsOpen(true);
          }
        },
        onChange: (val) => setValue(val),
        open: isOpen,
      }
    : {};

  const { isFetching, data, hasNextPage, fetchNextPage } = usePaginatedQuery({
    key: remoteKey,
    url: remoteUrl,
    params: {
      ...params,
      [searchKey]: searchValue,
    },
    searchKey,
    valueKey,
    ...restQueryProps,
  });

  const isInValidValueForLabelInValue = (v) =>
    (typeof v !== 'object' && typeof v === 'string') ||
    (typeof v === 'object' && !isEmpty(v) && !('label' in v) && !('value' in v));

  const valuesNotInData = useMemo(() => {
    if (isEmpty(data) || !_value) {
      return [];
    }
    if (props.labelInValue) {
      if (isArray(_value) && props.mode === 'multiple') {
        return filter(
          _value,
          (v) => isInValidValueForLabelInValue(v) && !some(data, (d) => d[valueKey] === v)
        );
      } else if (!isArray(_value) && props.mode !== 'multiple') {
        if (isInValidValueForLabelInValue(_value) && !some(data, (d) => d[valueKey] === _value)) {
          return [_value];
        }
      }
    } else {
      if (isArray(_value) && props.mode === 'multiple') {
        return filter(_value, (v) => !some(data, (d) => d[valueKey] === v));
      } else if (!isArray(_value) && props.mode !== 'multiple') {
        if (!some(data, (d) => d[valueKey] === _value)) {
          return [_value];
        }
      }
    }

    return [];
  }, [data, _value, valueKey, props.labelInValue, props.mode]);

  const { data: missingValueData } = useAkinonQuery({
    queryKey: [remoteKey, valueKey, remoteUrl, limitQuery],
    queryFn: () =>
      client.get(remoteUrl, {
        params: {
          [`${valueKey}__in`]: valuesNotInData?.join(','),
          ...limitQuery,
        },
      }),
    enabled: !isEmpty(valuesNotInData),
  });

  const missingValues = useMemo(() => {
    return createSelectOptions(missingValueData?.results, {
      valueKey,
      labelKey,
    });
  }, [missingValueData, valueKey, labelKey]);

  const finalValue = useMemo(() => {
    if (isEmpty(missingValues)) return _value;
    if (props.labelInValue) {
      if (props.mode === 'multiple') {
        return map(_value, (v) => find(missingValues, { value: get(v, 'value', v) }) ?? v);
      } else {
        return find(missingValues, { value: get(_value, 'value', _value) }) ?? _value;
      }
    } else if (!props.labelInValue) {
      if (props.mode === 'multiple') {
        return map(_value, (v) => find(missingValues, { value: v }) ?? v);
      } else {
        return find(missingValues, { value: _value }) ?? _value;
      }
    }
    return _value;
  }, [_value, missingValues, props.mode, props.labelInValue]);

  useEffect(() => {
    setSearchValue(undefined);
  }, [finalValue]);

  const options = useMemo(() => {
    return createSelectOptions(data, {
      valueKey,
      labelKey,
    });
  }, [data, valueKey, labelKey]);

  return (
    <Select
      className={clsx({ 'akinon-select w-full': !isLightTheme }, className)}
      aria-label={
        restProps?.['aria-label'] ??
        restProps.label ??
        restProps.formItemProps?.label ??
        restProps?.id
      }
      onPopupScroll={(event) => {
        const { scrollTop, offsetHeight, scrollHeight } = event.target;
        const isScrollReached = scrollTop + offsetHeight === scrollHeight;
        if (isScrollReached && hasNextPage && !isFetching) {
          fetchNextPage();
        }
      }}
      dropdownRender={
        isMobile
          ? (menu) => (
              <MobileSelectDropdown
                onClear={onClear}
                title={restProps.placeholder}
                menu={menu}
                onApply={onApply}
                onClose={onClose}
                isFadingOut={isFadingOut}
              />
            )
          : null
      }
      {...omit(restProps, ['suffixIcon', 'aria-label'])}
      value={finalValue}
      {...mobileProps}
      onBlur={() => {
        setSearchValue(undefined);
      }}
      searchValue={searchValue}
      onSearch={debounce((val) => {
        setSearchValue(isEmpty(val) ? undefined : val);
      }, 500)}
      loading={isFetching}
      filterOption={false}
      options={options}
    />
  );
});

/**
 * @param {import('antd').SelectProps} props
 */
const AkinonRemoteSelect = (props) => {
  const { formItemProps, ...rest } = props;
  if (formItemProps) {
    return (
      <AkinonFormItem {...formItemProps}>
        <Component {...rest} />
      </AkinonFormItem>
    );
  }

  return <Component {...rest} />;
};

AkinonRemoteSelect.displayName = 'AkinonRemoteSelect';
export default memo(AkinonRemoteSelect);
