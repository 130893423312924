import { client } from '@services/api/client';
import { useInfiniteQuery } from '@tanstack/react-query';
import flatten from 'lodash/flatten';
import isEqual from 'lodash/isEqual';
import isNil from 'lodash/isNil';
import map from 'lodash/map';
import property from 'lodash/property';
import reject from 'lodash/reject';
import uniqBy from 'lodash/uniqBy';
import { usePrevious } from 'react-use';
import * as z from 'zod';

const urlSchema = z.string().url();

export default function usePaginatedQuery({
  key,
  url,
  searchKey,
  valueKey,
  params = {},
  queryOptions = {},
}) {
  const prevParams = usePrevious(params);
  const { data, isFetching, hasNextPage, fetchNextPage } = useInfiniteQuery({
    queryKey: [`${key}_dropdown`, params],
    async queryFn({ pageParam = 1 }) {
      return await client.get(url, {
        params: {
          ...params,
          page: pageParam,
        },
      });
    },
    getNextPageParam(lastPage) {
      if (!lastPage?.next) return undefined;
      const parsedNextUrl = urlSchema.safeParse(lastPage.next);
      if (!parsedNextUrl.success) return undefined;
      const nextUrl = new URL(lastPage.next);
      const nextPage = nextUrl.searchParams.get('page');

      if (!isEqual(prevParams, params)) return undefined;
      return nextPage;
    },
    ...queryOptions,
  });

  const _data = uniqBy(flatten(map(reject(data?.pages, isNil), property('results'))), valueKey);

  return {
    data: _data,
    isFetching,
    hasNextPage,
    fetchNextPage,
  };
}
