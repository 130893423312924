import './styles.scss';

import AkinonButton from '@components/AkinonButton';
import AkinonFormItem from '@components/AkinonFormItem';
import { openDebouncedNotification } from '@components/akinonNotification';
import { zodResolver } from '@hookform/resolvers/zod';
import { useDynamicSettingsQuery } from '@services/api/hooks/useDynamicSettingsQuery';
import { Checkbox, Divider, Modal, Space, Typography } from 'antd';
import get from 'lodash/get';
import map from 'lodash/map';
import { useCallback, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ProductsToBeAddedTableFormFormItemKey } from '../../common';
import ProductImageUpload from '../ProductImageUpload';
import { getImageFormSchema } from './common';

const { Text } = Typography;

/**
 * @param {{ form: import('react-hook-form').UseFormReturn, }} props
 */
const AddImagesToSelectedProductsModal = ({
  form,
  variants,
  dataSource,
  modalProps,
  variantDynamicFields,
  selectedRowKeys,
}) => {
  const { t } = useTranslation('ProductsAndCategories');

  const { dynamicSettings: maxImageDimensionSettings } = useDynamicSettingsQuery({
    params: {
      key: 'MAX_IMAGE_DIMENSIONS',
      is_active: true,
    },
  });
  const { dynamicSettings: minImageDimensionSettings } = useDynamicSettingsQuery({
    params: {
      key: 'MIN_IMAGE_DIMENSIONS',
      is_active: true,
    },
  });

  const maxImageDimensions = maxImageDimensionSettings?.find(
    (setting) => setting.key === 'MAX_IMAGE_DIMENSIONS'
  )?.value;
  const minImageDimensions = minImageDimensionSettings?.find(
    (setting) => setting.key === 'MIN_IMAGE_DIMENSIONS'
  )?.value;

  const imageFormSchema = getImageFormSchema({ t, maxImageDimensions, minImageDimensions });
  const { reset, control, getFieldState, trigger, formState } = useForm({
    mode: 'onChange',
    resolver: zodResolver(imageFormSchema),
  });

  const dataSourcesToUpdate = dataSource.filter((item) => selectedRowKeys?.includes(item.id));

  const { bulk, single } = useWatch({
    control: control,
    defaultValue: {},
  });

  const [selectedVariants, setSelectedVariants] = useState([]);

  const handleApply = useCallback(
    async (variantsToApply) => {
      await trigger('bulk.product_image');
      const bulkErrors = getFieldState('bulk.product_image').error;
      if (variantsToApply.length === 0 || bulkErrors !== undefined) return;
      reset({
        bulk: {},
        single: {
          ...single,
          ...Object.entries(bulk)
            .filter(([_, value]) => value !== undefined && value !== null)
            .reduce((acc, [key, value]) => {
              acc[key] = dataSource.map((item) =>
                variantsToApply.includes(item.id)
                  ? [...value, ...(single[key]?.[item.id] ?? [])]
                  : single[key]?.[item.id]
              );
              return acc;
            }, {}),
        },
      });
    },
    [dataSource, bulk, reset, single]
  );

  const onAddImagesToProduct = useCallback(async () => {
    await trigger('single.product_image');
    const errors = getFieldState('single.product_image').error;
    if (errors !== undefined) {
      if (errors.minImageError) {
        openDebouncedNotification({
          type: 'error',
          message: errors.minImageError.message,
          t,
        });
      }
      return;
    }

    const productImagesForAll = dataSource.map((row) => {
      const currentProductImages = row[ProductsToBeAddedTableFormFormItemKey.PRODUCT_IMAGE] ?? [];
      const productImages =
        single?.[ProductsToBeAddedTableFormFormItemKey.PRODUCT_IMAGE]?.[row.id]?.map(
          (imageFile) => ({
            src: imageFile.thumbUrl ?? imageFile.originFileObj.thumbUrl,
            ...imageFile,
          })
        ) ?? [];
      return [...currentProductImages, ...productImages];
    });
    form.setValue(ProductsToBeAddedTableFormFormItemKey.PRODUCT_IMAGE, productImagesForAll);

    handleCancel();
  }, [dataSource, selectedVariants, single]);

  const resetProductImagesForm = useCallback(() => {
    reset({
      bulk: {},
      single: {},
    });
  }, [reset]);

  const handleChange = (event) => {
    setSelectedVariants((prevSelectedVariant) => {
      if (event.target.checked) {
        return [...prevSelectedVariant, event.target.value];
      }
      return prevSelectedVariant.filter((variantId) => variantId !== event.target.value);
    });
  };

  const handleCancel = useCallback(() => {
    modalProps?.onCancel?.();
    setSelectedVariants([]);
    resetProductImagesForm();
  }, [modalProps, resetProductImagesForm, setSelectedVariants]);

  const toggleAllVariantsCheckbox = (event) => {
    if (event.target.checked) {
      setSelectedVariants(map(dataSourcesToUpdate, 'id'));
    } else {
      setSelectedVariants([]);
    }
  };

  return (
    <Modal
      footer={null}
      width={800}
      title={t('product.images.title')}
      onCancel={handleCancel}
      afterClose={handleCancel}
      className="add-images-to-selected-products-modal"
      destroyOnClose
      {...modalProps}
    >
      <Space direction="vertical" size="large" style={{ width: '100%' }}>
        <Space direction="vertical" size="large" style={{ width: '100%' }}>
          <Text className="add-images-to-selected-products-modal__description">
            İmajlarınızı yüklemek için lütfen yüklemek istediğiniz ürünü seçiniz.
          </Text>
          <AkinonFormItem
            name={`bulk.${ProductsToBeAddedTableFormFormItemKey.PRODUCT_IMAGE}`}
            control={control}
            help={() => null}
          >
            <ProductImageUpload
              accept="image/*"
              multiple
              errors={map(get(formState.errors, 'bulk.product_image'), 'message')}
              itemRender={() => null}
              fileList={bulk?.[ProductsToBeAddedTableFormFormItemKey.PRODUCT_IMAGE] ?? []}
            />
          </AkinonFormItem>
          <Space>
            <AkinonButton
              type="primary"
              htmlType="button"
              onClick={() => {
                handleApply(map(dataSourcesToUpdate, 'id'));
              }}
            >
              {t('add.images.to.all.products').toUpperCase()}
            </AkinonButton>
            <AkinonButton
              type="primary"
              htmlType="button"
              onClick={() => {
                handleApply(selectedVariants);
              }}
              disabled={selectedVariants.length === 0}
            >
              {t('add.images.to.selected.products').toUpperCase()}
            </AkinonButton>
          </Space>
        </Space>
        <Divider orientation="left" />
        <Checkbox
          checked={selectedVariants.length === dataSourcesToUpdate.length}
          className="add-images-to-selected-products-modal__variant-list__item__checkbox"
          onChange={toggleAllVariantsCheckbox}
        >
          {t('select_all_variants')}
        </Checkbox>
        <Space
          direction="vertical"
          size="large"
          className="add-images-to-selected-products-modal__variant-list"
        >
          {dataSourcesToUpdate.map((item, index) => {
            const variantKeys = Object.keys(variants)
              .map((variantKey) => {
                const variantValue = item[variantKey];
                const variantLabel = variantDynamicFields
                  .find((field) => field.key === variantKey)
                  .options.find((option) => option.value === variantValue)?.label;
                return variantLabel ?? variantValue;
              })
              .join('/');

            return (
              <div
                className="add-images-to-selected-products-modal__variant-list__item"
                key={index}
              >
                <Checkbox
                  value={item.id}
                  checked={selectedVariants.includes(item.id)}
                  onChange={handleChange}
                  className="add-images-to-selected-products-modal__variant-list__item__checkbox"
                >
                  {variantKeys}
                </Checkbox>
                <AkinonFormItem
                  name={`single.${ProductsToBeAddedTableFormFormItemKey.PRODUCT_IMAGE}.${item.id}`}
                  control={control}
                  isWithColumn
                  colSpan={16}
                  help={() => null}
                >
                  <ProductImageUpload
                    accept="image/*"
                    listType="picture-card"
                    itemRender={() => null}
                    errors={map(
                      get(formState.errors, `single.product_image.${item.id}`),
                      'message'
                    )}
                    fileList={
                      single?.[ProductsToBeAddedTableFormFormItemKey.PRODUCT_IMAGE]?.[item.id]
                    }
                  />
                </AkinonFormItem>
              </div>
            );
          })}
        </Space>
        <Space className="add-images-to-selected-products-modal__variant-list__buttons">
          <AkinonButton type="primary" htmlType="button" onClick={onAddImagesToProduct}>
            {t('save').toUpperCase()}
          </AkinonButton>
          <AkinonButton type="danger" htmlType="button" onClick={resetProductImagesForm}>
            {t('clean.all.content').toUpperCase()}
          </AkinonButton>
        </Space>
      </Space>
    </Modal>
  );
};

export default AddImagesToSelectedProductsModal;
