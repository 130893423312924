import './styles.scss';

import { Empty, Table } from 'antd';
import clsx from 'clsx';
import isFunction from 'lodash/isFunction';
import isString from 'lodash/isString';
import React from 'react';
import { useTranslation } from 'react-i18next';

/**
 * @param {import('antd').TableProps} props
 */
const HeadlessAkinonTable = ({ columns, rowClassName, className, ...otherProps }) => {
  const { t } = useTranslation();
  const columnsWithUpperCasedTitles = columns.map((column) => ({
    ...column,
    title: isString(column.title) ? column.title?.toUpperCase() : column.title,
  }));

  const locale = {
    emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t('no_data_available')} />,
  };

  return (
    <Table
      className={clsx('headless-akinon-table', className)}
      rowClassName={(record, index) =>
        (index % 2 === 0 ? 'even-row' : 'odd-row').concat(
          isFunction(rowClassName) ? ` ${rowClassName(record, index)}` : ` ${rowClassName}`
        )
      }
      columns={columnsWithUpperCasedTitles}
      locale={otherProps?.locale || locale}
      {...otherProps}
    />
  );
};

export default HeadlessAkinonTable;
