import { openNotification } from '@components/akinonNotification';
import { useAttributeValuesQuery } from '@services/api/hooks/useAttributeValuesQuery';
import { usePaginatedDataSourcesQuery } from '@services/api/hooks/usePaginatedDataSourcesQuery';
import { useProductImagesQuery } from '@services/api/hooks/usePaginatedProductImagesQuery';
import { usePaginatedProductOffersQuery } from '@services/api/hooks/usePaginatedProductOffersQuery';
import { useProductsQuery } from '@services/api/hooks/usePaginatedProductsQuery';
import { useCategoryTreeData } from '@utils/hooks/useCategoryTreeData';
import { getTreeNodeByValue } from '@utils/index';
import cloneDeep from 'lodash/cloneDeep';
import entries from 'lodash/entries';
import find from 'lodash/find';
import flatten from 'lodash/flatten';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import pick from 'lodash/pick';
import reduce from 'lodash/reduce';
import uniq from 'lodash/uniq';
import { useTranslation } from 'react-i18next';

export const useDataSource = ({ dynamicFormMetaFields, pagination, filters, params = {} }) => {
  const { t } = useTranslation('ProductsAndCategories');

  const { productOffers, isFetchingProductOffers, totalProductOffers, productOffersError } =
    usePaginatedProductOffersQuery({
      pagination,
      filters,
      params,
      onError: (error) => {
        if (error.code === 'ECONNABORTED') {
          openNotification({
            message: t('filtering_unsuccessful'),
            description: t('request_timeout'),
            type: 'error',
            t,
          });
        }
      },
    });

  const datasourceIds = uniq(productOffers?.map((item) => item.datasource)).filter(Boolean);
  const { dataSources, isFetchingDataSources } = usePaginatedDataSourcesQuery({
    params: { id__in: datasourceIds.join(','), limit: datasourceIds?.length },
    queryOptions: {
      enabled: !isFetchingProductOffers && !isEmpty(datasourceIds),
    },
  });

  const productIds = uniq(productOffers?.map(({ product }) => product)).filter(Boolean);
  const { products, isFetchingProducts } = useProductsQuery({
    params: {
      id__in: productIds?.join(','),
      limit: productIds?.length,
    },
    queryOptions: {
      enabled: !isFetchingProductOffers && !isEmpty(productIds),
    },
  });

  const { productImages, isFetchingProductImages } = useProductImagesQuery({
    params: {
      limit: productIds?.length,
      parent__in: productIds?.join(','),
      main: true,
    },
    queryOptions: {
      enabled: !isFetchingProductOffers && !isEmpty(productIds),
    },
  });

  const attributeIds = uniq(map(dynamicFormMetaFields, 'id'));
  const attributeKeys = uniq(map(dynamicFormMetaFields, 'key'));
  const attributeVals = uniq(
    flatten(
      map(productOffers, (offer) => {
        const product = find(products, { id: offer.product });
        const attributes = {
          ...offer.attributes,
          ...product?.attributes,
        };
        return Object.values(pick(attributes, attributeKeys));
      })
    )
  );
  const { attributeValues, isFetchingAttributeValues } = useAttributeValuesQuery({
    params: {
      attribute__in: attributeIds.join(','),
      value__in: attributeVals.join(','),
    },
    queryOptions: {
      enabled:
        !isFetchingProductOffers &&
        !isFetchingProducts &&
        !isEmpty(attributeVals) &&
        !isEmpty(attributeIds),
    },
  });

  const { categoryTreeData, isCategoriesFetching } = useCategoryTreeData({
    queryOptions: {
      enabled: !isFetchingProducts,
    },
  });

  const isLoading =
    isFetchingAttributeValues ||
    isFetchingDataSources ||
    isFetchingProducts ||
    isFetchingProductImages ||
    isCategoriesFetching;

  // Map related data.
  const data = productOffers?.map((offer) => {
    const datasource = dataSources?.find(({ id }) => id === offer.datasource);
    const product = products?.find(({ id }) => id === offer?.product);
    const image = productImages?.find(({ parent }) => parent === product?.id);
    const category = getTreeNodeByValue({ tree: categoryTreeData, nodeValue: product?.category });

    const clonedOffer = cloneDeep(offer);
    clonedOffer.attributes = {
      ...reduce(
        entries({ ...clonedOffer.attributes, ...cloneDeep(product?.attributes) }),
        (acc, [key, value]) => {
          const attribute = find(dynamicFormMetaFields, { key });
          if (!attribute) return acc;
          const valueLabel = get(
            find(attributeValues, { value, attribute: attribute.id }),
            'label',
            value
          );
          return {
            ...acc,
            [key]: valueLabel,
          };
        },
        {}
      ),
    };

    return {
      ...clonedOffer,
      offer_code: clonedOffer.sku,
      datasource,
      product,
      image,
      category,
    };
  });

  return {
    data,
    total: totalProductOffers,
    isLoading,
    error: productOffersError,
  };
};
