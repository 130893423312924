import { getRequestFiltersParams, getRequestPaginationParams } from '@common/index';
import { useDataSourcesQuery } from '@services/api/hooks';
import { useAgreementConsentQuery } from '@services/api/hooks/useAgreementConsentQuery';
import { useAgreementDetailQuery } from '@services/api/hooks/useAgreementDetailQuery';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import map from 'lodash/map';
import reject from 'lodash/reject';
import uniq from 'lodash/uniq';
import { useParams } from 'react-router-dom';

export default function useDataSource({ pagination, filters }) {
  const params = {
    ...getRequestPaginationParams(pagination),
    ...getRequestFiltersParams(filters),
  };
  const { agreementId } = useParams();

  const { agreementConsents, isAgreementConsentsFetching, totalAgreementConsents } =
    useAgreementConsentQuery({
      params,
      agreementId,
      queryOptions: {
        enabled: Boolean(agreementId),
      },
    });

  const { agreementDetail, isAgreementDetailFetching } = useAgreementDetailQuery({
    agreementId,
    queryOptions: {
      enabled: Boolean(agreementId),
    },
  });

  const dataSourceIds = uniq(reject(map(agreementConsents, 'datasource'), isNil));

  const { dataSources, isFetchingDataSources } = useDataSourcesQuery({
    params: {
      id__in: dataSourceIds.join(','),
    },
    queryOptions: {
      enabled: !isEmpty(dataSourceIds),
    },
  });

  const dataSource = map(agreementConsents, (agreementConsent) => {
    return {
      ...agreementConsent,
      agreement: agreementDetail,
      datasource: find(dataSources, { id: agreementConsent.datasource }),
    };
  });

  return {
    dataSource,
    isDataSourceLoading:
      isAgreementConsentsFetching || isFetchingDataSources || isAgreementDetailFetching,
    total: totalAgreementConsents,
  };
}
