import { createNavigationItems, getUserRole } from '@common/auth';
import { UserRole } from '@constants/auth';
import { RouteUrls } from '@constants/routeUrls';
import React from 'react';

export const getNavigationItems = ({ t, user, isLiveAppVersion }) => {
  const userRole = getUserRole({ user });

  return createNavigationItems({
    navigationItems: [
      {
        to: RouteUrls.finance.sellerManagement.landing,
        title: userRole === UserRole.SUPER_USER ? t('seller.management') : t('account_management'),
        description: t('seller.management.desc'),
        icon: <i className="icon-satici-yonetimi" />,
      },
      {
        to: RouteUrls.finance.reconciliations.landing,
        title: t('reconciliations'),
        description: t('reconciliations.desc'),
        icon: <i className="icon-mutabakatlar" />,
      },
      {
        to: RouteUrls.finance.agreements,
        title: t('agreements'),
        description: t('agreements_description'),
        icon: <i className="icon-mutabakatlar" />,
      },
      {
        to: RouteUrls.finance.sellerPerformanceMetrics.landing,
        title: t('seller_performance_metrics'),
        description: t('seller_performance_metrics_desc'),
        icon: <i className="icon-mutabakatlar" />,
      },
      // {
      //   to: RouteUrls.finance.reconciliationTransactions,
      //   title: t('reconciliations.transactions'),
      //   description: t('reconciliations.transactions.desc'),
      //   icon: <i className="icon-mutabakat-islemleri1" />,
      // },
    ],
    userRole,
    isLiveAppVersion,
  });
};
