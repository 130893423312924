import { getRequestFiltersParams, getRequestPaginationParams } from '@common/index';
import { useAgreementsQuery } from '@services/api/hooks/useAgreementsQuery';

export default function useDataSource({ pagination, filters }) {
  const params = {
    ...getRequestPaginationParams(pagination),
    ...getRequestFiltersParams(filters),
  };

  const { agreements, isFetchingAgreements, totalAgreements } = useAgreementsQuery({
    params,
  });

  return {
    dataSource: agreements,
    isDataSourceLoading: isFetchingAgreements,
    total: totalAgreements,
  };
}
