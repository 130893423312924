import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';

export const FormKey = {
  REJECTION_REASON: 'rejection_reason',
};

export const getFormResolver = () => {
  return zodResolver(
    z.object({
      [FormKey.REJECTION_REASON]: z.string().min(1),
    })
  );
};
