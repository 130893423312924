import useLanguageSlice from '@components/AkinonFilter/hooks/store/useLanguageSlice';
import { openNotification } from '@components/akinonNotification';
import { Language } from '@constants/locale';
import { TranslationKey } from '@root/i18n';
import { useMeMutation } from '@services/api/hooks/useMeMutation';
import { useLanguagesQuery } from '@services/hooks';
import isEmpty from 'lodash/isEmpty';
import { useTranslation } from 'react-i18next';

export function useLanguage() {
  const { t } = useTranslation(TranslationKey.PROFILE);

  const { patchMe, isPatchingMe } = useMeMutation({
    mutationOptions: {
      onSuccess: () => {
        openNotification({
          message: t('transaction_success'),
          description: t('language_saved'),
          type: 'success',
        });
      },
      onError: (error) => {
        openNotification({
          message: t('language_save_failed'),
          description: error.response?.data?.detail ?? error.message,
          type: 'error',
        });
      },
    },
  });
  const { language, setLanguage, resetLanguage } = useLanguageSlice();
  const { languages } = useLanguagesQuery({
    cacheTime: Infinity,
    placeholderData: {},
  });

  const onSetLanguage = (language) => {
    patchMe({ requestBody: { language } });
    setLanguage(language);
  };

  return {
    languages,
    isPatchingMe,
    isLanguageReady: !isEmpty(language),
    language: language ?? Language.ENGLISH,
    setLanguage: onSetLanguage,
    resetLanguage,
  };
}
