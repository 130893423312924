import './styles.scss';

import AkinonBox from '@components/AkinonBox';
import AkinonButton from '@components/AkinonButton';
import AkinonForm from '@components/AkinonForm';
import AkinonInput, { AkinonTextArea } from '@components/AkinonInput';
import { AkinonRadio } from '@components/AkinonRadio';
import AkinonSelect from '@components/AkinonSelect';
import AkinonSpin from '@components/AkinonSpin';
import AkinonSwitch from '@components/AkinonSwitch';
import If from '@components/If';
import Show from '@components/Show';
import Box from '@components/utility/box';
import { UserRole } from '@constants/auth';
import { verticalFormItemLayout } from '@constants/layoutTypes';
import { Color } from '@constants/theme';
import { zodResolver } from '@hookform/resolvers/zod';
import { useUser } from '@root/contexts/hooks/useUser';
import { useDataSourceDetailQuery } from '@services/api/hooks/useDataSourceDetailQuery';
import { useDynamicSettingsQuery } from '@services/api/hooks/useDynamicSettingsQuery';
import { usePatchDatasourceDetailMutation } from '@services/api/hooks/usePatchDatasourceDetailMutation';
import { usePatchSupplierDetailMutation } from '@services/api/hooks/usePatchSupplierDetailMutation';
import { IconInfoCircle } from '@tabler/icons-react';
import { useUserRole } from '@utils/hooks/useUserRole';
import first from 'lodash/first';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import omit from 'lodash/omit';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { getPeriodProperties } from '../common';
import { FormKey, getFormSchema, STRATEGY_TYPE } from './common';
import { useBreadcrumbs } from './hooks/useBreadcrumbs';

const GeneralInfoForm = () => {
  const { t } = useTranslation('Finance');
  const { id } = useParams();
  const user = useUser();
  const userRole = useUserRole();
  const isSuperUser = userRole === UserRole.SUPER_USER;

  const dataSourceId = id ?? user?.datasource;
  const { dataSourceDetail, isFetchingDataSourceDetail, refetchDataSourceDetail } =
    useDataSourceDetailQuery({
      dataSourceId,
      queryOptions: {
        enabled: Boolean(dataSourceId),
      },
    });

  const { updateSupplierDetail } = usePatchSupplierDetailMutation({
    mutationOptions: {
      onSuccess: refetchDataSourceDetail,
    },
  });
  const { updateDataSourceDetail } = usePatchDatasourceDetailMutation({
    mutationOptions: {
      onSuccess: refetchDataSourceDetail,
    },
  });

  const { dynamicSettings, isDynamicSettingsFetching } = useDynamicSettingsQuery({
    params: {
      key: 'DATASOURCE_CUSTOM_FIELDS',
      is_active: true,
    },
  });
  const dynamicUserSettings = first(dynamicSettings)?.value;

  const schema = getFormSchema({ t, dynamicUserSettings });

  const defaultValues = omit(dataSourceDetail, [
    'commerce_id',
    'created_at',
    'easy_return_detail',
    'id',
    'token',
    'updated_at',
    'version_date',
  ]);

  const { control, handleSubmit, reset, watch } = useForm({
    resolver: zodResolver(schema),
  });

  const isLoading = isFetchingDataSourceDetail || isDynamicSettingsFetching;

  useEffect(() => {
    const modifiedValues = {
      ...defaultValues,
      [FormKey.PACKAGE_MANAGEMENT_TYPE]: Boolean(defaultValues[FormKey.PACKAGE_MANAGEMENT_TYPE]),
      [FormKey.STOCK_RELEASE_TYPE]: Boolean(defaultValues[FormKey.STOCK_RELEASE_TYPE]),
      [FormKey.AUTO_CANCEL]: Boolean(defaultValues[FormKey.AUTO_CANCEL]),
    };
    reset(modifiedValues);
  }, [isLoading, reset]);

  const formValues = watch();
  const strategyType = formValues?.conf?.reconciliation_conf?.creation_strategy?.strategy_type;

  const isStrategyDaily = strategyType === STRATEGY_TYPE.DAILY;
  const isStrategyWeekly = strategyType === STRATEGY_TYPE.WEEKLY;

  const handleInformationSubmit = (values) => {
    const requestBody = isSuperUser ? values : values.supplier_detail;
    const integrationUrl = values?.conf?.integration_url;
    const reconciliationConf = values?.conf?.reconciliation_conf;
    if (isSuperUser) {
      const modifiedRequestBody = {
        ...requestBody,
        stock_release_type: Number(requestBody.stock_release_type),
        package_management_type: Number(requestBody.package_management_type),
        cancel_management_type: Number(requestBody.cancel_management_type),
        conf: {
          integration_url: isEmpty(integrationUrl) ? null : integrationUrl,
          reconciliation_conf: reconciliationConf,
        },
      };
      updateDataSourceDetail({
        requestBody: modifiedRequestBody,
        dataSourceId,
        requestConfig: {
          successMessage: t('transaction_success'),
          successDescription: t('seller_management_detail_message.success'),
          errorMessage: t('transaction_failed'),
          errorDescription: t('seller_management_detail_message.error'),
        },
      });
    } else {
      updateSupplierDetail({
        requestBody,
        dataSourceId,
        requestConfig: {
          successMessage: t('transaction_success'),
          successDescription: t('seller_management_detail_message.success'),
          errorMessage: t('transaction_failed'),
          errorDescription: t('seller_management_detail_message.error'),
        },
      });
    }
  };

  useBreadcrumbs();

  return (
    <section className="seller-management-detail">
      <Show when={isSuperUser}>
        <Box className="seller-management-detail__header">
          <div className="seller-management-detail__header-info">
            <div>
              {t('seller')}: {dataSourceDetail?.name}
            </div>
          </div>
        </Box>
      </Show>
      <AkinonBox
        title={t('general_information')}
        bannerColor={Color.WILD_WATERMELON}
        shadow
        className="box-primary form-box seller-management-detail__form-box"
      >
        <AkinonSpin spinning={isLoading}>
          <AkinonForm layout="vertical" {...verticalFormItemLayout}>
            <AkinonInput
              formItemProps={{ name: FormKey.SUPPLIER_DETAIL.TITLE, label: t('title'), control }}
              placeholder={t('title')}
            />
            <AkinonInput
              formItemProps={{ name: FormKey.NAME, label: t('company_name'), control }}
              disabled={!isSuperUser}
              placeholder={t('company_name')}
            />
            <AkinonInput
              formItemProps={{ name: FormKey.SUPPLIER_CODE, label: t('seller_code'), control }}
              disabled={!isSuperUser}
              placeholder={t('seller_code')}
            />

            <AkinonTextArea
              formItemProps={{
                name: FormKey.SUPPLIER_DETAIL.ADDRESS,
                label: t('company_address'),
                control,
              }}
              placeholder={t('company_address')}
            />
            <AkinonInput
              formItemProps={{
                name: FormKey.SUPPLIER_DETAIL.TAX_NUMBER,
                label: t('tax_id'),
                control,
              }}
              placeholder={t('tax_id')}
            />
            <AkinonInput
              formItemProps={{
                name: FormKey.SUPPLIER_DETAIL.TRADE_ASSOCIATION,
                label: t('tax_administration'),
                control,
              }}
              placeholder={t('tax_administration')}
            />
            <AkinonInput
              formItemProps={{
                name: FormKey.SUPPLIER_DETAIL.IBAN_NUMBER,
                label: t('iban_number'),
                control,
              }}
              placeholder={t('iban_number')}
            />
            <AkinonInput
              formItemProps={{
                name: FormKey.SUPPLIER_DETAIL.PHONE_NUMBER,
                label: t('authorized_phone_number'),
                control,
              }}
              placeholder={t('authorized_phone_number')}
            />
            <AkinonInput
              formItemProps={{
                name: FormKey.SUPPLIER_DETAIL.FAX_NUMBER,
                label: t('authorized_fax_number'),
                control,
              }}
              placeholder={t('authorized_fax_number')}
            />
            <AkinonInput
              formItemProps={{
                name: FormKey.SUPPLIER_DETAIL.EMAIL,
                label: t('authorized_email_address'),
                control,
              }}
              placeholder={t('authorized_email_address')}
            />
            <AkinonInput
              formItemProps={{
                name: FormKey.SUPPLIER_DETAIL.KEP_ADDRESS,
                label: t('supplier_kep_address'),
                control,
              }}
              placeholder={t('supplier_kep_address')}
            />
            <AkinonInput
              formItemProps={{
                name: FormKey.SUPPLIER_DETAIL.MERSIS_NUMBER,
                label: t('supplier_mersis_number'),
                control,
              }}
              placeholder={t('supplier_mersis_number')}
            />
            {isSuperUser && (
              <AkinonInput
                formItemProps={{
                  name: FormKey.CONF.INTEGRATION_URL,
                  label: t('integrationUrl'),
                  control,
                }}
                placeholder={t('integrationUrl')}
              />
            )}
            <AkinonSwitch
              formItemProps={{
                name: FormKey.IS_ACTIVE,
                label: t('status'),
                control,
              }}
              aria-label={t('status')}
              checkedChildren={t('active')}
              unCheckedChildren={t('passive')}
              disabled={!isSuperUser}
            />
            <AkinonRadio
              formItemProps={{
                name: FormKey.PACKAGE_MANAGEMENT_TYPE,
                label: t('autopack'),
                control,
              }}
              aria-label={t('autopack')}
              options={[
                {
                  label: t('manual'),
                  value: true,
                },
                {
                  label: t('automatic'),
                  value: false,
                },
              ]}
              disabled={!isSuperUser}
            />
            <AkinonSwitch
              formItemProps={{
                name: FormKey.AUTO_APPROVE_ENABLED,
                label: t('seller_auto_approve'),
                control,
              }}
              aria-label={t('seller_auto_approve')}
              checkedChildren={t('active')}
              unCheckedChildren={t('passive')}
              disabled={!isSuperUser}
            />
            <AkinonSwitch
              formItemProps={{
                name: FormKey.IS_SUSPENDED,
                label: t('holiday_mode'),
                control,
                tooltip: {
                  title: t('holiday_moode_info'),
                  icon: <IconInfoCircle />,
                  className: 'stroke-color-error',
                },
              }}
              aria-label={t('holiday_mode')}
              checkedChildren={t('active')}
              unCheckedChildren={t('passive')}
            />
            <AkinonRadio
              formItemProps={{
                name: FormKey.STOCK_RELEASE_TYPE,
                label: t('stock_release_type'),
                control,
              }}
              aria-label={t('stock_release_type')}
              options={[
                {
                  label: t('manual'),
                  value: true,
                },
                {
                  label: t('automatic'),
                  value: false,
                },
              ]}
              disabled={!isSuperUser}
            />
            {isSuperUser && (
              <AkinonRadio
                formItemProps={{
                  name: FormKey.AUTO_CANCEL,
                  label: t('auto_cancel'),
                  control,
                }}
                aria-label={t('auto_cancel')}
                options={[
                  {
                    label: t('manual'),
                    value: true,
                  },
                  {
                    label: t('automatic'),
                    value: false,
                  },
                ]}
              />
            )}
            <AkinonForm className="reconciliation_box">
              <div className="period_box">
                <AkinonSelect
                  aria-label={t('reconciliation_period')}
                  formItemProps={{
                    name: FormKey.CONF.RECONCILIATION_CONF.CREATION_STRATEGY.STRATEGY_TYPE,
                    label: t('reconciliation_period'),
                    control,
                    required: true,
                    className: 'period_box__strategy-type',
                  }}
                  options={[
                    {
                      id: '1',
                      label: t('daily'),
                      value: 'daily',
                    },
                    {
                      id: '2',
                      label: t('weekly'),
                      value: 'weekly',
                    },
                    {
                      id: '3',
                      label: t('monthly'),
                      value: 'monthly',
                    },
                  ]}
                  disabled={!isSuperUser}
                  placeholder={t('reconciliation_period')}
                />
                <If
                  condition={!isNil(strategyType)}
                  then={
                    <AkinonSelect
                      aria-label={t('set_day')}
                      formItemProps={{
                        name: isStrategyWeekly
                          ? FormKey.CONF.RECONCILIATION_CONF.CREATION_STRATEGY.DAY_OF_WEEK
                          : FormKey.CONF.RECONCILIATION_CONF.CREATION_STRATEGY.DAY_OF_MONTH,
                        control,
                        required: !isStrategyDaily,
                        className: 'period_box__strategy-date',
                      }}
                      options={getPeriodProperties({ t })?.[strategyType]?.items}
                      placeholder={t('set_day')}
                      disabled={!isSuperUser || isStrategyDaily}
                    />
                  }
                  otherwise={<div className="period_box__strategy-date" />}
                />
              </div>
              <div className="sales_rule_box">
                <AkinonSelect
                  aria-label={t('reconciliation_sales_rule')}
                  disabled={!isSuperUser}
                  formItemProps={{
                    name: FormKey.CONF.RECONCILIATION_CONF.ITEM_SELECTION_STRATEGY.STRATEGY_TYPE,
                    label: t('reconciliation_sales_rule'),
                    control,
                    required: true,
                    className: 'sales_rule_box__strategy-type',
                  }}
                  options={[
                    {
                      id: '1',
                      label: t('reconciliation_sale_rule_after_sale'),
                      value: 'shipped_date',
                    },
                    {
                      id: '2',
                      label: t('reconciliation_sale_rule_after_delivery'),
                      value: 'delivered_date',
                    },
                  ]}
                  placeholder={t('reconciliation_sales_rule')}
                />

                <AkinonInput
                  aria-label={`${t('day')}s`}
                  disabled={!isSuperUser}
                  formItemProps={{
                    name: FormKey.CONF.RECONCILIATION_CONF.ITEM_SELECTION_STRATEGY.DAYS_LATER,
                    control,
                    required: true,
                    className: 'sales_rule_box__strategy-date',
                  }}
                  placeholder={`+ ${t('day')}`}
                />
              </div>
              <AkinonInput
                aria-label={t('time_zone')}
                type="number"
                role="textbox"
                disabled={!isSuperUser}
                formItemProps={{
                  name: FormKey.CONF.RECONCILIATION_CONF.CREATION_STRATEGY.TIME_ZONE,
                  label: t('time_zone'),
                  control,
                  className: 'reconciliation_conf',
                }}
              />
              <AkinonInput
                aria-label={t('commission')}
                type="number"
                role="textbox"
                formItemProps={{
                  name: FormKey.COMMISSION,
                  label: t('commission'),
                  control,
                  className: 'reconciliation_conf',
                }}
                disabled={!isSuperUser}
              />
              <AkinonInput
                aria-label={t('maturity')}
                type="number"
                role="textbox"
                formItemProps={{
                  name: FormKey.MATURITY,
                  label: t('maturity'),
                  control,
                  className: 'reconciliation_conf',
                }}
                disabled={!isSuperUser}
              />
            </AkinonForm>

            {dynamicUserSettings?.map((setting) => {
              return (
                <>
                  <AkinonInput
                    key={[FormKey.EXTRAS, setting?.trim?.()].join('.')}
                    formItemProps={{
                      name: [FormKey.EXTRAS, setting?.trim?.()].join('.'),
                      label: setting,
                      control,
                    }}
                    placeholder={t('enter_variable', { variable: setting })}
                  />
                </>
              );
            })}
            <AkinonButton
              htmlType="submit"
              type="primary"
              onClick={handleSubmit(handleInformationSubmit)}
            >
              {t('submit').toUpperCase()}
            </AkinonButton>
          </AkinonForm>
        </AkinonSpin>
      </AkinonBox>
    </section>
  );
};

export default GeneralInfoForm;
