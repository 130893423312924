import { useStaticFilterFields } from './useStaticFilterFields';

const useFilterFields = () => {
  const staticFilters = useStaticFilterFields();

  return {
    dynamic: { fields: [], isLoading: false },
    static: staticFilters,
  };
};

export default useFilterFields;
