import './ProductRevisionModal.style.scss';

import AkinonBox from '@components/AkinonBox';
import { openNotification } from '@components/akinonNotification';
import AkinonSpin from '@components/AkinonSpin';
import { revisionStatusMapping } from '@pages/ProductsAndCategories/ProductRevisionsLanding/common';
// import { limitQuery } from '@constants/commontypes';
import { useProductImagesQuery } from '@services/api/hooks';
import { usePostCreateProductRevisionImageMutation } from '@services/api/hooks/usePostCreateProductImageRevisionMutation';
import { usePostCreateProductRevisionMutation } from '@services/api/hooks/usePostCreateProductRevisionMutation';
import { usePostSubmitProductRevisionMutation } from '@services/api/hooks/usePostSubmitProductRevisionMutation';
import { useProductRevisionsQuery } from '@services/api/hooks/useProductRevisionsQuery';
import { Modal } from 'antd';
import { isEmpty, isUndefined, omit, omitBy, range } from 'lodash';
import first from 'lodash/first';
import React from 'react';
import { useTranslation } from 'react-i18next';

import MultipleProductsRevisionsForm from './MultipleProductsRevisionsForm';
import ProductRevisionForm from './ProductRevisionForm';

const ProductRevisionModal = ({
  isLoading,
  isVisible,
  onCancel,
  productAttributes,
  attributeConfigs,
  productMeta,
  productSummariesDataSource,
}) => {
  const { t } = useTranslation('ProductsAndCategories');
  const hasProductVariants = productMeta?.length > 1;
  const firstProductMeta = first(productMeta);

  const { productImages } = useProductImagesQuery({
    params: {
      limit: productSummariesDataSource?.length,
      parent__in: productSummariesDataSource?.map((product) => product.id).filter(Boolean),
    },
    queryOptions: {
      enabled: !isEmpty(productSummariesDataSource),
    },
  });
  const { submitProductRevision } = usePostSubmitProductRevisionMutation();

  const { asyncCreateProductImageRevision } = usePostCreateProductRevisionImageMutation({
    mutationOptions: {
      onError: (error) => {
        openNotification({
          message: t('transaction_failed'),
          description: error.response?.data?.detail ?? error.message,
          type: 'error',
        });
      },
    },
  });
  const { productRevisions, isFetchingProductRevisions, refetchProductRevisions } =
    useProductRevisionsQuery({
      queryOptions: {
        enabled: !isEmpty(productMeta),
      },
      params: {
        limit: 999,
        product__in: productMeta?.map((meta) => meta?.id).join(','),
        status__in: revisionStatusMapping.revision_submitted.join(','),
      },
    });

  const { createProductRevision, isCreatingProductRevision } = usePostCreateProductRevisionMutation(
    {
      mutationOptions: {
        onSuccess: () => {
          onCancel();
          openNotification({
            message: t('transaction_success'),
            description: t('product_revision_success'),
            type: 'success',
          });
        },
      },
    }
  );

  const productRevision = first(productRevisions);

  const onSubmit = (values, { productId }) => {
    const hasEditedValues = !isEmpty(omitBy(values, isUndefined));
    if (!hasEditedValues) return;

    const targetImages = values.images ?? [];

    const unchangedProductImages = targetImages.filter((image) =>
      productImages.some((productImage) => productImage?.image === image?.url)
    );

    const modifiedProductImages = productImages
      .filter(
        (productImage) =>
          !unchangedProductImages.some(
            (unchangedProductImage) => unchangedProductImage?.image === productImage?.image
          )
      )
      .filter((img) => !img?.width);

    const imagesToUpdate = targetImages.filter(
      (image) =>
        !unchangedProductImages.some(
          (unchangedProductImage) => unchangedProductImage?.image === image?.url
        )
    );

    const getImageUpdateBodies = (productRevisionId) =>
      range(Math.max(imagesToUpdate.length, modifiedProductImages.length)).map((updateIndex) => {
        const oldImage = modifiedProductImages[updateIndex];
        const newImage = imagesToUpdate[updateIndex];
        return {
          parent: productRevisionId,
          target: oldImage?.id,
          image: newImage?.originFileObj,
        };
      });

    const requestBody = {
      product: productId ?? firstProductMeta.id,
      attributes: omit(values, ['name', 'images']),
    };
    if (values?.name) {
      requestBody.name = values.name;
    }

    createProductRevision(
      {
        productId: productId ?? firstProductMeta.id,
        requestBody,
      },
      {
        onSuccess(data) {
          const imageUpdateBodies = getImageUpdateBodies(data?.id);
          const imageCreateBodies = imageUpdateBodies.filter((body) => Boolean(!body.target));
          const imageDeleteBodies = imageUpdateBodies.filter((body) => Boolean(body.target));

          const imageDeletePromises = imageDeleteBodies.map((body) => {
            const formData = new FormData();
            formData.append('target', body.target);
            formData.append('parent', body.parent);
            return asyncCreateProductImageRevision({ formData }).then(() => {
              formData.delete('target');
              formData.append('image', body.image);
              asyncCreateProductImageRevision({ formData });
            });
          });

          const imageCreatePromises = imageCreateBodies.map((body) => {
            const formData = new FormData();

            Object.entries(body).forEach(([key, value]) => {
              if (value) {
                formData.append(key, value);
              }
            });
            return asyncCreateProductImageRevision({ formData });
          });
          const allImageRequestPromises = [...imageDeletePromises, ...imageCreatePromises];

          Promise.all(allImageRequestPromises).finally(() => {
            submitProductRevision(
              { productRevisionId: data?.id },
              {
                onSuccess: () => {
                  refetchProductRevisions();
                },
              }
            );
          });
        },
      }
    );
  };

  return (
    <Modal
      width={hasProductVariants ? 1200 : 800}
      open={isVisible}
      onCancel={onCancel}
      title={t('product_revision')}
      footer={null}
      className="product-revision-modal"
    >
      <AkinonSpin spinning={isFetchingProductRevisions || isLoading}>
        <AkinonBox className="box-primary form-box">
          {hasProductVariants ? (
            <MultipleProductsRevisionsForm
              isLoading={isCreatingProductRevision}
              productMeta={productMeta}
              attributeConfigs={attributeConfigs}
              productAttributes={productAttributes}
              productRevisions={productRevisions}
              productImages={productImages}
              onSubmit={onSubmit}
            />
          ) : (
            <ProductRevisionForm
              isLoading={isCreatingProductRevision}
              productAttributes={productAttributes}
              attributeConfigs={attributeConfigs}
              productRevision={productRevision}
              productImages={productImages}
              onSubmit={onSubmit}
            />
          )}
        </AkinonBox>
      </AkinonSpin>
    </Modal>
  );
};

export default ProductRevisionModal;
