import { useSellerShipmentCompanyOptionsQuery } from '@services/api/hooks/useSellerShipmentCompanyOptionsQuery';
import { useShipmentCompanyOptionsQuery } from '@services/api/hooks/useShipmentCompanyOptionsQuery';
import { useShippingCompaniesQuery } from '@services/api/hooks/useShippingCompaniesQuery';
import { useShippingOptionsQuery } from '@services/api/hooks/useShippingOptionsQuery';
import find from 'lodash/find';
import map from 'lodash/map';

export const useShippingOptionList = ({ isSuperUser }) => {
  const { shipmentCompanyOptions, isShipmentCompanyOptionsLoading, refetchShipmentCompanyOptions } =
    useShipmentCompanyOptionsQuery({
      queryOptions: {
        enabled: false,
      },
    });
  const {
    sellerShipmentCompanyOptionsConfig,
    isSellerShipmentCompanyOptionsLoading,
    refetchSellerShipmentCompanyOptions,
  } = useSellerShipmentCompanyOptionsQuery({
    queryOptions: {
      enabled: false,
    },
  });

  const { shippingCompanies, isShippingCompaniesLoading, refetchShippingCompanies } =
    useShippingCompaniesQuery({
      queryOptions: {
        enabled: false,
      },
    });

  const { shippingOptions, isShippingOptionsLoading, refetchShippingOptions } =
    useShippingOptionsQuery({
      queryOptions: {
        enabled: false,
      },
    });

  const shippingOptionList = map(
    sellerShipmentCompanyOptionsConfig,
    (sellerShipmentCompanyOptionConfig) => {
      const shippingCompanyOption = find(shipmentCompanyOptions, {
        id: sellerShipmentCompanyOptionConfig.shipping_company_option,
      });

      const shippingOption = find(shippingOptions, {
        id: shippingCompanyOption?.shipping_option,
      });

      const shippingCompany = find(shippingCompanies, {
        id: shippingCompanyOption?.shipping_company,
      });

      const isActive = isSuperUser
        ? shippingCompanyOption?.is_active
        : sellerShipmentCompanyOptionConfig?.is_active && shippingCompanyOption?.is_active;

      return {
        id: shippingCompanyOption?.id,
        delivery_option: shippingOption?.name,
        name: shippingCompany?.name,
        label: `${shippingCompany?.name} / ${shippingOption?.name}`,
        last_updated_date: shippingCompanyOption?.updated_at,
        shipping_option: shippingCompanyOption?.shipping_option,
        shipping_company: shippingCompanyOption?.shipping_company,
        is_active: isActive,
      };
    }
  );

  const activeShippingOptionList = shippingOptionList.filter((option) => option.is_active);

  const isShippingOptionListLoading =
    (isSuperUser
      ? isShipmentCompanyOptionsLoading
      : isShipmentCompanyOptionsLoading || isSellerShipmentCompanyOptionsLoading) ||
    isShippingCompaniesLoading ||
    isShippingOptionsLoading;

  const refetchShippingOptionList = () => {
    refetchShippingCompanies();
    refetchShippingOptions();
    refetchShipmentCompanyOptions();
    refetchSellerShipmentCompanyOptions();
  };

  return {
    activeShippingOptionList,
    isShippingOptionListLoading,
    refetchShippingOptionList,
  };
};
