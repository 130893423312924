import Sales from './components/Sales';

const StepKey = {
  SALES: 'sales',
  CANCELLATION: 'cancelation',
  RETURN: 'return',
};

export const getStepList = ({ t }) => [
  {
    title: t('sale_reports.stepper_sales_title'),
    icon: <i className="icon-rapor-satis" />,
    key: StepKey.SALES,
    Component: Sales,
  },
  {
    title: t('sale_reports.stepper_cancellation_title'),
    icon: <i className="icon-rapor-iptal" />,
    key: StepKey.CANCELLATION,
    Component: () => <div></div>,
  },
  {
    title: t('sale_reports.stepper_return_title'),
    icon: <i className="icon-rapor-iade" />,
    key: StepKey.RETURN,
    Component: () => <div></div>,
  },
];
