import AkinonButton from '@components/AkinonButton';
import AkinonForm from '@components/AkinonForm';
import AkinonInput from '@components/AkinonInput';
import { Form, Modal } from 'antd';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormKey, getFormResolver } from './common';

const RevisionActionRejectModal = ({ modalProps }) => {
  const { t } = useTranslation('ProductsAndCategories');
  const resolver = getFormResolver();
  const { control, handleSubmit, reset } = useForm({
    mode: 'onChange',
    resolver,
  });

  const handleClose = () => {
    modalProps?.onCancel();
    reset();
  };

  const onSubmit = (formValues) => {
    modalProps?.onOk(formValues);
    handleClose();
  };

  return (
    <Modal className="box-primary form-box" {...modalProps} onCancel={handleClose} footer={null}>
      <AkinonForm layout="vertical">
        <AkinonInput
          formItemProps={{
            name: FormKey.REJECTION_REASON,
            label: t('rejection_reason'),
            control,
            required: true,
          }}
          placeholder={t('rejection_reason')}
        />
        <Form.Item wrapperCol={{ span: 24 }}>
          <AkinonButton
            htmlType="submit"
            type="primary"
            onClick={handleSubmit(onSubmit)}
            aria-label="Submit"
          >
            {t('submit')}
          </AkinonButton>
        </Form.Item>
      </AkinonForm>
    </Modal>
  );
};

export default RevisionActionRejectModal;
