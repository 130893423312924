import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';

import { FormFieldKey } from '../common';

export const getZodResolver = ({ t }) => {
  return zodResolver(
    z.object({
      [FormFieldKey.QUICK_MESSAGE]: z.string().min(1),
      [FormFieldKey.MESSAGE_CONTENT]: z.string().superRefine((value, ctx) => {
        if (value?.replaceAll(/<[^>]*>?/gm, '').length > 500) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: t('editor_limit_error_message', { character: 500 }),
          });
        }
      }),
    })
  );
};
