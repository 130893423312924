import { createSelectOptions } from '@common/index';
import Link from '@components/Link';
import { WidgetType } from '@constants/commontypes';
import { RouteUrls } from '@constants/routeUrls';
import { dateFormatter } from '@utils/index';
import { Tag } from 'antd';
import { createSearchParams, generatePath } from 'react-router-dom';

const DataIndex = {
  SELLER: ['datasource', 'name'],
  CONSENTED_AT: 'consented_at',
  ID: 'id',
};

const StaticFilterKey = {
  DATASOURCE: 'datasource',
  CONSENTED_AT__ISNULL: 'consented_at__isnull',
};

export const getColumns = ({ t }) => {
  return [
    {
      title: t('seller'),
      dataIndex: DataIndex.SELLER,
      tooltip: true,
      render: (seller, { datasource }) => {
        return (
          <Link
            to={{
              pathname: generatePath(RouteUrls.finance.sellerManagement.sellerDetail, {
                id: datasource?.id ?? '',
              }),
              search: createSearchParams({ step: 1 }).toString(),
            }}
          >
            {seller}
          </Link>
        );
      },
    },

    {
      title: t('consented_at'),
      dataIndex: DataIndex.CONSENTED_AT,
      tooltip: true,
      render: dateFormatter,
    },
    {
      title: t('status'),
      dataIndex: DataIndex.ID,
      tooltip: true,
      render: (_, { consented_at }) => {
        return consented_at ? (
          <Tag color="green">{t('accepted')}</Tag>
        ) : (
          <Tag color="yellow">{t('waiting_approval')}</Tag>
        );
      },
    },
  ];
};

export const getStaticFilters = ({ t, dataSources, isDataSourcesLoading }) => ({
  fields: [
    {
      name: StaticFilterKey.DATASOURCE,
      widget: WidgetType.SELECT,
      showSearch: true,
      optionFilterProp: 'label',
      loading: isDataSourcesLoading,
      placeholder: t('seller'),
      options: createSelectOptions(dataSources, {
        labelKey: 'name',
        valueKey: 'id',
      }),
      filterSort: (optionA, optionB) =>
        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase()),
    },
    {
      name: StaticFilterKey.CONSENTED_AT__ISNULL,
      widget: WidgetType.SELECT,
      placeholder: t('consented_at_isnull'),
      options: [
        {
          label: t('yes'),
          value: true,
        },
        {
          label: t('no'),
          value: false,
        },
      ],
    },
  ],
});
