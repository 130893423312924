import { postAgreementConsentsUrl } from '@constants/apiUrls';

import { useAkinonMutation } from '../adapters/useAkinonMutation';
import { client } from '../client';

/**
 * @param {Object} [args]
 * @param {import('@tanstack/react-query').MutationOptions} [args.mutationOptions]
 */
export function usePostAgreementConsentsMutation({ mutationOptions } = {}) {
  const { mutateAsync, isLoading } = useAkinonMutation({
    mutationFn: ({ requestBody, requestConfig }) =>
      client.post(postAgreementConsentsUrl, requestBody, requestConfig),
    ...mutationOptions,
  });

  return {
    addAgreementConsent: mutateAsync,
    isAgreementConsentAdding: isLoading,
  };
}
