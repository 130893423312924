import { useDynamicFilterFields } from '@utils/hooks/useDynamicFilterFields';
import { useMemo } from 'react';

import { useStaticFilters } from './useStaticFilters';

const useFilterFields = ({ t, attributes, isAttributesLoading }) => {
  const dynamicFields = useDynamicFilterFields({ attributes, isAttributesLoading });
  const staticFields = useStaticFilters({
    t,
  });

  return useMemo(
    () => ({
      dynamic: dynamicFields,
      static: staticFields,
    }),
    [dynamicFields, staticFields]
  );
};

export default useFilterFields;
