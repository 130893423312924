import { WidgetType } from '@constants/commontypes';
import { useTranslation } from 'react-i18next';

import { StaticFilterKey } from '../common';

export const useStaticFilterFields = ({ isSuperUser }) => {
  const { t } = useTranslation('Marketing');

  const initiallyVisibleFields = [
    ...(isSuperUser
      ? [
          {
            name: StaticFilterKey.SELLER,
            placeholder: t('seller'),
          },
        ]
      : []),
    {
      name: StaticFilterKey.NAME,
      placeholder: t('collections_name'),
    },
    {
      name: StaticFilterKey.STATUS,
      widget: WidgetType.SELECT,
      placeholder: t('status'),
      options: [
        { label: t('active'), value: true },
        { label: t('passive'), value: false },
      ],
      showSearch: true,
      optionFilterProp: 'label',
    },
  ].filter(Boolean);

  const restFields = [
    {
      widget: WidgetType.DATE_PICKER,
      name: StaticFilterKey.FIRST_CREATED_DATE,
      placeholder: t('first.creation.date'),
    },
    {
      widget: WidgetType.DATE_PICKER,
      name: StaticFilterKey.LAST_CREATED_DATE,
      placeholder: t('last.creation.date'),
    },
    {
      widget: WidgetType.DATE_PICKER,
      name: StaticFilterKey.FIRST_UPDATED_DATE,
      placeholder: t('first.updated.date'),
    },
    {
      widget: WidgetType.DATE_PICKER,
      name: StaticFilterKey.LAST_UPDATED_DATE,
      placeholder: t('last.updated.date'),
    },
    {
      widget: WidgetType.DATE_PICKER,
      name: StaticFilterKey.FIRST_EXPIRATION_DATE,
      placeholder: t('first_expiration_date'),
    },
    {
      widget: WidgetType.DATE_PICKER,
      name: StaticFilterKey.LAST_EXPIRATION_DATE,
      placeholder: t('last_expiration_date'),
    },
  ].map((field) => ({ ...field, isInitiallyVisible: false }));

  return {
    fields: initiallyVisibleFields.concat(restFields),
  };
};
