import './styles.scss';

import { generateTreeData } from '@common/data';
import AkinonBox from '@components/AkinonBox';
import AkinonButton from '@components/AkinonButton';
import AkinonSpin from '@components/AkinonSpin';
import { Color } from '@constants/theme';
import { Tree } from 'antd';
import isEmpty from 'lodash/isEmpty';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DataIndex } from '../../common';

const CategoriesTree = ({ categories, checkedKeys, setCheckedKeys, isLoading }) => {
  const { t } = useTranslation('Finance');

  const [expandedKeys, setExpandedKeys] = useState([]);
  const [autoExpandParent, setAutoExpandParent] = useState(true);

  const allKeys = categories?.map((item) => item.path);
  const areAllKeysAdded = allKeys?.every((key) => checkedKeys?.includes(key));

  useEffect(() => {
    const defaultExpandedKeys = categories?.map((item) => item.path);
    setExpandedKeys(defaultExpandedKeys);
  }, [categories]);

  const treeData = generateTreeData({
    data: categories,
    columnKeys: Object.values(DataIndex),
  });

  const handleExpandAll = () => {
    setExpandedKeys(allKeys);
    setAutoExpandParent(false);
  };

  const handleCollapseAll = () => {
    setExpandedKeys([]);
    setAutoExpandParent(false);
  };

  const handleAddAll = () => {
    setCheckedKeys(allKeys);
  };

  const handleOnExpand = (expandedKeys) => {
    setExpandedKeys(expandedKeys);
    setAutoExpandParent(false);
  };

  const handleOnCheck = (checkedKeys) => {
    setCheckedKeys(checkedKeys);
  };

  return (
    <AkinonBox
      bannerColor={Color.WILD_WATERMELON}
      title={t('select_to_added_permissions')}
      className="box-primary form-box category-tree"
    >
      <AkinonSpin spinning={isLoading}>
        <div className="tree-table-actions">
          <AkinonButton
            className="title-section-btn"
            onClick={handleExpandAll}
            disabled={isEmpty(treeData)}
          >
            {t('expand_all')}
          </AkinonButton>
          <AkinonButton
            className="title-section-btn"
            onClick={handleCollapseAll}
            disabled={isEmpty(treeData)}
          >
            {t('minimize_all')}
          </AkinonButton>
          <AkinonButton
            className="title-section-btn"
            onClick={handleAddAll}
            disabled={isEmpty(treeData) || areAllKeysAdded}
          >
            {t('add_all')}
          </AkinonButton>
        </div>
        {!isLoading && (
          <Tree
            showLine={true}
            checkable={true}
            autoExpandParent={autoExpandParent}
            treeData={treeData}
            checkedKeys={checkedKeys}
            expandedKeys={expandedKeys}
            switcherIcon={({ expanded }) => {
              return expanded ? (
                <i className="icon-folder_minus table-icon" />
              ) : (
                <i className="icon-folder_plus table-icon" />
              );
            }}
            onCheck={handleOnCheck}
            onExpand={handleOnExpand}
          />
        )}
      </AkinonSpin>
    </AkinonBox>
  );
};

export default CategoriesTree;
