import { UserRole } from '@constants/auth';
import { useTranslation } from 'react-i18next';

export const StaticFilterKey = {
  ORDER_NUMBER: 'order__number',
  RECONCILIATION_NAME: 'reconciliation__name',
  RECONCILIATION_NO: 'reconciliation__no',
  PRODUCT_CODE: 'product__code',
  STATUS: 'status',
  START_DATE_GTE: 'start_date__gte',
  START_DATE_LTE: 'start_date__lte',
  END_DATE_GTE: 'end_date__gte',
  END_DATE_LTE: 'end_date__lte',
  SUPPLIER: 'supplier',
  ORDER_ITEM: 'order_item',
};

export function useStaticFilterFields({ userRole }) {
  const { t } = useTranslation('Finance');
  const isSuperUser = userRole === UserRole.SUPER_USER;

  return {
    fields: [
      {
        name: StaticFilterKey.ORDER_NUMBER,
        placeholder: t('order_number_1'),
      },
      isSuperUser && {
        name: StaticFilterKey.RECONCILIATION_NAME,
        placeholder: t('seller.name'),
      },
      {
        name: StaticFilterKey.RECONCILIATION_NO,
        placeholder: t('reconciliation_number'),
      },
    ].filter(Boolean),
  };
}
