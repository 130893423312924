import { WidgetType } from '@constants/commontypes';

import { StaticFilterKey } from '../common';

const useFilterFields = ({ t }) => {
  return {
    static: {
      fields: [
        {
          name: StaticFilterKey.SKU,
          widget: WidgetType.INPUT,
          placeholder: t('sku'),
        },
        {
          name: StaticFilterKey.BASE_CODE,
          widget: WidgetType.INPUT,
          placeholder: t('base.code'),
        },
        {
          name: StaticFilterKey.PRODUCT_NAME,
          widget: WidgetType.INPUT,
          placeholder: t('product_name'),
        },
      ],
    },
    dynamic: { fields: [], isLoading: false },
  };
};

export default useFilterFields;
