import AkinonSteps, { AkinonStep } from '@components/AkinonSteps';
import { TranslationKey } from '@root/i18n';
import { useAgreementDetailQuery } from '@services/api/hooks/useAgreementDetailQuery';
import { Divider } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { getStepItems } from './common';
import useBreadcrumbs from './hooks/useBreadcrumb';
import usePageMeta from './hooks/usePageMeta';

function AgreementDetail() {
  const { t } = useTranslation(TranslationKey.FINANCE);
  const { agreementId } = useParams();
  const [currentStep, setCurrentStep] = useState(0);
  const { agreementDetail } = useAgreementDetailQuery({
    agreementId,
    queryOptions: { enabled: Boolean(agreementId) },
  });

  usePageMeta();
  useBreadcrumbs();

  const stepItems = getStepItems({ t });
  const stepHandleOnChange = (step) => {
    setCurrentStep(step);
  };
  return (
    <section>
      {agreementDetail && (
        <>
          <AkinonSteps current={currentStep} onChange={stepHandleOnChange}>
            {stepItems.map(({ key, title, icon, disabled }) => (
              <AkinonStep key={key} title={title} icon={icon} disabled={disabled} />
            ))}
          </AkinonSteps>
          <Divider />
        </>
      )}

      {stepItems.find((_, index) => index === currentStep)?.content}
    </section>
  );
}

export default AgreementDetail;
