import { useCitiesQuery } from '@services/api/hooks/useCitiesQuery';
import { useCountriesQuery } from '@services/api/hooks/useCountriesQuery';
import { useDistrictsQuery } from '@services/api/hooks/useDistrictsQuery';
import { useTownshipsQuery } from '@services/api/hooks/useTownshipsQuery';

export function useDatasource({ country, city, township }) {
  const { countries, isCountriesFetching } = useCountriesQuery({
    params: {
      is_active: true,
    },
  });
  const { cities, isCitiesFetching } = useCitiesQuery({
    params: {
      country,
      is_active: true,
    },
    queryOptions: {
      enabled: Boolean(country),
    },
  });
  const { townships, isTownshipsFetching } = useTownshipsQuery({
    params: {
      city,
      is_active: true,
    },
    queryOptions: {
      enabled: Boolean(city),
    },
  });
  const { districts, isDistrictsFetching } = useDistrictsQuery({
    params: {
      city,
      township,
      is_active: true,
    },
    queryOptions: {
      enabled: Boolean(city) && Boolean(township),
    },
  });
  return {
    countries,
    cities,
    districts,
    townships,
    isLoading:
      isCountriesFetching || isCitiesFetching || isDistrictsFetching || isTownshipsFetching,
  };
}
