import './styles.scss';

import { QueryKey } from '@constants/query';
import { useCategoriesQuery } from '@services/api/hooks/useCategoriesQuery';
import { useCategoryNodePermissionsQuery } from '@services/api/hooks/useCategoryNodePermissionsQuery';
import { useDeleteCategoryNodePermissionsMutation } from '@services/api/hooks/useDeleteCategoryNodePermissionMutation';
import { usePostCategoryNodePermissionsMutation } from '@services/api/hooks/usePostCategoryNodePermissionMutation';
import { queryClient } from '@services/api/queryClient';
import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import CategoriesTree from './components/CategoriesTree';
import CategoryPermissionsTree from './components/CategoryPermissionsTree';

const CategoryPermissions = () => {
  const [checkedKeys, setCheckedKeys] = useState([]);
  const { id: dataSourceId } = useParams();

  const { categories, isFetchingCategories } = useCategoriesQuery();
  const { categoryNodePermissions, isFetchingCategoryNodePermissions } =
    useCategoryNodePermissionsQuery();
  const { isCreatingNewCategoryNodePermission, createCategoryNodePermission } =
    usePostCategoryNodePermissionsMutation();
  const { isDeletingNewCategoryNodePermission, deleteCategoryNodePermission } =
    useDeleteCategoryNodePermissionsMutation();

  const userCategoryNodePermissions = useMemo(
    () => categoryNodePermissions?.filter((permission) => permission.datasource === dataSourceId),
    [categoryNodePermissions, dataSourceId]
  );

  useEffect(() => {
    const allPermissions =
      userCategoryNodePermissions?.map((item) => item.category_node).filter(Boolean) ?? [];

    const initialCheckedKeys = categories
      ?.filter((category) => allPermissions.includes(category.id))
      .map((item) => item.path);

    setCheckedKeys(initialCheckedKeys);
  }, [categories, userCategoryNodePermissions]);

  const handleCategoryPermissionsSubmit = async (values) => {
    const activeCategoryPaths = values?.category_permissions.active;
    const categoryNodesToActivate = categories?.reduce((acc, category) => {
      const categoryPath = activeCategoryPaths?.find((path) => path === category.path);
      if (categoryPath) {
        acc.push(category.id);
      }
      return acc;
    }, []);

    const inactiveCategoryPaths = values?.category_permissions.inactive;
    const categoryNodesToDeactivate = categories?.reduce((acc, category) => {
      const categoryPath = inactiveCategoryPaths?.find((path) => path === category.path);
      if (categoryPath) {
        acc.push(category.id);
      }
      return acc;
    }, []);

    const deactivatePromises = categoryNodesToDeactivate.map(async (categoryNodeId) => {
      const categoryNodePermissionId = userCategoryNodePermissions.find(
        (permission) => permission.category_node === categoryNodeId
      )?.id;

      if (!categoryNodePermissionId) return Promise.resolve();

      return deleteCategoryNodePermission({
        categoryNodeId: categoryNodePermissionId,
      });
    });
    await Promise.allSettled(deactivatePromises);

    const activatePromises = categoryNodesToActivate.map(async (categoryNodeId) => {
      return createCategoryNodePermission({
        requestBody: {
          datasource: dataSourceId,
          is_active: true,
          category_node: categoryNodeId,
        },
      });
    });

    await Promise.allSettled(activatePromises);

    queryClient.invalidateQueries([QueryKey.CATEGORY_NODE_PERMISSIONS]);
  };

  const isLoading =
    isFetchingCategories ||
    isFetchingCategoryNodePermissions ||
    isCreatingNewCategoryNodePermission ||
    isDeletingNewCategoryNodePermission;

  return (
    <div className="category-permissions">
      <CategoriesTree
        categories={categories}
        checkedKeys={checkedKeys}
        setCheckedKeys={setCheckedKeys}
        isLoading={isLoading}
      />
      <CategoryPermissionsTree
        userCategoryNodePermissions={userCategoryNodePermissions}
        categories={categories}
        checkedKeys={checkedKeys}
        setCheckedKeys={setCheckedKeys}
        handleSubmit={handleCategoryPermissionsSubmit}
        isLoading={isLoading}
      />
    </div>
  );
};

export default CategoryPermissions;
