import clsx from 'clsx';
import React, { forwardRef } from 'react';

export const FolderTreeItemWrapper = forwardRef((props, ref) => {
  const {
    clone,
    depth,
    disableSelection,
    disableInteraction,
    disableSorting,
    ghost,
    handleProps,
    indentationWidth,
    indicator,
    collapsed,
    onCollapse,
    onRemove,
    item,
    wrapperRef,
    style,
    isLast,
    parent,
    hideCollapseButton,
    childCount,
    manualDrag,
    showDragHandle,
    disableCollapseOnItemClick,
    className,
    contentClassName,
    isOver,
    isOverParent,
    ...rest
  } = props;

  return (
    <li
      {...rest}
      className={clsx(
        `list-none box-border flex relative`,
        clone && 'inline-block p-2 pointer-events-none',
        ghost && 'opacity-50',
        disableSelection && 'select-none',
        disableInteraction && 'pointer-events-none',
        className
      )}
      ref={wrapperRef}
      style={style}
    >
      <div
        ref={ref}
        className={clsx(
          ' border-l-2 border-l-ebony-clay',
          `ml-${depth * 4}`,
          `flex items-center relative py-2 before:content-[" "]  before:bg-ebony-clay before:w-4 before:h-0.5 before:self-center`,
          contentClassName
        )}
      >
        {props.children}
      </div>
    </li>
  );
});
