import { rejectPreOfferURL, submitPreOfferURL } from '@constants/apiUrls';
import { client } from '@services/api/client';
import filter from 'lodash/filter';

import { PreOfferStatus } from '../../common';
import { FormKey } from '../../OfferDetail/components/RejectModal/common';

const useRejectOffers = ({ refetchOffers, resetFields, limitPerBulkRequest = 1 }) => {
  const rejectSelectedItems = async ({
    selectedRows,
    [FormKey.REJECTION_REASON]: rejectionReason,
    [FormKey.REJECTION_EXPLANATION]: rejectionExplanation,
    mutationOptions,
  }) => {
    const { onSuccess, onError } = mutationOptions;
    const approvalRequiredSelectedRows = filter(selectedRows, {
      approval_status: PreOfferStatus.APPROVAL_REQUIRED,
    });

    const approvalRequiredCount = approvalRequiredSelectedRows.length;

    try {
      if (approvalRequiredCount > 0) {
        const bulkRequestSize = Math.ceil(approvalRequiredCount / limitPerBulkRequest);

        for (let i = 0; i < bulkRequestSize; i++) {
          const start = i * limitPerBulkRequest;
          const end = (i + 1) * limitPerBulkRequest;
          const bulkApprovalRequiredSelectedRows = approvalRequiredSelectedRows.slice(start, end);

          const approvePreOfferPromises = bulkApprovalRequiredSelectedRows.map((row) => {
            return client.post(submitPreOfferURL, {}, { urlParams: { id: row.id } });
          });

          await Promise.allSettled(approvePreOfferPromises);
        }
      }

      const approvalCount = selectedRows.length;

      if (approvalCount > 0) {
        let result = [];
        const bulkRequestSize = Math.ceil(approvalCount / limitPerBulkRequest);
        for (let i = 0; i < bulkRequestSize; i++) {
          const start = i * limitPerBulkRequest;
          const end = (i + 1) * limitPerBulkRequest;
          const bulkSelectedRows = selectedRows.slice(start, end);

          const rejectionPromises = bulkSelectedRows.map((row) => {
            return client.post(
              rejectPreOfferURL,
              {
                reason: rejectionReason,
                explanation: rejectionExplanation,
              },
              { urlParams: { id: row.id } }
            );
          });

          const bulkResult = await Promise.allSettled(rejectionPromises);
          const errorCount = filter(bulkResult, { status: 'rejected' }).length;
          const successCount = filter(bulkResult, { status: 'fulfilled' }).length;
          onError(errorCount);
          onSuccess(successCount);
          result = [...result, ...bulkResult];
        }
        const isAllFulfilled = result.every((item) => item.status === 'fulfilled');
        const isSomeRejected = result.some((item) => item.status === 'rejected');
        const isSomeFulfilled = result.some((item) => item.status === 'fulfilled');
        if (isAllFulfilled || (isSomeFulfilled && isSomeRejected)) {
          refetchOffers();
        }
      }
    } catch (error) {
    } finally {
      resetFields();
    }
  };

  return {
    rejectSelectedItems,
  };
};

export default useRejectOffers;
