import { QueryKey } from '@constants/query';
import { queryClient } from '@services/api/queryClient';

const useAddRemoveProductFromCollection = ({
  addProductToCollectionAsync,
  deleteProductFromCollectionAsync,
  collectionId,
}) => {
  const onAddSelectedItems = ({ selectedRows, mutationOptions }) => {
    const { onSuccess, onError } = mutationOptions;
    const promises = selectedRows.map((row) => {
      return () =>
        addProductToCollectionAsync({
          requestBody: {
            offer: row.id,
            collection: collectionId,
          },
        })
          .then(() => {
            onSuccess();
          })
          .catch(() => {
            onError();
          });
    });

    Promise.allSettled(promises.map((f) => f())).then(() => {
      queryClient.invalidateQueries([QueryKey.COLLECTION_ITEMS, { collection: collectionId }]);
      queryClient.invalidateQueries([QueryKey.PRODUCT_OFFERS]);
    });
  };
  const onDeleteSelectedItems = ({ selectedRows, mutationOptions }) => {
    const { onSuccess, onError } = mutationOptions;
    const promises = selectedRows.map((row) => {
      return () =>
        deleteProductFromCollectionAsync({
          id: row.collectionItem.id,
        })
          .then(() => {
            onSuccess();
          })
          .catch(() => {
            onError();
          });
    });

    Promise.allSettled(promises.map((f) => f())).then(() => {
      queryClient.invalidateQueries([QueryKey.PRODUCT_OFFERS]);
    });
  };
  return { onAddSelectedItems, onDeleteSelectedItems };
};

export default useAddRemoveProductFromCollection;
