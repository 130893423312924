import { patchLocationsUrl } from '@constants/apiUrls';
import { useAkinonMutation } from '@services/api/adapters/useAkinonMutation';
import { client } from '@services/api/client';

/**
 * @param {{ mutationOptions: import('@tanstack/react-query').UseMutationOptions }} args
 */
export function usePatchLocationMutation({ mutationOptions } = {}) {
  const { isLoading, mutate } = useAkinonMutation({
    mutationFn: ({ requestBody, requestConfig }) =>
      client.patch(patchLocationsUrl, requestBody, requestConfig),
    ...mutationOptions,
  });

  return {
    patchLocation: mutate,
    isPatchingLocation: isLoading,
  };
}
