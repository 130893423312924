import { getStaticFilters } from '../common';

const useFilterFields = ({ t, isSuperUser }) => {
  const staticFields = getStaticFilters({ t, isSuperUser });

  return {
    dynamic: { fields: [], isLoading: false },
    static: staticFields,
  };
};

export default useFilterFields;
