import { useMemo } from 'react';

import { useStaticFilterFields } from './useStaticFilterFields';

const useFilterFields = ({ userRole }) => {
  const staticFields = useStaticFilterFields({
    userRole,
  });

  return useMemo(
    () => ({
      dynamic: {
        fields: [],
        isLoading: false,
      },
      static: staticFields,
    }),
    [staticFields]
  );
};

export default useFilterFields;
