import { useAttributeValuesQuery } from '@services/api/hooks/useAttributeValuesQuery';
import { useMasterProductPricesQuery } from '@services/api/hooks/useMasterProductPricesQuery';
import { usePaginatedDataSourcesQuery } from '@services/api/hooks/usePaginatedDataSourcesQuery';
import { usePaginatedProductImagesQuery } from '@services/api/hooks/usePaginatedProductImagesQuery';
import { usePaginatedProductOffersQuery } from '@services/api/hooks/usePaginatedProductOffersQuery';
import { usePaginatedProductPriceListQuery } from '@services/api/hooks/usePaginatedProductPriceListQuery';
import { useProductsQuery } from '@services/api/hooks/usePaginatedProductsQuery';
import { useProductLowestPriceQueries } from '@services/api/hooks/useProductLowestPriceQueries';
import cloneDeep from 'lodash/cloneDeep';
import entries from 'lodash/entries';
import find from 'lodash/find';
import flatten from 'lodash/flatten';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import map from 'lodash/map';
import pick from 'lodash/pick';
import reduce from 'lodash/reduce';
import reject from 'lodash/reject';
import uniq from 'lodash/uniq';

export const useDataSource = ({ dynamicFormMetaFields, pagination, filters, priceListId }) => {
  const { productPrices, isFetchingProductPrices, totalProductPrices, productPricesError } =
    usePaginatedProductPriceListQuery({
      pagination,
      filters,
      params: {
        price_list: priceListId,
      },
    });

  const datasourceIds = reject(uniq(map(productPrices, 'datasource')), isNil);
  const { dataSources, isFetchingDataSources } = usePaginatedDataSourcesQuery({
    params: {
      id__in: datasourceIds.join(','),
      limit: datasourceIds?.length,
    },
    queryOptions: {
      enabled: !isFetchingProductPrices && !isEmpty(datasourceIds),
    },
  });

  const offerIds = reject(uniq(map(productPrices, 'offer')), isNil);
  const { productOffers, isFetchingProductOffers } = usePaginatedProductOffersQuery({
    params: {
      id__in: offerIds.join(','),
      limit: offerIds?.length || pagination?.pageSize,
    },
    queryOptions: {
      enabled: !isFetchingProductPrices && !isEmpty(offerIds),
    },
  });

  const productIds = reject(uniq(map(productOffers, 'product')), isNil);
  const { products, isFetchingProducts } = useProductsQuery({
    params: {
      id__in: productIds.join(','),
      limit: productIds?.length,
    },
    queryOptions: {
      enabled: !isFetchingProductOffers && !isEmpty(productIds),
    },
  });

  const { productImages, isFetchingProductImages } = usePaginatedProductImagesQuery({
    params: {
      limit: productIds?.length,
      parent__in: productIds?.join(','),
      order: 0,
    },
    queryOptions: {
      enabled: !isFetchingProductOffers && !isEmpty(productIds),
    },
  });

  const { masterProductPrices, isFetchingMasterProductPrices } = useMasterProductPricesQuery({
    params: {
      limit: productIds?.length,
      product__in: productIds?.join(','),
    },
    queryOptions: {
      enabled: !isEmpty(productIds) && !isFetchingProductPrices,
    },
  });

  const attributeIds = uniq(map(dynamicFormMetaFields, 'id'));
  const attributeKeys = uniq(map(dynamicFormMetaFields, 'key'));
  const attributeVals = uniq(
    flatten(
      map(productOffers, (offer) => {
        const product = find(products, { id: offer.product });
        const attributes = {
          ...product?.attributes,
          ...offer.attributes,
        };
        return Object.values(pick(attributes, attributeKeys));
      })
    )
  );
  const { attributeValues, isFetchingAttributeValues } = useAttributeValuesQuery({
    params: {
      attribute__in: attributeIds.join(','),
      value__in: attributeVals.join(','),
    },
    queryOptions: {
      enabled:
        !isFetchingProductOffers &&
        !isFetchingProducts &&
        !isEmpty(attributeVals) &&
        !isEmpty(attributeIds),
    },
  });

  const { productLowestPrices, isFetchingProductLowestPrices } = useProductLowestPriceQueries({
    productIds: productIds,
    queryOptions: {
      enabled: !isEmpty(productIds) && !isFetchingProductPrices,
    },
  });

  const isLoading =
    isFetchingAttributeValues ||
    isFetchingProducts ||
    isFetchingProductPrices ||
    isFetchingProductImages ||
    isFetchingMasterProductPrices ||
    isFetchingDataSources ||
    isFetchingProductLowestPrices;

  // Map related data.
  const data = productPrices?.map((item, index) => {
    const datasource = dataSources?.find(({ id }) => id === item.datasource);
    const offer = productOffers?.find(({ id }) => id === item.offer);
    const product = products?.find(({ id }) => id === offer?.product);
    const image = productImages?.find(({ parent }) => parent === product?.id);
    const masterProductPrice = masterProductPrices?.find(
      ({ product }) => product === offer?.product
    )?.price;
    const clonedProduct = cloneDeep(product ?? {});
    const clonedOffer = cloneDeep(offer ?? {});
    const lowest_price = productLowestPrices?.[index]?.price;

    const attributes = {
      ...reduce(
        entries({ ...clonedProduct?.attributes, ...clonedOffer?.attributes }),
        (acc, [key, value]) => {
          const attribute = find(dynamicFormMetaFields, { key });
          if (!attribute) return acc;
          const valueLabel = get(
            find(attributeValues, { value, attribute: attribute.id }),
            'label',
            value
          );
          return {
            ...acc,
            [key]: valueLabel,
          };
        },
        {}
      ),
    };
    return {
      ...item,
      buybox_price: masterProductPrice,
      attributes,
      lowest_price,
      datasource,
      product,
      image,
    };
  });

  return {
    data,
    isLoading,
    total: totalProductPrices,
    error: productPricesError,
  };
};
