import { useCancellationReasonOptionsQuery } from '@services/api/hooks/useCancellationReasonOptionsQuery';
import { useCancellationReasonQuery } from '@services/api/hooks/useCancellationReasonQuery';

export const useDataSource = ({ cancellationReasonId }) => {
  const { cancellationReasonOptions, isFetchingCancellationReasonOptions } =
    useCancellationReasonOptionsQuery({ cancellationReasonId });

  const { cancellationReason, isFetchingCancellationReason } = useCancellationReasonQuery({
    cancellationReasonId,
  });

  const calculationOptions =
    cancellationReasonOptions?.actions?.PUT?.penalty_config?.children?.calculation_type?.choices?.map(
      (choice) => {
        const display_name = choice.display_name;
        return {
          value: choice.value,
          label: display_name,
        };
      }
    );

  const referenceOptions =
    cancellationReasonOptions?.actions?.PUT?.penalty_config?.children?.reference?.choices?.map(
      (choice) => {
        const display_name = choice.display_name;
        return {
          value: choice.value,
          label: display_name,
        };
      }
    );

  return {
    cancellationReason,
    calculationOptions,
    referenceOptions,
    isFetchingCancellationReason,
    isFetchingCancellationReasonOptions,
  };
};
