import AkinonFormItem from '@components/AkinonFormItem';
import { Checkbox } from 'antd';
import clsx from 'clsx';
import { forwardRef } from 'react';

/**
 * @param {import('antd').CheckboxProps && { value: boolean }} props
 */
const AkinonCheckbox = forwardRef(({ formItemProps, className, value, ...otherProps }, ref) => {
  const component = (
    <Checkbox
      ref={ref}
      className={clsx('akinon-checkbox', className)}
      checked={value}
      aria-label={otherProps?.['aria-label'] || otherProps?.label}
      {...otherProps}
    />
  );
  if (formItemProps) {
    return (
      <AkinonFormItem valuePropName="checked" {...formItemProps}>
        {component}
      </AkinonFormItem>
    );
  }

  return component;
});

/**
 * @param {import('antd/lib/checkbox/Group').CheckboxGroupProps} props
 */
export const AkinonCheckboxGroup = forwardRef(
  ({ formItemProps, className, ...otherProps }, ref) => {
    const component = (
      <Checkbox.Group
        ref={ref}
        className={clsx('akinon-checkbox-group', className)}
        aria-label={otherProps?.['aria-label'] || otherProps?.label}
        {...otherProps}
      />
    );
    if (formItemProps) {
      return <AkinonFormItem {...formItemProps}>{component}</AkinonFormItem>;
    }

    return component;
  }
);

export default AkinonCheckbox;
