import AkinonButton from '@components/AkinonButton';
import { usePageFilters } from '@components/AkinonFilter/hooks/store/usePageFilters';
import AkinonTable from '@components/AkinonTable';
import usePagination from '@components/AkinonTable/hooks/usePagination';
import { getDataSourcePermissionsUrl } from '@constants/apiUrls';
import { defaultRowKey } from '@constants/index';
import { RouteUrls } from '@constants/routeUrls';
import { useUser } from '@root/contexts/hooks/useUser';
import useAppNavigate from '@root/hooks/useAppNavigate';
import { useDataSourcePermissions } from '@services/hooks/datasources/useDataSourcePermissions';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { getColumns } from '../common';
import { useBreadcrumbs } from './hooks/useBreadcrumbs';
import { usePageMeta } from './hooks/usePageMeta';

const SellerUserList = () => {
  const { t } = useTranslation('Settings');
  const navigate = useAppNavigate();
  const [pagination, setPagination] = usePagination();
  const user = useUser();
  const dataSourceId = user?.datasource;
  const filters = usePageFilters();

  const {
    data: dataSource,
    isFetching: isLoading,
    total,
  } = useDataSourcePermissions({
    pagination,
    filters,
    setPagination,
    dataSourceId: dataSourceId,
    enabled: !!dataSourceId,
  });

  usePageMeta();
  useBreadcrumbs();

  const userListColumns = getColumns({ t });

  const onRow = (user) => ({
    onClick: () => {
      navigate(RouteUrls.settings.sellerEditUser, {
        dataSourceId,
        userId: user.id,
        state: { user },
      });
    },
  });

  return (
    <AkinonTable
      rowKey={defaultRowKey}
      title={t('sidebar_users')}
      dataSource={dataSource}
      optionsUrl={getDataSourcePermissionsUrl}
      columns={userListColumns}
      loading={isLoading}
      total={total}
      pagination={pagination}
      setPagination={setPagination}
      description={`${total} ${t('results.found')}`}
      onRow={onRow}
      tableHeaderExtra={
        <div>
          <AkinonButton
            type="secondary"
            onClick={() => {
              navigate(RouteUrls.settings.sellerUserManagementSettingsForm);
            }}
          >
            {t('add_user')}
          </AkinonButton>
        </div>
      }
    />
  );
};

export default SellerUserList;
