import AkinonButton from '@components/AkinonButton';
import { getDataSourcesUrl } from '@constants/apiUrls';
import { WidgetType } from '@constants/commontypes';
import { defaultDateTimeFormat } from '@constants/index';
import { QueryKey } from '@constants/query';
import { Space, Tag } from 'antd';
import moment from 'moment';

export const StatusKey = {
  WAITING_APPROVAL: 0,
  APPROVED: 1,
  REJECTED: 2,
};

const StaticFilterKey = {
  DATASOURCE: 'datasource',
  STATUS: 'status',
};

const getStatusOptions = ({ t }) => [
  {
    label: t('waiting_for_approval'),
    value: StatusKey.WAITING_APPROVAL,
  },
  {
    label: t('approved'),
    value: StatusKey.APPROVED,
  },
  {
    label: t('rejected'),
    value: StatusKey.REJECTED,
  },
];

const getOrderStatusRenderMap = ({ t }) => {
  return {
    [StatusKey.WAITING_APPROVAL]: {
      label: t('waiting_approval'),
      tagColor: 'yellow',
    },
    [StatusKey.APPROVED]: {
      label: t('approved'),
      tagColor: 'green',
    },
    [StatusKey.REJECTED]: {
      label: t('rejected'),
      tagColor: 'red',
    },
  };
};

const DataIndex = {
  SELLER: ['datasource', 'name'],
  APPROVED_AT: 'approved_at',
  STATUS: 'status',
};
export const getColumns = ({
  t,
  onApproveDataSourceLandingPage,
  onRejectDataSourceLandingPage,
}) => {
  const statusRenderMap = getOrderStatusRenderMap({ t });
  return [
    {
      dataIndex: DataIndex.SELLER,
      visibleOnFilter: [StaticFilterKey.DATASOURCE],
      title: t('seller'),
      render: (data_source) => data_source || '-',
    },
    {
      dataIndex: DataIndex.STATUS,
      visibleOnFilter: [StaticFilterKey.STATUS],
      title: t('status'),
      render: (status) => {
        const statusRenderItem = statusRenderMap[status];
        return <Tag color={statusRenderItem?.tagColor}>{statusRenderItem?.label}</Tag>;
      },
    },
    {
      dataIndex: DataIndex.APPROVED_AT,
      title: t('approved_at'),
      render: (approved_at) =>
        approved_at ? moment(approved_at).format(defaultDateTimeFormat) : '-',
    },
    {
      title: t('actions'),
      key: 'actions',
      width: 350,
      render: (_, record) => {
        const status = record[DataIndex.STATUS];
        const isWaitingApproval = status === StatusKey.WAITING_APPROVAL;
        return isWaitingApproval ? (
          <Space>
            <AkinonButton type="primary" onClick={() => onApproveDataSourceLandingPage(record)}>
              {t('approve')}
            </AkinonButton>
            <AkinonButton type="danger" onClick={() => onRejectDataSourceLandingPage(record)}>
              {t('reject')}
            </AkinonButton>
          </Space>
        ) : null;
      },
    },
  ];
};

export const getStaticFilters = ({ t, isSuperUser }) => ({
  fields: [
    isSuperUser && {
      name: StaticFilterKey.DATASOURCE,
      widget: WidgetType.REMOTE_SELECT,
      showSearch: true,
      labelInValue: true,
      queryProps: {
        remoteKey: QueryKey.DATA_SOURCES,
        remoteUrl: getDataSourcesUrl,
        params: {
          limit: 20,
          sort: 'name',
          is_active: true,
        },
        searchKey: 'name__icontains',
      },
      placeholder: t('seller'),
    },
    {
      name: StaticFilterKey.STATUS,
      widget: WidgetType.SELECT,
      showSearch: true,
      optionFilterProp: 'label',
      placeholder: t('status'),
      options: getStatusOptions({ t }),
    },
  ].filter(Boolean),
});
