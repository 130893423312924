import './styles.scss';

import AkinonButton from '@components/AkinonButton';
import { DraggerInputWithLabel } from '@components/AkinonDragger';
import AkinonForm from '@components/AkinonForm';
import AkinonFormItem from '@components/AkinonFormItem';
import { openNotification } from '@components/akinonNotification';
import Box from '@components/utility/box';
import { useBulkImportReconciliationRulesMutation } from '@services/api/hooks/useBulkImportReconciliationRulesMutation';
import { useDownloadReconciliationRulesTemplateMutation } from '@services/api/hooks/useDownloadReconciliationRulesTemplateMutation';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormKey, getFormResolver } from './common';

const BulkUpdateCommission = () => {
  const { t } = useTranslation('Finance');
  const { mutate: downloadTemplate, isMutating: isDownloadingTemplate } =
    useDownloadReconciliationRulesTemplateMutation();

  const resolver = getFormResolver({ t });

  const { control, handleSubmit } = useForm({
    resolver,
  });

  const { isBulkImportingReconciliationRules, bulkImportReconciliationRules } =
    useBulkImportReconciliationRulesMutation();

  const handleFileUpload = (values) => {
    const formData = new FormData();

    const fileList = values[FormKey.FILE]?.fileList;

    fileList?.forEach((file) => {
      formData.append(FormKey.FILE, file.originFileObj);
    });

    bulkImportReconciliationRules({ formData });
  };

  const onDownloadTemplate = () => {
    downloadTemplate(
      { type: 'product' },
      {
        onSuccess: () => {
          openNotification({
            message: t('file.preparing'),
            description: t('file.download.modal.description'),
            type: 'success',
          });
        },
      }
    );
  };

  return (
    <Box
      className="bulk-update-commission bulk-update-commission__box box-primary form-box"
      title={t('upload_product_commission')}
    >
      <AkinonForm layout="vertical">
        <AkinonFormItem
          name={FormKey.FILE}
          label={t('upload_new_commission')}
          control={control}
          required
        >
          <DraggerInputWithLabel
            accept=".csv,.xlsx,.xls"
            maxCount={1}
            multiple={false}
            beforeUpload={() => false}
          />
        </AkinonFormItem>
        <div className="download-template">
          <span>{t('bulk.actions.download')}</span>
          <AkinonButton
            type="default"
            icon={<i className="icon icon-download" />}
            size="small"
            loading={isDownloadingTemplate}
            onClick={onDownloadTemplate}
          >
            XLSX
          </AkinonButton>
        </div>
        <AkinonButton
          loading={isBulkImportingReconciliationRules}
          type="primary"
          onClick={handleSubmit(handleFileUpload)}
        >
          {t('save')}
        </AkinonButton>
      </AkinonForm>
    </Box>
  );
};

export default BulkUpdateCommission;
