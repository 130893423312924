import { WidgetType } from '@constants/commontypes';
import find from 'lodash/find';
import moment from 'moment';

export const getInitialValues = ({ pageFilters, fields, searchQuery }) => {
  const initialValues = {};

  Object.entries(pageFilters).forEach(([pageFilterKey, pageFilterValue]) => {
    const widget = find(fields, { key: pageFilterKey })?.widget;
    if (widget === WidgetType.DATE_PICKER) {
      initialValues[pageFilterKey] = pageFilterValue
        ? moment.isMoment(pageFilterValue)
          ? pageFilterValue
          : moment(pageFilterValue)
        : undefined;
    } else {
      initialValues[pageFilterKey] = pageFilterValue;
    }
  });

  if (searchQuery) {
    initialValues.q = searchQuery;
  }

  return initialValues;
};

export const getFilterFields = ({ filterFields, form }) => {
  const staticFields = filterFields.static.fields.map((field) => ({
    formItemProps: {
      control: form.control,
      name: field.name,
    },
    isInitiallyVisible: field?.isInitiallyVisible ?? true,
    ...field,
  }));
  const dynamicFields = filterFields.dynamic.fields.map((field) => ({
    formItemProps: {
      control: form.control,
      name: field.name,
    },
    ...field,
  }));

  return [...staticFields, ...dynamicFields];
};
