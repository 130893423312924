export const getPeriodProperties = ({ t }) => ({
  weekly: {
    items: [
      { label: t('monday') },
      { label: t('tuesday') },
      { label: t('wednesday') },
      { label: t('thursday') },
      { label: t('friday') },
      { label: t('saturday') },
      { label: t('sunday') },
    ].map((item, value) => ({ ...item, label: t(item.label), value })),
    placeholder: t('set_day'),
  },
  monthly: {
    items: Array(31)
      .fill()
      .map((_, value) => ({ label: String(value + 1), value: value + 1 })),
    placeholder: t('set_day'),
  },
});
