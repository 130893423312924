import { getRequestFiltersParams, getRequestPaginationParams } from '@common/index';
import { getOrderItems } from '@services/api';
import { useQuery } from '@tanstack/react-query';

export const orderItemQueryKey = ['order-item'];

/**
 * @typedef {import('@tanstack/react-query').UseQueryResult} UseQueryResult
 * @returns {import('@tanstack/react-query').UseQueryResult}
 */

export const useOrderItemQuery = ({ pagination, filters, status, orderId, enabled = true }) => {
  const params = {
    ...getRequestPaginationParams(pagination),
    ...getRequestFiltersParams(filters),
    status__in: status,
    order: orderId,
  };
  const queryFn = () =>
    getOrderItems({
      params,
    });

  const { data, isFetching, isLoading, error, ...rest } = useQuery({
    queryKey: [...orderItemQueryKey, params],
    queryFn,
    enabled,
  });

  return {
    ...rest,
    data: data?.results,
    isLoading,
    isFetching,
    error,
    total: data?.count,
  };
};
