import './styles.scss';

import { InfoCircleOutlined } from '@ant-design/icons';
import AkinonDividerBox from '@components/AkinonDividerBox';
import AkinonFlex from '@components/AkinonFlex';
import { AkinonInputNumber } from '@components/AkinonInput';
import CollapseExpandIcon from '@pages/SellerStoreManagement/components/CollapseExpandIcon';
import { Collapse, Tooltip } from 'antd';
import clsx from 'clsx';
import React from 'react';
import { useFieldArray } from 'react-hook-form';

import { MetricControls } from './MetricControls';
import { RangeInput } from './RangeInput';

export const MetricBox = ({ t, title, tooltip, metricKey, control, watch, setValue }) => {
  const { fields, insert, remove, update } = useFieldArray({
    control,
    name: `seller_performance_metrics.${metricKey}.grade_list`,
  });

  const handlePlusClick = (event, rangeIndex) => {
    event.stopPropagation();

    const currentValues = watch(`seller_performance_metrics.${metricKey}.grade_list`);
    const previousMax = currentValues?.[rangeIndex]?.max || 0;
    const nextItem = currentValues?.[rangeIndex + 1];
    const nextMin = nextItem ? nextItem.min : 100;

    insert(rangeIndex + 1, {
      min: previousMax,
      max: nextMin,
      score: 0,
    });

    if (nextItem) {
      update(rangeIndex + 2, { ...nextItem, min: previousMax });
    }

    update(rangeIndex, { ...currentValues[rangeIndex], max: previousMax });
  };

  const handleMinusClick = (event, rangeIndex) => {
    event.stopPropagation();

    const currentValues = watch(`seller_performance_metrics.${metricKey}.grade_list`);
    if (rangeIndex > 0) {
      const previousItem = currentValues[rangeIndex - 1];
      const removedItem = currentValues[rangeIndex];
      const nextItem = currentValues[rangeIndex + 1];

      if (rangeIndex > 0) {
        update(rangeIndex - 1, { ...previousItem, max: removedItem.max });
      }

      if (nextItem) {
        update(rangeIndex + 1, { ...nextItem, min: removedItem.max });
      }
    }

    remove(rangeIndex);
  };

  return (
    <AkinonDividerBox
      header={
        <AkinonFlex justifyContent="center" alignItems="center" className="gap-x-4 max-w-[350px]">
          <span>{t(title)}</span>
          <Tooltip title={t(tooltip)}>
            <InfoCircleOutlined />
          </Tooltip>
        </AkinonFlex>
      }
    >
      <AkinonInputNumber
        formItemProps={{
          name: `seller_performance_metrics.${metricKey}.weight`,
          control,
          label: t('weight'),
          required: true,
          className: clsx(t(tooltip).length > 100 && 'mt-12'),
        }}
        formatter={(value) => (value ? `% ${value}` : '')}
        parser={(value) => value?.replace('%', '')}
        placeholder={t('weight')}
      />

      <span className="text-white font-bold mb-2 block">{t('range')}</span>
      {fields.map((range, rangeIndex) => (
        <Collapse
          key={range.id}
          className="metric-box mb-5"
          expandIcon={({ isActive }) => <CollapseExpandIcon isExpanded={isActive} />}
          bordered={false}
        >
          <Collapse.Panel
            header={
              <MetricControls
                t={t}
                handlePlusClick={handlePlusClick}
                handleMinusClick={handleMinusClick}
                rangeIndex={rangeIndex}
                fields={fields}
              />
            }
          >
            <RangeInput
              t={t}
              metricKey={metricKey}
              rangeIndex={rangeIndex}
              control={control}
              setValue={setValue}
            />
          </Collapse.Panel>
        </Collapse>
      ))}
    </AkinonDividerBox>
  );
};
