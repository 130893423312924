import map from 'lodash/map';
import * as z from 'zod';

export const FormKey = {
  EMAIL: 'email',
  IS_ACTIVE: 'is_active',
  DATASOURCE_PERMISSION_LEVEL: 'datasource_permission_level',
  LAST_LOGIN: 'last_login',
};

export const getFormSchema = ({ permissionLevelChoises }) => {
  return z.object({
    email: z.string().email().min(1),
    is_active: z.coerce.boolean(),
    [FormKey.DATASOURCE_PERMISSION_LEVEL]: z
      .union(map(permissionLevelChoises, (choice) => z.literal(choice.value)))
      .optional(),
  });
};
