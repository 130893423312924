import { createSelectOptions } from '@common/index';
import { sendRequest } from '@common/network';
import { getAttributeInputOptionsUrl } from '@constants/apiUrls';
import { WidgetType } from '@constants/commontypes';
import partition from 'lodash/partition';

export const ApiConfigType = {
  TEXT: 'text',
  AREA: 'area',
  DROPDOWN: 'dropdown',
  BOOL: 'bool',
};

export const ConfigTypeMetaWidgetMap = {
  [ApiConfigType.TEXT]: WidgetType.INPUT,
  [ApiConfigType.AREA]: WidgetType.TEXTAREA,
  [ApiConfigType.DROPDOWN]: WidgetType.SELECT,
  [ApiConfigType.BOOL]: WidgetType.SWITCH,
};

export const getFormDynamicMetaFields = ({ t, attributeConfigs, productAttributes }) => {
  const forbiddenKeys = ['SELLER_BARCODE', 'SELLER_BASECODE', 'SELLER_DESCRIPTION'];
  const dynamicFormMeta = attributeConfigs
    .map((config) => {
      const matchedAttribute = productAttributes.find(
        (attribute) => attribute.id == config.attribute
      );

      if (!matchedAttribute) return null;
      const { id: attributeId, key, name, data_type = '' } = matchedAttribute;

      const isRequired = config.is_required || config.is_variant || config.is_offer;

      return (
        !forbiddenKeys.includes(key) && {
          key,
          label: name,
          initialValue: config.default_value,
          widget: ConfigTypeMetaWidgetMap[data_type],
          required: isRequired,
          is_variant: config.is_variant,
          is_offer: config.is_offer,
          widgetProps: {
            url: getAttributeInputOptionsUrl(attributeId),
            labelKey: 'label',
            valueKey: 'value',
            placeholder: t('attribute.set.desc'),
          },
        }
      );
    })
    .filter(Boolean);
  const [variantsFormDynamicMetaFields, variantsListAttributeMetaFields] = partition(
    dynamicFormMeta,
    (dynamicFormMetaItem) => dynamicFormMetaItem.is_variant
  );

  const sortByRequired = (a, b) => {
    if (a.required !== b.required) {
      return a.required ? -1 : 1;
    }
    return 0;
  };

  return {
    variantsListAttributeMetaFields: variantsListAttributeMetaFields
      .map((dynamicFormMetaItem) => ({
        ...dynamicFormMetaItem,
        key: `${dynamicFormMetaItem.key}`,
      }))
      .sort(sortByRequired),
    variantsFormDynamicMetaFields: variantsFormDynamicMetaFields.map((dynamicFormMetaItem) => ({
      ...dynamicFormMetaItem,
      key: `${dynamicFormMetaItem.key}`,
      widgetProps: {
        ...dynamicFormMetaItem.widgetProps,
        mode: 'multiple',
      },
    })),
  };
};

export const injectOptionsIntoMetaFields = ({ metaFields, t }) => {
  return metaFields.map(async (metaField) => {
    if (metaField.widget === WidgetType.SELECT) {
      return sendRequest({
        url: metaField.widgetProps.url,
      }).then(({ results: data }) => {
        return {
          ...metaField,
          options: createSelectOptions(data, metaField.widgetProps),
        };
      });
    } else if (metaField.widget === WidgetType.SWITCH) {
      return {
        ...metaField,
        widgetProps: {
          ...metaField.widgetProps,
          checkedChildren: t('yes'),
          unCheckedChildren: t('no'),
        },
      };
    }
    return metaField;
  });
};
