import { TranslationKey } from '@root/i18n';
import useStore from '@zustand-store/index';
import { setPageMetaSelector } from '@zustand-store/selectors/app';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useUnmount } from 'react-use';

function usePageMeta() {
  const { t } = useTranslation(TranslationKey.FINANCE);
  const setPageMeta = useStore(setPageMetaSelector);
  const { agreementId } = useParams();

  useEffect(() => {
    setPageMeta({
      title: t(!agreementId ? 'new_agreement' : 'agreement_detail'),
      description: t(!agreementId ? 'new_agreement_description' : 'agreement_detail_description'),
    });
  }, [t]);

  useUnmount(() => {
    setPageMeta({});
  });
}

export default usePageMeta;
