import { useCallback } from 'react';
import { useParams } from 'react-router-dom';

export const useOnSubmit = ({
  handleSubmit,
  formValues,
  productStock,
  productPrice,
  patchProductStockAsync,
  patchProductPriceAsync,
  patchProductOffersAsync,
  t,
}) => {
  const { offerId } = useParams();

  const onSubmit = useCallback(() => {
    const { stock, price, retail_price, tax_rate, attributes } = formValues;

    const promisePatchProductStock = patchProductStockAsync({
      stockId: productStock.id,
      requestBody: {
        stock: Number.parseInt(stock),
      },
    });

    const promisePatchProductOffer = patchProductOffersAsync({
      offerId,
      requestBody: {
        attributes,
      },
    });

    const promisePatchProductPrice = patchProductPriceAsync({
      priceId: productPrice.id,
      requestBody: {
        price,
        retail_price,
        tax_rate,
      },
      requestConfig: {
        successMessage: t('transaction_success'),
        successDescription: t('approved_offers_detail_message.success'),
        errorMessage: t('transaction_failed'),
        errorDescription: t('approved_offers_detail_message.error'),
      },
    });

    return Promise.all([
      promisePatchProductStock,
      promisePatchProductPrice,
      promisePatchProductOffer,
    ]);
  }, [formValues, productStock, productPrice, patchProductStockAsync, patchProductPriceAsync]);

  return handleSubmit(onSubmit);
};
