import AkinonButton from '@components/AkinonButton';
import Modal from '@components/Modal/Modal';
import { IconTrash } from '@tabler/icons-react';

export const DataIndex = {
  TITLE: 'title',
  CONTENT: 'content',
  ID: 'id',
};

export const getColumns = ({ t, deleteQuickReply }) => [
  {
    dataIndex: DataIndex.TITLE,
    title: t('title'),
  },
  {
    dataIndex: DataIndex.CONTENT,
    title: t('content'),
  },
  {
    dataIndex: null,
    title: t('actions'),
    className: 'flex justify-center',
    render: (_, { id, title }) => (
      <AkinonButton
        icon={<IconTrash size={16} stroke={1} className="self-center justify-self-center" />}
        type="ghost"
        className="border-columbia-blue flex w-full px-2"
        onClick={(event) => {
          event.stopPropagation();
          Modal.confirm({
            title: t('delete_quick_reply'),
            content: `${title} - ${t('delete_quick_reply_confirmation')}`,
            onOk: () => {
              deleteQuickReply({ id });
            },
          });
        }}
      />
    ),
  },
];
