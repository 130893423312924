import { useDynamicFilterFields } from '@utils/hooks/useDynamicFilterFields';
import { useMemo } from 'react';

import useStaticFilterFields from './useStaticFields';

const useFilterFields = ({
  t,
  attributes,
  categoryTreeData,
  isCategoriesLoading,
  isAttributesLoading,
  isSuperUser,
}) => {
  const dynamicFields = useDynamicFilterFields({ attributes, isAttributesLoading });
  const staticFields = useStaticFilterFields({
    t,
    categoryTreeData,
    isCategoriesLoading,
    isSuperUser,
  });

  return useMemo(
    () => ({
      dynamic: dynamicFields,
      static: staticFields,
    }),
    [dynamicFields, staticFields]
  );
};

export default useFilterFields;
