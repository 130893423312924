import './style.scss';

import AkinonButton from '@components/AkinonButton';
import AkinonInput from '@components/AkinonInput';
import { zodResolver } from '@hookform/resolvers/zod';
import { Checkbox, Divider, Form, Modal, Space, Typography } from 'antd';
import map from 'lodash/map';
import pick from 'lodash/pick';
import { useCallback, useEffect, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  getBulkStockUpdateModalFormSchema,
  ProductsToBeAddedTableFormFormItemKey,
} from '../../common';

const { Text } = Typography;

/**
 * @param {{ form: import('react-hook-form').UseFormReturn, }} props
 */
const BulkStockUpdateModal = ({
  form,
  setInvisible,
  isVisible,
  dataSource,
  variantDynamicFields,
  variants,
  selectedRowKeys,
}) => {
  const { t } = useTranslation('ProductsAndCategories');
  const [selectedVariants, setSelectedVariants] = useState([]);
  const { reset, control, trigger } = useForm({
    mode: 'onChange',
    resolver: zodResolver(getBulkStockUpdateModalFormSchema({ t })),
  });

  const dataSourcesToUpdate = dataSource.filter((item) => selectedRowKeys?.includes(item.id));

  const bulkStockValues = useWatch({
    control: control,
    defaultValue: {
      ...pick(form.getValues(), [ProductsToBeAddedTableFormFormItemKey.STOCK]),
    },
  });

  const { bulk, ...restFormValues } = bulkStockValues;

  const handleCheckboxChange = (event) => {
    setSelectedVariants((prevSelectedVariant) => {
      if (event.target.checked) {
        return [...prevSelectedVariant, event.target.value];
      }
      return prevSelectedVariant.filter((variantId) => variantId !== event.target.value);
    });
  };

  const onModalClose = useCallback(() => {
    setSelectedVariants([]);
    setInvisible();
    reset({
      bulk: {},
    });
  }, [setInvisible, setSelectedVariants, reset]);

  const handleApply = useCallback(
    (variantsToApply) => {
      if (variantsToApply.length === 0) return;
      trigger().then((isValid) => {
        if (!isValid) return;
        reset({
          bulk: {},
          ...restFormValues,
          ...Object.entries(bulk)
            .filter(([_, value]) => !!value)
            .reduce((acc, [key, value]) => {
              acc[key] = dataSource.map((item) =>
                variantsToApply.includes(item.id) ? value : restFormValues[key]?.[item.id]
              );
              return acc;
            }, {}),
        });
      });
    },
    [reset, bulk, dataSource, restFormValues]
  );

  const handleBulkStockUpdate = useCallback(() => {
    trigger().then((isValid) => {
      if (!isValid) return;
      form.reset({
        ...form.getValues(),
        ...restFormValues,
      });
      onModalClose();
    });
  }, [restFormValues, form]);

  const handleCleanAllContent = useCallback(() => {
    reset({
      bulk: {},
    });
  }, [reset]);

  const toggleAllVariantsCheckbox = (event) => {
    if (event.target.checked) {
      setSelectedVariants(map(dataSourcesToUpdate, 'id'));
    } else {
      setSelectedVariants([]);
    }
  };

  useEffect(() => {
    if (isVisible)
      reset({
        ...pick(form.getValues(), [ProductsToBeAddedTableFormFormItemKey.STOCK]),
      });
  }, [isVisible]);

  return (
    <Modal
      className="form-modal max-w-4xl min-w-min"
      onCancel={onModalClose}
      open={isVisible}
      destroyOnClose
      footer={null}
    >
      <Space direction="vertical" size="large" style={{ width: '100%' }}>
        <Form className="akn-form" layout="vertical">
          <div className="form-modal__header">
            <div className="form-modal__header-banner" />
            <div className="form-modal__header-title">{t('bulk.update.stock')}</div>
          </div>
          <Divider className="form-modal__divider" />
          <div className="form-modal__description">{t('bulk.update.stock.description')}</div>
          <div className="form-modal__root-form-builder">
            <AkinonInput
              formItemProps={{
                label: <Text className="text-white">{t('total.product.stocks')}</Text>,
                control: control,
                name: `bulk.${ProductsToBeAddedTableFormFormItemKey.STOCK}`,
                isWithColumn: true,
                colSpan: 8,
              }}
              placeholder={t('total.product.stocks')}
            />
          </div>
          <Space direction="horizontal" className="form-modal__form-actions">
            <AkinonButton
              onClick={() => {
                handleApply(map(dataSourcesToUpdate, 'id'));
              }}
              htmlType="button"
              type="primary"
            >
              {t('apply_to_all_variants').toUpperCase()}
            </AkinonButton>
            <AkinonButton
              onClick={() => {
                handleApply(selectedVariants);
              }}
              disabled={selectedVariants.length === 0}
              htmlType="button"
              type="primary"
            >
              {t('apply_to_selected_variants').toUpperCase()}
            </AkinonButton>
          </Space>
          <Divider orientation="left" />
          <Checkbox
            checked={selectedVariants.length === dataSourcesToUpdate.length}
            className="flex items-center text-white"
            onChange={toggleAllVariantsCheckbox}
          >
            {t('select_all_variants')}
          </Checkbox>

          {dataSourcesToUpdate.map((item, index) => (
            <div className="form-modal-item" key={index}>
              <div className="flex items-center gap-6 w-full">
                <Checkbox
                  value={item.id}
                  checked={selectedVariants.includes(item.id)}
                  onChange={handleCheckboxChange}
                />
                <Text className={'form-modal-item__variant'}>
                  {Object.keys(variants)
                    .map((variantKey) => {
                      const variantValue = item[variantKey];
                      const variantLabel = variantDynamicFields
                        .find((field) => field.key === variantKey)
                        .options.find((option) => option.value === variantValue)?.label;
                      return variantLabel ?? variantValue;
                    })
                    .join('/')}
                </Text>

                <AkinonInput
                  formItemProps={{
                    label: <Text className="text-white">{t('total.product.stocks')}</Text>,
                    control: control,
                    name: `${ProductsToBeAddedTableFormFormItemKey.STOCK}.${item.id}`,
                    isWithColumn: true,
                    colSpan: 8,
                    colProps: {
                      className: 'ml-auto',
                    },
                  }}
                  placeholder={t('total.product.stocks')}
                />
              </div>
              <Divider className="form-modal-item__divider" />
            </div>
          ))}
          <Space>
            <AkinonButton type="primary" htmlType="button" onClick={handleBulkStockUpdate}>
              {t('save').toUpperCase()}
            </AkinonButton>
            <AkinonButton onClick={handleCleanAllContent} type="danger" htmlType="button">
              {t('clean.all.content').toUpperCase()}
            </AkinonButton>
          </Space>
        </Form>
      </Space>
    </Modal>
  );
};

export default BulkStockUpdateModal;
