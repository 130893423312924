import { postSellerPerformanceMetricsUrl } from '@constants/apiUrls';

import { useAkinonMutation } from '../adapters/useAkinonMutation';
import { client } from '../client';

export const usePostSellerPerformanceMetricsMutation = ({ mutationOptions } = {}) => {
  const { mutate, isLoading } = useAkinonMutation({
    mutationFn: ({ requestBody, requestConfig }) =>
      client.post(postSellerPerformanceMetricsUrl, requestBody, requestConfig),
    ...mutationOptions,
  });

  return {
    postSellerPerformanceMetrics: mutate,
    isPostingSellerPerformanceMetrics: isLoading,
  };
};
