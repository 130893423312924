import { createConversationPrivateStatusUrl } from '@constants/apiUrls';
import { useAkinonMutation } from '@services/api/adapters/useAkinonMutation';
import { client } from '@services/api/client';

export function useCreateConversationStatusMutation({ mutationOptions } = {}) {
  const { mutate, mutateAsync, isLoading } = useAkinonMutation({
    mutationFn: ({ conversationId, requestBody, requestConfig }) =>
      client.post(createConversationPrivateStatusUrl, requestBody, {
        urlParams: { conversationId },
        ...requestConfig,
      }),
    ...mutationOptions,
  });

  return {
    createConversationStatusAsync: mutateAsync,
    createConversationStatus: mutate,
    isCreatingConversationStatusLoading: isLoading,
  };
}
