import get from 'lodash/get';
import * as z from 'zod';

export const getImageFormSchema = ({ t, maxImageDimensions, minImageDimensions }) => {
  const refineImage = async (productImage, ctx) => {
    const validationResult = await new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(productImage.originFileObj);
      reader.onload = (e) => {
        const image = new Image();
        image.src = e.target?.result;
        const { width, height } = image;

        const maxWidth = get(maxImageDimensions, 'WIDTH');
        const maxHeight = get(maxImageDimensions, 'HEIGHT');
        const minWidth = get(minImageDimensions, 'WIDTH');
        const minHeight = get(minImageDimensions, 'HEIGHT');

        if (width < minWidth || height < minHeight) {
          resolve({
            message: {
              uid: productImage.uid,
              detail: t('image_upload_failed_message', {
                imagefileName: productImage.name,
                condition: t('min'),
                width: minWidth,
                height: minHeight,
              }),
            },
            code: z.ZodIssueCode.custom,
          });
        } else if (width > maxWidth || height > maxHeight) {
          resolve({
            message: {
              uid: productImage.uid,
              detail: t('image_upload_failed_message', {
                imagefileName: productImage.name,
                condition: t('max'),
                width: maxWidth,
                height: maxHeight,
              }),
            },
            code: z.ZodIssueCode.custom,
          });
        } else {
          resolve(true);
        }
      };
    });

    if (validationResult !== true) {
      ctx.addIssue(validationResult);
    }
  };
  return z.object({
    bulk: z.object({
      product_image: z
        .array(
          z
            .object({
              originFileObj: z.instanceof(File),
              name: z.string(),
              uid: z.string(),
            })
            .superRefine(refineImage)
        )
        .optional(),
    }),
    single: z.object({
      product_image: z
        .array(
          z
            .array(
              z
                .object({
                  originFileObj: z.instanceof(File),
                  name: z.string(),
                  uid: z.string(),
                })
                .superRefine(refineImage)
            )
            .optional()
        )
        .refine(
          (productImages) => {
            return productImages.filter((image) => image?.length > 0).length > 0;
          },
          {
            message: t('min_image_error_message'),
            path: ['minImageError'],
          }
        ),
    }),
  });
};
