import { DatasourcePermissionLevel } from '@constants/commontypes';
import { queryClient } from '@services/api/queryClient';

export function useOnSubmit({
  createdDataSource,
  setCreatedDataSource,
  createSeller,
  createDataSource,
  setIsNewSellerFormVisible,
  queryKey,
  t,
}) {
  const onSubmit = (formValues) => {
    const { name, supplier_code, supplier_segment, is_active, stock_release_type, tax_number } =
      formValues;
    const { email, first_name, last_name } = formValues;

    if (createdDataSource) {
      createSeller(
        {
          requestBody: {
            email,
            first_name,
            last_name,
            datasource: createdDataSource.id,
            datasource_permission_level: DatasourcePermissionLevel.ADMIN,
          },
          requestConfig: {
            successMessage: t('transaction_success'),
            successDescription: t('seller_management_form_message.success'),
            errorMessage: t('transaction_failed'),
            errorDescription: t('seller_management_form_message.error'),
          },
        },
        {
          onSuccess() {
            setIsNewSellerFormVisible(false);
            queryClient.invalidateQueries(queryKey);
          },
        }
      );
    } else {
      createDataSource(
        {
          requestBody: {
            name,
            supplier_code,
            supplier_segment,
            is_active,
            stock_release_type: Number(stock_release_type),
            conf: formValues?.conf,
            supplier_detail: {
              tax_number,
            },
          },
          requestConfig: {
            errorMessage: t('transaction_failed'),
            errorDescription: t('seller_management_form_message.error'),
          },
        },
        {
          onSuccess: (dataSource) => {
            setCreatedDataSource(dataSource);
            createSeller(
              {
                requestBody: {
                  email,
                  first_name,
                  last_name,
                  datasource: dataSource.id,
                  datasource_permission_level: DatasourcePermissionLevel.ADMIN,
                },
                requestConfig: {
                  successMessage: t('transaction_success'),
                  successDescription: t('seller_management_form_message.success'),
                  errorMessage: t('transaction_failed'),
                  errorDescription: t('seller_management_form_message.error'),
                },
              },
              {
                onSuccess() {
                  setIsNewSellerFormVisible(false);
                  queryClient.invalidateQueries(queryKey);
                },
              }
            );
          },
        }
      );
    }
  };

  return onSubmit;
}
