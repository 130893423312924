import './index.scss';

import AkinonButton from '@components/AkinonButton';
import AkinonFlex from '@components/AkinonFlex';
import AkinonForm from '@components/AkinonForm';
import AkinonInput from '@components/AkinonInput';
import { IconX } from '@tabler/icons-react';
import { Typography } from 'antd';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const FormKey = {
  SKU: 'sku',
};

const ApproveAndChangeSKUModalContent = ({ initialSKU, onApprove, onClose }) => {
  const { t } = useTranslation('ProductsAndCategories');
  const { reset, handleSubmit, control } = useForm();

  useEffect(() => {
    reset({ [FormKey.SKU]: initialSKU });
    return () => {
      reset({});
    };
  }, [initialSKU]);

  const onSubmit = (formValues) => {
    const skuChanged = initialSKU !== formValues[FormKey.SKU];
    if (skuChanged) {
      onApprove(formValues);
    } else {
      onApprove();
    }
    onClose();
  };

  return (
    <div className="change-sku-modal">
      <div className="pb-4 change-sku-modal__header">
        <Typography.Title level={4} className="change-sku-modal__title">
          {t('Approve the Offer')}
        </Typography.Title>
      </div>

      <AkinonForm className="akn-form akn-form-v2 pb-5" layout="vertical">
        <div className="change-sku-modal__warning">{t('sku_change_warning')}</div>
        <AkinonInput
          className="max-w-64"
          formItemProps={{ label: t('sku'), control, name: FormKey.SKU }}
          placeholder={t('sku')}
        />
      </AkinonForm>
      <AkinonFlex className="gap-4 change-sku-modal__footer">
        <AkinonButton onClick={handleSubmit(onSubmit)}>{t('change_sku_and_approve')}</AkinonButton>
        <AkinonButton
          onClick={() => {
            onApprove();
            onClose();
          }}
        >
          {t('approve_without_changing_sku')}
        </AkinonButton>
      </AkinonFlex>
      <IconX
        className="top-3 right-3 absolute cursor-pointer text-slate-white close-icon"
        onClick={() => {
          onApprove();
          onClose();
        }}
      />
    </div>
  );
};

export default ApproveAndChangeSKUModalContent;
