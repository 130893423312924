import omit from 'lodash/omit';
import pick from 'lodash/pick';
import sortBy from 'lodash/sortBy';

/**
 * Creates a tree data for the antd tree component.
 * A path string consists of a sequence of atomic paths which are 4 characters long.
 * Each data item is picked by its props by columnKeys.
 * @param {{ data: (Record<string, any> | { path: string })[], columnKeys: string[] }} args
 */
export const generateTreeData = ({ data, columnKeys, keepMeta = false }) => {
  const treeDataSource = [];
  const dataLength = data?.length ?? 0;
  const sortedData = sortBy(data, ['depth', 'path']);

  for (let i = 0; i < dataLength; ++i) {
    const { path: itemPath, depth, name, ...rest } = sortedData[i];
    const pathList = itemPath.match(/\w{4}/g);
    const parentPathList = pathList.slice(0, -1);
    const isRootPath = pathList[0] === pathList.join('');

    if (isRootPath) {
      treeDataSource.push({
        ...pick(rest, columnKeys),
        ...(keepMeta && { extra: omit(rest, columnKeys) }),
        path: itemPath,
        key: itemPath,
        title: name,
        children: [],
      });
    } else {
      let currentChildren =
        treeDataSource.find(({ path }) => parentPathList[0] === path)?.children ?? [];

      for (let j = 1; j < depth - 1; ++j) {
        currentChildren =
          currentChildren.find(({ path }) => parentPathList.slice(0, j + 1).join('') === path)
            ?.children ?? [];
      }

      currentChildren.push({
        ...pick(rest, columnKeys),
        ...(keepMeta && { extra: omit(rest, columnKeys) }),
        path: itemPath,
        key: itemPath,
        children: [],
        title: name,
      });
    }
  }

  return treeDataSource;
};

/**
 * Creates a tree data source for nested tables.
 * A path string consists of a sequence of atomic paths which are 4 characters long.
 * Each data item is picked by its props by columnKeys.
 * @param {{ data: (Record<string, any> | { path: string })[], columnKeys: string[] }} args
 */
export const generateTreeDataSource = ({ data, columnKeys }) => {
  const treeDataSource = [];
  const dataLength = data?.length ?? 0;
  const sortedData = sortBy(data, ['depth', 'path']);

  for (let i = 0; i < dataLength; ++i) {
    const { path: itemPath, depth, numchild, attribute_set, ...rest } = sortedData[i];
    const pathList = itemPath.match(/\w{4}/g);
    const parentPathList = pathList.slice(0, -1);
    const isRootPath = pathList[0] === pathList.join('');
    const isLeaf = numchild === 0;

    if (isRootPath) {
      treeDataSource.push({
        ...pick(rest, columnKeys),
        path: itemPath,
        key: rest.id,
        attribute_set,
        isLeaf,
        children: [],
      });
    } else {
      let currentChildren = treeDataSource.find(({ path }) => parentPathList[0] === path)?.children;

      for (let j = 1; j < depth - 1; ++j) {
        currentChildren = currentChildren?.find(
          ({ path }) => parentPathList.slice(0, j + 1).join('') === path
        )?.children;
      }

      currentChildren?.push({
        ...pick(rest, columnKeys),
        path: itemPath,
        key: rest.id,
        attribute_set,
        isLeaf,
        children: [],
      });
    }
  }

  return treeDataSource;
};
