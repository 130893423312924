import './styles.scss';

import AkinonDragger from '@components/AkinonDragger';
import { openDebouncedNotification } from '@components/akinonNotification';
import AkinonSpin from '@components/AkinonSpin';
import { DndContext, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { SortableContext, sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import { usePreOfferImagesQuery } from '@services/api/hooks/usePaginatedPreOfferImagesQuery';
import { usePatchPreOfferImageMutation } from '@services/api/hooks/usePatchPreOfferImageMutation';
import { usePostPreOfferImagesMutation } from '@services/api/hooks/usePostPreOfferImagesMutation';
import get from 'lodash/get';
import isUndefined from 'lodash/isUndefined';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import SortableImageItem from './components/SortableImageItem';

const ProductImageUpload = ({ maxImageDimensions, minImageDimensions, ...props }) => {
  const { offerId } = useParams();
  const { t } = useTranslation('ProductsAndCategories');

  const { postPreOfferImages, isPostingPreOfferImages } = usePostPreOfferImagesMutation();
  const { patchPreOfferImage, isPatchingPreOfferImage } = usePatchPreOfferImageMutation();
  const { preOfferImages } = usePreOfferImagesQuery({
    params: {
      parent__in: offerId,
      sort: 'order',
    },
    queryOptions: { enabled: Boolean(offerId) },
  });

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );
  const dummyRequest = ({ onSuccess }) => {
    setTimeout(() => {
      onSuccess('ok');
    });
  };
  const beforeUpload = async (file) => {
    const validate = new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (e) => {
        var image = new Image();
        image.src = e.target?.result;
        image.onload = () => {
          const { width, height } = image;
          const maxWidth = get(maxImageDimensions, 'WIDTH');
          const maxHeight = get(maxImageDimensions, 'HEIGHT');
          const minWidth = get(minImageDimensions, 'WIDTH');
          const minHeight = get(minImageDimensions, 'HEIGHT');

          if (width < minWidth || height < minHeight) {
            openDebouncedNotification({
              message: t('image_upload_failed'),
              description: t('image_upload_failed_message', {
                imagefileName: file.name,
                condition: t('min'),
                width: minWidth,
                height: minHeight,
              }),
              type: 'error',
              t,
            });
            resolve(false);
          } else if (width > maxWidth || height > maxHeight) {
            openDebouncedNotification({
              message: t('image_upload_failed'),
              description: t('image_upload_failed_message', {
                imagefileName: file.name,
                condition: t('max'),
                width: maxWidth,
                height: maxHeight,
              }),
              type: 'error',
              t,
            });
            resolve(false);
          } else {
            resolve(true);
          }
        };
      };
    });

    return await validate;
  };
  const onChange = ({ fileList: newFileList, file: newFile }) => {
    props.onChange?.({ fileList: newFileList, file: newFile });
    const formData = new FormData();
    formData.append('parent', offerId);
    formData.append('image', newFile.originFileObj);
    postPreOfferImages({
      requestBodyList: [formData],
    });
  };
  function handleDragEnd(event) {
    const { active, over } = event;

    if (active.id !== over.id) {
      patchPreOfferImage({
        imageId: active.id,
        requestBody: { order: over.data.current.sortable.index },
      });
    }
  }

  return (
    <div direction="vertical" className="offer-product-image-upload">
      <AkinonDragger
        {...props}
        fileList={[]}
        itemRender={() => null}
        beforeUpload={beforeUpload}
        customRequest={dummyRequest}
        onChange={onChange}
      />
      <AkinonSpin
        spinning={isPostingPreOfferImages || isPatchingPreOfferImage || isUndefined(preOfferImages)}
      >
        <DndContext onDragEnd={handleDragEnd} sensors={sensors}>
          <SortableContext items={preOfferImages ?? []}>
            <div className="offer-product-image-upload__image-list">
              {preOfferImages?.map((image) => (
                <SortableImageItem key={image.id} image={image} />
              ))}
            </div>
          </SortableContext>
        </DndContext>
      </AkinonSpin>
    </div>
  );
};

export default ProductImageUpload;
