import './styles.scss';

import AkinonFormItem from '@components/AkinonFormItem';
import { Input, InputNumber } from 'antd';
import clsx from 'clsx';
import React, { forwardRef } from 'react';

/**
 * @param {import('antd').InputProps} props
 */
const AkinonInput = forwardRef(
  ({ className, formItemProps, isLightTheme = false, ...otherProps }, ref) => {
    const component = (
      <Input
        ref={ref}
        className={clsx({ 'akinon-input': !isLightTheme }, className)}
        {...otherProps}
        aria-label={otherProps?.['aria-label'] || otherProps?.label || formItemProps?.label}
      />
    );

    if (formItemProps) {
      return <AkinonFormItem {...formItemProps}>{component}</AkinonFormItem>;
    }

    return component;
  }
);

export const AkinonInputNumber = forwardRef(
  ({ className, formItemProps, isLightTheme = false, ...otherProps }, ref) => {
    const component = (
      <InputNumber
        ref={ref}
        className={clsx({ 'akinon-input-number': !isLightTheme }, className)}
        {...otherProps}
        aria-label={otherProps?.['aria-label'] || otherProps?.label}
      />
    );

    if (formItemProps) {
      return <AkinonFormItem {...formItemProps}>{component}</AkinonFormItem>;
    }

    return component;
  }
);

export const AkinonPasswordInput = ({
  className,
  formItemProps,
  isLightTheme = false,
  ...otherProps
}) => {
  const component = (
    <Input.Password
      className={clsx({ 'akinon-password-input': !isLightTheme }, className)}
      {...otherProps}
      aria-label={otherProps?.['aria-label'] || otherProps?.label}
    />
  );

  if (formItemProps) {
    return <AkinonFormItem {...formItemProps}>{component}</AkinonFormItem>;
  }

  return component;
};

/**
 * @type {import('react').FC<import('antd/lib/input').TextAreaProps & { ref: import('react').ForwardedRef}>}
 */
export const AkinonTextArea = forwardRef(
  ({ className, formItemProps, isLightTheme = false, ...otherProps }, ref) => {
    const component = (
      <Input.TextArea
        className={clsx({ 'akinon-textarea': !isLightTheme }, className)}
        {...otherProps}
        ref={ref}
      />
    );

    if (formItemProps) {
      return <AkinonFormItem {...formItemProps}>{component}</AkinonFormItem>;
    }

    return component;
  }
);

export const AkinonSearch = ({ className, formItemProps, isLightTheme = false, ...otherProps }) => {
  const component = (
    <Input.Search className={clsx({ 'akinon-search': !isLightTheme }, className)} {...otherProps} />
  );

  if (formItemProps) {
    return <AkinonFormItem {...formItemProps}>{component}</AkinonFormItem>;
  }

  return component;
};
export default AkinonInput;
