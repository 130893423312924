import { patchPackageUrl } from '@constants/apiUrls';

import { useAkinonMutation } from '../adapters/useAkinonMutation';
import { client } from '../client';

export function usePatchPackageMutation() {
  const { mutate, isLoading } = useAkinonMutation({
    mutationFn: ({ requestBody, requestConfig, packageId }) =>
      client.patch(patchPackageUrl, requestBody, { ...requestConfig, urlParams: { packageId } }),
  });

  return {
    updatePackagePartially: mutate,
    isUpdatingPackagePartially: isLoading,
  };
}
