import AkinonButton from '@components/AkinonButton';
import AkinonForm from '@components/AkinonForm';
import AkinonFormItem from '@components/AkinonFormItem';
import AkinonInput from '@components/AkinonInput';
import DynamicFormElement from '@components/DynamicFormElement';
import { zodResolver } from '@hookform/resolvers/zod';
import ProductImageUpload from '@pages/ProductsAndCategories/ProductForm/components/ProductsToBeAddedTableForm/components/ProductImageUpload';
import { useDynamicFormFields } from '@pages/ProductsAndCategories/ProductForm/hooks/useDynamicFormFields';
import useImageDimensionsDataSourceQuery from '@services/api/hooks/useImageDimensionsDataSourceQuery';
import { Space } from 'antd';
import omit from 'lodash/omit';
import React from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormKey, getFormSchema } from './common';

const ProductRevisionForm = ({
  isLoading,
  productRevision,
  productAttributes,
  attributeConfigs,
  productImages,
  onSubmit,
}) => {
  const alreadyHasRevision = Boolean(productRevision);
  const { t } = useTranslation('ProductsAndCategories');
  const { variantListAttributeFields, variantDynamicFields } = useDynamicFormFields({
    attributeConfigs,
    productAttributes,
    forbiddenKeys: [],
    t,
  });
  const { dimensionBounds } = useImageDimensionsDataSourceQuery();

  const allAttributeFields = [...variantListAttributeFields, ...variantDynamicFields];

  const schema = getFormSchema({ t, productAttributes, dimensionBounds });

  const { reset, control, handleSubmit } = useForm({
    resolver: zodResolver(schema),
    defaultValues: {},
  });
  const formValues = useWatch({
    control: control,
    defaultValue: {},
  });

  const formItems = [
    <AkinonFormItem
      key={FormKey.IMAGES}
      name={FormKey.IMAGES}
      id={FormKey.IMAGES}
      control={control}
    >
      <ProductImageUpload
        fileList={
          formValues?.[FormKey.IMAGES] ??
          productImages?.map((productImage) => {
            return {
              url: productImage?.image ?? productImage?.url,
              uuid: productImage.id,
              ...productImage,
            };
          }) ??
          []
        }
        label={t('images')}
        accept="image/*"
        disabled={alreadyHasRevision}
        style={{ marginBottom: 15 }}
        multiple
      />
    </AkinonFormItem>,
    <AkinonFormItem
      control={control}
      name={FormKey.NAME}
      key={FormKey.NAME}
      id={FormKey.NAME}
      disabled={alreadyHasRevision}
      label={t('product_name')}
      required={false}
    >
      <AkinonInput placeholder={t('product_name')} />
    </AkinonFormItem>,
    allAttributeFields?.length > 0 &&
      allAttributeFields?.map?.((field) => {
        const { key, options } = field ?? {};
        const props = {
          ...omit(field, 'mode'),
          name: key,
          disabled: alreadyHasRevision,
          required: false,
        };
        if (options) props.options = options;

        return (
          <AkinonFormItem key={key ?? field.key} control={control} {...props}>
            <DynamicFormElement
              widget={field.data_type}
              options={props?.options}
              placeholder={props?.placeholder}
            />
          </AkinonFormItem>
        );
      }),
  ];

  const onCleanAll = () => {
    reset({});
  };

  return (
    <div className="product-revision-form-wrapper">
      {alreadyHasRevision && (
        <Space className="existing-revision-warning">{t('already_has_a_revision')}</Space>
      )}
      <div className="product-revision-form-wrapper__inner">
        <AkinonForm className="product-revision-form-wrapper__fields" direction="horizontal">
          {formItems}
          <Space style={{ marginTop: '1rem' }}>
            <AkinonButton
              type="primary"
              htmlType="submit"
              loading={isLoading}
              disabled={alreadyHasRevision}
              onClick={handleSubmit(onSubmit)}
            >
              {t('send_for_approval').toUpperCase()}
            </AkinonButton>
            <AkinonButton
              onClick={onCleanAll}
              type="danger"
              htmlType="button"
              disabled={alreadyHasRevision}
            >
              {t('clean.all.content').toUpperCase()}
            </AkinonButton>
          </Space>
        </AkinonForm>
      </div>
    </div>
  );
};

export default ProductRevisionForm;
