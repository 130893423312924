import { dateWith12HoursFormat } from '@common/date';
import AkinonSpin from '@components/AkinonSpin';
import Modal from '@components/Modal/Modal';
import { Tag, Typography } from 'antd';
import moment from 'moment';
import { Link } from 'react-router-dom';

export const ExportTableColumnDataIndex = {
  ID: 'id',
  STATUS: 'status',
  CREATED_AT: 'created_at',
  FILE: 'file',
};

export const ExportStatus = {
  PENDING: 1,
  PROCESSING: 2,
  SUCCEEDED: 3,
  FAILED: 4,
};

export const getExportStatusMap = ({ t }) => ({
  [ExportStatus.PENDING]: t('pending'),
  [ExportStatus.PROCESSING]: t('processing'),
  [ExportStatus.SUCCEEDED]: t('succeeded'),
  [ExportStatus.FAILED]: t('failed'),
});

export const getTagColor = (status) => {
  switch (status) {
    case ExportStatus.PENDING:
      return 'blue';
    case ExportStatus.PROCESSING:
      return 'purple';
    case ExportStatus.SUCCEEDED:
      return 'green';
    case ExportStatus.FAILED:
      return 'red';
    default:
      return 'default';
  }
};

export const getExportTableColumns = ({ t, setDcoumentUrl, isFetching, documentUrl }) => {
  const exportStatusMap = getExportStatusMap({ t });

  return [
    {
      dataIndex: ExportTableColumnDataIndex.ID,
      title: t('batch_request_id'),
    },
    {
      dataIndex: ExportTableColumnDataIndex.STATUS,
      title: t('file_status'),
      render(status) {
        return <Tag color={getTagColor(status)}>{exportStatusMap[status]}</Tag>;
      },
    },
    {
      dataIndex: ExportTableColumnDataIndex.CREATED_AT,
      title: t('created_at'),
      render(createdAt) {
        return moment(createdAt).format(dateWith12HoursFormat);
      },
    },
    {
      dataIndex: ExportTableColumnDataIndex.FILE,
      title: t('error'),
      render(_, rowData) {
        const status = rowData?.status;
        if (status === ExportStatus.FAILED) {
          return (
            <Typography.Paragraph
              ellipsis={{
                suffix: (
                  <Typography.Text
                    type="danger"
                    onClick={() => {
                      Modal.confirm({
                        content: rowData?.error,
                        closable: true,
                        okButtonProps: { hidden: true },
                        cancelButtonProps: { hidden: true },
                        maskClosable: true,
                      });
                    }}
                  >
                    {t('more')}
                  </Typography.Text>
                ),
              }}
            >
              {rowData?.error}
            </Typography.Paragraph>
          );
        }
        return (
          <AkinonSpin spinning={documentUrl === `exports/${rowData.id}/download` && isFetching}>
            <Link
              onClick={async (event) => {
                event.preventDefault();
                setDcoumentUrl(`exports/${rowData.id}/download`);
              }}
            >
              {t('download')}
            </Link>
          </AkinonSpin>
        );
      },
    },
  ];
};

export const StaticFilterKey = {
  ID: 'id',
  STATUS: 'status',
};
