import filter from 'lodash/filter';

const useBulkRestrictions = ({
  restrictProduct,
  deleteProductRestriction,
  datasourceId,
  refetchAllProducts,
  limitPerBulkRequest = 1,
}) => {
  async function onRestrictProducts({ selectedRows, mutationOptions }) {
    const { onSuccess, onError } = mutationOptions;
    const restrictionCount = selectedRows.length;

    if (restrictionCount > 0) {
      let result = [];
      const bulkRequestSize = Math.ceil(restrictionCount / limitPerBulkRequest);
      for (let i = 0; i < bulkRequestSize; i++) {
        const start = i * limitPerBulkRequest;
        const end = (i + 1) * limitPerBulkRequest;
        const bulkSelectedRows = selectedRows.slice(start, end);

        const promises = bulkSelectedRows.map((record) => {
          const productKey = record.id;
          return restrictProduct({ product: productKey, datasource: datasourceId });
        });

        const bulkResult = await Promise.allSettled(promises);
        const errorCount = filter(bulkResult, { status: 'rejected' }).length;
        const successCount = filter(bulkResult, { status: 'fulfilled' }).length;
        onError(errorCount);
        onSuccess(successCount);
        result = [...result, ...bulkResult];
      }

      const isAllFulfilled = result.every((item) => item.status === 'fulfilled');
      const isSomeRejected = result.some((item) => item.status === 'rejected');
      const isSomeFulfilled = result.some((item) => item.status === 'fulfilled');
      if (isAllFulfilled || (isSomeFulfilled && isSomeRejected)) {
        refetchAllProducts();
      }
    }
  }

  async function onDeleteRestrictions({ selectedRows, mutationOptions }) {
    const { onSuccess, onError } = mutationOptions;
    const restrictionCount = selectedRows.length;

    if (restrictionCount > 0) {
      let result = [];
      const bulkRequestSize = Math.ceil(restrictionCount / limitPerBulkRequest);
      for (let i = 0; i < bulkRequestSize; i++) {
        const start = i * limitPerBulkRequest;
        const end = (i + 1) * limitPerBulkRequest;
        const bulkSelectedRows = selectedRows.slice(start, end);

        const promises = bulkSelectedRows.map((record) => {
          const restrictionId = record.id;
          return deleteProductRestriction({ restrictionId });
        });

        const bulkResult = await Promise.allSettled(promises);
        const errorCount = filter(bulkResult, { status: 'rejected' }).length;
        const successCount = filter(bulkResult, { status: 'fulfilled' }).length;
        onError(errorCount);
        onSuccess(successCount);
        result = [...result, ...bulkResult];
      }

      const isAllFulfilled = result.every((item) => item.status === 'fulfilled');
      const isSomeRejected = result.some((item) => item.status === 'rejected');
      const isSomeFulfilled = result.some((item) => item.status === 'fulfilled');
      if (isAllFulfilled || (isSomeFulfilled && isSomeRejected)) {
        refetchAllProducts();
      }
    }
  }

  return {
    onRestrictProducts,
    onDeleteRestrictions,
  };
};

export default useBulkRestrictions;
