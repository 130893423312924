const useRejectOrderItems = ({ t, orderId, rejectOrderItems }) => {
  const onRejectOrderItems = ({ selectedRows, mutationOptions, cancellationReason }) => {
    const orderItemIds = selectedRows.map((row) => row.id);
    rejectOrderItems(
      {
        orderId,
        orderItems: orderItemIds,
        cancellationReason,
        requestConfig: {
          successMessage: t('transaction_success'),
          successDescription: t('order_detail_message.success'),
          errorMessage: t('transaction_failed'),
          errorDescription: t('order_detail_message.error'),
        },
      },
      {
        onSuccess: () => {
          mutationOptions.onSuccess?.();
        },
        onError: () => {
          mutationOptions.onError?.();
        },
      }
    );
  };

  return { onRejectOrderItems };
};

export default useRejectOrderItems;
