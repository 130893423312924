import { PluginRenderer } from '@akinon/app-shell';
import { createSelectOptions } from '@common/index';
import AkinonFormItem, { AkinonFormItemWithTooltip } from '@components/AkinonFormItem';
import ImageTableHead from '@components/ImageTableHead';
import Intrinsic from '@components/Intrinsic';
import SmallColumnImage from '@components/SmallColumnImage';
import { Button, Input, Select, Tooltip } from 'antd';
import first from 'lodash/first';
import isEmpty from 'lodash/isEmpty';
import last from 'lodash/last';
import trim from 'lodash/trim';
import * as z from 'zod';

import { ApiConfigType, ConfigTypeInputMap } from '../../common';

export const ProductsToBeAddedTableFormColumnKey = {
  PRODUCT_IMAGE: 'product_image',
  SKU: 'sku',
  SELLER_BARCODE: 'seller_barcode',
  STOCK: 'stock',
  RETAIL_PRICE: 'retail_price',
  PRICE: 'price',
  CURRENCY_TYPE: 'currency_type',
  TAX_RATE: 'tax_rate',
  TRANSACTIONS: 'transactions',
};

export const ProductsToBeAddedTableFormFormItemKey = {
  ...ProductsToBeAddedTableFormColumnKey,
};

const binaryCombination = (a, b) => [].concat(...a?.map((d) => b.map((e) => [].concat(d, e))));
const combination = (a, b, ...c) => (b ? combination(binaryCombination(a, b), ...c) : a) ?? [];

export const getDataSource = ({ variants, variantDynamicFields }) => {
  const dataSourceKeys = variants
    ? Object.keys(variants)
    : variantDynamicFields.map((field) => last(field.key.split('.')));
  const areThereMultipleVariants = dataSourceKeys.length !== 1;
  const areAllVariantsFilled =
    !isEmpty(variants) && Object.values(variants).every((variant) => variant?.length);

  if (!areAllVariantsFilled) {
    return [{}];
  }

  const combinedVariantsValues = areThereMultipleVariants
    ? combination(...Object.values(variants))
    : Object.values(variants)
        .map((variant) => variant?.map((variantValue) => [variantValue]))
        .flat();

  return combinedVariantsValues.filter(Boolean).map((combinedVariantsTuple) =>
    combinedVariantsTuple.reduce?.(
      (dataSourceItem, combineVariantsTupleValue, index) => ({
        ...dataSourceItem,
        [dataSourceKeys[index]]: combineVariantsTupleValue,
      }),
      {}
    )
  );
};

export const PRODUCT_ATTRIBUTES = 'PRODUCT_ATTRIBUTES';

export function shiftVariantListValuesToLeft(variantListFormValues, targetIndex) {
  return Object.entries(variantListFormValues).reduce(
    (shiftedVariantListValues, [variantListItemKey, variantListItemValue]) => {
      return {
        ...shiftedVariantListValues,
        [variantListItemKey]: variantListItemValue.filter((_, index) => index !== targetIndex),
      };
    },
    {}
  );
}

export const getBulkPriceUpdateModalFormSchema = ({ t }) => {
  const decimalSchema = z
    .string()
    .superRefine((value, ctx) => {
      if (trim(value) !== value) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: t('white_space_validation_message'),
        });
      }
    })
    .pipe(
      z.coerce
        .number({
          invalid_type_error: t('integer.validation.message'),
        })
        .nonnegative({ message: t('positive_validation_message') })
    )
    .optional();
  return z.object({
    bulk: z.object({
      [ProductsToBeAddedTableFormFormItemKey.RETAIL_PRICE]: decimalSchema,
      [ProductsToBeAddedTableFormFormItemKey.PRICE]: decimalSchema,
      [ProductsToBeAddedTableFormFormItemKey.TAX_RATE]: decimalSchema,
      [ProductsToBeAddedTableFormFormItemKey.CURRENCY_TYPE]: z.string().optional(),
    }),
    [ProductsToBeAddedTableFormFormItemKey.RETAIL_PRICE]: z.array(decimalSchema),
    [ProductsToBeAddedTableFormFormItemKey.PRICE]: z.array(decimalSchema),
    [ProductsToBeAddedTableFormFormItemKey.TAX_RATE]: z.array(decimalSchema),
    [ProductsToBeAddedTableFormFormItemKey.CURRENCY_TYPE]: z.array(z.string().optional()),
  });
};

export const getBulkStockUpdateModalFormSchema = ({ t }) => {
  const integerSchema = z
    .string()
    .superRefine((value, ctx) => {
      if (trim(value) !== value) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: t('white_space_validation_message'),
        });
      }
    })
    .pipe(
      z.coerce
        .number({
          invalid_type_error: t('integer.validation.message'),
        })
        .int({ message: t('integer.validation.message') })
        .nonnegative({ message: t('positive_validation_message') })
    )
    .optional();
  return z.object({
    bulk: z.object({
      [ProductsToBeAddedTableFormFormItemKey.STOCK]: integerSchema,
    }),
    [ProductsToBeAddedTableFormFormItemKey.STOCK]: z.array(integerSchema),
  });
};

export const getProductsToBeAddedTableFormSchema = ({
  variantDynamicFields,
  variantListAttributeFields,
  t,
}) => {
  return z.object({
    [ProductsToBeAddedTableFormFormItemKey.SKU]: z.array(z.string().min(1)),
    [ProductsToBeAddedTableFormFormItemKey.SELLER_BARCODE]: z.array(z.string().min(1)),
    [ProductsToBeAddedTableFormFormItemKey.STOCK]: z
      .array(
        z
          .string()
          .superRefine((value, ctx) => {
            if (trim(value) !== value) {
              ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: t('white_space_validation_message'),
              });
              return z.NEVER;
            } else if (isEmpty(value)) {
              ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: t('formRule.required'),
              });
              return z.NEVER;
            }
          })
          .pipe(
            z.coerce
              .number({
                invalid_type_error: t('number.validation.message'),
              })
              .int({ message: t('integer.validation.message') })
              .nonnegative({ message: t('positive_validation_message') })
          )
      )
      .nonempty({ message: t('nonempty_validation_message') }),
    [ProductsToBeAddedTableFormFormItemKey.RETAIL_PRICE]: z.array(
      z
        .string()
        .superRefine((value, ctx) => {
          if (trim(value) !== value) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: t('white_space_validation_message'),
            });
          } else if (isEmpty(value)) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: t('formRule.required'),
            });
          }
        })
        .pipe(
          z.coerce
            .number({
              invalid_type_error: t('integer.validation.message'),
            })
            .nonnegative({ message: t('positive_validation_message') })
        )
    ),
    [ProductsToBeAddedTableFormFormItemKey.PRICE]: z.array(
      z
        .string()
        .superRefine((value, ctx) => {
          if (trim(value) !== value) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: t('white_space_validation_message'),
            });
          } else if (isEmpty(value)) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: t('formRule.required'),
            });
          }
        })
        .pipe(
          z.coerce
            .number({
              invalid_type_error: t('integer.validation.message'),
            })
            .nonnegative({ message: t('positive_validation_message') })
        )
    ),
    [ProductsToBeAddedTableFormFormItemKey.CURRENCY_TYPE]: z.array(z.string()).min(1),
    [ProductsToBeAddedTableFormFormItemKey.TAX_RATE]: z.array(
      z
        .string()
        .superRefine((value, ctx) => {
          if (trim(value) !== value) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: t('white_space_validation_message'),
            });
          } else if (isEmpty(value)) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: t('formRule.required'),
            });
          }
        })
        .pipe(
          z.coerce
            .number({
              invalid_type_error: t('integer.validation.message'),
            })
            .nonnegative({ message: t('positive_validation_message') })
        )
    ),
    ...variantDynamicFields?.reduce((acc, field) => {
      return {
        ...acc,
        [field.key]: field.required
          ? z.array(z.string().min(1))
          : z.array(z.string().optional()).optional(),
      };
    }, {}),
    ...variantListAttributeFields?.reduce((acc, field) => {
      let schema;

      if (field.data_type === ApiConfigType.BOOL) {
        schema = field.required ? z.boolean() : z.boolean().optional();
      } else {
        schema = field.required ? z.string().min(1) : z.string().optional();
      }

      return {
        ...acc,
        [field.key]: z.array(schema),
      };
    }, {}),
  });
};

export const getColumns = ({
  t,
  variantDynamicFields,
  variantListAttributeFields,
  dataSource,
  form,
  productPricesOptions,
  isProductPricesOptionsLoading,
  isMobile,
  setSelectedVariantToShowImages,
  deleteRow,
}) => {
  const variantColumns = variantDynamicFields.map((metaField) => {
    return {
      dataIndex: metaField.key,
      key: metaField.key,
      title: metaField.label,
      render: (cellData) => {
        const optionLabel = metaField.options.find((option) => option.value === cellData)?.label;

        return <Tooltip title={optionLabel}>{optionLabel}</Tooltip>;
      },
    };
  });

  const getTitlePrefix = (isRequired) => (isRequired ? '*' : '');

  const variantAttributeColumns = variantListAttributeFields.map((metaField) => {
    let Component = ConfigTypeInputMap[metaField.data_type];
    if (metaField.data_type === ApiConfigType.DROPDOWN) {
      Component = Select;
    } else if (metaField.data_type === ApiConfigType.TEXT) {
      Component = Input;
    } else if (metaField.data_type === ApiConfigType.AREA) {
      Component = Input.TextArea;
    }

    const props =
      metaField.data_type === ApiConfigType.DROPDOWN ? { options: metaField.options ?? [] } : {};
    return {
      dataIndex: metaField.key,
      key: metaField.key,
      title: `${getTitlePrefix(metaField.required)} ${metaField.label}`,
      render: (cellData, _, rowIndex) => (
        <AkinonFormItem
          control={form.control}
          name={`${metaField.key}.${rowIndex}`}
          initialValue={cellData}
          required={metaField.required}
        >
          <Component {...props} />
        </AkinonFormItem>
      ),
    };
  });

  const isThereOneProduct = dataSource.length === 1;
  const currencyOptions = createSelectOptions(
    productPricesOptions?.actions?.POST.currency_type.choices,
    {
      valueKey: 'value',
      labelKey: 'display_name',
    }
  );

  const inputColumns = [
    {
      dataIndex: ProductsToBeAddedTableFormColumnKey.PRODUCT_IMAGE,
      title: <ImageTableHead />,
      render: (productImage, _, rowIndex) => {
        const src = first(productImage)?.src;
        return (
          <SmallColumnImage onClick={() => setSelectedVariantToShowImages(rowIndex)} src={src} />
        );
      },
      align: 'center',
    },
    {
      dataIndex: ProductsToBeAddedTableFormColumnKey.SKU,
      title: `* ${t('sku')}`,
      render: (cellData, rowData, rowIndex) => (
        <AkinonFormItemWithTooltip
          control={form.control}
          name={`${ProductsToBeAddedTableFormColumnKey.SKU}.${rowIndex}`}
          tooltipTitle={cellData}
        >
          <Input />
          <PluginRenderer placeholderId="plugin-place-holder-1" params={{ sku: cellData?.value }} />
          <PluginRenderer
            placeholderId="ui-protocol-product-detail-sku"
            params={{ sku: cellData }}
          />
        </AkinonFormItemWithTooltip>
      ),
    },
    {
      dataIndex: ProductsToBeAddedTableFormColumnKey.SELLER_BARCODE,
      title: `* ${t('seller_barcode')}`,
      render: (cellData, rowData, rowIndex) => (
        <AkinonFormItemWithTooltip
          control={form.control}
          name={`${ProductsToBeAddedTableFormColumnKey.SELLER_BARCODE}.${rowIndex}`}
          tooltipTitle={cellData}
        >
          <Input />
        </AkinonFormItemWithTooltip>
      ),
    },
    {
      dataIndex: ProductsToBeAddedTableFormColumnKey.STOCK,
      title: `* ${t('stock')}`,
      render: (cellData, rowData, rowIndex) => (
        <AkinonFormItemWithTooltip
          control={form.control}
          name={`${ProductsToBeAddedTableFormColumnKey.STOCK}.${rowIndex}`}
          tooltipTitle={cellData}
        >
          <Input />
        </AkinonFormItemWithTooltip>
      ),
    },
    {
      dataIndex: ProductsToBeAddedTableFormColumnKey.RETAIL_PRICE,
      title: `* ${t('psf')}`,
      render: (cellData, rowData, rowIndex) => (
        <AkinonFormItemWithTooltip
          control={form.control}
          name={`${ProductsToBeAddedTableFormColumnKey.RETAIL_PRICE}.${rowIndex}`}
          tooltipTitle={cellData}
        >
          <Input />
        </AkinonFormItemWithTooltip>
      ),
    },
    {
      dataIndex: ProductsToBeAddedTableFormColumnKey.PRICE,
      title: `* ${t('selling.price')}`,
      render: (cellData, rowData, rowIndex) => (
        <AkinonFormItemWithTooltip
          control={form.control}
          name={`${ProductsToBeAddedTableFormColumnKey.PRICE}.${rowIndex}`}
          tooltipTitle={cellData}
        >
          <Input />
        </AkinonFormItemWithTooltip>
      ),
    },
    {
      dataIndex: ProductsToBeAddedTableFormColumnKey.CURRENCY_TYPE,
      title: `* ${t('currency')}`,
      render: (cellData, rowData, rowIndex) => {
        return (
          <div>
            <AkinonFormItem
              control={form.control}
              name={`${ProductsToBeAddedTableFormColumnKey.CURRENCY_TYPE}.${rowIndex}`}
              initialValue={cellData}
            >
              <Select loading={isProductPricesOptionsLoading} options={currencyOptions} />
            </AkinonFormItem>
          </div>
        );
      },
    },
    {
      dataIndex: ProductsToBeAddedTableFormColumnKey.TAX_RATE,
      title: `* ${t('vat')}`,
      render: (cellData, rowData, rowIndex) => (
        <AkinonFormItemWithTooltip
          control={form.control}
          name={`${ProductsToBeAddedTableFormColumnKey.TAX_RATE}.${rowIndex}`}
          tooltipTitle={cellData}
        >
          <Input />
        </AkinonFormItemWithTooltip>
      ),
    },
    ...variantAttributeColumns,
    !isThereOneProduct && {
      dataIndex: ProductsToBeAddedTableFormColumnKey.TRANSACTIONS,
      title: t('transactions'),
      fixed: isMobile ? false : 'right',
      render: (_, __, rowIndex) => (
        <Button onClick={() => deleteRow(rowIndex)} className="row-action">
          <Intrinsic className="icon-bin" />
        </Button>
      ),
    },
  ].filter(Boolean);

  return [...variantColumns, ...inputColumns];
};

export const isModalVisibleMapInitialState = {
  [ProductsToBeAddedTableFormColumnKey.PRODUCT_IMAGE]: false,
  [ProductsToBeAddedTableFormColumnKey.PRICE]: false,
  [ProductsToBeAddedTableFormColumnKey.STOCK]: false,
  [PRODUCT_ATTRIBUTES]: false,
};

export const getTableActions = ({
  t,
  setIsModalVisibleMap,
  setIsAddImagesToSelectedProductsModalVisible,
}) => [
  {
    label: t('add.images.to.selected.products'),
    actionHandler: () => setIsAddImagesToSelectedProductsModalVisible(true),
  },
  {
    label: t('add.bulk.price'),
    actionHandler: () =>
      setIsModalVisibleMap({
        ...isModalVisibleMapInitialState,
        [ProductsToBeAddedTableFormColumnKey.PRICE]: true,
      }),
  },
  {
    label: t('add.bulk.stock.amount'),
    actionHandler: () =>
      setIsModalVisibleMap({
        ...isModalVisibleMapInitialState,
        [ProductsToBeAddedTableFormColumnKey.STOCK]: true,
      }),
  },
  {
    label: t('edit.product.attributes'),
    actionHandler: () =>
      setIsModalVisibleMap({
        ...isModalVisibleMapInitialState,
        [PRODUCT_ATTRIBUTES]: true,
      }),
  },
];
