import { limitQuery } from '@constants/commontypes';
import {
  useOrderItemsQuery,
  usePaginatedProductOffersQuery,
  useProductsQuery,
} from '@services/api/hooks';
import { useOrdersQuery } from '@services/api/hooks/useOrdersQuery';
import { usePaginatedDataSourcesQuery } from '@services/hooks/datasources/usePaginatedDataSourcesQuery';
import { useReconciliationItems } from '@services/hooks/finance/useReconciliationItems';
import { useReconciliations } from '@services/hooks/finance/useReconciliations';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import map from 'lodash/map';
import reject from 'lodash/reject';
import uniq from 'lodash/uniq';

export const useDataSource = ({ pagination, filters, reconciliationId }) => {
  const { reconciliationItems, isFetching, total } = useReconciliationItems({
    pagination,
    filters,
    id: reconciliationId,
  });

  const orderItemIds = uniq(reject(map(reconciliationItems, 'order_item'), isNil));
  const { orderItems, isFetchingOrderItems } = useOrderItemsQuery({
    params: {
      id__in: orderItemIds.join(','),
      ...limitQuery,
    },
    queryOptions: {
      enabled: !isEmpty(orderItemIds),
    },
  });

  const orderIds = uniq(reject(map(orderItems, 'order'), isNil));
  const productOfferIds = uniq(reject(map(orderItems, 'product_offer'), isNil));

  const { orders, isFetching: isFetchingOrders } = useOrdersQuery({
    params: {
      id__in: orderIds.join(','),
      limit: orderIds.length,
    },
    queryOptions: {
      enabled: !isEmpty(orderIds),
    },
  });

  const { productOffers, isFetchingProductOffers } = usePaginatedProductOffersQuery({
    params: {
      id__in: productOfferIds.join(','),
      limit: productOfferIds.length,
    },
    queryOptions: {
      enabled: !isEmpty(productOfferIds),
    },
  });

  const productIds = uniq(reject(map(productOffers, 'product'), isNil));

  const { products, isFetchingProducts } = useProductsQuery({
    params: {
      id__in: productIds.join(','),
      limit: productIds.length,
    },
    queryOptions: {
      enabled: !isEmpty(productIds),
    },
  });

  const reconciliationIds = uniq(reject(map(reconciliationItems, 'reconciliation'), isNil));
  const datasources = uniq(reject(map(reconciliationItems, 'datasource'), isNil));

  const { reconciliations, isFetching: isFetchingReconcilations } = useReconciliations({
    pagination,
    filters: { id__in: reconciliationIds.join(',') },
    enabled: !isEmpty(reconciliationIds),
  });

  const { data: dataSources } = usePaginatedDataSourcesQuery({}, undefined, datasources);

  reconciliationItems?.forEach((reconciliationItem) => {
    const reconciliationData = reconciliations?.find(
      (dataReconciliation) => dataReconciliation.id === reconciliationItem.reconciliation
    );
    const dataSource = dataSources?.find(
      (dataSource) => dataSource.id === reconciliationItem.datasource
    );

    reconciliationItem.name = reconciliationData?.name;
    reconciliationItem.supplier = dataSource?.name;
    const orderItem = find(orderItems, { id: reconciliationItem.order_item });
    const order = find(orders, { id: orderItem?.order });
    const productOffer = find(productOffers, { id: orderItem?.product_offer });
    const product = find(products, { id: productOffer?.product });
    reconciliationItem.orderItem = {
      ...orderItem,
      order,
      productOffer,
      product,
    };
  });

  const isLoading =
    isFetching ||
    isFetchingOrderItems ||
    isFetchingOrders ||
    isFetchingProductOffers ||
    isFetchingProducts ||
    isFetchingReconcilations;

  return {
    reconciliationItems,
    isLoading,
    total,
  };
};
