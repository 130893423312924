import { submitAllPreOffersUrl } from '@constants/apiUrls';

import { useAkinonMutation } from '../adapters/useAkinonMutation';
import { client } from '../client';

export function useSubmitAllPreOffersMutation() {
  const { mutate, isLoading } = useAkinonMutation({
    mutationFn({ preOfferIds }) {
      return client.post(submitAllPreOffersUrl, null, {
        params: {
          id_in: preOfferIds.join(','),
        },
      });
    },
  });

  return {
    submitAllPreOffers: mutate,
    isSubmittingAllPreOffers: isLoading,
  };
}
