import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Carousel, Image, Modal, Space } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

const ProductImagesModal = ({ onImageModalCancel, isProductImagesModalVisible, productImages }) => {
  const { t } = useTranslation();

  return (
    <Modal
      className="products-to-be-added-table-form__product-images-modal"
      footer={null}
      width={800}
      onCancel={onImageModalCancel}
      open={isProductImagesModalVisible}
      title={t('product.images.title')}
    >
      <Carousel
        arrows
        prevArrow={<LeftOutlined color="white" />}
        nextArrow={<RightOutlined color="white" />}
        slidesToShow={4}
        dots={null}
        infinite={productImages?.length > 4}
      >
        {productImages?.map((productImage) => (
          <Space
            className="products-to-be-added-table-form__product-image-container"
            align="center"
            direction="vertical"
            key={productImage.id}
          >
            <Image
              className="products-to-be-added-table-form__product-image"
              src={productImage.image}
            />
          </Space>
        ))}
      </Carousel>
    </Modal>
  );
};

export default ProductImagesModal;
