import Link from '@components/Link';
import { RouteUrls } from '@constants/routeUrls';
import { TranslationKey } from '@root/i18n';
import useStore from '@zustand-store/index';
import { setBreadcrumbsSelector } from '@zustand-store/selectors/app';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useUnmount } from 'react-use';

function useBreadcrumbs() {
  const { t } = useTranslation(TranslationKey.FINANCE);
  const setBreadcrumbs = useStore(setBreadcrumbsSelector);

  useEffect(() => {
    setBreadcrumbs([
      <Link key={RouteUrls.finance.landing} to={RouteUrls.finance.landing}>
        {t('finance')}
      </Link>,
      t('seller_performance_metrics'),
    ]);
  }, [t]);

  useUnmount(() => {
    setBreadcrumbs([]);
  });
}

export default useBreadcrumbs;
