import AkinonFilter from '@components/AkinonFilter';
import AkinonTable from '@components/AkinonTable';
import usePagination from '@components/AkinonTable/hooks/usePagination';
import { getExportsUrl } from '@constants/apiUrls';
import { defaultRowKey } from '@constants/index';
import useDocumentDownloadQuery from '@services/api/hooks/useDocumentDownloadQuery';
import { Divider } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getExportTableColumns } from './common';
import { useDataSource } from './hooks/useDataSource';
import useFilterFields from './hooks/useFilterFields';

export default function ExportsTab() {
  const { t } = useTranslation('ImportExportOperations');

  const [pagination, setPagination] = usePagination();
  const { dataSource, isLoading, total } = useDataSource({ pagination });
  const [documentUrl, setDcoumentUrl] = useState();
  const { isFetching } = useDocumentDownloadQuery({
    documentUrl: documentUrl,
    queryOptions: {
      enabled: Boolean(documentUrl),
    },
    onSettled: (data) => {
      setDcoumentUrl(null);
      if (data?.url) {
        window.location.href = data?.url;
      }
    },
  });
  const exportTableColumns = getExportTableColumns({ t, setDcoumentUrl, documentUrl, isFetching });

  const filterFields = useFilterFields({ t });

  return (
    <section>
      <AkinonFilter title={t('filters')} filterFields={filterFields} />
      <Divider />
      <AkinonTable
        pagination={pagination}
        setPagination={setPagination}
        rowKey={defaultRowKey}
        title={t('exports')}
        dataSource={dataSource}
        optionsUrl={getExportsUrl}
        loading={isLoading}
        total={total}
        columns={exportTableColumns}
        description={t('n_results_found', { count: total })}
      />
    </section>
  );
}
