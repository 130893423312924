import { getTreeNodeByValue, linkParents, markPermission } from '@utils/index';
import cloneDeep from 'lodash/cloneDeep';
import uniq from 'lodash/uniq';

export const DataIndex = {
  NAME: 'name',
};

const markAllParentsDirty = (treeData, key) => {
  const treeNode = getTreeNodeByValue({ tree: treeData, nodeValue: key, key: 'path' });
  if (treeNode?.parent) {
    treeNode.parent.dirty = true;
  }
  if (key.length === 4) {
    return;
  } else {
    markAllParentsDirty(treeData, key.slice(0, -4));
  }
};

const getParentWithoutPermissionUntilDirty = (currentNode) => {
  if (currentNode?.parent?.dirty || currentNode?.parent?.hasPermission) {
    return currentNode;
  }
  return currentNode?.parent
    ? getParentWithoutPermissionUntilDirty(currentNode.parent)
    : currentNode;
};

const getParentWithPermissionUntilDirty = (currentNode) => {
  if (currentNode?.parent?.dirty || !currentNode?.parent?.hasPermission) {
    return currentNode;
  }
  return currentNode?.parent ? getParentWithPermissionUntilDirty(currentNode.parent) : currentNode;
};

const findAnyParentWithPermissionUntilDirty = (currentNode) => {
  if (currentNode?.parent?.hasPermission && !currentNode?.parent?.dirty) {
    return currentNode.parent;
  }
  return currentNode?.parent ? findAnyParentWithPermissionUntilDirty(currentNode.parent) : null;
};

const findAnyParentWithPermission = (currentNode) => {
  if (currentNode?.parent?.hasPermission) {
    return currentNode.parent;
  }
  return currentNode?.parent ? findAnyParentWithPermission(currentNode.parent) : null;
};

function getLeafNodes(node) {
  if (!node.children || node.children.length === 0) {
    return node;
  }

  let leaves = [];
  for (let child of node.children) {
    leaves = leaves.concat(getLeafNodes(child));
  }

  return leaves;
}

export const optimizeCheckedKeys = (userCategoryNodePermissions, treeData, checkedKeys) => {
  const clonedTreeData = cloneDeep(treeData)
    .map((node) => linkParents({ currentNode: node }))
    .map((node) => markPermission({ node, userCategoryNodePermissions }));

  checkedKeys.forEach((key) => {
    const treeNode = getTreeNodeByValue({
      tree: clonedTreeData,
      nodeValue: key,
      key: 'path',
    });
    treeNode.isChecked = true;
    if (treeNode?.parent) {
      const siblings = treeNode.parent.children;
      if (siblings.length > 1) {
        if (!siblings.every((sibling) => checkedKeys.includes(sibling.key)))
          markAllParentsDirty(clonedTreeData, treeNode.key);
      }
    }
  });

  const leafNodes = clonedTreeData.flatMap((node) => getLeafNodes(node));

  let nodesToActivate = [];
  let nodesToDeactivate = [];

  leafNodes.forEach((node) => {
    const { isChecked, hasPermission, dirty } = node;

    const firstParentWithPermission = findAnyParentWithPermissionUntilDirty(node);
    if (!isChecked && !hasPermission) {
      const firstParentWithPermission = findAnyParentWithPermission(node);
      nodesToDeactivate.push(firstParentWithPermission);
    } else if (isChecked && !hasPermission) {
      if (dirty) {
        nodesToActivate.push(node);
      } else {
        nodesToActivate.push(getParentWithoutPermissionUntilDirty(node) ?? node);
      }
    } else if (!isChecked && hasPermission) {
      if (dirty) {
        nodesToDeactivate.push(node);
      } else {
        nodesToDeactivate.push(getParentWithPermissionUntilDirty(node));
      }
    } else if (firstParentWithPermission) {
      nodesToDeactivate.push(firstParentWithPermission);
    }
  });

  nodesToActivate.forEach((node) => {
    const parentNodeToDeactivate = findAnyParentWithPermissionUntilDirty(node);
    parentNodeToDeactivate && nodesToDeactivate.push(parentNodeToDeactivate);
  });

  const keysToActivate = uniq(nodesToActivate.map((node) => node?.key));
  const keysToDeactivate = uniq(nodesToDeactivate.filter(Boolean).map((node) => node?.key));

  return [keysToActivate, keysToDeactivate];
};
