import { addAuthObject, resetAuthObjects } from '@common/auth';
import { openNotification } from '@components/akinonNotification';
import { useTokenObtainMutation } from '@services/hooks/auth/useTokenObtainMutation';
import useStore from '@zustand-store/index';
import { resetAppSelector } from '@zustand-store/selectors/app';
import { useTranslation } from 'react-i18next';
import { useMount } from 'react-use';

export function useSignIn() {
  const { t } = useTranslation('Authentication');

  const resetApp = useStore(resetAppSelector);

  useMount(() => {
    resetApp();
  });

  const {
    postTokenObtain,
    isPostTokenObtainMutating,
    isPostTokenObtainError,
    resetPostTokenObtain,
  } = useTokenObtainMutation({
    onSuccess(tokenMeta) {
      addAuthObject(tokenMeta);
    },
    onError(error) {
      openNotification({
        message: t('signin_failed'),
        description: error?.response?.data?.detail || t('get.user.info.error.message'),
        type: 'error',
      });
      resetAuthObjects();
    },
  });

  return {
    postTokenObtain,
    isSignInMutating: isPostTokenObtainMutating,
    isPostTokenObtainError,
    resetPostTokenObtain,
  };
}
