import { getContentTypesUrl } from '@constants/apiUrls';
import { WidgetType } from '@constants/commontypes';
import { QueryKey } from '@constants/query';

import { StaticFilterKey } from '../common';

const useFilterFields = ({ t }) => {
  return {
    static: {
      fields: [
        {
          name: StaticFilterKey.ID,
          placeholder: 'ID',
        },
        {
          name: StaticFilterKey.ERROR,
          placeholder: t('error'),
        },
        {
          name: StaticFilterKey.IS_RESOLVED,
          placeholder: t('is_resolved'),
          widget: WidgetType.SELECT,
          options: [
            {
              label: t('yes'),
              value: true,
            },
            {
              label: t('no'),
              value: false,
            },
          ],
        },
        {
          name: StaticFilterKey.RESOLVED_AT_START,
          placeholder: t('resolved_at_start'),
          widget: WidgetType.DATE_PICKER,
        },
        {
          name: StaticFilterKey.RESOLVED_AT_END,
          placeholder: t('resolved_at_end'),
          widget: WidgetType.DATE_PICKER,
        },
        {
          name: StaticFilterKey.CONTENT_TYPE,
          placeholder: t('content_type'),
          widget: WidgetType.REMOTE_SELECT,
          showSearch: true,
          labelInValue: true,
          labelKey: 'model',
          queryProps: {
            remoteKey: QueryKey.CONTENT_TYPES,
            remoteUrl: getContentTypesUrl,
            params: {
              limit: 20,
              sort: 'model',
            },
            searchKey: 'model__icontains',
          },
        },
        {
          name: StaticFilterKey.OBJECT_ID,
          placeholder: t('object_id'),
        },
        {
          name: StaticFilterKey.CREATED_AT_START,
          placeholder: t('created_at_start'),
          widget: WidgetType.DATE_PICKER,
        },
        {
          name: StaticFilterKey.CREATED_AT_END,
          placeholder: t('created_at_end'),
          widget: WidgetType.DATE_PICKER,
        },
        {
          name: StaticFilterKey.UPDATED_AT_START,
          placeholder: t('updated_at_start'),
          widget: WidgetType.DATE_PICKER,
        },
        {
          name: StaticFilterKey.UPDATED_AT_END,
          placeholder: t('updated_at_end'),
          widget: WidgetType.DATE_PICKER,
        },
      ],
    },
    dynamic: { fields: [], isLoading: false },
  };
};

export default useFilterFields;
