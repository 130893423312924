import NavigationList from '@components/Navigation/List';
import { Color } from '@constants/theme';
import { useUser } from '@root/contexts/hooks/useUser';
import { useIsLiveAppVersion } from '@root/hooks/useIsLiveAppVersion';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { getNavigationItems } from './common';
import { useBreadcrumbs } from './hooks/useBreadcrumbs';
import { usePageMeta } from './hooks/usePageMeta';

export default function SellerManagementDetailLanding() {
  const { t } = useTranslation('Finance');
  const { id } = useParams();
  const user = useUser();
  const isLiveAppVersion = useIsLiveAppVersion();
  const navigationItems = getNavigationItems({ t, user, isLiveAppVersion, id });

  useBreadcrumbs();
  usePageMeta({ id });

  return <NavigationList items={navigationItems} cardColor={Color.SLATE_GRAY} />;
}
