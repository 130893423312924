import './styles.scss';

import AkinonFormItem from '@components/AkinonFormItem';
import { defaultTimeFormat } from '@constants/index';
import { useIsMobile } from '@hooks/useIsMobile';
import { TimePicker } from 'antd';
import omit from 'lodash/omit';
import moment from 'moment';
import React, { useState } from 'react';

import MobileTimePicker from './components/MobileTimePicker';

/**
 * @param {import('antd').TimePickerProps} props
 */
const AkinonTimePicker = (props) => {
  const [value, setValue] = useState(props.value);
  const [isFadingOut, setIsFadingOut] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const isMobile = useIsMobile();

  const onClear = () => {
    //? workaround for antd bug, onChange sometimes does not trigger when the value is undefined.
    props?.onChange?.(false);
    setTimeout(() => {
      props?.onChange?.(null);
    }, 0);
    onClose();
  };

  const onApply = () => {
    onClose();
    props?.onChange?.(value);
  };

  const onClose = () => {
    setIsFadingOut(true);
    setTimeout(() => {
      setIsOpen(false);
      setIsFadingOut(false);
    }, 300);
  };

  const mobileProps = isMobile
    ? {
        onClick: (e) => {
          if (e.target.tagName === 'INPUT') {
            setIsOpen(true);
          }
        },
        onChange: (val) => setValue(val),
        open: isOpen,
      }
    : {};

  const parseDate = (value) => {
    if (!value) return null;
    else if (moment.isMoment(value))
      return moment(value.format(defaultTimeFormat), defaultTimeFormat);
    else return moment(value, defaultTimeFormat);
  };

  const component = (
    <TimePicker
      aria-label={props?.['aria-label'] ?? props?.name}
      panelRender={(originPanel) => {
        if (!isMobile) {
          return originPanel;
        }

        return (
          <MobileTimePicker
            onClear={onClear}
            title={props?.placeholder}
            onApply={onApply}
            onClose={onClose}
            isFadingOut={isFadingOut}
            menu={originPanel}
          />
        );
      }}
      defaultValue={parseDate(props?.value)}
      {...omit(props, ['aria-label'])}
      {...mobileProps}
    />
  );

  if (props?.formItemProps) {
    return (
      <AkinonFormItem {...props?.formItemProps} valuePropName="value" valuePropParser={parseDate}>
        {component}
      </AkinonFormItem>
    );
  }

  return component;
};

export default AkinonTimePicker;
