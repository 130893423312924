import { useDynamicFilterFields } from '@utils/hooks/useDynamicFilterFields';
import { useMemo } from 'react';

import { useStaticFilterFields } from './useStaticFilterFields';

const useFilterFields = ({ attributes, isAttributesLoading, product }) => {
  const dynamicFields = useDynamicFilterFields({ attributes, isAttributesLoading });
  const staticFields = useStaticFilterFields({
    product,
  });

  return useMemo(
    () => ({
      dynamic: dynamicFields,
      static: staticFields,
    }),
    [dynamicFields, staticFields]
  );
};

export default useFilterFields;
