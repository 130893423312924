import './index.scss';

import { UserRole } from '@constants/auth';
import { zodResolver } from '@hookform/resolvers/zod';
import { usePostPreOfferMutation } from '@services/api/hooks/usePostPreOfferMutation';
import { useSubmitAllPreOffersMutation } from '@services/api/hooks/useSubmitAllPreOffersMutation';
import { useUserRole } from '@utils/hooks/useUserRole';
import useStore from '@zustand-store/index';
import { resetPreOfferSelector } from '@zustand-store/selectors/preOffer';
import { Space } from 'antd';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useUnmount } from 'react-use';

import { getDescriptionFormSchema } from './common';
import BasicProductInfoForm from './components/BasicProductInfoForm';
import { getBasicInfoFormSchema } from './components/BasicProductInfoForm/common';
import DescriptionForm from './components/DescriptionForm';
import ProductRevisionModal from './components/ProductRevision/ProductRevisionModal';
import ProductsToBeAddedTableForm from './components/ProductsToBeAddedTableForm';
import {
  getDataSource,
  getProductsToBeAddedTableFormSchema,
} from './components/ProductsToBeAddedTableForm/common';
import SubmitForm from './components/SubmitForm';
import { QueriesProvider } from './contexts/Queries';
import { useQueries } from './hooks/api/useQueries';
import { useBreadcrumbs } from './hooks/useBreadcrumbs';
import useFormMetaFields from './hooks/useFormMetaFields';
import { useOnSubmit } from './hooks/useOnSubmit';
import { usePageMeta } from './hooks/usePageMeta';

const ProductDetail = () => {
  const { t } = useTranslation();
  const [isProductRevisionModalVisible, setIsProductRevisionModalVisible] = useState(false);
  const userRole = useUserRole();
  const isSuperUser = userRole === UserRole.SUPER_USER;
  const basicInfoFormSchema = getBasicInfoFormSchema();
  const basicProductInfoForm = useForm({
    resolver: zodResolver(basicInfoFormSchema),
    defaultValues: {},
  });
  const productsToBeAddedTableForm = useForm({
    defaultValues: {},
    resolver: zodResolver(getProductsToBeAddedTableFormSchema({ t })),
  });

  const schema = getDescriptionFormSchema({ t });
  const descriptionForm = useForm({
    resolver: zodResolver(schema),
  });
  const { state } = useLocation();

  const { isEditDisabled } = state ?? { isEditDisabled: false };

  const { postPreOffer, isPostingPreOffer } = usePostPreOfferMutation();
  const { submitAllPreOffers, isSubmittingAllPreOffers } = useSubmitAllPreOffersMutation();
  const resetPreOffer = useStore(resetPreOfferSelector);
  const {
    product,
    attributeConfigs,
    attributeSetId,
    dataSources,
    priceLists,
    productAttributes,
    stockLists,
    productMeta,
    buyboxPrice,
  } = useQueries();
  const [productSummariesDataSource, setProductSummariesDataSource] = useState([]);
  const [rowsToHide, setRowsToHide] = useState([]);
  const visibleProductSummariesDataSource = productSummariesDataSource.filter(
    (dataSource) => !rowsToHide.includes(dataSource.id)
  );

  useEffect(() => {
    if (productMeta) {
      setProductSummariesDataSource(getDataSource({ productMeta }));
    }
  }, [productMeta]);

  const onSubmit = useOnSubmit({
    productsToBeAddedTableFormDataSource: visibleProductSummariesDataSource,
    basicProductInfoForm,
    productsToBeAddedTableForm,
    descriptionForm,
    priceLists,
    stockLists,
    dataSources,
    attributeSetId,
    postPreOffer,
    submitAllPreOffers,
  });

  useUnmount(() => {
    resetPreOffer();
  });

  useFormMetaFields({ attributeConfigs, productAttributes });

  const onCancelProductRevisionModal = () => {
    setIsProductRevisionModalVisible(false);
  };
  const onShowProductRevisionModal = () => setIsProductRevisionModalVisible(true);

  useBreadcrumbs();
  usePageMeta();

  return (
    <section className="single-product-form">
      <ProductRevisionModal
        productSummariesDataSource={productSummariesDataSource}
        setProductSummariesDataSource={setProductSummariesDataSource}
        isVisible={isProductRevisionModalVisible}
        onCancel={onCancelProductRevisionModal}
        productAttributes={productAttributes}
        attributeConfigs={attributeConfigs}
        productMeta={productMeta}
        isLoading={!productAttributes}
      />
      <QueriesProvider
        value={{
          product,
          attributeSetId,
          dataSources,
          priceLists,
          stockLists,
          attributeConfigs,
          productAttributes,
          productMeta,
          buyboxPrice,
        }}
      >
        <BasicProductInfoForm form={basicProductInfoForm} />
        <Space style={{ width: '100%' }} direction="vertical" size="large">
          <ProductsToBeAddedTableForm
            form={productsToBeAddedTableForm}
            productSummariesDataSource={productSummariesDataSource}
            setProductSummariesDataSource={setProductSummariesDataSource}
            onShowProductRevisionModal={onShowProductRevisionModal}
            setRowsToHide={setRowsToHide}
            visibleProductSummariesDataSource={visibleProductSummariesDataSource}
          />
          <DescriptionForm form={descriptionForm} schema={schema} disabled={isSuperUser} />
          {!isSuperUser && !isEditDisabled && (
            <SubmitForm
              isPostingPreOffer={isPostingPreOffer}
              isSubmittingAllPreOffers={isSubmittingAllPreOffers}
              onSubmit={onSubmit}
            />
          )}
        </Space>
      </QueriesProvider>
    </section>
  );
};

export default ProductDetail;
