import { convertFileToBase64 } from '@common/file';
import AkinonFormItem from '@components/AkinonFormItem';
import { Upload } from 'antd';
import clsx from 'clsx';
import { forwardRef, useRef } from 'react';
import { useTranslation } from 'react-i18next';

const { Dragger } = Upload;

const CustomDragger = ({ formItemProps, ...draggerProps }, ref) => {
  const { t } = useTranslation();
  const inputRef = useRef();

  const dummyRequest = ({ onSuccess }) => {
    setTimeout(() => {
      onSuccess('ok');
    });
  };

  const beforeUpload = async (file) => {
    draggerProps.beforeUpload?.(file);

    const base64 = await convertFileToBase64(file);
    file.base64 = base64;
    return true;
  };

  const component = (
    <Dragger
      data-testId="akinon-dragger"
      beforeUpload={beforeUpload}
      customRequest={dummyRequest}
      {...draggerProps}
      ref={ref}
      className={clsx(
        'akinon-dragger !h-12 flex justify-center items-center',
        draggerProps.className
      )}
    >
      <div tabIndex={-1} ref={inputRef}>
        {draggerProps.children ?? t('dragger.desc')}
      </div>
    </Dragger>
  );
  if (formItemProps) {
    return (
      <AkinonFormItem inputRef={inputRef} {...formItemProps}>
        {component}
      </AkinonFormItem>
    );
  }
  return component;
};

export default forwardRef(CustomDragger);
