import { QueryKey } from '@constants/query';

import { useAkinonQuery } from '../adapters/useAkinonQuery';
import { client } from '../client';

const useDocumentDownloadQuery = ({ documentUrl, queryOptions = {}, onSuccess, onSettled }) => {
  const response = useAkinonQuery({
    queryKey: [QueryKey.DOCUMENT, documentUrl],
    queryFn: async () => {
      let response, error;
      try {
        response = await client.get(documentUrl);

        onSuccess?.(response);
        return response;
      } catch (err) {
        error = err;
        throw new Error(err);
      } finally {
        onSettled?.(response, error);
      }
    },
    ...queryOptions,
  });

  return {
    ...response,
  };
};

export default useDocumentDownloadQuery;
