import AkinonSteps, { AkinonStep } from '@components/AkinonSteps';
import { useCurrentStep } from '@utils/hooks/useCurrentStep';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { getStepList } from './common';
import { useBreadcrumbs } from './hooks/useBreadcrumbs';
import { usePageMeta } from './hooks/usePageMeta';

function SaleReports() {
  const { t } = useTranslation('Reporting');

  const stepList = getStepList({ t });

  const { currentStep, setCurrentStep } = useCurrentStep({
    stepCount: stepList.length,
    fallbackStep: 0,
  });

  const { Component: StepComponent = () => null } = stepList[currentStep] ?? {};

  useBreadcrumbs();
  usePageMeta();

  return (
    <section className="seller-management-detail">
      <AkinonSteps current={currentStep} onChange={setCurrentStep}>
        {stepList.map(({ key, title, icon }) => (
          <AkinonStep key={key} title={title} icon={icon} />
        ))}
      </AkinonSteps>

      <StepComponent />
    </section>
  );
}

export default SaleReports;
