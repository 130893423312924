import { getActivePassiveOptions, WidgetType } from '@constants/commontypes';

import { StaticFilterKey } from '../common';

const useFilterFields = ({ t }) => {
  return {
    dynamic: { fields: [], isLoading: false },
    static: {
      fields: [
        {
          widget: WidgetType.INPUT,
          name: StaticFilterKey.NAME,
          placeholder: t('seller'),
          showTime: true,
        },
        {
          widget: WidgetType.SELECT,
          name: StaticFilterKey.IS_ACTIVE,
          placeholder: t('seller_status'),
          options: getActivePassiveOptions({ t }),
        },
        {
          widget: WidgetType.DATE_PICKER,
          name: StaticFilterKey.CREATED_AT_GTE,
          placeholder: t('first.creation.date'),
        },
        {
          widget: WidgetType.DATE_PICKER,
          name: StaticFilterKey.CREATED_AT_LTE,
          placeholder: t('last_modified_date'),
        },
      ],
    },
  };
};

export default useFilterFields;
