import { getAttributesUrl } from '@constants/apiUrls';
import { limitQuery } from '@constants/commontypes';
import { QueryKey } from '@constants/query';
import { useAkinonQuery } from '@services/api/adapters/useAkinonQuery';
import { client } from '@services/api/client';

/**
 * @param {{ queryOptions: import('@tanstack/react-query').UseQueryOptions }} args
 */
export function useProductAttributesQuery({ queryOptions } = {}) {
  const { data, error } = useAkinonQuery({
    queryKey: [QueryKey.PRODUCT_ATTRIBUTES],
    queryFn: () =>
      client.get(getAttributesUrl, {
        params: {
          is_active: true,
          ...limitQuery,
        },
      }),
    ...queryOptions,
  });

  return {
    productAttributes: data?.results,
    productAttributesError: error,
  };
}
