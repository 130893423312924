/**
 * @type {import('antd').PaginationProps}
 */
export const defaultPagination = {
  current: 1,
  pageSize: '20',
  pageSizeOptions: ['20', '50', '100'],
};

// export const getSimpleDynamicColumns = ({ dynamicFiltersFormMetaFields }) => {
//   return dynamicFiltersFormMetaFields.map((formMetaField) => {
//     const fieldKey = RegExp(/attributes__(.*)__/).exec(formMetaField?.key)[1];

//     return {
//       title: formMetaField.placeholder,
//       dataIndex: ['attributes', fieldKey],
//       key: fieldKey,
//       classname: 'uppercase',
//       render: (data) => data ?? '-',
//     };
//   });
// };
