import { dateFormatter, statusActivePassiveFormatter } from '@utils/index';
import { Tooltip } from 'antd';
import React from 'react';

export const StaticFilterKey = {
  NAME: 'name',
  STATUS: 'status',
  FIRST_CREATED_DATE: 'created_at__gte',
  LAST_CREATED_DATE: 'created_at__lte',
  FIRST_EXPIRATION_DATE: 'expiration_date__gte',
  LAST_EXPIRATION_DATE: 'expiration_date__lte',
};

export const DataIndex = {
  NAME: 'promotion',
  BEGINNING_DATE: 'beginning_date',
  EXPIRATION_DATE: 'expiration_date',
  STATUS: 'status',
};

export const getColumns = ({ t }) => [
  {
    dataIndex: DataIndex.NAME,
    visibleOnFilter: [StaticFilterKey.NAME],
    title: t('campaign_name'),
    render: (promotion) => {
      return <Tooltip title={promotion.name}>{promotion.name}</Tooltip>;
    },
  },
  {
    dataIndex: DataIndex.STATUS,
    visibleOnFilter: [StaticFilterKey.STATUS],
    title: t('status'),
    render: (cellData) => {
      return statusActivePassiveFormatter({ t, cellData });
    },
  },
  {
    dataIndex: DataIndex.BEGINNING_DATE,
    visibleOnFilter: [StaticFilterKey.FIRST_CREATED_DATE, StaticFilterKey.LAST_CREATED_DATE],
    title: t('start_date'),
    render: (cellData) => dateFormatter(cellData),
  },
  {
    dataIndex: DataIndex.EXPIRATION_DATE,
    visibleOnFilter: [StaticFilterKey.FIRST_EXPIRATION_DATE, StaticFilterKey.LAST_EXPIRATION_DATE],
    title: t('end_date'),
    render: (cellData) => dateFormatter(cellData),
  },
];
