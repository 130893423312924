import AkinonFilter from '@components/AkinonFilter';
import AkinonTable from '@components/AkinonTable';
import usePagination from '@components/AkinonTable/hooks/usePagination';
import { Divider } from 'antd';
import { useTranslation } from 'react-i18next';

export default function CategoryBasedTable() {
  const { t } = useTranslation('Reporting');
  const [pagination, setPagination] = usePagination();
  const columns = [];
  const dataSource = [];
  const filterFields = {
    dynamic: { fields: [], isLoading: false },
    static: { fields: [], isLoading: false },
  };
  const total = dataSource.length;
  const isDataSourceLoading = false;

  return (
    <div>
      <AkinonFilter title={t('filters')} filterFields={filterFields} total={total} />
      <Divider />
      <AkinonTable
        title={t('sale_reports.stepper_sales_tabs_based_on_category_title')}
        columns={columns}
        dataSource={dataSource}
        pagination={pagination}
        setPagination={setPagination}
        loading={isDataSourceLoading}
        rowKey="id"
        total={total}
        description={`${total} ${t('results.found')}`}
      />
    </div>
  );
}
