import { getRequestFiltersParams, getRequestPaginationParams } from '@common/index';
import { getBasketOfferUrl } from '@constants/apiUrls';
import { QueryKey } from '@constants/query';
import { useAkinonQuery } from '@services/api/adapters/useAkinonQuery';
import { client } from '@services/api/client';

export function useBasketOfferQuery({ queryOptions, onSuccess, pagination, filters, params } = {}) {
  const { data, isLoading } = useAkinonQuery({
    queryKey: [QueryKey.BASKET_OFFER, pagination, filters],
    async queryFn() {
      const response = await client.get(getBasketOfferUrl, {
        params: {
          ...params,
          ...getRequestPaginationParams(pagination),
          ...getRequestFiltersParams(filters),
        },
      });
      onSuccess?.(response);
      return response;
    },
    ...queryOptions,
  });

  return {
    basketOffers: data?.results,
    isBasketOffersLoading: isLoading,
    total: data?.count,
  };
}
