import './style.scss';

import { DownCircleOutlined } from '@ant-design/icons';
import AkinonButton from '@components/AkinonButton';
import AkinonFormItem from '@components/AkinonFormItem';
import { Checkbox, Collapse, Space } from 'antd';
import { memo, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import VariantSelect from '../VariantSelect';

const { Panel } = Collapse;

export const VariantsCollapse = memo(
  /**
   * @param {{ form: import('react-hook-form').UseFormReturn }} props
   */
  ({
    variantDynamicFields,
    variantDynamicFieldsKeys,
    form,
    expandedPanels,
    setExpandedPanels,
    isOfferProductsReadyToBeDisplayed,
  }) => {
    const [showAllVariants] = useState(true);
    const { t } = useTranslation('ProductsAndCategories');

    const { control, register } = form;
    const handleCollapseAll = () => setExpandedPanels([]);
    const handleExpandAll = useCallback(
      () => setExpandedPanels(variantDynamicFieldsKeys),
      [variantDynamicFieldsKeys]
    );
    const handlePanelChange = (keys) => setExpandedPanels(keys);

    useEffect(() => {
      variantDynamicFields?.forEach((variantsFormDynamicField) => {
        register(`variantsForm.${variantsFormDynamicField.key}`);
      });
    }, [register, variantDynamicFields]);

    return (
      <Collapse onChange={handlePanelChange} activeKey={expandedPanels} className="variant-box">
        <div className="box-primary form-box variants-form__actions">
          <Checkbox checked={showAllVariants} className="akn-checkbox variants-form__show-all">
            Show all variants
          </Checkbox>
          <Space className="variants-form__collapse-actions">
            <AkinonButton type="button" onClick={handleExpandAll}>
              Expand All
            </AkinonButton>
            <AkinonButton type="button" onClick={handleCollapseAll}>
              Collapse All
            </AkinonButton>
          </Space>
        </div>
        {variantDynamicFields?.map((variantsFormDynamicField) => (
          <Panel
            className="variant-box__panel"
            showArrow={false}
            key={variantsFormDynamicField.key}
            header={
              <div className="variant-box__panel-header">
                <Space align="center" size={13}>
                  <AkinonButton type="icon" onClick={() => null}>
                    <DownCircleOutlined />
                  </AkinonButton>
                  <p className="variant-box__panel-header-label">
                    {variantsFormDynamicField.label}
                  </p>
                </Space>
              </div>
            }
          >
            <div
              className="variant-box__panel-context"
              style={{ marginTop: '20px', marginBottom: '0px' }}
            >
              <AkinonFormItem
                control={control}
                label={variantsFormDynamicField.label}
                name={`variantsForm.${variantsFormDynamicField.key}`}
                disabled={variantsFormDynamicField.disabled}
                tooltip={{
                  title: t('variant_select_info'),
                }}
                requiredMarkContent={`* ${t('required')}`}
                requiredMarkPosition="right"
                initialValue={variantsFormDynamicField.initialValue}
                required={variantsFormDynamicField.required}
              >
                <VariantSelect
                  attributeOptions={variantsFormDynamicField.options}
                  disabled={isOfferProductsReadyToBeDisplayed}
                  variantsForm={form}
                  _key={`variantsForm.${variantsFormDynamicField.key}`}
                />
              </AkinonFormItem>
            </div>
          </Panel>
        ))}
      </Collapse>
    );
  }
);

export default VariantsCollapse;
