import If from '@components/If';
import React from 'react';

import AttributeSetSelect from './components/AttributeSetSelect';

export const DataIndex = {
  NAME: 'name',
  ATTRIBUTE_SET: 'attribute_set',
};

export const getColumns = ({ attributeSets }) => {
  return [
    {
      dataIndex: DataIndex.NAME,
      width: '30%',
    },
    {
      dataIndex: DataIndex.ATTRIBUTE_SET,
      width: '60%',
      render: (_, record) => {
        return (
          record.isLeaf && (
            <AttributeSetSelect
              attributeSets={attributeSets}
              categoryId={record.key}
              defaultValue={record.attribute_set}
              showSearch
              optionFilterProp="label"
            />
          )
        );
      },
    },
  ];
};

/**
 * @returns {import('antd/es/table/interface').ExpandableConfig}
 */
export const getExpandableConfig = ({ setExpandedKeys, expandedKeys }) => {
  const handleOnTableExpand = (expanded, record) => {
    if (expanded) {
      setExpandedKeys([...expandedKeys, record.key]);
    } else {
      setExpandedKeys(expandedKeys.filter((item) => item !== record.key));
    }
  };

  return {
    onExpand: handleOnTableExpand,
    expandedRowKeys: expandedKeys,
    indentSize: 27,
    expandIcon: ({ expanded, onExpand, record }) => {
      const handleOnExpand = (e) => onExpand(record, e);
      return (
        <If
          condition={!record.isLeaf}
          then={
            <If
              condition={expanded}
              then={<i className="icon-folder_minus table-icon" onClick={handleOnExpand} />}
              otherwise={<i className="icon-folder_plus table-icon" onClick={handleOnExpand} />}
            />
          }
          otherwise={null}
        />
      );
    },
  };
};
