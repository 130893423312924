import { getStaticFilters } from '../common';

const useFilterFields = ({ t, dataSources, isDataSourcesLoading }) => {
  const staticFilters = getStaticFilters({
    t,
    dataSources,
    isDataSourcesLoading,
  });

  return {
    dynamic: { fields: [], isLoading: false },
    static: staticFilters,
  };
};

export default useFilterFields;
