import Link from '@components/Link';
import { UserRole } from '@constants/auth';
import { RouteUrls } from '@constants/routeUrls';
import { useUserRole } from '@utils/hooks/useUserRole';
import useStore from '@zustand-store/index';
import { setBreadcrumbsSelector } from '@zustand-store/selectors/app';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useParams } from 'react-router-dom';
import { useUnmount } from 'react-use';

export function useBreadcrumbs() {
  const { t } = useTranslation('Finance');
  const { id } = useParams();
  const setBreadcrumbs = useStore(setBreadcrumbsSelector);
  const userRole = useUserRole();
  const isSuperUser = userRole === UserRole.SUPER_USER;

  useEffect(() => {
    setBreadcrumbs(
      [
        <Link key={RouteUrls.finance.landing} to={RouteUrls.finance.landing}>
          {t('sidebar_finance')}
        </Link>,
        <Link
          key={RouteUrls.finance.sellerManagement.landing}
          to={RouteUrls.finance.sellerManagement.landing}
        >
          {isSuperUser ? t('seller.management') : t('account_management')}
        </Link>,
        isSuperUser && (
          <Link
            key={RouteUrls.finance.sellerManagement.sellerDetail}
            to={generatePath(RouteUrls.finance.sellerManagement.sellerDetail, { id })}
          >
            {t('seller_management_detail')}
          </Link>
        ),

        t('permissions'),
      ].filter(Boolean)
    );
  }, [t]);

  useUnmount(() => {
    setBreadcrumbs([]);
  });
}
