import { getDocumentMeta } from '@common/index';
import { Badge, Close, Content, ContentComponent, Navigation } from '@components/AkinonTour';
import RootErrorBoundary from '@components/ErrorBoundry/RootErrorBoundary';
// import { Color } from '@constants/colors';
import { languageToAntdLocaleMap } from '@constants/locale';
import { Color } from '@constants/theme';
import useCancelPreviousRequests from '@hooks/useCancelPreviousRequests';
import { TourProvider } from '@reactour/tour';
import { AppMetaProvider } from '@root/contexts/AppMetaContext';
import * as Sentry from '@sentry/react';
import { queryClient } from '@services/api/queryClient';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { ConfigProvider } from 'antd';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { Suspense } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';

import BaseLayoutFallback from './BaseLayoutFallback';

function BaseLayout() {
  const { i18n } = useTranslation();

  useCancelPreviousRequests();

  const documentMeta = getDocumentMeta();
  const antdLocale = languageToAntdLocaleMap[i18n.language];
  const disableBody = (target) => disableBodyScroll(target);
  const enableBody = (target) => enableBodyScroll(target);
  return (
    <Sentry.ErrorBoundary fallback={RootErrorBoundary}>
      <DndProvider backend={HTML5Backend}>
        <TourProvider
          components={{ Badge, Close, Content, Navigation }}
          ContentComponent={ContentComponent}
          highlightedMaskClassName="akinon-tour__highlighted-mask"
          afterOpen={disableBody}
          beforeClose={enableBody}
          styles={{
            popover: (base) => ({
              ...base,
              backgroundColor: Color.PICKLED_BLUEWOOD,
              color: 'white',
              borderRadius: 8,
              padding: '32px 24px 24px',
              minWidth: 300,
            }),
          }}
          scrollSmooth
        >
          <ConfigProvider locale={antdLocale}>
            <AppMetaProvider value={documentMeta}>
              <QueryClientProvider client={queryClient}>
                <ReactQueryDevtools initialIsOpen={false} />
                <Suspense fallback={<BaseLayoutFallback />}>
                  <Outlet />
                </Suspense>
              </QueryClientProvider>
            </AppMetaProvider>
          </ConfigProvider>
        </TourProvider>
      </DndProvider>
    </Sentry.ErrorBoundary>
  );
}

export default Sentry.withProfiler(BaseLayout);
