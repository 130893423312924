import * as z from 'zod';

export const BasicProductInfoKey = {
  NAME: 'name',
  BASE_CODE: 'base_code',
  ATTRIBUTE_SET: 'id',
};

export const getBasicInfoFormSchema = () => {
  return z.object({
    [BasicProductInfoKey.NAME]: z.string().min(1),
    [BasicProductInfoKey.BASE_CODE]: z.string().min(1),
    [BasicProductInfoKey.ATTRIBUTE_SET]: z.string().min(1),
  });
};
