import * as React from 'react';

const IconLogicOperator = (props) => (
  <svg width="26" height="16" viewBox="0 0 26 16" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M21.89 4.5a1 1 0 0 1 1 1v2h2a1 1 0 0 1 0 2h-2v2a1 1 0 0 1-2 0v-2h-2a1 1 0 1 1 0-2h2v-2a1 1 0 0 1 1-1zM8.083.019C19.836 2.333 19.836 14.44 8.02 15.99a1 1 0 0 1-1.063-1.352c.34-.88.614-1.76.82-2.639H1.89a1 1 0 1 1 0-2h6.237a17.15 17.15 0 0 0 0-4H1.89a1 1 0 1 1 0-2h5.886a19.791 19.791 0 0 0-.819-2.64A1 1 0 0 1 8.083.02zM9.43 2.43l.123.438c.96 3.567.919 7.144-.126 10.71l-.037.12.227-.06c7.04-1.932 7.036-8.759-.05-11.163L9.43 2.43z"
      fill="#5EB9E4"
      fillRule="nonzero"
    />
  </svg>
);
export default IconLogicOperator;
