import CopyButton from '@components/CopyButton';
import { getActivePassiveBooleanOptions, WidgetType } from '@constants/commontypes';
import { defaultDateValueFormat } from '@constants/index';
import { verticalFormItemLayout } from '@constants/layoutTypes';
import { QueryKey } from '@constants/query';
import { queryClient } from '@services/api/queryClient';
import { Tooltip } from 'antd';
import moment from 'moment';

export const FormKey = {
  NAME: 'name',
  SLUG: 'slug',
  BEGINNING_DATE: 'beginning_date',
  EXPIRATION_DATE: 'expiration_date',
  IS_ACTIVE: 'is_active',
};

export const getFormMeta = ({ t }) => {
  return {
    formItemLayout: verticalFormItemLayout,
    columns: 2,
    fields: [
      {
        label: t('collections_name'),
        key: FormKey.NAME,
        widget: WidgetType.INPUT,
        placeholder: t('collections_name'),
        required: true,
      },
      {
        widget: WidgetType.INPUT,
        key: FormKey.SLUG,
        label: t('slug'),
        placeholder: t('slug'),
        required: true,
      },
      {
        widget: WidgetType.DATE_PICKER,
        key: FormKey.BEGINNING_DATE,
        label: t('start_date'),
        active: true,
        widgetProps: {
          format: defaultDateValueFormat,
          disabledDate: (current) => {
            return current && current < moment().endOf('day');
          },
        },
      },
      {
        widget: WidgetType.DATE_PICKER,
        key: FormKey.EXPIRATION_DATE,
        label: t('end_date'),
        active: true,
        widgetProps: {
          format: defaultDateValueFormat,
          disabledDate: (current) => {
            return current && current < moment().endOf('day');
          },
        },
      },
      {
        widget: WidgetType.SELECT,
        key: FormKey.IS_ACTIVE,
        label: t('status'),
        active: true,
        options: getActivePassiveBooleanOptions({ t }),
        placeholder: t('is.active.choose'),
      },
    ],
  };
};

export const getMappedProductOffers = ({ collectionItems, collectionProducts, productOffers }) => {
  const mappedProductOffers = productOffers?.map((item) => {
    const collectionItem = collectionItems?.find?.(
      (collectionItem) => collectionItem?.offer === item?.id
    );
    const product = collectionProducts?.find?.((product) => product?.id === item?.product);
    if (!product) return false;

    return {
      ...item,
      product,
      collectionItem,
    };
  });
  return mappedProductOffers;
};

export const getProductListColumns = ({
  t,
  addProductToCollectionAsync,
  collectionId,
  isMobile,
}) => [
  {
    dataIndex: ['product', 'name'],
    title: t('product_name'),
    className: 'col-type-product-name',
    render: (name) => <Tooltip title={name}>{name}</Tooltip>,
  },
  {
    dataIndex: 'sku',
    title: t('sku'),
    render: (sku) => <CopyButton text={sku} label={sku} isColumn />,
  },
  {
    dataIndex: ['attributes', 'SELLER_BASECODE'],
    title: 'Base Code',
    className: 'uppercase',
    render: (baseCode) => <CopyButton text={baseCode} label={baseCode} isColumn />,
  },
  {
    dataIndex: 'add_button',
    title: ' ',
    fixed: isMobile ? false : 'right',
    render: (_, row) => {
      return (
        <div
          onClick={(event) => {
            event.stopPropagation();
            addProductToCollectionAsync({
              requestBody: {
                offer: row.id,
                collection: collectionId,
              },
            }).finally(() => {
              queryClient.invalidateQueries([
                QueryKey.COLLECTION_ITEMS,
                { collection: collectionId },
              ]);
              queryClient.invalidateQueries([QueryKey.PRODUCT_OFFERS]);
            });
          }}
          className="bulk-action-button-container"
        >
          <i className="icon-plus" />
        </div>
      );
    },
  },
];

export const getCollectionProductsColumns = ({ t, deleteProductFromCollectionAsync, isMobile }) => [
  {
    dataIndex: ['product', 'name'],
    title: t('product_name'),
  },
  {
    dataIndex: ['product', 'sku'],
    title: t('sku'),
    render: (sku) => <CopyButton text={sku} label={sku} isColumn />,
  },
  {
    dataIndex: ['product', 'base_code'],
    title: t('basecode'),
    render: (baseCode) => <CopyButton text={baseCode} label={baseCode} isColumn />,
  },
  {
    dataIndex: ['collectionItem', 'id'],
    title: ' ',
    fixed: isMobile ? false : 'right',
    render: (collectionItemId) => {
      return (
        <div
          onClick={(event) => {
            event.stopPropagation();

            deleteProductFromCollectionAsync({
              id: collectionItemId,
            }).finally(() => {
              queryClient.invalidateQueries([QueryKey.PRODUCT_OFFERS]);
            });
          }}
          className="bulk-action-button-container"
        >
          <i className="icon-minus" />
        </div>
      );
    },
  },
];

export const getTableActions = ({ t, onShowAddSelectedItemsModal }) => {
  return [
    {
      label: t('Marketing:bulk_add_selected_items'),
      actionHandler: onShowAddSelectedItemsModal,
      isSelectionRequired: true,
    },
  ];
};

export const getCollectionProductsTableActions = ({ t, onShowDeleteItemsModal }) => {
  return [
    {
      label: t('Marketing:bulk_delete_selected_items'),
      actionHandler: onShowDeleteItemsModal,
      isSelectionRequired: true,
    },
  ];
};

export const StaticFilterKey = {
  BASE_CODE: 'product__base_code__iexact',
  SKU: 'product__sku__iexact',
  PRODUCT_NAME: 'product__name__icontains',
};
