import { getSellerPerformanceMetricsUrl } from '@constants/apiUrls';
import { limitQuery } from '@constants/commontypes';
import { QueryKey } from '@constants/query';
import { useAkinonQuery } from '@services/api/adapters/useAkinonQuery';
import { client } from '@services/api/client';

export const useSellerPerformanceMetricsQuery = ({ queryOptions, params, onSuccess } = {}) => {
  const { data, isFetching, error, refetch } = useAkinonQuery({
    queryKey: [QueryKey.SELLER_PERFORMANCE_METRICS, params],
    async queryFn() {
      const response = await client.get(getSellerPerformanceMetricsUrl, {
        params: {
          ...limitQuery,
          ...params,
        },
      });
      onSuccess?.(response);
      return response;
    },
    ...queryOptions,
  });
  return {
    sellerPerformanceMetrics: data?.results || [],
    isFetchingSellerPerformanceMetrics: isFetching,
    sellerPerformanceMetricsError: error,
    refetchSellerPerformanceMetrics: refetch,
  };
};
