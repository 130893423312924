import { createSelectOptions } from '@common/index';
import AkinonButton from '@components/AkinonButton';
import AkinonSelect from '@components/AkinonSelect';
import { SortableTree } from '@components/SortableTree';
import { FolderTreeItemWrapper } from '@components/SortableTree/FolderTreeItemWrapper';
import IconLogicOperator from '@components/SortableTree/IconLogicOperator';
import { buildTree, findItemDeep, flattenTree } from '@components/SortableTree/utilities';
import {
  IconAlertTriangle,
  IconArrowDown,
  IconArrowsVertical,
  IconArrowUp,
  IconCaretDownFilled,
  IconCaretRightFilled,
  IconCircleMinus,
  IconCirclePlus,
  IconPencilMinus,
} from '@tabler/icons-react';
import { Tooltip, Typography } from 'antd';
import clsx from 'clsx';
import find from 'lodash/find';
import first from 'lodash/first';
import get from 'lodash/get';
import initial from 'lodash/initial';
import map from 'lodash/map';
import { forwardRef, memo, useState } from 'react';
import { useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { compatibleCrypto, getErrorPaths, RuleType } from './common';
import NewConditionalRuleFormModal from './NewConditionalRuleFormModal';

const { Text } = Typography;
/**
 * @param {{ rules:any, form: import('react-hook-form').UseFormReturn}} props
 */
function Rules({ rules: _rules, form, name, readOnly, label }) {
  const { t } = useTranslation('Settings');
  const logicalRules = get(_rules, RuleType.LOGICAL);

  const [ruleDetail, setRuleDetail] = useState(null);
  const [formStateErrors, setFormStateErrors] = useState({});

  const { control, watch, trigger, setValue, getFieldState } = form;

  const { fields, append, update, remove, swap } = useFieldArray({
    control,
    name,
    keyName: '_id',
  });

  const rules = watch(name, []);
  const rulesTree = buildTree(rules);

  const handleAppend = async ({ data }) => {
    append({
      canHaveChildren: (_, parent) => {
        const parentChildCount = parent?.children?.length;
        return parentChildCount < parent.maxLength;
      },
      ...data,
      id: compatibleCrypto.randomUUID(),
    });
    await trigger(name);
    const errors = getFieldState(name).error;
    setFormStateErrors(errors);
  };

  const handleUpdate = async ({ index, data }) => {
    update(index, data);
    await trigger(name);
    const errors = getFieldState(name).error;
    setFormStateErrors(errors);
  };

  const findChildrenIndexes = (field, indexes = []) => {
    indexes.push(fields.findIndex((_field) => _field.id === field.id));
    fields.forEach((_field) => {
      if (_field.parentId === field.id) {
        findChildrenIndexes(_field, indexes);
      }
    });
    return indexes;
  };

  const handleMove = async ({ field, action }) => {
    let move = 0;
    if (action === 'up') move = -1;
    else if (action === 'down') move = 1;
    const item = findItemDeep(rulesTree, field.id);
    let nextItem = null;
    if (!item?.parentId) {
      const itemIndex = rulesTree.findIndex((rule) => rule.id === item.id);
      nextItem = rulesTree[itemIndex + move];
    } else {
      const parent = findItemDeep(rulesTree, item?.parentId);
      const itemIndex = parent?.children.findIndex((child) => child.id === item.id);
      nextItem = parent.children[itemIndex + move];
    }
    if (nextItem) {
      const fromIndex = fields.findIndex((field) => field.id === item.id);
      const nextIndex = fields.findIndex((field) => field.id === nextItem.id);
      swap(fromIndex, nextIndex);
      await trigger(name);
      const errors = getFieldState(name).error;
      setFormStateErrors(errors);
    }
  };

  const handleRemove = async ({ field }) => {
    remove(findChildrenIndexes(field));
    await trigger(name);
    const errors = getFieldState(name).error;
    setFormStateErrors(errors);
  };

  const errorPaths = getErrorPaths(formStateErrors);

  return (
    <>
      <NewConditionalRuleFormModal
        rules={_rules}
        handleAppend={handleAppend}
        handleUpdate={handleUpdate}
        ruleDetail={ruleDetail}
        onCancel={() => {
          setRuleDetail(null);
        }}
      />
      <div className="flex flex-col gap-2 items-start justify-start overflow-auto">
        {label && <Text className="text-white font-bold">{label}</Text>}
        <div className="flex gap-1">
          <AkinonButton
            type="secondary"
            icon={<i className="icon-arti" />}
            onClick={() => {
              setRuleDetail({});
            }}
            disabled={readOnly}
          >
            {t('add_new_conditional_rule')}
          </AkinonButton>

          <AkinonButton
            className="!bg-blue-jeans"
            icon={<i className="icon-arti" />}
            disabled={readOnly}
            onClick={() => {
              const firstRule = first(logicalRules);
              const slug = firstRule?.slug;
              const childParams = firstRule?.params?.child ?? firstRule?.params.children;

              handleAppend({
                data: {
                  slug,
                  type: RuleType.LOGICAL,
                  minLength: childParams?.min_length,
                  maxLength: childParams?.max_length,
                },
              });
            }}
          >
            {t('add_new_logical_rule')}
          </AkinonButton>
        </div>
        <div
          className={clsx('overflow-auto my-2 rounded-md', {
            'border-ebony-clay border w-full py-3 px-2 bg-[#313954]': fields.length > 0,
          })}
        >
          <SortableTree
            items={rulesTree}
            disableSorting={readOnly}
            onItemsChanged={async (_items) => {
              setValue(name, flattenTree(_items));
              await trigger(name);
              const errors = getFieldState(name).error;
              setFormStateErrors(errors);
            }}
            TreeItemComponent={forwardRef((props, ref) => {
              const errorFields = map(errorPaths, (path) => ({
                ...get(rulesTree, initial(path?.split('.'))),
                error: get(formStateErrors, path),
              }));
              const _fieldIndex = fields.findIndex((field) => field.id === props.item.id);
              const currentField = fields[_fieldIndex];
              const error = get(find(errorFields, { id: currentField?.id }), 'error');
              const fieldChildren = fields.filter((field) => field.parentId === currentField?.id);
              const params = find(logicalRules, { slug: currentField?.slug })?.params;
              const rootCanHaveChildren =
                currentField?.type === RuleType.LOGICAL
                  ? (fieldChildren?.length ?? 0) <
                    (params?.children?.max_length ?? params?.child?.max_length ?? Infinity)
                  : false;

              return (
                <FolderTreeItemWrapper {...props} ref={ref} contentClassName="w-min text-white">
                  <div className="flex items-center">
                    <div
                      className={clsx(
                        'flex items-center min-w-72 gap-4  border-2 border-ebony-clay rounded-lg px-4 py-2 hover:join-[bg-info,border-dashed,border-white]',
                        {
                          '!border-color-error': error,
                        }
                      )}
                    >
                      <div className="flex items-center gap-0.5 flex-wrap">
                        {props.onCollapse &&
                          (props.item.collapsed ? (
                            <IconCaretRightFilled
                              color="white"
                              className="!cursor-pointer outline-none"
                              onClick={props.onCollapse}
                            />
                          ) : (
                            <IconCaretDownFilled
                              color="white"
                              className="!cursor-pointer outline-none"
                              onClick={props.onCollapse}
                            />
                          ))}
                        {!readOnly && (
                          <IconArrowsVertical
                            className="!text-white outline-none cursor-move"
                            color="white"
                            size={18}
                            {...props.handleProps}
                          />
                        )}
                      </div>
                      {props.item.type === RuleType.LOGICAL &&
                        (readOnly ? (
                          currentField.title
                        ) : (
                          <AkinonSelect
                            value={currentField?.slug}
                            onChange={async (slug) => {
                              const selectedSlug = find(logicalRules, { slug });
                              const childParams =
                                selectedSlug?.params?.child ?? selectedSlug?.params.children;
                              update(_fieldIndex, {
                                ...currentField,
                                slug,
                                maxLength: childParams?.max_length,
                                minLength: childParams?.min_length,
                              });
                              await trigger(name);
                              const errors = getFieldState(name).error;
                              setFormStateErrors(errors);
                            }}
                            options={createSelectOptions(logicalRules, {
                              labelKey: 'name',
                              valueKey: 'slug',
                            })}
                            placeholder="Select Rule"
                          />
                        ))}
                      {props.item.type === RuleType.CONDITIONAL && <span>{props.item.title}</span>}

                      {error && (
                        <Tooltip title={error.message}>
                          <IconAlertTriangle className="text-color-error -ml-2" />
                        </Tooltip>
                      )}
                      <div
                        className={clsx(
                          'flex min-w-12  gap-2 rounded-xl bg-white text-black p-1 ml-auto -mr-12',
                          {
                            hidden: readOnly,
                          }
                        )}
                      >
                        <IconArrowUp
                          className="cursor-pointer"
                          onClick={(event) => {
                            event.stopPropagation();
                            handleMove({ field: currentField, action: 'up' });
                          }}
                        />
                        <IconArrowDown
                          className="cursor-pointer"
                          onClick={(event) => {
                            event.stopPropagation();
                            handleMove({ field: currentField, action: 'down' });
                          }}
                        />
                        {props.item.type === RuleType.CONDITIONAL && (
                          <IconPencilMinus
                            className="cursor-pointer"
                            onClick={(event) => {
                              event.stopPropagation();
                              setRuleDetail({
                                ...currentField,
                                index: _fieldIndex,
                              });
                            }}
                          />
                        )}
                      </div>
                    </div>
                    <div
                      className={clsx('flex items-center ml-12 gap-1', {
                        hidden: readOnly,
                      })}
                    >
                      <IconCircleMinus
                        className="text-xl cursor-pointer text-wild-watermelon"
                        onClick={(event) => {
                          event.stopPropagation();
                          handleRemove({ field: currentField });
                        }}
                      />
                      {rootCanHaveChildren && (
                        <>
                          <IconCirclePlus
                            className="text-xl cursor-pointer text-lima"
                            onClick={(event) => {
                              event.stopPropagation();
                              setRuleDetail({
                                parentId: currentField.id,
                              });
                            }}
                          />
                          <IconLogicOperator
                            onClick={(event) => {
                              event.stopPropagation();
                              const firstRule = first(logicalRules);
                              const slug = firstRule?.slug;
                              const childParams =
                                firstRule?.params?.child ?? firstRule?.params.children;
                              handleAppend({
                                data: {
                                  slug,
                                  type: RuleType.LOGICAL,
                                  parentId: currentField.id,
                                  minLength: childParams?.min_length,
                                  maxLength: childParams?.max_length,
                                },
                              });
                            }}
                            className="cursor-pointer"
                          />
                        </>
                      )}
                    </div>
                  </div>
                </FolderTreeItemWrapper>
              );
            })}
          />
        </div>
      </div>
    </>
  );
}

Rules.propTypes = {};

export default memo(Rules);
