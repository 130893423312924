import { postCollectionsURL } from '@constants/apiUrls';

import { useAkinonMutation } from '../adapters/useAkinonMutation';
import { client } from '../client';

export function useCreateCollectionMutation({ mutationOptions } = {}) {
  const { mutate, isLoading } = useAkinonMutation({
    mutationFn: ({ requestBody, requestConfig }) =>
      client.post(postCollectionsURL, requestBody, requestConfig),
    ...mutationOptions,
  });

  return {
    createCollection: mutate,
    isCreatingCollection: isLoading,
  };
}
