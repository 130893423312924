import AkinonFilter from '@components/AkinonFilter';
import { useFilterParams } from '@components/AkinonFilter/hooks/store/usePageFilters';
import AkinonTable from '@components/AkinonTable';
import usePagination from '@components/AkinonTable/hooks/usePagination';
import { getReconciliationsURL } from '@constants/apiUrls';
import { UserRole } from '@constants/auth';
import { defaultRowKey } from '@constants/index';
import { RouteUrls } from '@constants/routeUrls';
import useAppNavigate from '@hooks/useAppNavigate';
import { useReconciliations } from '@services/hooks/finance/useReconciliations';
import { useUserRole } from '@utils/hooks/useUserRole';
import { Divider } from 'antd';
import { useTranslation } from 'react-i18next';

import { getColumns } from './common';
import { useBreadcrumbs } from './hooks/useBreadcrumbs';
import useFilterFields from './hooks/useFilterFields';
import { usePageMeta } from './hooks/usePageMeta';

const ReconciliationListing = () => {
  const { t } = useTranslation('Finance');
  const [pagination, setPagination] = usePagination();
  const userRole = useUserRole();
  const navigate = useAppNavigate();
  const isSuperUser = userRole === UserRole.SUPER_USER;

  const filterFields = useFilterFields({
    userRole,
  });
  const filters = useFilterParams({ filterFields });

  const { reconciliations, isFetching, total } = useReconciliations({ pagination, filters });

  useBreadcrumbs();
  usePageMeta();

  const columns = getColumns({ t, isSuperUser });

  const onRow = (record) => ({
    onClick() {
      navigate(RouteUrls.finance.reconciliations.detail, {
        reconciliationId: record.pk,
      });
    },
  });

  return (
    <section>
      <AkinonFilter title={t('filters')} filterFields={filterFields} total={total} />
      <Divider />
      <AkinonTable
        title={t('reconciliations')}
        columns={columns}
        dataSource={reconciliations}
        optionsUrl={getReconciliationsURL}
        loading={isFetching}
        total={total}
        pagination={pagination}
        rowKey={defaultRowKey}
        setPagination={setPagination}
        description={`${total} ${t('results.found')}`}
        onRow={onRow}
      />
    </section>
  );
};

export default ReconciliationListing;
