import './index.scss';

import { MinusCircleOutlined } from '@ant-design/icons';
import { Input, Select, Space } from 'antd';
import { forwardRef, memo, useCallback } from 'react';
import { useWatch } from 'react-hook-form';

const { Option } = Select;

const VariantSelect = forwardRef(
  ({ disabled, attributeOptions, onChange, variantsForm, _key }, ref) => {
    const { control } = variantsForm;

    const selectedVariants = useWatch({
      control,
      name: _key,
      defaultValue: [],
    });

    const handleChange = useCallback(
      (value) => {
        if (selectedVariants?.includes(value)) {
          return;
        }
        const newSelectedVariants = [...selectedVariants, value];
        onChange?.(newSelectedVariants);
      },
      [selectedVariants, onChange]
    );

    const handleRemove = useCallback(
      (value) => {
        const newSelectedVariants = selectedVariants.filter((variant) => variant !== value);
        onChange?.(newSelectedVariants);
      },
      [selectedVariants, onChange]
    );

    return (
      <Space direction="vertical" size="large" className="variant-select">
        <Select
          value={null}
          ref={ref}
          onChange={handleChange}
          className="variant-select__select"
          loading={attributeOptions?.length === 0}
          showSearch
          disabled={disabled}
          optionFilterProp="children"
        >
          {attributeOptions?.map((option) => (
            <Option key={option.value} value={option.value}>
              {option.label}
            </Option>
          ))}
        </Select>
        {selectedVariants?.map((variant) => (
          <div key={variant} className="variant-select__selected-variant">
            <Input value={attributeOptions.find((option) => option.value === variant).label} />
            {!disabled && (
              <MinusCircleOutlined
                disabled={disabled}
                className="variant-select__remove-icon"
                onClick={() => handleRemove(variant)}
              />
            )}
          </div>
        ))}
      </Space>
    );
  }
);

export default memo(VariantSelect);
