import './style.scss';

import { createSelectOptions } from '@common/index';
import AkinonBox from '@components/AkinonBox';
import AkinonButton from '@components/AkinonButton';
import AkinonForm from '@components/AkinonForm';
import AkinonInput from '@components/AkinonInput';
import AkinonSelect from '@components/AkinonSelect';
import { getDataSourcePermissionsUrl } from '@constants/apiUrls';
import { getDatasourcePermissionLevelOptions } from '@constants/commontypes';
import { RouteUrls } from '@constants/routeUrls';
import { Color } from '@constants/theme';
import { zodResolver } from '@hookform/resolvers/zod';
import { useUser } from '@root/contexts/hooks/useUser';
import useAppNavigate from '@root/hooks/useAppNavigate';
import { useOptionsQuery } from '@services/api/hooks/useOptionsQuery';
import { useDataSourceCreateUser } from '@services/hooks/datasources/useDataSourceCreateUser';
import get from 'lodash/get';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormKey, getFormSchema } from './common';

const SellerCreateUser = () => {
  const { t } = useTranslation('Settings');
  const navigate = useAppNavigate();
  const user = useUser();
  const dataSourceId = user?.datasource;
  const { options: userOptions } = useOptionsQuery({
    url: getDataSourcePermissionsUrl,
  });
  const permissionLevelChoises = get(
    userOptions,
    'actions.POST.datasource_permission_level.choices',
    getDatasourcePermissionLevelOptions({ t })
  );

  const schema = getFormSchema({ permissionLevelChoises });
  const { handleSubmit, control } = useForm({
    defaultValues: {},
    resolver: zodResolver(schema),
  });

  const { mutate: createUser, isLoading } = useDataSourceCreateUser({
    mutationOptions: {
      onSuccess: () => navigate(RouteUrls.settings.sellerUserManagementSettings),
    },
  });

  const onSubmit = (values) => {
    const requestConfig = {
      successMessage: t('transaction_success'),
      successDescription: t('seller_detail_message.save.success'),
      errorMessage: t('transaction_failed'),
      errorDescription: t('seller_detail_message.save.error'),
      urlParams: { dataSourceId },
    };
    createUser({ params: values, requestConfig });
  };

  return (
    <section className="seller-create-user-form">
      <AkinonBox
        className="box-primary form-box"
        bannerColor={Color.WILD_WATERMELON}
        title={t('add_user')}
        description={t('add_user_desc')}
      >
        <AkinonForm className="akn-form akn-form-v2" layout="vertical">
          <AkinonInput
            formItemProps={{
              label: t('email'),
              name: FormKey.EMAIL,
              control,
              required: true,
            }}
            placeholder={t('enter_variable', { variable: t('email') })}
          />
          <AkinonInput
            formItemProps={{
              label: t('first_name'),
              name: FormKey.FIRST_NAME,
              control,
              required: true,
            }}
            placeholder={t('enter_variable', { variable: t('first_name') })}
          />
          <AkinonInput
            formItemProps={{
              label: t('last_name'),
              name: FormKey.LAST_NAME,
              control,
              required: true,
            }}
            placeholder={t('enter_variable', { variable: t('last_name') })}
          />
          <AkinonSelect
            formItemProps={{
              control,
              label: t('datasource_permission_level'),
              name: FormKey.DATASOURCE_PERMISSION_LEVEL,
            }}
            options={createSelectOptions(permissionLevelChoises, {
              valueKey: 'value',
              labelKey: 'display_name',
            })}
            placeholder={t('datasource_permission_level')}
          />
          <AkinonButton
            htmlType="submit"
            type="primary"
            className="akn-submit-button"
            loading={isLoading}
            onClick={handleSubmit(onSubmit)}
          >
            {t('save')}
          </AkinonButton>
        </AkinonForm>
      </AkinonBox>
    </section>
  );
};

export default SellerCreateUser;
