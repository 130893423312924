import './style.scss';

import AkinonFilter from '@components/AkinonFilter';
import { useFilterParams } from '@components/AkinonFilter/hooks/store/usePageFilters';
import { openNotification } from '@components/akinonNotification';
import AkinonTable from '@components/AkinonTable';
import usePagination from '@components/AkinonTable/hooks/usePagination';
import { getProductRevisionsUrl } from '@constants/apiUrls';
import { defaultRowKey } from '@constants/index';
import useAppNavigate from '@hooks/useAppNavigate';
import { useAttributesQuery } from '@services/api/hooks/useAttributesQuery';
import { usePostApproveProductRevisionMutation } from '@services/api/hooks/usePostApproveProductRevisionMutation';
import { usePostRejectProductRevisionMutation } from '@services/api/hooks/usePostRejectProductRevisionMutation';
import { useUserRole } from '@utils/hooks/useUserRole';
import { Divider } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useImmer } from 'use-immer';

import { getColumns } from './common';
import RevisionActionConfirmationModal from './components/RevisionActionConfirmationModal';
import RevisionActionRejectModal from './components/RevisionActionRejectModal';
import RevisionDetailModal from './components/RevisionDetailModal';
import { useBreadcrumbs } from './hooks/useBreadcrumbs';
import useDataSource from './hooks/useDataSource';
import useFilterFields from './hooks/useFilterFields';
import { usePageMeta } from './hooks/usePageMeta';

const ProductRevisionsLanding = () => {
  const { t } = useTranslation('ProductsAndCategories');
  const navigate = useAppNavigate();
  const [pagination, setPagination] = usePagination();
  const userRole = useUserRole();
  const [selectedRevision, setSelectedRevision] = useState();
  const [isApproveModalVisible, setIsApproveModalVisible] = useState(false);
  const [isRejectModalVisible, setIsRejectModalVisible] = useState(false);
  const [isRevisionDetailModalVisible, setIsRevisionDetailModalVisible] = useState(false);
  const [revisionDetailModalInfo, setRevisionDetailModalInfo] = useImmer({
    hasLongItems: false,
    record: {},
    currentAttributes: [],
    requestedAttributes: [],
  });

  const { attributes, isAttributesLoading } = useAttributesQuery();

  const filterFields = useFilterFields({
    t,
    attributes,
    isAttributesLoading,
  });

  const filters = useFilterParams({
    filterFields,
  });

  const { dataSource, totalProductRevisions, isFetchingProductRevisions, refetchProductRevisions } =
    useDataSource({
      pagination,
      filters,
    });

  const { approveProductRevision } = usePostApproveProductRevisionMutation({
    mutationOptions: {
      onSuccess: () => {
        refetchProductRevisions();
        openNotification({
          type: 'success',
          message: t('transaction_success'),
          description: t('revision_approved_successfully'),
        });
      },
      onError: () => {
        openNotification({
          type: 'error',
          message: t('transaction_failed'),
          description: t('failed_to_approve_revision'),
        });
      },
    },
  });
  const { rejectProductRevision } = usePostRejectProductRevisionMutation({
    mutationOptions: {
      onSuccess: () => {
        refetchProductRevisions();
        openNotification({
          type: 'success',
          message: t('transaction_success'),
          description: t('revision_rejected_successfully'),
        });
      },
      onError: () => {
        openNotification({
          type: 'error',
          message: t('transaction_failed'),
          description: t('failed_to_reject_revision'),
        });
      },
    },
  });

  useBreadcrumbs();
  usePageMeta();

  const columns = getColumns({
    t,
    userRole,
    attributes,
    setIsApproveModalVisible,
    setIsRejectModalVisible,
    setSelectedRevision,
    setIsRevisionDetailModalVisible,
    setRevisionDetailModalInfo,
    navigate,
  });

  return (
    <section className="product-revisions">
      <AkinonFilter
        title={t('filters')}
        filterFields={filterFields}
        total={totalProductRevisions}
      />
      <Divider />
      <RevisionActionConfirmationModal
        isVisible={isApproveModalVisible}
        title={t('approve_revision')}
        description={t('are_you_sure_to_approve_revision')}
        onCancel={() => setIsApproveModalVisible(false)}
        onOk={() => {
          approveProductRevision({ productRevisionId: selectedRevision });
        }}
      />
      <RevisionActionRejectModal
        modalProps={{
          open: isRejectModalVisible,
          title: t('reject_revision'),
          onCancel: () => setIsRejectModalVisible(false),
          onOk: (values) => {
            rejectProductRevision({ productRevisionId: selectedRevision, requestBody: values });
          },
        }}
      />

      <RevisionDetailModal
        attributes={attributes}
        revisionDetailModalInfo={revisionDetailModalInfo}
        isVisible={isRevisionDetailModalVisible}
        title={t('revision_detail')}
        onCancel={() => setIsRevisionDetailModalVisible(false)}
      />
      <AkinonTable
        title={t('product_revisions')}
        columns={columns}
        dataSource={dataSource}
        optionsUrl={getProductRevisionsUrl}
        loading={isFetchingProductRevisions}
        total={totalProductRevisions}
        pagination={pagination}
        rowKey={defaultRowKey}
        setPagination={setPagination}
        description={`${totalProductRevisions} ${t('results.found')}`}
      />
    </section>
  );
};

export default ProductRevisionsLanding;
