import { putCollectionsURL } from '@constants/apiUrls';

import { useAkinonMutation } from '../adapters/useAkinonMutation';
import { client } from '../client';

export function usePutCollectionMutation({ mutationOptions } = {}) {
  const { mutate, isLoading } = useAkinonMutation({
    mutationFn: ({ collectionId, requestBody, requestConfig }) => {
      return client.put(putCollectionsURL, requestBody, {
        urlParams: { collectionId },
        ...requestConfig,
      });
    },
    ...mutationOptions,
  });

  return {
    putCollection: mutate,
    isPuttingCollection: isLoading,
  };
}
