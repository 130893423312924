import { QueryKey } from '@constants/query';
import { queryClient } from '@services/api/queryClient';
import last from 'lodash/last';

const useChangeMessagePrivacyStatus = ({ t, createConversationStatusAsync }) => {
  const changeMessageStatus = ({ isPublic, selectedRows, mutationOptions }) => {
    const { onSuccess, onError } = mutationOptions;
    const requestConfig = {
      successMessage: t('transaction_success'),
      successDescription: t('order_list_message.success'),
      errorMessage: t('transaction_failed'),
      errorDescription: t('order_list_message.error'),
    };

    const promises = selectedRows.map((row) => {
      return () =>
        createConversationStatusAsync({
          conversationId: row.id,
          requestBody: {
            is_public: isPublic,
          },
          requestConfig:
            last(selectedRows) === row
              ? requestConfig
              : {
                  suppressedNotifications: ['success', 'error'],
                },
        })
          .then(() => {
            onSuccess();
          })
          .catch(() => {
            onError();
          });
    });

    Promise.allSettled(promises.map((f) => f())).then(() => {
      queryClient.invalidateQueries([QueryKey.CONVERSATIONS]);
    });
  };

  const onMakePublic = ({ selectedRows, mutationOptions }) => {
    changeMessageStatus({ isPublic: true, selectedRows, mutationOptions });
  };
  const onMakePrivate = ({ selectedRows, mutationOptions }) => {
    changeMessageStatus({ isPublic: false, selectedRows, mutationOptions });
  };
  return { onMakePublic, onMakePrivate };
};

export default useChangeMessagePrivacyStatus;
