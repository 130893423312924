import { packagesRecreateURL } from '@constants/apiUrls';
import { postPackageReCreate } from '@services/api';
import { useAkinonMutation } from '@services/api/adapters/useAkinonMutation';

export function usePackageReCreateMutation({ onSuccess, onError }) {
  const mutationKey = ['package-recreate'];
  const mutationFn = ({ requestConfig, ...params }) =>
    postPackageReCreate(packagesRecreateURL, params, requestConfig);

  const { mutate, ...rest } = useAkinonMutation({
    mutationKey,
    mutationFn,
    onSuccess,
    onError,
  });

  return {
    ...rest,
    mutate,
  };
}
